import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import {
	Box,
	Button,
	IconButton,
	Grid,
	TextField,
} from '@material-ui/core';
import { useMutation } from 'react-apollo';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import { useUi } from '../../../../../../hoc';

const UPDATE_PROJECT_BRANCHES = gql`
	mutation UpdateProject($projectId: String!, $data: UpdateProjectInput!) {
		updateProject(projectId: $projectId, data: $data) {
			_id
			branches
			createdAt
			updatedAt
		}
	}
`;

const Branches = ({
	projectId,
	data,
	refetch,
}) => {
	const [projectBranches, setProjectBranches] = useState([]);
	const [textFieldValue, setTextFieldValue] = useState({
		type: '',
		value: '',
		index: '',
	})
	const [branchesErrors, setBranchesErrors] = useState(false);
	const ui = useUi();
	const snackbar = useSnackbar();
	const [updateProjectBranches, {loading: updateBranchesLoading}] = useMutation(
		UPDATE_PROJECT_BRANCHES,
		{
			onCompleted: () => {
				ui.setAppProgressShown(false);
				snackbar.enqueueSnackbar('Project branches updated successfully', {
					variant: 'success',
					autoHideDuration: 4000,
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
				});
				refetch(); // refetch whole project
			},
			onError: error => {
				console.error(error);
				ui.setAppProgressShown(false);
				snackbar.enqueueSnackbar(
					'Error occurred while updating the project...',
					{
						variant: 'error',
						autoHideDuration: 4000,
						anchorOrigin: {vertical: 'top', horizontal: 'center'},
					},
				);
			},
		},
	);
	
	const addBranch = () => {
		setProjectBranches(old => {
			return [
				...old,
				{
					name: '',
					url: '',
					nameError: '',
					urlError: ''
				}
			]
		})
	}

	const deleteBranch = (index) => {
		setProjectBranches(old => {
			return old.filter((branch, i) => i !== index);
		})
	}

	// const getBranches = () => {

	// }

	const validateBranches = (branches) => {
		// console.log("Validating!")
		const tempBranches = branches ? branches : [...projectBranches];
		let hasErrors = false;
		
		for (let branch of tempBranches) {
			if (branch.name === "") {
				hasErrors = true;
				branch.nameError = "Required field!";
			} else {
				branch.nameError = "";
			}
			if (branch.url === "") {
				hasErrors = true;
				branch.urlError = "Required field!";
			} else {
				branch.urlError = "";
			}
		}
		setProjectBranches(tempBranches);
		setBranchesErrors(hasErrors);
		return hasErrors;
	}

	const saveProjectBranches = () => {
		const hasErrors = validateBranches();
		if (hasErrors) return;

		ui.setAppProgressShown(true);
		updateProjectBranches({
			variables: {
				projectId,
				data: {
					branches: projectBranches,
				},
			},
		});
	}

	useEffect(() => {
		// console.log(data);
		setProjectBranches(data?.getProject?.branches);
	}, [data]);

	useEffect(() => {
		let oldBranches = [...projectBranches];
		if (textFieldValue.type === 'name') {
			oldBranches[textFieldValue.index].name = textFieldValue.value;
		} else if (textFieldValue.type === 'url') {
			oldBranches[textFieldValue.index].url = textFieldValue.value;
		}

		validateBranches(oldBranches);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [textFieldValue])

	// function showError(errorMessage) {
	// 	enqueueSnackbar(errorMessage, {
	// 		variant: 'error',
	// 		autoHideDuration: 4000,
	// 		anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
	// 	});
	// }

	// function showSuccess(successMessage) {
	// 	enqueueSnackbar(successMessage, {
	// 		variant: 'success',
	// 		autoHideDuration: 4000,
	// 		anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
	// 	});
	// }

	const disableSaveBranches = updateBranchesLoading || branchesErrors;

	return (
		<Grid item xs={12} md={10}>
			{
				projectBranches?.map((branch, index) => {
					return (
						<Box key={`branch${index}`}
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="center"
							sx={{ mt: index!==0? 2 : 0 }}
						>
							<Box 
								flex="11"
								display="flex"
							>
								<Box flex="3" sx={{ mr: 2}}>
									<TextField
										title={branch.name}
										variant="outlined"
										error={branch.nameError ? true : false}
										helperText={branch.nameError !== "" ? branch.nameError : " "}
										label={"Description"}
										value={branch.name}
										onChange={(e) => {
											setTextFieldValue({
												type: 'name',
												value: e.target.value,
												index: index
											});
										}}
									/>
								</Box>
								<Box flex="8">
									<TextField
										title={branch.url}
										fullWidth
										variant="outlined"
										error={branch.urlError ? true : false}
										helperText={branch.urlError !== "" ? branch.urlError : " "}
										label='Github URL'
										value={branch.url}
										onChange={(e) => {
											setTextFieldValue({
												type: 'url',
												value: e.target.value,
												index: index
											});
										}}
									/>
								</Box>
							</Box>
							<Box flex="1" sx={{ mb: 3 }}>
								<IconButton onClick={() => deleteBranch(index)}>
									<DeleteIcon />
								</IconButton>
							</Box>
						</Box>
					)
				})
			}
			
			<Box sx={{mt: "20px"}} width="100px">
				<Button
					fullWidth
					variant='contained'
					onClick={() => {addBranch()}}
				>
					<AddIcon />
				</Button>
			</Box>
			<Box sx={{mt: '20px'}} width="160px">
				<Button
					fullWidth
					variant='contained'
					color='primary'
					disabled={disableSaveBranches}
					onClick={() => {saveProjectBranches()}}
				>
					Save Branches
				</Button>
			</Box>
		</Grid>
	);
};

export default Branches;
