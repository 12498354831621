import gql from 'graphql-tag';

export default gql`
	mutation DeleteProject($projectId: String!) {
		deleteProject(projectId: $projectId) {
			_id
			createdAt
			updatedAt
			name
			description
			swagger
			tags
			users {
				_id
			}
			stories {
				_id
			}
			requirements {
				_id
			}
		}
	}
`;
