// Basically it's the same with getProject but we return swagger as well
import gql from 'graphql-tag';

export default gql`
	query GetProjectSwagger($projectId: String!) {
		getProject(projectId: $projectId) {
			_id
			swagger
		}
	}
`;
