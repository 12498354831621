import gql from 'graphql-tag';

export default gql`
	mutation AuthenticateUser($emailOrUsername: String!, $password: String!) {
		authenticateUser(emailOrUsername: $emailOrUsername, password: $password) {
			token
			user {
				_id
				email
				username
				role
				fullName
				projects {
					_id
					name
					description
				}
				createdAt
			}
		}
	}
`;
