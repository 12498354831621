import React from 'react';
import {
	Person as MaterialUserIcon,
	People as MaterialUsersIcon,
	PersonAdd as MaterialCreateUserIcon,
	GroupAdd as MaterialInvitedUsersIcon,
	Work as MaterialProjectIcon,
	AddBox as MaterialCreateProjectIcon,
	Label as MaterialProjectTagIcon,
	Dashboard as MaterialProjectDashboardIcon,
	Assignment as MaterialProjectRequirementIcon,
	ChromeReaderMode as MaterialProjectStoryIcon,
	Folder as MaterialProjectFileIcon,
	Code as MaterialProjectSwaggerIcon,
	Settings as MaterialProjectSettingsIcon,
	DeviceHub as MaterialCycloptTaskIcon,
	
} from '@material-ui/icons';

export const DRAWER_WIDTH_PX = 240;

export const UsersIcon = props => <MaterialUsersIcon {...props} />;
export const UserIcon = props => <MaterialUserIcon {...props} />;
export const InvitedUserIcon = props => <MaterialCreateUserIcon {...props} />;
export const InvitedUsersIcon = props => (
	<MaterialInvitedUsersIcon {...props} />
);
export const ProjectIcon = props => <MaterialProjectIcon {...props} />;
export const CreateProjectIcon = props => (
	<MaterialCreateProjectIcon {...props} />
);
export const ProjectTagIcon = props => <MaterialProjectTagIcon {...props} />;
export const ProjectDashboardIcon = props => (
	<MaterialProjectDashboardIcon {...props} />
);
export const ProjectRequirementIcon = props => (
	<MaterialProjectRequirementIcon {...props} />
);
export const ProjectStoryIcon = props => (
	<MaterialProjectStoryIcon {...props} />
);
export const ProjectFileIcon = props => <MaterialProjectFileIcon {...props} />;
export const ProjectSwaggerIcon = props => (
	<MaterialProjectSwaggerIcon {...props} />
);
export const ProjectSettingsIcon = props => (
	<MaterialProjectSettingsIcon {...props} />
);
export const CycloptTaskIcon = props => <MaterialCycloptTaskIcon {...props} />;

export const USER_ROLES = [
	{label: 'Admin', value: 'Admin', description: ''},
	{label: 'User', value: 'User', description: 'Can only be invited to projects'},
	{label: 'TeamLead', value: 'TeamLead', description: 'Can create one project'},
	{label: 'Researcher', value: 'Researcher', description: 'Can create many projects'}
];

export const PROJECT_REQUIREMENT_TYPES = [
	{label: 'Functional', value: 'Functional'},
	{label: 'Non Functional', value: 'NonFunctional'},
	{label: 'Other', value: 'Other'},
];

export const PROJECT_REQUIREMENT_RATINGS = [
	{
		label: 'Correct',
		value: 'Correct',
		style: {
			backgroundColor: '#00ba00',
			color: '#ffffff',
		},
	},
	{
		label: 'Minor Flaw',
		value: 'MinorFlaw',
		style: {
			backgroundColor: '#ff6c23',
			color: '#ffffff',
		},
	},
	{
		label: 'Major Flaw',
		value: 'MajorFlaw',
		style: {
			backgroundColor: '#FF0000',
			color: '#ffffff',
		},
	},
];

export const PROJECT_STORY_RATINGS = [
	{
		label: 'Correct',
		value: 'Correct',
		style: {
			backgroundColor: '#00ba00',
			color: '#ffffff',
		},
	},
	{
		label: 'Complete',
		value: 'Complete',
		style: {
			backgroundColor: '#0b5a01',
			color: '#ffffff',
		},
	},
	{
		label: 'Incomplete',
		value: 'Incomplete',
		style: {
			backgroundColor: '#9c9c9c',
			color: '#ffffff',
		},
	},
	{
		label: 'Minor Flaw',
		value: 'MinorFlaw',
		style: {
			backgroundColor: '#ff6c23',
			color: '#ffffff',
		},
	},
	{
		label: 'Major Flaw',
		value: 'MajorFlaw',
		style: {
			backgroundColor: '#FF0000',
			color: '#ffffff',
		},
	},
];
