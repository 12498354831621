import React, { useState } from 'react';
import {
	Typography,
	CircularProgress,
	DialogTitle,
	DialogContent,
	Dialog,
	DialogActions,
	Button
} from '@material-ui/core';

export default function RemoveCycloptIntegration({
    open,
    setOpen,
    projectId,
	showSuccess,
	showError,
	updateProjectIntegrations,
	refetchProjectIntegrations,
	setCycloptApiKey,
	getProjects,
}) {
    // const ui = useUi();
	// const snackbar = useSnackbar();

    const [loading, setLoading] = useState(false);

    // const [executeRemoveCycloptIntegration] = useLazyQuery(
	// 	REQUEST_TO_BECOME_TEAMLEAD,
	// 	{
	// 		onCompleted: data => {
    //             console.log(data);
    //             setLoading(false);
    //             ui.setAppProgressShown(false);
    //             if (data.requestToBecomeTeamLead === true) {
    //                 setOpen(false);
    //                 snackbar.enqueueSnackbar('An email was sent!', {
    //                     variant: 'success',
    //                     anchorOrigin: {vertical: 'top', horizontal: 'center'},
    //                 });
    //             } else {
    //                 console.log('something went wrong');
    //                 snackbar.enqueueSnackbar('Something went wrong!', {
    //                     variant: 'error',
    //                     anchorOrigin: {vertical: 'top', horizontal: 'center'},
    //                 });
    //             }
	// 		},
	// 	},
	// );  

    const onClose = () => {
        setOpen(false);
    };

    const onClickDelete = async () => {
        console.log('removing integration...');
		setLoading(true);
		try {

			await updateProjectIntegrations({
				variables: {
					projectId,
					data: {integrations: [{name: 'cyclopt', apiKey: '', projectId: '', links: []}]},
				},
			});

			refetchProjectIntegrations();
			setCycloptApiKey('');
			getProjects('');
			showSuccess('Cyclopt project updated!');
			setOpen(false);
			setLoading(false);
		} catch (error) {
			console.log(error);
			showError("Something went wrong!");
			setLoading(false);
		}
    }

    return (
        <Dialog
			open={open}
			maxWidth="sm"
			//disableBackdropClick={loading}
			disableEscapeKeyDown={loading}
			onClose={onClose}
		>
			<DialogTitle>{`Remove cyclopt integration?`}</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure you want to remove the cyclopt integration of this project? This will also delete
					all the tasks automatically created to the linked cyclopt project!
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button disabled={loading} onClick={() => onClose()}>
					Cancel
				</Button>
				<Button
					color="primary"
					disabled={loading}
					endIcon={
						loading ? (
							<CircularProgress disableShrink size={20} color="inherit" />
						) : null
					}
					onClick={() => onClickDelete()}
				>
					Remove Integration
				</Button>
			</DialogActions>
		</Dialog>
    );
}