import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {
	pipe,
	propOr,
	length,
	prop,
	propEq,
	map,
	includes,
	find,
	flatten,
	uniqBy,
	pick,
} from 'ramda';
import {
	Chip,
	Grid,
	Link,
	Typography,
	Box,
	Tab,
	Tabs,
	Button
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import {Link as RouterLink} from 'react-router-dom';
import {WithSkeleton} from '../../../../common/hoc';
import {
	TableColumnFilterText,
	TableUncontrolled,
	TableColumnFilterDateRange,
	TableColumnFilterAutocomplete,
} from '../../../../common/control';
import {
	filterByDateRange,
	isNilOrEmpty,
	filterByObjectMultiSelect,
	filterByStringMultiSelect,
} from '../../../../../utilities/tools';
import {UserIcon, ProjectTagIcon} from '../../../../../utilities/constants';
import {ItemsWithMore} from '../../../../common/ui';
import {ProjectsTableSkeleton} from './skeletons';
import SelectedProjectsOptions from './selected-projects-options';
// import { useReactiveVar } from '@apollo/react-hooks';
import { useEffect } from 'react';

function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
	  >
		{value === index && (
		  <Box sx={{ p: 3 }}>
			<Box>{children}</Box>
		  </Box>
		)}
	  </div>
	);
  }
  
  TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
	return {
	  id: `simple-tab-${index}`,
	  'aria-controls': `simple-tabpanel-${index}`,
	};
  }

const getProjects = ({isAdmin}) =>
	propOr([], isAdmin ? 'getProjects' : 'getMyProjects');

const getInvitedProjects = () =>
	propOr([], 'getInvitedProjectsOfUser');

const getTags = ({isAdmin}) =>
	pipe(getProjects({isAdmin}), map(propOr([], 'tags')), flatten, s => [
		...new Set(s),
	]);

const getUsers = ({isAdmin}) =>
	pipe(
		getProjects({isAdmin}),
		map(propOr([], 'users')),
		flatten,
		uniqBy(prop('_id')),
	);

const getTotalCount = ({isAdmin}) =>
	pipe(propOr([], isAdmin ? 'getProjects' : 'getMyProjects'), length);

const AssignedUsers = ({cell: {value}, state}) => {
	const usersIdsFilters = pipe(
		propOr([], 'filters'),
		find(propEq('id', 'users')),
		propOr([], 'value'),
		map(prop('_id')),
	)(state);

	const ItemRenderer = ({item: {_id, username}}) => (
		<Chip
			icon={<UserIcon />}
			color="secondary"
			size="small"
			variant={
				isNilOrEmpty(usersIdsFilters) || includes(_id, usersIdsFilters)
					? 'default'
					: 'outlined'
			}
			label={username}
		/>
	);

	return (
		<ItemsWithMore
			items={value}
			maxShown={3}
			EmptyRenderer="-"
			ItemRenderer={ItemRenderer}
			buttonMoreProps={{color: 'secondary'}}
		/>
	);
};

const ProjectTags = ({cell: {value}, state}) => {
	const tagsFilters = pipe(
		propOr([], 'filters'),
		find(propEq('id', 'tags')),
		propOr([], 'value'),
	)(state);

	const ItemRenderer = ({item: tag}) => (
		<Chip
			icon={<ProjectTagIcon />}
			color="primary"
			size="small"
			variant={
				isNilOrEmpty(tagsFilters) || includes(tag, tagsFilters)
					? 'default'
					: 'outlined'
			}
			label={tag}
		/>
	);

	return (
		<ItemsWithMore
			items={value}
			maxShown={3}
			EmptyRenderer="-"
			ItemRenderer={ItemRenderer}
			buttonMoreProps={{color: 'default'}}
		/>
	);
};

const ProjectsTableWrapper = ({
	data, 
	deleteProject, 
	isAdmin, 
	invitedProjects, 
	setInvitationResult, 
	setShowInvitationModal, 
	...props
}) => {
	const projects = getProjects({isAdmin})(data);
	const invitedProjectsOfUser = getInvitedProjects({isAdmin})(invitedProjects);

	const users = getUsers({isAdmin})(data);
	const tags = getTags({isAdmin})(data);
	const totalCount = getTotalCount({isAdmin})(data);

	const [tabValue, setTabValue] = useState(0);
	const handleTabChange = (event, newValue) => {
	  setTabValue(newValue);
	};

	useEffect(() => {
		// console.log(invitedProjectsOfUser)
	}, [invitedProjectsOfUser])

	const columns = useMemo(
		() => {
			return [
				{
					Header: 'Name',
					id: 'name',
					accessor: 'name',
					Filter: TableColumnFilterText,
					headerStyle: {width: 200, minWidth: 175},
					style: {width: 200, minWidth: 175, fontWeight: 'bold'},
					Cell: ({cell: {value}, row: {original}}) => (
						<Link
							component={RouterLink}
							to={{
								pathname: `/projects/${original._id}/dashboard`,
								state: {projectName: value},
							}}
							color="secondary"
							style={{fontSize: '18px'}}
						>
							{value}
						</Link>
					),
				},
				{
					Header: 'Stats',
					id: 'stats',
					accessor: pick(['requirements', 'stories']),
					Filter: TableColumnFilterText,
					disableFilters: true,
					disableSortBy: true,
					headerStyle: {marginTop: -35, width: 160, minWidth: 160},
					style: {width: 160, minWidth: 160},
					Cell: ({cell: {value}}) => {
						const reqLength = value.requirements && value.requirements.length;
						const storiesLength = value.stories && value.stories.length;

						return (
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Typography
										variant="body2"
										color="primary"
										style={{fontWeight: 500}}
									>
										<span style={{color: '#000'}}>- Total Requirements:</span>{' '}
										{reqLength}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography
										variant="body2"
										color="primary"
										style={{fontWeight: 500}}
									>
										<span style={{color: '#000'}}>- Total Stories:</span>{' '}
										{storiesLength}
									</Typography>
								</Grid>
							</Grid>
						);
					},
				},
				{
					Header: 'Assigned Users',
					id: 'users',
					accessor: 'users',
					disableSortBy: true,
					disableFilters: !isAdmin,
					headerStyle: {
						marginTop: isAdmin ? 0 : -35,
						width: 200,
						minWidth: 175,
					},
					style: {width: 200, minWidth: 175},
					filter: filterByObjectMultiSelect,
					Filter: filterProps => (
						<TableColumnFilterAutocomplete
							{...filterProps}
							options={users}
							formatOption={({username}) => username}
							optionKeyExtractor={prop('_id')}
							chipProps={{color: 'secondary'}}
						/>
					),
					Cell: AssignedUsers,
				},
				{
					Header: 'Tags',
					id: 'tags',
					accessor: 'tags',
					Cell: ProjectTags,
					Filter: filterProps => (
						<TableColumnFilterAutocomplete {...filterProps} options={tags} />
					),
					filter: filterByStringMultiSelect,
					disableSortBy: true,
					headerStyle: {width: 140, minWidth: 120, maxWidth: 150},
					style: {width: 140, minWidth: 120, maxWidth: 150},
				},
				{
					Header: 'Created at',
					id: 'createdAt',
					accessor: 'createdAt',
					Cell: ({cell: {value}}) =>
						moment(value).format('MMMM Do YYYY, HH:mm'),
					Filter: TableColumnFilterDateRange,
					filter: filterByDateRange,
					headerStyle: {width: 160, minWidth: 160, maxWidth: 160},
					style: {width: 160, minWidth: 160, maxWidth: 160},
				},
				{
					Header: 'Modified at',
					id: 'updatedAt',
					accessor: 'updatedAt',
					Cell: ({cell: {value}}) =>
						moment(value).format('MMMM Do YYYY, HH:mm'),
					Filter: TableColumnFilterDateRange,
					filter: filterByDateRange,
					headerStyle: {width: 160, minWidth: 160, maxWidth: 160},
					style: {width: 160, minWidth: 160, maxWidth: 160},
				},
			];
		},
		// eslint-disable-next-line
		[isAdmin],
	);
	
	const invitedProjectColumns = useMemo(() => {
		return [
			{
				Header: 'Accept/Decline',
				id: 'decision',
				accessor: 'decision',
				Filter: TableColumnFilterText,
				disableFilters: true,
				headerStyle: {
					marginTop: -35,
					width: 130, 
					minWidth: 130
				},
				style: {width: 130, minWidth: 130, fontWeight: 'bold'},
				Cell: ({cell: {value}, row: {original}}) => (
					<Box>
						<Button
							title='Accept Invitation'
							onClick={() => {
								setInvitationResult({result: true, ...original});
								setShowInvitationModal(true);
							}}
						>
							<CheckIcon/>
						</Button> 
						<Button
							title='Decline Invitation'
							onClick={() => {
								setInvitationResult({result: false, ...original});
								setShowInvitationModal(true);
							}}
						>
							<ClearIcon/>
						</Button> 
					</Box>
				),
			},
			{
				Header: 'Name',
				id: 'name',
				accessor: 'name',
				Filter: TableColumnFilterText,
				headerStyle: {width: 200, minWidth: 175},
				style: {width: 200, minWidth: 175, fontWeight: 'bold'},
				Cell: ({cell: {value}, row: {original}}) => (
					<Link
						component={RouterLink}
						to={{
							pathname: `/projects/${original._id}/dashboard`,
							state: {projectName: value},
						}}
						color="secondary"
						style={{fontSize: '18px'}}
					>
						{value}
					</Link>
				),
			},
			{
				Header: 'Assigned Users',
				id: 'users',
				accessor: 'users',
				disableSortBy: true,
				disableFilters: !isAdmin,
				headerStyle: {
					marginTop: isAdmin ? 0 : -35,
					width: 200,
					minWidth: 175,
				},
				style: {width: 200, minWidth: 175},
				filter: filterByObjectMultiSelect,
				Filter: filterProps => (
					<TableColumnFilterAutocomplete
						{...filterProps}
						options={users}
						formatOption={({username}) => username}
						optionKeyExtractor={prop('_id')}
						chipProps={{color: 'secondary'}}
					/>
				),
				Cell: AssignedUsers,
			},
			{
				Header: 'Tags',
				id: 'tags',
				accessor: 'tags',
				Cell: ProjectTags,
				Filter: filterProps => (
					<TableColumnFilterAutocomplete {...filterProps} options={tags} />
				),
				filter: filterByStringMultiSelect,
				disableSortBy: true,
				headerStyle: {width: 140, minWidth: 120, maxWidth: 150},
				style: {width: 140, minWidth: 120, maxWidth: 150},
			},
			{
				Header: 'Created at',
				id: 'createdAt',
				accessor: 'createdAt',
				Cell: ({cell: {value}}) =>
					moment(value).format('MMMM Do YYYY, HH:mm'),
				Filter: TableColumnFilterDateRange,
				filter: filterByDateRange,
				headerStyle: {width: 160, minWidth: 160, maxWidth: 160},
				style: {width: 160, minWidth: 160, maxWidth: 160},
			}
		];
		// eslint-disable-next-line
	}, [isAdmin]);

	return (
		<>
			<Box sx={{ width: '100%' }}>
				
				<TabPanel value={tabValue} index={0}>
					<TableUncontrolled
						{...props}
						enableRowSelect={isAdmin}
						columns={columns}
						data={projects}
						totalCount={totalCount}
						tableTitle={
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
								<Tab label="Projects" {...a11yProps(0)} />
								<Tab label={
									invitedProjectsOfUser?.length === 0?
									"Invitations"
									:
									`Invitations (${invitedProjectsOfUser?.length})`
								} {...a11yProps(1)} />
								</Tabs>
							</Box>
						}
						noRecordsFoundMessage={
							isAdmin
								? 'You have not been created any projects yet.'
								: 'You have not been assigned to any projects yet.'
						}
						SelectedRowsActionsComponent={SelectedProjectsOptions}
						selectedRowsActionsProps={props}
					/>
				</TabPanel>
				<TabPanel value={tabValue} index={1}>
					<TableUncontrolled
						{...props}
						enableRowSelect={isAdmin}
						columns={invitedProjectColumns}
						data={invitedProjectsOfUser}
						totalCount={invitedProjectsOfUser?.length}
						tableTitle={
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
								<Tab label="Projects" {...a11yProps(0)} />
								<Tab label={
									invitedProjectsOfUser?.length === 0?
									"Invitations"
									:
									`Invitations (${invitedProjectsOfUser?.length})`
								} {...a11yProps(1)} />
								</Tabs>
							</Box>
						}
						noRecordsFoundMessage={
							'You have not been invited to any projects.'
						}
						SelectedRowsActionsComponent={SelectedProjectsOptions}
						selectedRowsActionsProps={props}
					/>
				</TabPanel>
				
			</Box>
			
		</>
		
	);
};

export default props => (
	<WithSkeleton
		shouldShowSkeleton={props.shouldShowSkeleton}
		Skeleton={ProjectsTableSkeleton}
	>
		<ProjectsTableWrapper {...props} />
	</WithSkeleton>
);
