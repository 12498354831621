import React from 'react';
import { Grid } from '@material-ui/core';
import { TextField } from '../../../../../../common/form';
import { Tags, Users } from '../../../../../../common/form/project';

const Form = ({
	setUsersChanged,
	isProjectCreator,
	...props
}) => {

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={10}>
				<TextField {...props} name="name" label="Project Name"/>
			</Grid>
			<Grid item xs={12} md={10}>
				<TextField
					{...props}
					multiline
					textFieldProps={{
						multiline: true,
						minRows: 2,
						maxRows: 4,
					}}
					name="description"
					label="Description"
				/>
			</Grid>
			
			<Grid item xs={12} md={10}>
				<Tags {...props} />
			</Grid>
			{
				!props.amIAdmin?
				<>
					<Grid item xs={12} md={10}>
						<Users {...props} isAssigned={true} isProjectCreator={isProjectCreator} setUsersChanged={setUsersChanged} />
					</Grid>
					<Grid item xs={12} md={10}>
						<Users {...props} isInvited={true} isProjectCreator={isProjectCreator} setUsersChanged={setUsersChanged} />
					</Grid>
					<Grid item xs={12} md={10}>
						<Users {...props} isInviteMore={true} isProjectCreator={isProjectCreator} setUsersChanged={setUsersChanged} />
					</Grid>
				</>
				:
				<>
					<Grid item xs={12} md={10}>
						<Users {...props} isReadOnly={!props.amIAdmin} />
					</Grid>
					<Grid item xs={12} md={10}>
						<Users {...props} isInvited={true} isProjectCreator={true} setUsersChanged={setUsersChanged} />
					</Grid>
					<Grid item xs={12} md={10}>
						<Users {...props} isInviteMore={true} isProjectCreator={true} setUsersChanged={setUsersChanged} />
					</Grid>
				</>
			}
			
		</Grid>
	);
};

export default Form;
