import gql from 'graphql-tag';

export default gql`
	mutation EditUser($userId: String!, $newUser: EditUserInput!) {
		editUser(userId: $userId, newUser: $newUser) {
			_id
			email
			username
			role
			fullName
			projects {
				_id
			}
			createdAt
		}
	}
`;