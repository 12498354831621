import React from 'react';
import {Route} from 'react-router-dom';
import {path} from 'ramda';
import RequirementEdit from './requirement-edit';
import RequirementCreate from './requirement-create';
import RequirementsTableWrapper from './requirements-table-wrapper';

export const REQUIREMENTS_PAGE_SIZE_DEFAULT = 25;
export const REQUIREMENTS_PAGE_SIZE_DEFAULT_PAGE_OPTIONS = [12, 25, 50];

export default props => {
	return (
		<div>
			<RequirementsTableWrapper {...props} />
			<Route
				exact
				path="/projects/:projectId/requirements/:requirementId"
				component={renderProps => {
					const requirementIdMaybe = path(
						['match', 'params', 'requirementId'],
						renderProps,
					);
					if (requirementIdMaybe === 'create') {
						return <RequirementCreate {...props} />;
					}

					if (requirementIdMaybe) {
						return <RequirementEdit {...props} />;
					}

					return null;
				}}
			/>
		</div>
	);
};
