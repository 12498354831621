import {memo} from 'react';
import {useQuery} from 'react-apollo';
import {path} from 'ramda';
import {GET_MY_USER} from '../../../../graphql/queries';

const Authorize = ({allowedRoles = [], children, FallBack = null}) => {
	const {data} = useQuery(GET_MY_USER, {fetchPolicy: 'cache-only'});
	const myRole = path(['getMyUser', 'role'], data);

	if (allowedRoles.includes(myRole)) {
		return children;
	}

	return FallBack;
};

export default memo(Authorize);
