import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
	EMAIL_SCHEMA,
	USERNAME_SCHEMA,
	FULLNAME_SCHEMA,
	PASSWORD_SCHEMA,
} from '../../../../utilities/validations/user-validation-schema';

const handleSubmit = props => async values => {
	const {
		createUser,
		ui,
		snackbar,
		invitationHash,
		onAuthenticateSuccess,
	} = props;
	try {
		ui.setAppProgressShown(true);
		const {
			data: {createUser: data},
		} = await createUser({variables: {data: {...values, invitationHash}}});
		onAuthenticateSuccess(data);
	} catch (error) {
		snackbar.enqueueSnackbar(error.message.replace('GraphQL error:', ''), {
			variant: 'error',
			anchorOrigin: {vertical: 'top', horizontal: 'center'},
		});
	} finally {
		ui.setAppProgressShown(false);
	}
};

export default (props) => {
	const invitationHash = props.invitationHash;
	let validationSchema = invitationHash? 
		Yup.object().shape({
			username: USERNAME_SCHEMA,
			fullName: FULLNAME_SCHEMA,
			password: PASSWORD_SCHEMA,
		})
	: 
	 	Yup.object().shape({
			email: EMAIL_SCHEMA,
			username: USERNAME_SCHEMA,
			fullName: FULLNAME_SCHEMA,
			password: PASSWORD_SCHEMA,
		})

	let initialValues = invitationHash? {
		username: '',
		fullName: '',
		password: '',
	} : {
		email: '',
		username: '',
		fullName: '',
		password: '',
	}


	return useFormik({
		initialValues,
		onSubmit: handleSubmit(props),
		validationSchema,
	});
}
