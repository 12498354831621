import {useFormik} from 'formik';
import {pathOr, /*pipe, propOr*/} from 'ramda';
// import {constructArrayConnectDisconnectQuery} from '../../../../../../../utilities/tools';
import {updateProjectValidationSchema} from '../../../../../../../utilities/validations/project-validation-schema';

const getInitialValues = props => ({
	name: pathOr('', ['project', 'name'], props),
	description: pathOr('', ['project', 'description'], props),
	tags: pathOr([], ['project', 'tags'], props),
	users: pathOr([], ['project', 'users'], props),
	invitedUsers: pathOr([], ['project', 'invitedUsers'], props),  
	usersToInvite: pathOr([], ['project', 'usersToInvite'], props),  
});

// const withPrepareUsers = ({users, project, amIAdmin, isProjectCreator}) =>
	// amIAdmin || isProjectCreator
		// ? {
		// 	users: constructArrayConnectDisconnectQuery(
		// 		propOr([], 'users', project),
		// 		users,
		// 	),
		//   }
		// : {};

// const formatValues = ({values, project, amIAdmin, isProjectCreator}) => {
// 	return {
// 		projectId: project._id,

// 	}
// }
	// pipe(({users, ...rest}) => ({
	// 	...rest,
	// 	...withPrepareUsers({users, project, amIAdmin, isProjectCreator}),
	// }))(values);


const handleSubmit = props => async values => {
	// console.log("Props:", props)
	const {updateProject, ui, snackbar, project, /*amIAdmin, isProjectCreator*/} = props;
	values.users = values.users.map(user => user._id);
	// console.log("Project:", project)
	console.log("Values:", values)
	try {
		ui.setAppProgressShown(true);
		// const formattedValues = formatValues({
		// 	values,
		// 	project,
		// 	amIAdmin,
		// 	isProjectCreator
		// });
		// let formattedValues = {
		// 	values,
		// }
		// console.log(formattedValues)
		// await delay(5000);
		// return;
		await updateProject({
			variables: {projectId: project._id, data: values},
		});
		snackbar.enqueueSnackbar('Project has been updated successfully.', {
			variant: 'success',
			anchorOrigin: {vertical: 'top', horizontal: 'center'},
		});
	} catch (error) {
		snackbar.enqueueSnackbar(error.message.replace('GraphQL error:', ''), {
			variant: 'error',
			anchorOrigin: {vertical: 'top', horizontal: 'center'},
		});
	} finally {
		ui.setAppProgressShown(false);
	}
};

const useFormikBag = props =>
	useFormik({
		enableReinitialize: true,
		initialValues: getInitialValues(props),
		onSubmit: handleSubmit(props),
		validationSchema: updateProjectValidationSchema,
	});

export default useFormikBag;
