import React, {useState, useEffect} from 'react';
import {
	FormControl,
	Select,
	MenuItem,
	Checkbox,
	ListItemText,
	Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {pipe, ifElse, identity, map, isNil, join} from 'ramda';
import {
	isNilOrEmpty,
	stringArraysEqual,
	mantainUniqueValues,
} from '../../../../utilities/tools';

const useStyles = makeStyles(() => ({
	root: {
		fontWeight: '400!important',
		'& .MuiInputBase-root': {
			backgroundColor: '#fff',
		},
	},
}));

const defaultRenderValue = formatOption =>
	pipe(
		ifElse(() => isNil(formatOption), identity, map(formatOption)),
		join(', '),
	);

const TableColumnFilterSelect = ({
	options,
	formatOption = identity, // used to format label option
	// Warning: if selected values are not plain texts then a custom renderValue must be provided!
	optionKeyExtractor = identity, // extract key from option item
	optionValueExtractor = identity, // DON'T USE THAT extract value from option item
	renderValue, // function to render the selected options in the input text
	column: {filterValue, setFilter},
}) => {
	const classes = useStyles();
	const [internalState, setInternalState] = useState(filterValue);

	const handleCheckboxChange = event => {
		// there is a bug if event.target.value is not array of strings and internalState it does not return the unique ones
		const {value} = event.target;
		if (value && value.length !== 0 && typeof value[0] !== 'string') {
			setInternalState(
				mantainUniqueValues(event.target.value, optionKeyExtractor),
			);
			return;
		}

		setInternalState(event.target.value);
	};

	const handleClose = () => {
		if (isNilOrEmpty(internalState) && isNilOrEmpty(filterValue)) return;
		if (
			stringArraysEqual(
				internalState && internalState.map(optionKeyExtractor),
				filterValue && filterValue.map(optionKeyExtractor),
			)
		) {
			return;
		}

		setFilter(isNilOrEmpty(internalState) ? null : internalState);
	};

	// To reset internal state
	useEffect(() => {
		if (filterValue === null || filterValue === undefined) {
			setInternalState(null);
		}
	}, [filterValue]);

	// used to render checkbox state
	const selectedKeys = internalState
		? internalState.map(optionKeyExtractor)
		: [];

	return (
		<FormControl
			fullWidth
			className={classes.root}
			margin="dense"
			variant="outlined"
		>
			<Select
				multiple
				margin="dense"
				value={internalState || []}
				renderValue={
					renderValue ? renderValue : defaultRenderValue(formatOption)
				}
				MenuProps={{variant: 'menu'}}
				onClose={handleClose}
				onChange={handleCheckboxChange}
			>
				{options && options.length > 0 ? (
					options.map(option => (
						<MenuItem
							key={optionKeyExtractor(option)}
							value={optionValueExtractor(option)}
						>
							<Checkbox
								checked={selectedKeys.includes(optionKeyExtractor(option))}
								value={option}
							/>
							<ListItemText primary={formatOption(option)} />
						</MenuItem>
					))
				) : (
					<Typography
						variant="body2"
						align="center"
						color="textSecondary"
						style={{padding: '5px'}}
					>
						No options to show.
					</Typography>
				)}
			</Select>
		</FormControl>
	);
};

export default TableColumnFilterSelect;
