import React, {useState} from 'react';
import {
	Button,
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	CircularProgress,
	Typography,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {useDropzone} from 'react-dropzone';
import SwaggerParser from '@apidevtools/swagger-parser';
import {useSnackbar} from 'notistack';
import {makeStyles} from '@material-ui/styles';
import {propEq} from 'ramda';
import {toJSONorYAML} from '../../../../../../../utilities/tools';

const useStyles = makeStyles(theme => ({
	dropzone: {
		cursor: 'pointer',
		flex: '1',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: '2px',
		borderRadius: '2px',
		borderColor: theme.palette.primary.main,
		borderStyle: 'dashed',
		backgroundColor: '#fafafa',
		color: theme.palette.primary.main,
		outline: 'none',
		transition: 'border .24s ease-in-out',

		'&.disabled': {
			opacity: 0.6,
		},
	},
}));

const UploadSwaggerDialog = ({open, setOpen, afterFileAccepted}) => {
	const classes = useStyles();
	const snackbar = useSnackbar();
	const [validating, setValidating] = useState(false);

	const dropzoneProps = useDropzone({
		accept: ['.json', '.yaml'],
		maxSize: 100000, // 100 kilo bytes
		multiple: false,
		onDropAccepted: async files => {
			const file = files[0];
			const isYaml = propEq('type', 'application/x-yaml', file);
			const reader = new FileReader();
			reader.addEventListener('load', async function(e) {
				const contents = isYaml
					? toJSONorYAML(e.target.result, 'json')
					: e.target.result;
				try {
					const parsed = JSON.parse(contents);
					setValidating(true);
					await SwaggerParser.validate(parsed, {
						parse: {json: true, yaml: false, text: false, binary: false},
					});
					afterFileAccepted(contents);
					setOpen(false);
				} catch (error) {
					snackbar.enqueueSnackbar(error.message, {
						variant: 'error',
						anchorOrigin: {vertical: 'top', horizontal: 'center'},
						autoHideDuration: 6000,
					});
				} finally {
					setValidating(false);
				}
			});

			reader.readAsText(file);
		},
		onDropRejected: () => {
			snackbar.enqueueSnackbar(
				'You must upload a single .json or .yaml swagger file no more than 100 KB',
				{
					variant: 'error',
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
					autoHideDuration: 6000,
				},
			);
		},
	});

	const {getRootProps, getInputProps} = dropzoneProps;

	const onClose = () => setOpen(false);

	return (
		<Dialog
			fullWidth
			disableEscapeKeyDown={validating}
			//disableBackdropClick={validating}
			open={open}
			maxWidth="sm"
			onClose={onClose}
		>
			<DialogTitle>Upload JSON or YAML Swagger</DialogTitle>
			<DialogContent>
				{validating && (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
					>
						<CircularProgress />
						<br />
						<Typography>Validating Swagger Specs...</Typography>
					</Box>
				)}
				{!validating && (
					<div>
						<Box mb={4}>
							<Alert variant="standard" severity="info">
								Upload a single valid Swagger JSON or YAML file no more than
								100KB.
							</Alert>
						</Box>
						<section className="container">
							<div {...getRootProps({className: classes.dropzone})}>
								<input {...getInputProps()} />
								<p>
									Drag &apos;n&apos; drop a file here, or click to select file
								</p>
							</div>
						</section>
					</div>
				)}
			</DialogContent>
			<DialogActions>
				<Button disabled={validating} onClick={onClose}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default UploadSwaggerDialog;
