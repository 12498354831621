import React from 'react';
import {propOr, pathOr} from 'ramda';
import {useQuery} from 'react-apollo';
import {InputAdornment, Grid, Paper, Box, Chip} from '@material-ui/core';
import {paramCase} from 'change-case';
import {useSnackbar} from 'notistack';
import {ProjectTagIcon} from '../../../../utilities/constants';
import {getReadOnlyProps} from '../../../../utilities/tools';
import {GET_PROJECTS_TAGS} from '../../../../graphql/queries';
import {Skeletons} from '../../ui';
import {AutocompleteCreatable} from '..';

const getTags = propOr([], 'getProjectsTags');

export const Tags = ({isReadOnly, fieldOverrideProps = {}, ...props}) => {
	const {enqueueSnackbar} = useSnackbar();
	const formikName = propOr('tags', 'name', fieldOverrideProps);
	const {data, loading} = useQuery(GET_PROJECTS_TAGS, {
		onError: error => {
			console.error(error);
			enqueueSnackbar('Project tags could not be loaded.', {
				variant: 'error',
				anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
			});
		},
	});

	const allTags = getTags(data);

	return (
		<AutocompleteCreatable
			{...props}
			formatInputValueBeforeAdding={paramCase}
			name={formikName}
			label="Project tags (optional)"
			options={allTags}
			optionsLoading={loading}
			autoCompleteProps={{
				ChipProps: {
					icon: <ProjectTagIcon />,
					color: 'primary',
				},
			}}
			textFieldProps={{
				fullWidth: true,
				margin: 'none',
				variant: 'outlined',
				...(propOr(true, 'multiple', fieldOverrideProps)
					? {}
					: {
							InputProps: {
								startAdornment: (
									<InputAdornment position="start">
										<ProjectTagIcon fontSize="inherit" color="action" />
									</InputAdornment>
								),
							},
					  }),
				...getReadOnlyProps('Autocomplete')(isReadOnly),
			}}
			{...fieldOverrideProps}
		/>
	);
};

const TagsReadOnly = props => {
	const tags = pathOr([], ['values', 'tags'], props);

	return (
		<Paper elevation={1}>
			<Box p={2}>
				<Grid container alignItems="center" spacing={2}>
					<Grid item>Project tags:</Grid>
					<Grid item container xs={10} spacing={1}>
						{tags.map((tag, i) => (
							<Grid key={i} item>
								<Chip label={tag} icon={<ProjectTagIcon />} color="primary" />
							</Grid>
						))}
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};

export default ({isReadOnly, initLoading, skeletonProps = {}, ...props}) => {
	if (initLoading) {
		return <Skeletons.TextFieldSkeleton {...skeletonProps} />;
	}

	if (isReadOnly) {
		return <TagsReadOnly {...props} />;
	}

	return <Tags {...props} />;
};
