import React from 'react';
import {Grid, Button, Box, InputAdornment} from '@material-ui/core';
import {useMutation} from 'react-apollo';
import {useSnackbar} from 'notistack';
import {Password} from '../../../common/form/user';
import {TextField} from '../../../common/form';
import {isSubmitButtonDisabled} from '../../../../utilities/tools';
import {AUTHENTICATE_USER} from '../../../../graphql/mutations';
import {useUi} from '../../../hoc';
import {UserIcon} from '../../../../utilities/constants';
import useFilledInputAdornmentStyles from '../../../common/ui/filled-input-adornment-classes';
import useFormikBag from './use-formik-bag';

const LoginForm = ({onAuthenticateSuccess}) => {
	const classes = useFilledInputAdornmentStyles();
	const ui = useUi();
	const snackbar = useSnackbar();
	const [authenticateUser] = useMutation(AUTHENTICATE_USER);
	const formikProps = useFormikBag({
		ui,
		snackbar,
		authenticateUser,
		onAuthenticateSuccess,
	});

	return (
		<form onSubmit={formikProps.handleSubmit}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<TextField
						name="emailOrUsername"
						// label="Email or Username*"
						textFieldProps={{
							placeholder: 'Email or Username*',
							InputProps: {
								classes: {
									root: classes.inputRoot,
									adornedStart: classes.inputAdornedStart,
									focused: classes.inputFocused,
									notchedOutline: classes.notchedOutline,
									input: classes.inputInput,
								},
								startAdornment: (
									<InputAdornment
										className={classes.inputAdornment}
										position="start"
									>
										<UserIcon />
									</InputAdornment>
								),
							},
						}}
						{...formikProps}
					/>
				</Grid>
				<Grid item xs={12}>
					<Password {...formikProps} />
				</Grid>
				
				<Grid item xs={12}>
					<Grid container justifyContent="center">
						<Grid item xs={12} md={6}>
							<Box mt={[1, 1, 3]}>
								<Button
									fullWidth
									id="create-user-form-submit-button"
									type="submit"
									color="primary"
									variant="contained"
									disabled={isSubmitButtonDisabled(formikProps)}
								>
									Log in
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Grid>
				
				<Grid item xs={12}>
					<Grid container justifyContent="center">
						<Grid item xs={12} md={6}>
							<Button
								fullWidth
								// id="create-user-form-submit-button"
								color="secondary"
								variant="contained"
								onClick={() => {
									window.location.href='/complete-registration';
								}}
							>
								Sign Up
							</Button>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Grid container justifyContent="center">
						<Grid item xs={12} md={6}>
							<Button
								fullWidth
								id="forgot-password-button"
								color="secondary"
								variant="contained"
								onClick={() => {
									window.location.href='/forgot';
								}}
							>
								Forgot Password
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
};

export default LoginForm;
