import React, { useMemo, useRef } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { useLazyQuery } from 'react-apollo';
import { useDebouncedCallback } from 'use-debounce/lib';
import { propOr } from 'ramda';
import useDidUpdate from '@rooks/use-did-update';
import { EMAIL_SCHEMA } from '../../../../utilities/validations/user-validation-schema';
import {
	formatFormErrorHelperText,
	getFormErrorsField,
} from '../../../../utilities/tools';
import { IS_EMAIL_AVAILABLE } from '../../../../graphql/queries';
import useFilledInputAdornmentStyles from '../../ui/filled-input-adornment-classes';
import EmailIcon from '@material-ui/icons/Email';

const getNotAvailableMessage = isAvailable => {
	if (isAvailable === null || isAvailable === true) return null;
	// if (isAvailable === true) return null;
	return 'Not available';
};

const getNotAvailableError = isAvailable => {
	if (isAvailable === null) return false;
	return !isAvailable;
};

const Email = ({
	enableIsAvailableQuery = false,
	errors,
	touched,
	isSubmitting,
	values,
	status,    // this is also from formik
	setStatus, // this is also from formik
	handleChange,
	handleBlur,
	textFieldProps = {},
	shouldUseFilledInputAdornmentStyles = true,
}) => {
	const classes = useFilledInputAdornmentStyles();
	const emailInitialValue = useRef(values.email);
	const [executeIsEmailAvailable, {data}] = useLazyQuery(
		IS_EMAIL_AVAILABLE,
		{
			onCompleted: data => {
				const isAvailable = propOr(null, 'isEmailAvailable', data);
				if (isAvailable === true) {
					setStatus({...status, isEmailAvailable: true});
				} else {
					setStatus({...status, isEmailAvailable: false});
				}
			},
		},
	);

	const [debouncedEmail] = useDebouncedCallback(
		value =>
			executeIsEmailAvailable({
				variables: {email: value},
			}),
			200,
		);

	useDidUpdate(() => {
		if (
			enableIsAvailableQuery === true &&
			emailInitialValue.current !== values.email
		) {
			// only debounce if email is valid
			const { email } = values;
			try {
				EMAIL_SCHEMA.validateSync(email);
				debouncedEmail(email);
			} catch (_) {}
		}
	}, [values.email]);

	const isEmailAvailable = propOr(null, 'isEmailAvailable', data);

	const filledInputAdornmentProps = useMemo(() => {
		return shouldUseFilledInputAdornmentStyles
			? {
					classes: {
						root: classes.inputRoot,
						adornedStart: classes.inputAdornedStart,
						focused: classes.inputFocused,
						notchedOutline: classes.notchedOutline,
						input: classes.inputInput,
					},
					startAdornment: (
						<InputAdornment className={classes.inputAdornment} position="start">
							<EmailIcon />
						</InputAdornment>
					),
			  }
			: {};
	}, [
		classes.inputAdornedStart,
		classes.inputAdornment,
		classes.inputFocused,
		classes.inputInput,
		classes.inputRoot,
		classes.notchedOutline,
		shouldUseFilledInputAdornmentStyles,
	]);

	return (
		<TextField
			fullWidth
			inputProps={{
				form: {
					autocomplete: 'off',
				},
			}}
			disabled={isSubmitting}
			id="email"
			name="email"
			placeholder="Email* (e.g johndoe@ece.auth.gr)"
			value={values.email}
			helperText={
				formatFormErrorHelperText('email', errors, touched) ||
				getNotAvailableMessage(isEmailAvailable)
			}
			error={
				Boolean(getFormErrorsField('email', errors, touched)) ||
				getNotAvailableError(isEmailAvailable)
			}
			variant="outlined"
			type="text"
			// eslint-disable-next-line react/jsx-no-duplicate-props
			InputProps={filledInputAdornmentProps}
			onChange={handleChange}
			onBlur={handleBlur}
			{...textFieldProps}
		/>
	);
};

export default Email;
