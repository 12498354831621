import React, {useMemo} from 'react';
import {Formik} from 'formik';
import {pipe, prop} from 'ramda';
import {createProjectValidationSchema} from '../../../../../utilities/validations/project-validation-schema';
import {isNilOrEmpty} from '../../../../../utilities/tools';

const initialValues = {
	name: '',
	description: '',
	tags: [],
	users: [],
	usersToInvite: [],
	copies: 1,
};

const handleSubmit = (setOpenCreateProjectModal, currentUser, props) => async values => {
	const {createProject, ui, snackbar, history} = props;
	const totalCopies = isNilOrEmpty(values.copies)
		? [1]
		: new Array(Math.round(values.copies)).fill(0).map((_, i) => i + 1);

	try {
		ui.setAppProgressShown(true);
		for (const copy of totalCopies) {
			const formattedValues = pipe(
				({users, copies, name, description, ...rest}) => ({
					...rest,
					name:
						copy === 1 && totalCopies.length === 1 ? name : `${name} - ${copy}`,
					description: `${description}`,
					users: {connect: users.map(prop('_id'))},
					creator: currentUser.email,
				}),
			)(values);
			// eslint-disable-next-line no-await-in-loop
			await createProject({variables: {
				data: formattedValues
			}});
		}

		snackbar.enqueueSnackbar(
			`${
				totalCopies.length === 1
					? `Project has been`
					: `${totalCopies.length} Projects have been`
			} created successfully.`,
			{
				variant: 'success',
				anchorOrigin: {vertical: 'top', horizontal: 'center'},
			},
		);
		history.push('/projects');
		setOpenCreateProjectModal(false);
	} catch (error) {
		snackbar.enqueueSnackbar(error.message.replace('GraphQL error:', ''), {
			variant: 'error',
			anchorOrigin: {vertical: 'top', horizontal: 'center'},
		});
	} finally {
		ui.setAppProgressShown(false);
	}
};

export default ({ setOpenCreateProjectModal, currentUser, ...props}) => {
	const handleSubmitMemo = useMemo(
		() => handleSubmit(setOpenCreateProjectModal, currentUser, props),
		// eslint-disable-next-line
	[]);

	return (
		<Formik
			{...props}
			initialValues={initialValues}
			validationSchema={createProjectValidationSchema}
			onSubmit={handleSubmitMemo}
		>
			{formikProps => props.children(formikProps)}
		</Formik>
	);
};
