import React from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {
	Title,
	useStyles,
} from '../../../../../../common/control/table-uncontrolled';

export const StoriesTableSkeleton = () => {
	const classes = useStyles();
	const rows = [...new Array(12).keys()];

	return (
		<Paper className={classes.paper}>
			<Title
				tableTitle={
					<Typography variant="h6" component="div" color="secondary">
						Project Requirements
					</Typography>
				}
			/>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Story Title</TableCell>
						<TableCell>Rating</TableCell>
						<TableCell>Created at</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map(row => (
						<TableRow key={row} style={{height: 100}}>
							<TableCell>
								<Skeleton variant="text" />
							</TableCell>
							<TableCell>
								<Skeleton variant="text" />
							</TableCell>
							<TableCell>
								<Skeleton variant="text" />
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Paper>
	);
};
