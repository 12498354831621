// All routes declared in this file are private (only for authenticated users)
import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {useQuery} from 'react-apollo';
import {path} from 'ramda';
import {Project, InvitedUser, User} from '../screens/private';
import {GET_MY_USER} from '../../graphql/queries';

const AuthorizedRoute = ({isAuthorized, ...props}) =>
	isAuthorized ? <Route {...props} /> : <Redirect to="/projects" />;

const AppRoutes = () => {
	const {data} = useQuery(GET_MY_USER, {fetchPolicy: 'cache-only'});
	const myRole = path(['getMyUser', 'role'], data);
	const isAuthorized = myRole === 'Admin';

	return (
		<Switch>
			<Route exact path="/projects" component={Project.ProjectsScreen} />
			<Route
				path="/projects/:projectId"
				component={Project.ViewProjectScreen}
			/>
			<AuthorizedRoute
				exact
				isAuthorized={isAuthorized}
				path="/create-project"
				component={Project.CreateProjectScreen}
			/>
			<AuthorizedRoute
				exact
				isAuthorized={isAuthorized}
				path="/create-invited-user"
				component={InvitedUser.CreateInvitedUserScreen}
			/>
			<AuthorizedRoute
				exact
				isAuthorized={isAuthorized}
				path="/invited-users"
				component={InvitedUser.InvitedUsersScreen}
			/>
			<AuthorizedRoute
				exact
				isAuthorized={isAuthorized}
				path="/users"
				component={User.UsersScreen}
			/>
			<AuthorizedRoute
				exact
				isAuthorized={isAuthorized}
				path="/team-leads"
				component={User.WonnabeTeamLeads}
			/>
			<Redirect from="*" to="/projects" />
		</Switch>
	);
};

export default AppRoutes;
