import React from 'react';
import MaterialAutocomplete from '@material-ui/lab/Autocomplete';
import {TextField, Checkbox, CircularProgress} from '@material-ui/core';
import {
	CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
	CheckBox as CheckBoxIcon,
} from '@material-ui/icons';
import {getIn} from 'formik';
import {ifElse, equals, propEq, find, prop} from 'ramda';
import {
	getFormErrorsField,
	mantainUniqueValues,
} from '../../../../utilities/tools';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const isCheckboxSelected = ({option, value, optionUniqueProp}) =>
	Boolean(
		find(propEq(optionUniqueProp, option[optionUniqueProp]))(value || []),
	);

const getMultipleProps = ({getCheckboxLabel, value, optionUniqueProp}) =>
	ifElse(
		equals(true),
		() => ({
			disableCloseOnSelect: true,
			renderOption: option => (
				<>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{marginRight: 8}}
						checked={isCheckboxSelected({option, value, optionUniqueProp})}
					/>
					{getCheckboxLabel(option)}
				</>
			),
		}),
		() => {},
	);

const Autocomplete = ({
	name,
	label,
	options,
	optionsLoading = false,
	optionUniqueProp = '_id',
	multiple = false,
	autoCompleteProps,
	textFieldProps = {inputProps: {}, InputProps: {}},
	handleChangeOverride,
	getCheckboxLabel = val => val.name,
	...formikProps
}) => {
	const {values, setFieldValue, errors, touched, handleBlur} = formikProps;
	const error = getFormErrorsField(name, errors, touched);
	const value = multiple ? getIn(values, name) || [] : getIn(values, name);

	const handleChange = (_, newValue) => {
		if (handleChangeOverride) {
			handleChangeOverride(newValue);
		} else if (multiple) {
			setFieldValue(
				name,
				mantainUniqueValues(newValue, prop(optionUniqueProp)),
			);
		} else {
			setFieldValue(name, newValue);
		}
	};

	return (
		<MaterialAutocomplete
			multiple={multiple}
			{...getMultipleProps({getCheckboxLabel, optionUniqueProp, value})(
				multiple,
			)}
			// just to avoid a material-ui warning
			getOptionSelected={(option, value) =>
				prop(optionUniqueProp, option) === prop(optionUniqueProp, value)
			}
			value={value}
			options={options}
			loading={optionsLoading}
			getOptionLabel={getCheckboxLabel} // is used only for non-multiple
			renderInput={params => (
				<TextField
					{...params}
					label={label}
					{...textFieldProps}
					inputProps={{...params.inputProps, ...textFieldProps.inputProps}}
					// eslint-disable-next-line
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{optionsLoading ? (
									<CircularProgress color="inherit" size={20} />
								) : null}
								{params.InputProps.endAdornment}
							</>
						),
						...textFieldProps.InputProps,
					}}
					name={name}
					error={Boolean(error)}
					helperText={error}
					onBlur={handleBlur}
				/>
			)}
			onChange={handleChange}
			{...autoCompleteProps}
		/>
	);
};

export default Autocomplete;
