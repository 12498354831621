import {Box, Divider, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
	title: {
		fontWeight: 500,
		marginBottom: theme.spacing(2),
	},

	divider: {
		margin: '16px 0',
	},
}));

/**
 *
 * @param {node} title
 * @param {[node]} contents
 */
const BoxInfo = ({
	title = '',
	contents = [],
	loading,
	divider,
	SkeletonTitle = null,
	skeletonContents = [],
}) => {
	const classes = useStyles();

	return (
		<div>
			<div>
				<Typography className={classes.title}>
					{loading ? SkeletonTitle : title}
				</Typography>
				<div>
					{loading
						? skeletonContents.map(SkeletonContent => SkeletonContent)
						: contents.map((content, i) => (
								<Box key={i} mb={1}>
									{content}
								</Box>
						  ))}
				</div>
			</div>
			{divider && <Divider className={classes.divider} />}
		</div>
	);
};

export default BoxInfo;
