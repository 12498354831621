import {Box, Button, Chip, Grid, Link, Typography} from '@material-ui/core';
import {
	find,
	identity,
	ifElse,
	isEmpty,
	join,
	map,
	path,
	pathOr,
	pipe,
	prop,
	propEq,
	propOr,
	__,
} from 'ramda';
import React, {useMemo} from 'react';
import {useQuery} from 'react-apollo';
import {useRouteMatch, Link as RouterLink} from 'react-router-dom';
import {capitalCase} from 'change-case';
import moment from 'moment';
import {makeStyles} from '@material-ui/styles';
import {JsonParam, NumberParam, useQueryParams} from 'use-query-params';
import {
	TableColumnFilterDateRange,
	TableColumnFilterSelect,
	TableColumnFilterText,
	TableUncontrolled,
} from '../../../../../../common/control';
import {WithSkeleton} from '../../../../../../common/hoc';
import {
	filterByDateRange,
	isNilOrEmpty,
} from '../../../../../../../utilities/tools';
import {
	PROJECT_REQUIREMENT_TYPES,
	PROJECT_REQUIREMENT_RATINGS,
} from '../../../../../../../utilities/constants';
import {GET_PROJECT_REQUIREMENTS} from '../../../../../../../graphql/queries';
import {RequirementsTableSkeleton} from './skeletons';
import AddRequirementsFromFile from './add-requirements-from-file';
import {
	REQUIREMENTS_PAGE_SIZE_DEFAULT,
	REQUIREMENTS_PAGE_SIZE_DEFAULT_PAGE_OPTIONS,
} from '.';

const PROJECT_REQUIREMENT_TYPES_LABELS = PROJECT_REQUIREMENT_TYPES.reduce(
	(acc, {label, value}) => ({...acc, [value]: label}),
	{},
);

const PROJECT_REQUIREMENT_RATINGS_LABELS = PROJECT_REQUIREMENT_RATINGS.reduce(
	(acc, {label, value}) => ({...acc, [value]: label}),
	{},
);

const findAppliedRating = value =>
	pipe(find(propEq('value', value)))(PROJECT_REQUIREMENT_RATINGS);

const useStyles = makeStyles(theme => ({
	requirementText: {
		fontWeight: 600,
		'&:hover': {color: theme.palette.primary.main},
	},
	comment: {
		whiteSpace: 'pre-wrap',
		lineHeight: '16px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		boxOrient: 'vertical',
		WebkitBoxOrient: 'vertical',
		lineClamp: 3,
		WebkitLineClamp: 3,
		maxHeight: `${3 *
			Number(pathOr(1.5, ['typography', 'body2', 'lineHeight'], theme))}em`,
	},
}));

const RequirementsTableWrapper = props => {
	const classes = useStyles();
	const {queryParams, setQueryParams, projectId} = props;
	const requirements = propOr([], 'requirements', props);
	const totalCount = requirements.length;
	const pageIndex = propOr(0, 'pageIndex', queryParams);
	const pageSize = propOr(
		REQUIREMENTS_PAGE_SIZE_DEFAULT,
		'pageSize',
		queryParams,
	);

	const newPageAfterRequirementRemove = useMemo(
		() =>
			totalCount === 1 || (totalCount - 1) % pageSize !== 0
				? pageIndex
				: pageIndex - 1,
		[pageIndex, pageSize, totalCount],
	);

	const newPageAfterRequirementAdd = useMemo(() => {
		const lastPage = Math.floor(totalCount / pageSize);
		return lastPage;
	}, [pageSize, totalCount]);

	const columns = useMemo(
		() => [
			{
				Header: '#',
				id: 'seq',
				accessor: pipe(
					propOr('', 'seq'),
					ifElse(isEmpty, identity, s => `R-${s}`),
				),
				disableSortBy: false,
				disableFilters: true,
				headerStyle: {width: 25, maxWidth: 25, marginTop: -35},
				style: {width: 25, maxWidth: 25},
			},
			{
				Header: 'Requirement',
				id: 'name',
				accessor: 'text',
				disableSortBy: true,
				Filter: TableColumnFilterText,
				headerStyle: {width: 275, minWidth: 175},
				style: {width: 275, minWidth: 175},
				Cell: ({cell: {value}, row: {original}}) => (
					<Link
						color="textPrimary"
						underline="none"
						component={RouterLink}
						to={{
							pathname: `/projects/${projectId}/requirements/${original._id}`,
							state: {
								from: `${window.location.pathname}${window.location.search}`,
								newPageAfterRequirementRemove,
							},
						}}
					>
						<Typography className={classes.requirementText} variant="body1">
							{value}
						</Typography>
					</Link>
				),
			},
			{
				Header: 'Type',
				id: 'type',
				accessor: 'type',
				Filter: filterProps => (
					<TableColumnFilterSelect
						{...filterProps}
						options={PROJECT_REQUIREMENT_TYPES.map(prop('value'))}
						formatOption={prop(__, PROJECT_REQUIREMENT_TYPES_LABELS)}
						renderValue={pipe(
							map(prop(__, PROJECT_REQUIREMENT_TYPES_LABELS)),
							join(', '),
						)}
					/>
				),
				filter: 'includesValue',
				disableSortBy: true,
				headerStyle: {width: 125, minWidth: 100},
				style: {width: 125, minWidth: 100},
				Cell: ({cell: {value = ''}}) =>
					isNilOrEmpty(value) ? '-' : <Chip label={capitalCase(value)} />,
			},
			{
				Header: 'Rating',
				id: 'rating',
				accessor: 'rating',
				headerStyle: {width: 150, minWidth: 100},
				style: {width: 150, minWidth: 100},
				Filter: filterProps => (
					<TableColumnFilterSelect
						{...filterProps}
						options={PROJECT_REQUIREMENT_RATINGS.map(prop('value'))}
						formatOption={prop(__, PROJECT_REQUIREMENT_RATINGS_LABELS)}
						renderValue={pipe(
							map(prop(__, PROJECT_REQUIREMENT_RATINGS_LABELS)),
							join(', '),
						)}
					/>
				),
				filter: 'includesValue',
				Cell: ({
					cell: {
						row: {original},
					},
				}) => {
					const rating = prop('rating', original);
					const comment = prop('comment', original);
					const object = findAppliedRating(rating);
					const chipStyle = {
						backgroundColor: pathOr(
							'#e0e0e0',
							['style', 'backgroundColor'],
							object,
						),
						color: pathOr('rgba(0, 0, 0, 0.87)', ['style', 'color'], object),
					};

					if (!rating && isNilOrEmpty(comment)) return '-';

					return (
						<div>
							{rating && (
								<Chip
									size="small"
									label={propOr(rating, 'label', object)}
									style={chipStyle}
								/>
							)}
							{!isNilOrEmpty(comment) && (
								<Box mt={rating ? 1 : 0}>
									<Typography variant="body2" className={classes.comment}>
										{comment}
									</Typography>
								</Box>
							)}
						</div>
					);
				},
				disableSortBy: true,
			},
			{
				Header: 'Created at',
				id: 'createdAt',
				accessor: 'createdAt',
				Cell: ({cell: {value}}) => moment(value).format('MMMM Do YYYY, HH:mm'),
				Filter: TableColumnFilterDateRange,
				filter: filterByDateRange,
				headerStyle: {width: 125, minWidth: 125},
				style: {width: 125, minWidth: 125},
			},
		],
		[
			classes.comment,
			classes.requirementText,
			newPageAfterRequirementRemove,
			projectId,
		],
	);

	return (
		<TableUncontrolled
			{...props}
			// enableRowSelect
			columns={columns}
			data={requirements}
			totalCount={totalCount}
			tableTitle={
				<Grid container justifyContent="space-between">
					<Grid item>
						<Typography variant="h6" component="div" color="secondary">
							Project Requirements
						</Typography>
					</Grid>
					<Grid item>
						<Grid container spacing={3} alignItems="center">
							<Grid item>
								<Link
									underline="none"
									component={RouterLink}
									to={{
										pathname: `/projects/${projectId}/requirements/create`,
										state: {
											from: `${window.location.pathname}${window.location.search}`,
											newPageAfterRequirementAdd,
										},
									}}
								>
									<Button variant="contained" color="primary">
										Add Requirement
									</Button>
								</Link>
							</Grid>
							<Grid item>
								<AddRequirementsFromFile projectId={projectId} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			}
			noRecordsFoundMessage="You have not created any requirements for this project yet."
			pageOptions={REQUIREMENTS_PAGE_SIZE_DEFAULT_PAGE_OPTIONS}
			defaultPageSize={REQUIREMENTS_PAGE_SIZE_DEFAULT}
			paperProps={{elevation: 4}}
			queryParams={queryParams}
			setQueryParams={setQueryParams}
			// SelectedRowsActionsComponent={SelectedProjectsOptions}
			// selectedRowsActionsProps={props}
		/>
	);
};

export default props => {
	const match = useRouteMatch();
	const projectId = path(['params', 'projectId'], match);
	const [queryParams, setQueryParams] = useQueryParams({
		sortBy: JsonParam, // although this is actually a JSON array
		filters: JsonParam, // although this is actually a JSON array
		pageIndex: NumberParam,
		pageSize: NumberParam,
	});
	const {data, loading} = useQuery(GET_PROJECT_REQUIREMENTS, {
		variables: {projectId},
	});
	const requirements = pathOr([], ['getProject', 'requirements'], data);

	return (
		<WithSkeleton
			shouldShowSkeleton={loading}
			Skeleton={RequirementsTableSkeleton}
		>
			<RequirementsTableWrapper
				{...props}
				requirements={requirements}
				projectId={projectId}
				queryParams={queryParams}
				setQueryParams={setQueryParams}
			/>
		</WithSkeleton>
	);
};
