import React, {useState, useEffect} from 'react';
import {Typography, TextField, Grid, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	typography: {
		cursor: 'pointer',
		'&:hover': {
			fontStyle: 'italic',
		},
	},
}));

const EditableTypographyV2 = ({
	typographyProps = {},
	textFieldProps = {},
	okButtonProps = {},
	cancelButtonProps = {},
	initialIsEdit = false,
	initialValue,
	onSubmit = () => {},
	onCancel = () => {},
}) => {
	const classes = useStyles();
	const [isEdit, setIsEdit] = useState(initialIsEdit);
	const [value, setValue] = useState(initialValue);

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	const onTypographyClick = () => {
		setIsEdit(true);
	};

	const onInputChange = event => {
		setValue(event.target.value);
	};

	const onBeforeSubmit = inputValue => {
		setIsEdit(false);
		onSubmit(inputValue);
	};

	const cancel = () => {
		setValue(initialValue);
		setIsEdit(false);
		onCancel();
	};

	const onKeyDown = keyEvent => {
		if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
			keyEvent.preventDefault();
			const {value} = keyEvent.target;
			onBeforeSubmit(value);
		} else if ((keyEvent.charCode || keyEvent.keyCode) === 27) {
			cancel();
		}
	};

	if (!isEdit) {
		return (
			<Typography
				className={classes.typography}
				onClick={onTypographyClick}
				{...typographyProps}
			>
				{value}
			</Typography>
		);
	}

	return (
		<Grid container direction="column" spacing={1}>
			<Grid item xs={12}>
				<TextField
					autoFocus
					value={value}
					onChange={onInputChange}
					onKeyDown={onKeyDown}
					{...textFieldProps}
				/>
			</Grid>
			<Grid item container xs={12} spacing={1}>
				<Grid item>
					<Button
						size="small"
						variant="contained"
						color="primary"
						onClick={() => onBeforeSubmit(value)}
						{...okButtonProps}
					>
						Ok
					</Button>
				</Grid>
				<Grid item>
					<Button
						size="small"
						variant="outlined"
						color="primary"
						onClick={() => cancel()}
						{...cancelButtonProps}
					>
						Cancel
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default EditableTypographyV2;
