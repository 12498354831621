import React from 'react';
import {styled, makeStyles} from '@material-ui/styles';
import logo from '../../../../assets/logo.png';

const Container = styled('div')(({theme}) => ({
	...theme.mixins.toolbar,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	textAlign: 'center',
	color: theme.palette.primary.dark,
	fontFamily: "'Lemonada', cursive",
}));

const useStyles = makeStyles(() => ({
	'@keyframes bounce': {
		'from, 20%, 53%, 80%, to': {
			animationTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
			transform: 'translate3d(0, 0, 0)',
		},
		'40%, 43%': {
			animationTimingFunction: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
			transform: 'translate3d(0, -30px, 0)',
		},
		'70%': {
			animationTimingFunction: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
			transform: 'translate3d(0, -15px, 0)',
		},
		'90%': {
			transform: 'translate3d(0, -4px, 0)',
		},
	},
	animation: {
		animation: '$bounce 1s',
	},
}));

const LogoWithTitle = ({
	imgStyle = {},
	textStyle = {},
	withAnimation = false,
}) => {
	const defaultImgStyle = {width: 35, marginRight: 16};
	const defaultTextStyle = {fontSize: 25, fontWeight: 500};
	const classes = useStyles();

	return (
		<Container>
			<img
				className={withAnimation ? classes.animation : ''}
				src={logo}
				alt="logo"
				style={{...defaultImgStyle, ...imgStyle}}
			/>
			<div style={{...defaultTextStyle, ...textStyle}}>useReq</div>
		</Container>
	);
};

export default LogoWithTitle;
