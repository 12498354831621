import React from 'react';
import { LoginRegistrationContainer } from '../../../common/ui';
import LoginForm from './login-form';
import { Button } from '../../../common/control';
import { Grid, Box } from '@material-ui/core';
import { useEffect } from 'react';
import axios from 'axios';
import { useUi } from '../../../hoc';
import { useSnackbar } from 'notistack';


function loginWithGithub() {
	window.location.assign("https://github.com/login/oauth/authorize?client_id=" + process.env.REACT_APP_GITHUB_CLIENT_ID);
}

const LoginScreen = ({onAuthenticateSuccess}) => {
	const backendURL = process.env.REACT_APP_GRAPHQL_HTTP_URI.split('/graphql')[0];
	const snackbar = useSnackbar();
	const ui = useUi();

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const codeParam = urlParams.get("code");
		console.log("code:", codeParam);

		if (codeParam && localStorage.getItem("githubAccessToken") === null) {
			async function getAccessToken() {
				ui.setAppProgressShown(true);
				let res = await axios.get(`${backendURL}/api/users/getGithubAccessToken?code=${codeParam}`);
				console.log("Getting access token:", res.data);

				if (res.data.success === "false") {
					snackbar.enqueueSnackbar('Error while logging in: ' + res.data.message, {
						variant: 'error',
						anchorOrigin: {vertical: 'top', horizontal: 'center'},
					});
				} else if (res.data.success === "true") {
					const authData = {
						token: res.data.token,
						user: res.data.user
					}
					onAuthenticateSuccess(authData);
				}
				
				ui.setAppProgressShown(false);
			}
			getAccessToken();
		}
		// eslint-disable-next-line
	}, []);

	return (
		<LoginRegistrationContainer subtitle="Please fill the form below in order to login.">
			<LoginForm onAuthenticateSuccess={onAuthenticateSuccess} />
			
			{/* External logins */}
			<Grid container justifyContent="center">
				<Grid item xs={12} md={6} >
					<Box sx={{ mt: 4, mb: 4 }}> 
						<Button
							fullWidth
							id="forgot-password-button"
							color="secondary"
							variant="contained"
							sx={{ height: "15px" }}
							onClick={() => {
								loginWithGithub();
							}}
						>
							<img src="/assets/github-mark-white.svg" alt="Github logo" height="30px"/>&nbsp;&nbsp; Login with Github
						</Button>
					</Box>
				</Grid>
			</Grid>
		</LoginRegistrationContainer>
	);
};

export default LoginScreen;
