import React from 'react';
import {
	Divider,
	Drawer,
	Hidden,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	ListSubheader,
	Link,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory, useLocation, Link as RouterLink} from 'react-router-dom';
import {useQuery} from 'react-apollo';
import {path} from 'ramda';
import {
	DRAWER_WIDTH_PX,
	UsersIcon,
	InvitedUserIcon,
	InvitedUsersIcon,
	ProjectIcon,
	CreateProjectIcon,
} from '../../utilities/constants';
import {useUi} from '../hoc';
import logoBlue from '../../assets/logo_blue.png';
import {GET_MY_USER} from '../../graphql/queries';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	drawer: {
		[theme.breakpoints.up('md')]: {
			width: DRAWER_WIDTH_PX,
			flexShrink: 0,
		},
	},
	drawerPaper: {
		width: DRAWER_WIDTH_PX,
	},
	toolbar: theme.mixins.toolbar,
	logo: {
		width: '140px',
		height: 'auto',
		alignSelf: 'center',
		paddingTop: '7px',
		paddingBottom: '14px',
	},
}));

const isActiveRoute = routeName => ({location}) =>
	location.pathname.includes(routeName);

const makeRouteItemProps = routeName => props => ({
	selected: isActiveRoute(routeName)(props),
	// onClick: () => props.history.push(routeName),
});

const projectItems = [
	{
		Icon: <ProjectIcon />,
		Text: 'View Projects',
		to: '/projects',
		listItemPropsFn: makeRouteItemProps('/projects'),
	},
	{
		Icon: <CreateProjectIcon />,
		Text: 'Create Project',
		to: '/create-project',
		listItemPropsFn: makeRouteItemProps('/create-project'),
	},
];

const userItems = [
	{
		Icon: <UsersIcon />,
		Text: 'View Users',
		to: '/users',
		listItemPropsFn: makeRouteItemProps('/users'),
	},
	{
		Icon: <InvitedUsersIcon />,
		Text: 'View Invited Users',
		to: '/invited-users',
		listItemPropsFn: makeRouteItemProps('/invited-users'),
	},
	{
		Icon: <InvitedUserIcon />,
		Text: 'Invite User',
		to: '/create-invited-user',
		listItemPropsFn: makeRouteItemProps('/create-invited-user'),
	},
	{
		Icon: <InvitedUserIcon />,
		Text: 'TeamLead Requests',
		to: '/team-leads',
		listItemPropsFn: makeRouteItemProps('/team-leads'),
	},
];

const AppDrawer = () => {
	const history = useHistory();
	const location = useLocation();
	// const {user} = useMe();
	const classes = useStyles();
	const {drawerMobileOpen, setDrawerMobileOpen} = useUi();

	const handleDrawerToggle = () => {
		setDrawerMobileOpen(!drawerMobileOpen);
	};

	const {data} = useQuery(GET_MY_USER, {fetchPolicy: 'cache-only'});
	const role = path(['getMyUser', 'role'], data);

	if (role !== 'Admin') {
		return null;
	}

	const drawer = (
		<div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
			<img alt="usereq-logo" src={logoBlue} className={classes.logo} />
			<Divider />
			{/* Projects area */}
			<List
				component="nav"
				subheader={<ListSubheader component="div">Projects</ListSubheader>}
			>
				{projectItems.map(({Icon, Text, to, listItemPropsFn}, i) => (
					<Link
						key={i}
						component={RouterLink}
						to={to}
						underline="none"
						color="inherit"
					>
						<ListItem {...listItemPropsFn({history, location})}>
							<ListItemIcon>{Icon}</ListItemIcon>
							<ListItemText primary={Text} />
						</ListItem>
					</Link>
				))}
			</List>
			{/* Users area */}
			<List
				component="nav"
				subheader={<ListSubheader component="div">Users</ListSubheader>}
			>
				{userItems.map(({Icon, Text, to, listItemPropsFn}, i) => (
					<Link
						key={i}
						color="inherit"
						component={RouterLink}
						to={to}
						underline="none"
					>
						<ListItem button {...listItemPropsFn({history, location})}>
							<ListItemIcon>{Icon}</ListItemIcon>
							<ListItemText primary={Text} />
						</ListItem>
					</Link>
				))}
			</List>

			{/* Bottom  */}
			{/* <div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-end',
					height: '100%',
				}}
			>
				TODO: stats here
			</div> */}
		</div>
	);

	return (
		<nav className={classes.drawer} aria-label="mailbox folders">
			{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
			<Hidden mdUp implementation="css">
				<Drawer
					variant="temporary"
					anchor="left"
					open={drawerMobileOpen}
					classes={{
						paper: classes.drawerPaper,
					}}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					onClose={handleDrawerToggle}
				>
					{drawer}
				</Drawer>
			</Hidden>
			<Hidden smDown implementation="css">
				<Drawer
					open
					classes={{
						paper: classes.drawerPaper,
					}}
					variant="permanent"
				>
					{drawer}
				</Drawer>
			</Hidden>
		</nav>
	);
};

export default AppDrawer;
