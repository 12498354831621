import React, {useState, useEffect} from 'react';
import {Checkbox, TextField} from '@material-ui/core';
import MaterialAutocomplete from '@material-ui/lab/Autocomplete';
import {makeStyles} from '@material-ui/styles';
import {identity} from 'ramda';
import {
	CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
	CheckBox as CheckBoxIcon,
} from '@material-ui/icons';
import {isNilOrEmpty, mantainUniqueValues} from '../../../../utilities/tools';

const useStyles = makeStyles(() => ({
	inputRoot: {
		flexWrap: 'nowrap',
		backgroundColor: '#fff',
	},
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const TableColumnFilterAutocomplete = ({
	options,
	formatOption = identity, // used to format label option
	// Warning: if selected values are not plain texts then a custom renderValue must be provided!
	optionKeyExtractor = identity, // extract key from option item
	column: {filterValue, setFilter},
	chipProps = {},
}) => {
	const classes = useStyles();
	const [internalState, setInternalState] = useState(filterValue);

	const handleChange = (_, newValue, reason) => {
		const values = mantainUniqueValues(newValue, optionKeyExtractor);
		setInternalState(values);
		if (reason === 'clear') {
			setFilter(null);
		} else if (reason === 'remove-option') {
			setFilter(values);
		}
	};

	const handleClose = () => {
		setFilter(isNilOrEmpty(internalState) ? null : internalState);
	};

	// To reset internal state
	useEffect(() => {
		if (filterValue === null || filterValue === undefined) {
			setInternalState(null);
		}
	}, [filterValue]);

	// used to render checkbox state
	const selectedKeys = internalState
		? internalState.map(optionKeyExtractor)
		: [];

	return (
		<MaterialAutocomplete
			multiple
			disableCloseOnSelect
			openOnFocus
			classes={{
				inputRoot: classes.inputRoot,
			}}
			renderOption={option => (
				<>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{marginRight: 8}}
						checked={selectedKeys.includes(optionKeyExtractor(option))}
					/>
					{formatOption(option)}
				</>
			)}
			// just to avoid a material-ui warning
			getOptionSelected={(option, value) =>
				optionKeyExtractor(option) === optionKeyExtractor(value)
			}
			value={internalState || []}
			options={options || []}
			getOptionLabel={formatOption} // is used only for non-multiple
			renderInput={params => (
				<TextField {...params} margin="dense" variant="outlined" />
			)}
			ChipProps={{
				size: 'small',
				color: 'default',
				...chipProps,
			}}
			noOptionsText="No options to show."
			onClose={handleClose}
			onChange={handleChange}
		/>
	);
};

export default TableColumnFilterAutocomplete;
