import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { path, propEq } from 'ramda';
import { useMutation, useQuery } from 'react-apollo';
import { Prompt, useRouteMatch } from 'react-router-dom';
import {
	Box,
	Button,
	// FormControlLabel,
	Grid,
	// Radio,
	Typography,
	CircularProgress
} from '@material-ui/core';
import { CloudUpload, GetApp as DownloadFile } from '@material-ui/icons';
import gql from 'graphql-tag';
import isEqual from 'react-fast-compare';
import { GET_PROJECT_CICD } from '../../../../../../../graphql/queries';
import { ErrorStates } from '../../../../../../common/ui';
import { isNilOrEmpty, toJSONorYAML } from '../../../../../../../utilities/tools';
import { useUi } from '../../../../../../hoc';
import { useComponentDidUpdate } from '../../../../../../../hooks';
import CicdEditor from './ci-cd-editor';
import UploadCicdDialog from './upload-cicd-dialog';

const downloadAsFile = ({ value }) => {
	const dataStr =
		'data:text/json;charset=utf-8,' +
		encodeURIComponent(value);
	const dlAnchorElem = document.querySelector('#downloadAnchorElem');
	dlAnchorElem.setAttribute('href', dataStr);
	dlAnchorElem.setAttribute('download', 'ci-cd.yml');
	dlAnchorElem.click();
};

const UPDATE_PROJECT_CICD = gql`
	mutation UpdateProject($projectId: String!, $data: UpdateProjectInput!) {
		updateProject(projectId: $projectId, data: $data) {
			_id
			cicd
			createdAt
			updatedAt
		}
	}
`;

const objectize = value => {
	try {
		const parsed = JSON.parse(value);
		return parsed;
	} catch {
		return {};
	}
};

const Swagger = () => {
	const match = useRouteMatch();
	const ui = useUi();
	const projectId = path(['params', 'projectId'], match);
	const snackbar = useSnackbar();
	const {data, loading, error, refetch} = useQuery(GET_PROJECT_CICD, {
		notifyOnNetworkStatusChange: true,
		variables: {projectId},
	});
	const [updateProject, {loading: updateLoading}] = useMutation(
		UPDATE_PROJECT_CICD,
		{
			onCompleted: () => {
				ui.setAppProgressShown(false);
				snackbar.enqueueSnackbar('CI/CD updated succesfully!', {
					variant: 'success',
					autoHideDuration: 4000,
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
				});
				refetch();
			},
			onError: error => {
				console.error(error);
				ui.setAppProgressShown(false);
				snackbar.enqueueSnackbar(
					'Error occured, please make sure your Swagger input is valid!',
					{
						variant: 'error',
						autoHideDuration: 8000,
						anchorOrigin: {vertical: 'top', horizontal: 'center'},
					},
				);
			},
		},
	);
	const cicdProject = path(['getProject', 'cicd'], data);
	const initCicd = useMemo(() => {
		if (isNilOrEmpty(cicdProject)) return '';
		return JSON.stringify(cicdProject, null, 2);
	}, [cicdProject]);
	const initCicdInYAML = useMemo(() => {
		if (isNilOrEmpty(cicdProject)) return '';
		return cicdProject; //toJSONorYAML(JSON.stringify(cicdProject, null, 2), 'yaml')
	}, [cicdProject]);

	const [cicdValue, setCicdValue] = useState(initCicdInYAML);
	const [codeAnnotations, setCodeAnnotations] = useState([]);
	const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
	const viewMode = 'yaml';

	const submit = useCallback(
		(value, viewMode = 'yaml') => {
			try {
				const parsed = viewMode === 'yaml'? value : "";
				ui.setAppProgressShown(true);
				updateProject({
					variables: {
						projectId,
						data: {
							cicd: parsed,
						},
					},
				});
			} catch {
				snackbar.enqueueSnackbar(
					'There seems to be an error with your CI/CD input',
					{
						variant: 'error',
						autoHideDuration: 8000,
						anchorOrigin: {vertical: 'top', horizontal: 'center'},
					},
				);
			}
		},
		[projectId, snackbar, ui, updateProject],
	);

	useEffect(() => {
		// we only want this effect for synchronization of swagger after the mutation
		if (viewMode === 'yaml') {
			setCicdValue(initCicdInYAML);
		} else {
			setCicdValue(initCicd);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initCicd, initCicdInYAML]);

	useComponentDidUpdate(() => {
		setCicdValue(toJSONorYAML(cicdValue, viewMode));
	}, [viewMode]);

	const hasCodeError =
		Boolean(codeAnnotations) && codeAnnotations.some(propEq('type', 'error'));
	const dirty = !isEqual(
		cicdValue,
		viewMode === 'yaml' ? initCicdInYAML : initCicd,
	);

	const submitDisabled =
		!dirty ||
		(viewMode === 'json' && isNilOrEmpty(objectize(cicdValue))) ||
		(viewMode === 'yaml' && isNilOrEmpty(cicdValue)) ||
		hasCodeError;

	useEffect(() => {
		if (dirty) {
			// eslint-disable-next-line
			window.onbeforeunload = () => true;
		} else {
			// eslint-disable-next-line
			window.onbeforeunload = undefined;
		}
	}, [dirty]);

	useEffect(() => {
		return () => {
			// eslint-disable-next-line
			window.onbeforeunload = undefined;
		};
	}, []);

	if (error) {
		return (
			<ErrorStates.TryAgain withGoBackButton error={error} refetch={refetch} />
		);
	}

	return (
		<div>
			<Prompt
				when={dirty}
				message="You have unsaved changes. Are you sure you want to leave?"
			/>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={3}>
					<Typography variant="h6" color="secondary">
						Use the code editor in order to create your CI/CD workflow:
					</Typography>
					<Box mt={2}>
						<a href="/" id="downloadAnchorElem" style={{visibility: 'hidden'}}>
							&nbsp;
						</a>
						<Button
							variant="text"
							size="small"
							color="primary"
							startIcon={<DownloadFile />}
							disabled={updateLoading}
							onClick={() => downloadAsFile({value: initCicdInYAML})}
						>
							Download as Yaml file
						</Button>
						<Box mt="50px">
							<Grid container spacing={2}>
								<Grid item>
									<Button
										variant="outlined"
										color="primary"
										startIcon={<CloudUpload />}
										disabled={updateLoading}
										onClick={() => setUploadDialogOpen(true)}
									>
										Upload from file
									</Button>
								</Grid>
								<Grid item>
									<Box width="140px">
										{p => (
											<Button
												{...p}
												variant="contained"
												color="primary"
												disabled={submitDisabled || updateLoading}
												onClick={() => submit(cicdValue, viewMode)}
											>
												Submit
											</Button>
										)}
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Box>
					{hasCodeError && (
						<Box mt={1} fontWeight="bold">
							{p => (
								<Typography {...p} color="error" variant="body2">
									Your code has errors!
								</Typography>
							)}
						</Box>
					)}
				</Grid>
				<Grid item xs={12} lg={9}>
					{/* <SelectViewMode viewMode={viewMode} setViewMode={setViewMode} /> */}
					{
						!loading? (
							<CicdEditor
								disabled={updateLoading}
								initLoading={loading}
								swagger={cicdValue}
								setSwagger={setCicdValue}
								codeAnnotations={codeAnnotations}
								setCodeAnnotations={setCodeAnnotations}
								viewMode={viewMode}
							/>
						) : (
							<CircularProgress
								disableShrink
								// className={classes.circularProgress}
							/>
						)
					}
					
				</Grid>
			</Grid>
			<UploadCicdDialog
				open={uploadDialogOpen}
				setOpen={setUploadDialogOpen}
				afterFileAccepted={submit}
				editorValue={cicdValue}
				setEditorValue={setCicdValue}
			/>
		</div>
	);
};

export default Swagger;
