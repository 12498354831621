import React from 'react';

const WithSkeleton = ({
	shouldShowSkeleton,
	Skeleton,
	skeletonProps = {},
	children,
}) => {
	if (shouldShowSkeleton) {
		return <Skeleton {...skeletonProps} />;
	}

	return children;
};

export default WithSkeleton;
