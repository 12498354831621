import React, {useState} from 'react';
import {Box, Button} from '@material-ui/core';
import {CloudDownload} from '@material-ui/icons';
import {pathOr, pipe, map, propOr} from 'ramda';
import {ExportProjectsDialog} from '../../../../common/control';

const SelectedProjectsOptions = props => {
	const [open, setOpen] = useState(false);

	const selectedProjects = pipe(
		pathOr([], ['selectedRows']),
		map(propOr({}, 'original')),
	)(props);

	return (
		<Box>
			<Button
				variant="contained"
				color="primary"
				startIcon={<CloudDownload />}
				onClick={() => setOpen(true)}
			>
				Export
			</Button>
			<ExportProjectsDialog
				open={open}
				setOpen={setOpen}
				projects={selectedProjects}
			/>
		</Box>
	);
};

export default SelectedProjectsOptions;
