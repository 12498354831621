import React, { useMemo } from 'react';
import {
	pipe,
	propOr,
	length,
	path,
} from 'ramda';
import {
	Chip,
	Grid,
	Box,
	IconButton,
	Typography,
} from '@material-ui/core';
// import {useSnackbar} from 'notistack';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {WithSkeleton} from '../../../../common/hoc';
import {
	TableColumnFilterText,
	TableUncontrolled,
} from '../../../../common/control';
import {UsersTableSkeleton} from './skeletons';


const WonnabeTeamLeadsTableWrapper = ({data, makeUserTeamLead, refetchWonnabes, ...props}) => {
	// const snackbar = useSnackbar();
	const getUsers = propOr([], 'getWonnabeTeamLeads');
	const getTotalCount = pipe(propOr([], 'getWonnabeTeamLeads'), length);
	const users = getUsers(data);
	const totalCount = getTotalCount(data);
	// useEffect(() => {
	// 	console.log(data);
	// }, [])

	const columns = useMemo(
		() => [
			{
				Header: 'Accept/Decline',
				id: 'decision',
				accessor: 'decision',
				Filter: TableColumnFilterText,
				disableFilters: true,
				headerStyle: {
					marginTop: -35,
					width: 130, 
					minWidth: 130
				},
				style: {width: 130, minWidth: 130, fontWeight: 'bold'},
				Cell: ({cell: {value}, row: {original}}) => (
					<Box
						display={'flex'}
						flexDirection={'row'}
						alignItems={'center'}
						justifyContent={'start'}	
					>
						<Box sx={{mr: 2}}>
							<IconButton size="small" onClick={async () => {
								console.log('Accepting request');
								console.log(original);
								await makeUserTeamLead({
									variables: {
										userId: original._id,
										result: 'accept'
									},
								});
								await refetchWonnabes();
							}}>
								<CheckIcon fontSize="small" />
							</IconButton>
						</Box>
						<Box sx={{mr: 1}}>
							<IconButton size="small" onClick={async () => {
								console.log('declining request');
								console.log(original);
								await makeUserTeamLead({
									variables: {
										userId: original._id,
										result: 'decline'
									},
								});
								await refetchWonnabes();
							}}>
								<ClearIcon fontSize="small" />
							</IconButton>
						</Box>
					</Box>
				),
			},
			{
				Header: 'Username',
				id: 'username',
				accessor: 'username',
				Filter: TableColumnFilterText,
				headerStyle: {width: 160, minWidth: 160},
				style: {width: 160, minWidth: 160, fontWeight: 'bold'},
				Cell: ({cell: {value, row}}) => {
					const role = path(['original', 'role'], row); // TODO: edit user role
					return (
						<Grid container spacing={1}>
							<Grid item>{value}</Grid>
							<Grid item> 
								<Chip
									label={role}
									size="small"
									style={{fontWeight: 'normal'}}
								/>
							</Grid>
						</Grid>
					)
				},
			},
			{
				Header: 'Full name',
				id: 'fullName',
				accessor: 'fullName',
				Filter: TableColumnFilterText,
				headerStyle: {width: 175, minWidth: 175},
				style: {width: 175, minWidth: 175},
			},
			{
				Header: 'Email',
				id: 'email',
				accessor: 'email',
				Filter: TableColumnFilterText,
				headerStyle: {width: 200, minWidth: 175},
				style: {width: 200, minWidth: 175},
			}
		],
		// eslint-disable-next-line
		[],
	);

	return (
		<TableUncontrolled
			{...props}
			columns={columns}
			data={users}
			totalCount={totalCount}
			tableTitle={
				<Typography variant="h6" component="div" color="secondary">
					Users
				</Typography>
			}
		/>
	);
};

export default props => (
	<WithSkeleton
		shouldShowSkeleton={props.shouldShowSkeleton}
		Skeleton={UsersTableSkeleton}
	>
		<WonnabeTeamLeadsTableWrapper {...props} />
	</WithSkeleton>
);
