/* eslint-disable no-await-in-loop */
import {
	Box,
	Button,
	Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	FormHelperText,
	Grid,
	Typography,
} from '@material-ui/core';
import {Close, Done, Info, Warning} from '@material-ui/icons';
import {makeStyles} from '@material-ui/styles';
import {useFormik} from 'formik';
import {
	filter,
	path,
	prop,
	propEq,
	propOr,
	update,
	reject,
	propSatisfies,
	trim,
} from 'ramda';
import React, {useCallback, useMemo, useState, useEffect} from 'react';
import {useApolloClient} from 'react-apollo';
import * as Yup from 'yup';
import {
	CREATE_INVITED_USER,
	SEND_INVITATION_EMAIL,
} from '../../../../../graphql/mutations';
import {USER_ROLES} from '../../../../../utilities/constants';
import {isNilOrEmpty} from '../../../../../utilities/tools';
import {AutocompleteCreatable, Select} from '../../../../common/form';
import updateCache from './update-cache';

const INITIAL_VALUES = {emails: [], role: 'User'};

Yup.addMethod(Yup.array, 'unique', function(message, mapper = a => a) {
	return this.test('unique', message, function(list) {
		return !list || list.length === new Set(list.map(mapper)).size;
	});
});

const VALIDATION_SCHEMA = Yup.object().shape({
	emails: Yup.array()
		.unique('Emails list must be unique')
		.of(Yup.string().email('Each entry must be a valid e-mail!'))
		.min(1, 'Required')
		.required('Required'),
});

const useStyles = makeStyles(() => ({
	dialogContent: {
		minHeight: 150,
	},
}));

const StatusDialog = ({state = [], setState}) => {
	const [open, setOpen] = useState(false);
	const classes = useStyles();
	const completed = filter(propEq('status', 'completed'))(state);
	const errored = reject(propSatisfies(isNilOrEmpty, 'error'))(state);
	const finished = completed.length + errored.length;
	const percent = Math.round(100 * (finished / state.length));
	const allCompleted = state.length !== 0 && finished === state.length;
	const loading = state.length !== 0 && !allCompleted;
	const hasSomeErrors = errored.length !== 0;

	useEffect(() => {
		if (!isNilOrEmpty(state)) setOpen(true);
	}, [state]);

	return (
		<Dialog
			fullWidth
			open={open}
			//disableBackdropClick={!allCompleted}
			disableEscapeKeyDown={!allCompleted}
			maxWidth="xs"
			onClose={() => setOpen(false)}
			onExited={() => setState([])}
		>
			<DialogContent className={classes.dialogContent}>
				{hasSomeErrors && allCompleted && (
					<Typography variant="h5">
						<Warning /> There were some problems with inviting some users.
					</Typography>
				)}
				{!hasSomeErrors && allCompleted && (
					<Typography variant="h5" style={{color: 'green'}}>
						<Done style={{color: 'green'}} /> All users have been invited
						successfully.
					</Typography>
				)}
				{loading && (
					<>
						<Box mb={3}>
							<Typography variant="h5">Inviting users...</Typography>
						</Box>
						<Box textAlign="center">
							<Box position="relative" display="inline-flex">
								<CircularProgress
									variant="determinate"
									value={percent}
									size={60}
								/>
								<Box
									top={0}
									left={0}
									bottom={0}
									right={0}
									position="absolute"
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									<Typography
										variant="caption"
										component="div"
										color="textSecondary"
									>{`${percent}%`}</Typography>
								</Box>
							</Box>
						</Box>{' '}
					</>
				)}
				{allCompleted && hasSomeErrors && (
					<Box mt={3}>
						<Typography variant="body1" color="textSecondary">
							Below you can see for which users the invitation was not completed
							properly. All the other users that are not listed below, were
							successfully invited.
						</Typography>
						<Box mt={2}>
							{errored.map(({email, error}) => (
								<Grid key={email} container spacing={2} direction="column">
									<Grid item xs={12}>
										<Grid container spacing={1} alignItems="center">
											<Grid item>
												<Chip label={email} />
											</Grid>
											<Grid item>
												<Typography color="error" variant="body2">
													<Close
														fontSize="inherit"
														style={{verticalAlign: 'middle'}}
													/>{' '}
													{error}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							))}
						</Box>
					</Box>
				)}
			</DialogContent>
			{allCompleted && (
				<DialogActions>
					<Button onClick={() => setOpen(false)}>Close</Button>
				</DialogActions>
			)}
		</Dialog>
	);
};

const InviteMultipleUsersForm = () => {
	const [state, setState] = useState([]);
	const apolloClient = useApolloClient();

	const setEmailInviteState = useCallback(
		(email, state) =>
			setState((_state = []) => {
				const index = _state.findIndex(propEq('email', email));
				if (index === -1) {
					return [..._state, {email, ...state}];
				}

				return update(index, {email, ...state}, _state);
			}),
		[],
	);

	const handleSubmit = useCallback(
		async values => {
			const emails = propOr([], 'emails', values);
			const role = prop('role', values);

			for (const email of emails) {
				try {
					setEmailInviteState(email, {status: 'creating'});
					const {data: data1} = await apolloClient.mutate({
						mutation: CREATE_INVITED_USER,
						variables: {data: {email, role}},
						update: updateCache,
					});
					const invitedUserId = path(['createInvitedUser', '_id'], data1);
					try {
						setEmailInviteState(email, {status: 'emailing'});
						await apolloClient.mutate({
							mutation: SEND_INVITATION_EMAIL,
							variables: {invitedUserId},
						});
						setEmailInviteState(email, {status: 'completed'});
					} catch (error) {
						// email error
						const message = `User invited but could not send e-mail, reason: ${propOr(
							'',
							'message',
							error,
						).replace('GraphQL error:', '')}`;

						setEmailInviteState(email, {error: message});
					}
				} catch (error) {
					// invite error
					const message = `User could not be invited, reason: ${propOr(
						'',
						'message',
						error,
					).replace('GraphQL error:', '')}`;
					setEmailInviteState(email, {error: message});
				}
			}
		},
		[apolloClient, setEmailInviteState],
	);

	const formikProps = useFormik({
		initialValues: INITIAL_VALUES,
		onSubmit: handleSubmit,
		validationSchema: VALIDATION_SCHEMA,
		initialTouched: {emails: true},
	});

	const shouldWarnAboutAdmins = useMemo(
		() => formikProps.values.role === 'Admin',
		[formikProps.values.role],
	);

	const isSubmitDisabled = !isNilOrEmpty(formikProps.errors);

	const onPaste = useCallback(
		event => {
			const paste = (event.clipboardData || window.clipboardData).getData(
				'text',
			);
			if (paste) {
				try {
					const arr = paste.split(',').map(trim);
					formikProps.setFieldValue('emails', arr);
					event.preventDefault();
					return false;
				} catch {}
			}
		},
		[formikProps],
	);

	return (
		<>
			<StatusDialog state={state} setState={setState} />
			<Grid container spacing={2} justifyContent="center">
				<Grid item xs={12}>
					<AutocompleteCreatable
						{...formikProps}
						formatInputValueBeforeAdding={s => s && s.toLowerCase()}
						name="emails"
						label="*Emails"
						options={[]}
						autoCompleteProps={{
							ChipProps: {
								color: 'default',
							},
						}}
						textFieldProps={{
							placeholder: 'Type the e-mails',
							fullWidth: true,
							margin: 'none',
							variant: 'outlined',
							onPaste,
						}}
					/>
					<FormHelperText>
						<Info fontSize="inherit" />
						&nbsp; You can insert multiple values by pasting e-mails separated
						with commas.
					</FormHelperText>
				</Grid>
				<Grid item xs={12}>
					<Select
						{...formikProps}
						name="role"
						label="Role*"
						options={USER_ROLES}
					/>
					{shouldWarnAboutAdmins && (
						<FormHelperText style={{color: 'rgb(162 67 0)'}}>
							<Warning fontSize="inherit" color="inherit" /> Warning, you are
							going to create multiple Admin users.
						</FormHelperText>
					)}
				</Grid>
				<Grid item xs={12} md={6}>
					<Box mt={[1, 1, 3]}>
						<Button
							fullWidth
							id="create-invited-user-form-submit-button"
							type="submit"
							color="primary"
							variant="contained"
							disabled={isSubmitDisabled}
							onClick={() => formikProps.handleSubmit()}
						>
							Submit
						</Button>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default InviteMultipleUsersForm;
