import gql from 'graphql-tag';

export default gql`
	mutation UpdateProject($projectId: String!, $data: UpdateProjectInput!) {
		updateProject(projectId: $projectId, data: $data) {
			_id
			stories {
				_id
				seq
				title
				text
				rating
				comment
				createdAt
				updatedAt
				requirementsLinked {
					_id
					seq
					text
				}
			}
			createdAt
			updatedAt
		}
	}
`;
