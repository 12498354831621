import React, {useState} from 'react';
import {TextField as MaterialTextField} from '@material-ui/core';
import {getIn} from 'formik';
import {getFormErrorsField} from '../../../../utilities/tools';
import {Skeletons} from '../../ui';

const FastTextField = props => {
	const {name, label, textFieldProps, ...formikProps} = props;
	const {values, setFieldValue, handleBlur, errors, touched} = formikProps;
	const formikValue = getIn(values, name);
	const [value, setValue] = useState(formikValue);

	const onChange = e => {
		setValue(e.target.value);
	};

	const onFocus = _ => {
		setValue(formikValue);
	};

	const onBlur = e => {
		setFieldValue(name, value);
		handleBlur(e);
	};

	return (
		<MaterialTextField
			fullWidth
			variant="outlined"
			name={name}
			label={label}
			value={value}
			error={Boolean(getFormErrorsField(name, errors, touched))}
			helperText={getFormErrorsField(name, errors, touched)}
			onChange={onChange}
			onBlur={onBlur}
			onFocus={onFocus}
			{...textFieldProps}
		/>
	);
};

const TextField = ({updateFormikOnlyOnBlur, ...props}) => {
	const {
		name,
		label,
		textFieldProps,
		initLoading,
		skeletonProps = {},
		...formikProps
	} = props;
	const {values, errors, touched, handleChange, handleBlur} = formikProps;

	if (updateFormikOnlyOnBlur) {
		return <FastTextField {...props} />;
	}

	if (initLoading) return <Skeletons.TextFieldSkeleton {...skeletonProps} />;

	return (
		<MaterialTextField
			fullWidth
			variant="outlined"
			name={name}
			label={label}
			value={getIn(values, name)}
			error={Boolean(getFormErrorsField(name, errors, touched))}
			helperText={getFormErrorsField(name, errors, touched)}
			onChange={handleChange}
			onBlur={handleBlur}
			{...textFieldProps}
		/>
	);
};

export default TextField;
