import React, {useState} from 'react';
import {Popover, TextField, Box, Grid, Button} from '@material-ui/core';
import {DatePicker, DateTimePicker} from '@material-ui/pickers';
import {ArrowRightAlt as ArrowRightIcon} from '@material-ui/icons';
import moment from 'moment';
import {isNilOrEmpty} from '../../../../utilities/tools';

const defaultTextFieldProps = {
	fullWidth: true,
	variant: 'outlined',
	margin: 'dense',
};

const defaultDateProps = {
	variant: 'static',
};

const formatDateLabel = ({time}) => date => {
	if (!date) return '-';
	const format = time ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
	if (moment.isMoment(date)) {
		return date.format(format);
	}

	return moment(date).format(format);
};

const getTextFieldValue = props => (startDate, endDate) => {
	if (isNilOrEmpty(startDate) && isNilOrEmpty(endDate)) {
		return '';
	}

	if (isNilOrEmpty(startDate)) {
		return `To: ${formatDateLabel(props)(endDate)}`;
	}

	if (isNilOrEmpty(endDate)) {
		return `From: ${formatDateLabel(props)(startDate)}`;
	}

	return `${formatDateLabel(props)(startDate)} ~ ${formatDateLabel(props)(
		endDate,
	)}`;
};

const DateRangePicker = ({
	time = false,
	textFieldProps = {},
	popoverProps = {},
	clearable = true,
	startDate,
	endDate,
	onChange,
	onBlur,
}) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		if (onBlur) onBlur();
	};

	const clear = () => {
		onChange(null, null);
	};

	const open = Boolean(anchorEl);

	const startDateProps = {
		...defaultDateProps,
		disableToolbar: !time,
		value: startDate,
		onChange: _newStartDate => {
			const newStartDate = time
				? _newStartDate
				: _newStartDate.set({hour: 0, minute: 0, second: 0, millisecond: 0});
			if (
				endDate &&
				moment.isMoment(endDate) &&
				newStartDate.isAfter(endDate)
			) {
				onChange(newStartDate, newStartDate);
			} else {
				onChange(newStartDate, endDate);
			}
		},
	};
	const endDateProps = {
		...defaultDateProps,
		disableToolbar: !time,
		value: endDate,
		...(startDate ? {minDate: startDate} : {}),
		onChange: _newEndDate => {
			const newEndDate = time
				? _newEndDate
				: _newEndDate.set({hour: 0, minute: 0, second: 0, millisecond: 0});
			onChange(startDate, newEndDate);
		},
	};

	const content = (
		<Grid
			container
			direction="column"
			spacing={0}
			justifyContent="center"
			align="center"
		>
			{!time && (
				<Grid item xs>
					<Grid container spacing={0}>
						<Grid item xs={6}>
							{formatDateLabel({time})(startDate)}
							<ArrowRightIcon
								style={{position: 'absolute', right: '48%', top: 13}}
							/>
						</Grid>

						<Grid item xs={6}>
							{formatDateLabel({time})(endDate)}
						</Grid>
					</Grid>
				</Grid>
			)}
			<Grid item xs>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						{time ? (
							<DateTimePicker {...startDateProps} />
						) : (
							<DatePicker {...startDateProps} />
						)}
					</Grid>
					<Grid item xs={12} sm={6}>
						{time ? (
							<DateTimePicker {...endDateProps} />
						) : (
							<DatePicker {...endDateProps} />
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs style={{textAlign: 'right'}}>
				{clearable && (
					<Button
						size="small"
						style={{marginRight: 16}}
						onClick={() => clear()}
					>
						Clear
					</Button>
				)}
				<Button
					size="small"
					color="primary"
					variant="contained"
					onClick={() => handleClose()}
				>
					Done
				</Button>
			</Grid>
		</Grid>
	);

	return (
		<div>
			<TextField
				value={getTextFieldValue({time})(startDate, endDate)}
				InputProps={{
					readOnly: true,
				}}
				// eslint-disable-next-line
				inputProps={{
					style: {
						cursor: 'default',
					},
				}}
				onClick={handleClick}
				{...defaultTextFieldProps}
				{...textFieldProps}
			/>
			<Popover
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={handleClose}
				{...popoverProps}
			>
				<Box p={2}>{content}</Box>
			</Popover>
		</div>
	);
};

export default DateRangePicker;
