import {
	Box,
	Button,
	Chip,
	Grid,
	Link,
	Tooltip,
	Typography,
} from '@material-ui/core';
import gql from 'graphql-tag';
import {
	find,
	identity,
	ifElse,
	isEmpty,
	join,
	map,
	path,
	pathOr,
	pipe,
	prop,
	propEq,
	propOr,
	__,
} from 'ramda';
import React, { useMemo } from 'react';
import {useQuery} from 'react-apollo';
import {useRouteMatch, Link as RouterLink} from 'react-router-dom';
// import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import {makeStyles} from '@material-ui/styles';
import {JsonParam, NumberParam, useQueryParams} from 'use-query-params';
import {
	TableColumnFilterDateRange,
	TableColumnFilterSelect,
	TableColumnFilterText,
	TableUncontrolled,
} from '../../../../../../common/control';
import {WithSkeleton} from '../../../../../../common/hoc';
import {
	filterByDateRange,
	isNilOrEmpty,
} from '../../../../../../../utilities/tools';
import {
	ProjectRequirementIcon,
	PROJECT_STORY_RATINGS,
} from '../../../../../../../utilities/constants';
import {StoriesTableSkeleton} from './skeletons';
import {
	STORIES_PAGE_SIZE_DEFAULT,
	STORIES_PAGE_SIZE_DEFAULT_PAGE_OPTIONS,
} from '.';
// import { warnOnceInDevelopment } from 'apollo-utilities';

const PROJECT_STORY_RATINGS_LABELS = PROJECT_STORY_RATINGS.reduce(
	(acc, {label, value}) => ({...acc, [value]: label}),
	{},
);

const GET_PROJECT_STORIES = gql`
	query GetProjectStories($projectId: String!) {
		getProject(projectId: $projectId) {
			_id
			stories {
				_id
				seq
				title
				rating
				comment
				requirementsLinked {
					_id
					seq
					text
				}
				createdAt
				updatedAt
			}
			createdAt
			updatedAt
		}
	}
`;

const findAppliedRating = value =>
	pipe(find(propEq('value', value)))(PROJECT_STORY_RATINGS);

const useStyles = makeStyles(theme => ({
	storyText: {
		fontWeight: 600,
		'&:hover': {color: theme.palette.primary.main},
	},
	ratingChips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	ratingChip: {
		margin: 2,
	},
	comment: {
		whiteSpace: 'pre-wrap',
		lineHeight: '16px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		boxOrient: 'vertical',
		WebkitBoxOrient: 'vertical',
		lineClamp: 3,
		WebkitLineClamp: 3,
		maxHeight: `${3 *
			Number(pathOr(1.5, ['typography', 'body2', 'lineHeight'], theme))}em`,
	},
}));

const StoriesTableWrapper = (props) => {
	const classes = useStyles();
	const {
		queryParams,
		setQueryParams,
		projectId,
		setOpenCreate,
		setOpenEdit,
		setStoryToEdit,
	} = props;
	
	const stories = propOr([], 'stories', props);
	const totalCount = stories.length;
	const pageIndex = propOr(0, 'pageIndex', queryParams);
	const pageSize = propOr(STORIES_PAGE_SIZE_DEFAULT, 'pageSize', queryParams);

	const newPageAfterStoryRemove = useMemo(
		() =>
			totalCount === 1 || (totalCount - 1) % pageSize !== 0
				? pageIndex
				: pageIndex - 1,
		[pageIndex, pageSize, totalCount],
	);

	// const newPageAfterStoryAdd = useMemo(() => {
	// 	const lastPage = Math.floor(totalCount / pageSize);
	// 	return lastPage;
	// }, [pageSize, totalCount]);

	const columns = useMemo(
		() => [
			{
				Header: '#',
				id: 'seq',
				accessor: pipe(
					propOr('', 'seq'),
					ifElse(isEmpty, identity, s => `S-${s}`),
				),
				disableSortBy: false,
				disableFilters: true,
				headerStyle: {width: 25, maxWidth: 25, marginTop: -35},
				style: {width: 25, maxWidth: 25},
			},
			{
				Header: 'Story Title',
				id: 'title',
				accessor: 'title',
				disableSortBy: true,
				Filter: TableColumnFilterText,
				headerStyle: {width: 100, minWidth: 100},
				style: {width: 100, minWidth: 100},
				Cell: ({cell: {value}, row: {original}}) => (
					<Link
						color="textPrimary"
						underline="none"
						component={RouterLink}
						to={{}} // goes nowhere - used just for styling
					>
						<Typography className={classes.storyText} variant="body1" onClick={() => {
							console.log("clicked on:", original);
							setStoryToEdit(original);
							setOpenEdit(true);
						}}>
							{value}
						</Typography>
					</Link>
				),
			},
			{
				Header: 'Rating',
				id: 'rating',
				accessor: 'rating',
				headerStyle: {width: 100, minWidth: 100},
				style: {width: 100, minWidth: 100},
				Filter: filterProps => (
					<TableColumnFilterSelect
						{...filterProps}
						options={PROJECT_STORY_RATINGS.map(prop('value'))}
						formatOption={prop(__, PROJECT_STORY_RATINGS_LABELS)}
						renderValue={pipe(
							map(prop(__, PROJECT_STORY_RATINGS_LABELS)),
							join(', '),
						)}
					/>
				),
				filter: 'includesValue',
				Cell: ({
					cell: {
						row: {original},
					},
				}) => {
					const rating = propOr([], 'rating', original);
					const comment = prop('comment', original);

					if (isNilOrEmpty(rating) && isNilOrEmpty(comment)) return '-';

					return (
						<div>
							<div className={classes.ratingChips}>
								{rating.map(value => {
									const object = findAppliedRating(value);
									const chipStyle = {
										backgroundColor: pathOr(
											'#e0e0e0',
											['style', 'backgroundColor'],
											object,
										),
										color: pathOr(
											'rgba(0, 0, 0, 0.87)',
											['style', 'color'],
											object,
										),
									};

									return (
										<Chip
											key={value}
											className={classes.ratingChip}
											size="small"
											label={propOr(value, 'label', object)}
											style={chipStyle}
										/>
									);
								})}
							</div>
							{!isNilOrEmpty(comment) && (
								<Box mt={isNilOrEmpty(rating) ? 0 : 1}>
									<Typography variant="body2" className={classes.comment}>
										{comment}
									</Typography>
								</Box>
							)}
						</div>
					);
				},
				disableSortBy: true,
			},
			{
				Header: 'Linked Requirements',
				id: 'requirementsLinked',
				accessor: propOr([], 'requirementsLinked'),
				disableSortBy: true,
				disableFilters: true,
				headerStyle: {width: 135, minWidth: 135, marginTop: -35},
				style: {width: 135, minWidth: 135},
				Cell: ({cell: {value: requirementsLinked}}) => {
					if (isEmpty(requirementsLinked)) return '-';

					return (
						<Grid container spacing={1}>
							{requirementsLinked.map(({_id: reqId, seq, text}) => (
								<Grid key={seq} item>
									<Tooltip
										title={
											<Typography variant="body2">{`(R-${seq}) ${text}`}</Typography>
										}
										placement="top"
									>
										<Link
											component={RouterLink}
											to={{
												pathname: `/projects/${projectId}/requirements/${reqId}`,
											}}
											underline="none"
										>
											<Chip
												clickable
												label={`R-${seq}`}
												size="small"
												icon={<ProjectRequirementIcon />}
											/>
										</Link>
									</Tooltip>
								</Grid>
							))}
						</Grid>
					);
				},
			},
			{
				Header: 'Created at',
				id: 'createdAt',
				accessor: 'createdAt',
				Cell: ({cell: {value}}) => moment(value).format('MMMM Do YYYY, HH:mm'),
				Filter: TableColumnFilterDateRange,
				filter: filterByDateRange,
				headerStyle: {width: 75, minWidth: 75},
				style: {width: 75, minWidth: 75},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			classes.comment,
			classes.ratingChip,
			classes.ratingChips,
			classes.storyText,
			newPageAfterStoryRemove,
			projectId,
		],
	);

	return (
		<TableUncontrolled
			{...props}
			// enableRowSelect
			columns={columns}
			data={stories}
			totalCount={totalCount}
			tableTitle={
				<Grid container justifyContent="space-between">
					<Grid item>
						<Typography variant="h6" component="div" color="secondary">
							Project Stories
						</Typography>
					</Grid>
					<Grid item>
						<Button variant="contained" color="primary" onClick={() => setOpenCreate(true)}>
							Add Story
						</Button>
					</Grid>
				</Grid>
			}
			noRecordsFoundMessage="You have not created any stories for this project yet."
			pageOptions={STORIES_PAGE_SIZE_DEFAULT_PAGE_OPTIONS}
			defaultPageSize={STORIES_PAGE_SIZE_DEFAULT}
			paperProps={{elevation: 4}}
			queryParams={queryParams}
			setQueryParams={setQueryParams}
			// SelectedRowsActionsComponent={SelectedProjectsOptions}
			// selectedRowsActionsProps={props}
		/>
	);
};

export default (props) => {
	const match = useRouteMatch();
	const projectId = path(['params', 'projectId'], match);
	const [queryParams, setQueryParams] = useQueryParams({
		sortBy: JsonParam, // although this is actually a JSON array
		filters: JsonParam, // although this is actually a JSON array
		pageIndex: NumberParam,
		pageSize: NumberParam,
	});
	const {data, loading} = useQuery(GET_PROJECT_STORIES, {
		variables: {projectId},
	});
	const stories = pathOr([], ['getProject', 'stories'], data);

	return (
		<WithSkeleton shouldShowSkeleton={loading} Skeleton={StoriesTableSkeleton}>
			<StoriesTableWrapper
				{...props}
				stories={stories}
				projectId={projectId}
				queryParams={queryParams}
				setQueryParams={setQueryParams}
			/>
		</WithSkeleton>
	);
};
