import React, {lazy, Suspense} from 'react';
import {
	Tabs,
	Tab,
	Box,
	useMediaQuery,
	CircularProgress,
} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/styles';
import {Edit, PlayArrow} from '@material-ui/icons';
import Swagger from './swagger';

const SwaggerUIPreview = lazy(() => import('./swagger-ui-preview'));

const SuspenseFallback = () => (
	<Box
		height="500px"
		display="flex"
		alignItems="center"
		justifyContent="center"
		flexDirection="column"
	>
		<Box flex={0.25}>
			<CircularProgress />
		</Box>
	</Box>
);

const useStyles = makeStyles(theme => ({
	root: {
		'& .ui-tabpanel': {
			marginTop: '24px',
		},

		[theme.breakpoints.up('md')]: {
			flexGrow: 1,
			backgroundColor: theme.palette.background.paper,
			display: 'flex',
			minHeight: '70vh',
			padding: '16px',
			borderRadius: '8px',
			'& .ui-tabpanel': {
				flex: 1,
			},
		},
	},
	tabs: {
		[theme.breakpoints.up('md')]: {
			borderRight: `1px solid ${theme.palette.divider}`,
		},
	},
	tabWrapper: {
		[theme.breakpoints.up('md')]: {
			alignItems: 'flex-start',
		},
	},
}));

function TabPanel(props) {
	const {children, value, index, ...other} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			className="ui-tabpanel"
			{...other}
		>
			{value === index && <Box p="0 24px">{children}</Box>}
		</div>
	);
}

export default props => {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);
	const theme = useTheme();
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));

	const handleChange = (_, newValue) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			<Tabs
				centered={false}
				orientation={isSm ? 'horizontal' : 'vertical'}
				variant="fullWidth"
				value={value}
				aria-label="Vertical tabs example"
				className={classes.tabs}
				onChange={handleChange}
			>
				<Tab
					classes={{wrapper: classes.tabWrapper}}
					label={
						<div>
							<Box mr={1} component="span">
								<Edit fontSize="inherit" />
							</Box>
							Edit Swagger
						</div>
					}
				/>
				<Tab
					classes={{wrapper: classes.tabWrapper}}
					label={
						<div>
							<Box mr={1} component="span">
								<PlayArrow fontSize="inherit" />
							</Box>
							Preview
						</div>
					}
				/>
			</Tabs>
			<TabPanel value={value} index={0}>
				<Swagger {...props} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Suspense fallback={<SuspenseFallback />}>
					<SwaggerUIPreview {...props} />
				</Suspense>
			</TabPanel>
		</div>
	);
};
