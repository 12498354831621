import {
	Chip,
	Grid,
	IconButton,
	Link,
	Tooltip,
	Typography,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {makeStyles} from '@material-ui/styles';
import gql from 'graphql-tag';
import moment from 'moment';
import {isEmpty, path, pathOr} from 'ramda';
import React, { useEffect } from 'react';
import {useQuery} from 'react-apollo';
import {useRouteMatch, Link as RouterLink} from 'react-router-dom';
import {format} from 'timeago.js';
import {
	ProjectSettingsIcon,
	ProjectTagIcon,
	UserIcon,
} from '../../../../../../../utilities/constants';

import StarIcon from '@material-ui/icons/Star';

import BoxInfo from './box-info';

const useStyles = makeStyles(() => ({
	settingsButton: {
		top: 0,
		right: 0,
		position: 'absolute',
	},
}));

const GET_PROJECT_INFO = gql`
	query GetProjectInfo($projectId: String!) {
		getProject(projectId: $projectId) {
			_id
			description
			creatorId
			users {
				_id
				username
				fullName
			}
			invitedUsers
			tags
			createdAt
			updatedAt
		}
	}
`;

const SkeletonTitle = () => <Skeleton width={150} variant="rect" />;

const InfoSection = () => {
	const classes = useStyles();
	const match = useRouteMatch();
	const projectId = path(['params', 'projectId'], match);
	const {data, loading, refetch: refetch_data} = useQuery(GET_PROJECT_INFO, {
		variables: {projectId},
	});

	const description = pathOr('N/A', ['getProject', 'description'], data);
	const createdAt = pathOr(null, ['getProject', 'createdAt'], data);
	const updatedAt = pathOr(null, ['getProject', 'updatedAt'], data);
	const tags = pathOr([], ['getProject', 'tags'], data);
	const users = pathOr([], ['getProject', 'users'], data);
	const invitedUsers = pathOr([], ['getProject', 'invitedUsers'], data);
	const creatorId = pathOr([], ['getProject', 'creatorId'], data);

	useEffect(() => {
		// console.log('Dashboard Created!')
		// console.log('refetching data')
		refetch_data();
		// console.log(data);
		// eslint-disable-next-line
	}, [data])

	return (
		<div>
			{!loading && (
				<Link
					component={RouterLink}
					underline="none"
					to={`/projects/${projectId}/settings`}
				>
					<IconButton size="small" className={classes.settingsButton}>
						<ProjectSettingsIcon />
					</IconButton>
				</Link>
			)}
			<BoxInfo
				divider
				loading={loading}
				title="Description"
				contents={[
					<Typography key="description" variant="body2">
						{description}
					</Typography>,
				]}
				SkeletonTitle={<SkeletonTitle />}
				skeletonContents={[
					<div key="description-skeleton">
						<Skeleton width="100%" variant="text" />
						<Skeleton width="75%" variant="text" />
					</div>,
				]}
			/>
			<BoxInfo
				divider
				type="chip"
				loading={loading}
				title="Users"
				contents={[
					<Grid key="users" container spacing={1} alignItems="center">
						{!users || isEmpty(users) ? (
							<Grid item>
								<Typography variant="body2" color="textSecondary">
									No users have been assigned to this project.
								</Typography>
							</Grid>
						) : (
							users.map(({username, fullName, _id}) => (
								<Grid key={username} item>
									<Tooltip title={fullName} placement="top">
										<Chip
											size="small"
											color={"secondary"}
											label={username}
											icon={creatorId === _id? <StarIcon /> : <UserIcon />}
										/>
									</Tooltip>
								</Grid>
							))
						)}
					</Grid>,
				]}
				skeletonContents={[
					<Grid key="users-skeleton" container spacing={1} alignItems="center">
						<Grid item>
							<Skeleton
								width="80px"
								variant="rect"
								style={{borderRadius: '8px'}}
							/>
						</Grid>
						<Grid item>
							<Skeleton
								width="70px"
								variant="rect"
								style={{borderRadius: '8px'}}
							/>
						</Grid>
					</Grid>,
				]}
				SkeletonTitle={<SkeletonTitle />}
			/>
			<BoxInfo
				divider
				type="chip"
				loading={loading}
				title="Invited Users"
				contents={[
					<Grid key="invitedUsers" container spacing={1} alignItems="center">
						{isEmpty(invitedUsers) || loading ? (
							<Grid item>
								<Typography variant="body2" color="textSecondary">
									No pending invites.
								</Typography>
							</Grid>
						) : (
							invitedUsers.map((userEmail, index) => (
								<Grid key={userEmail} item>
									<Tooltip title={userEmail} placement="top">
										<Chip
											size="small"
											color="secondary"
											label={userEmail}
											icon={<UserIcon />}
										/>
									</Tooltip>
								</Grid>
							))
						)}
					</Grid>,
				]}
				skeletonContents={[
					<Grid key="users-skeleton" container spacing={1} alignItems="center">
						<Grid item>
							<Skeleton
								width="80px"
								variant="rect"
								style={{borderRadius: '8px'}}
							/>
						</Grid>
						<Grid item>
							<Skeleton
								width="70px"
								variant="rect"
								style={{borderRadius: '8px'}}
							/>
						</Grid>
					</Grid>,
				]}
				SkeletonTitle={<SkeletonTitle />}
			/>
			<BoxInfo
				divider
				type="chip"
				loading={loading}
				title="Tags"
				contents={[
					<Grid key="tags" container spacing={1} alignItems="center">
						{isEmpty(tags) ? (
							<Grid item>
								<Typography variant="body2" color="textSecondary">
									There are not any tags for this project.
								</Typography>
							</Grid>
						) : (
							tags.map(tag => (
								<Grid key={tag} item>
									<Chip
										size="small"
										color="primary"
										label={tag}
										icon={<ProjectTagIcon />}
									/>
								</Grid>
							))
						)}
					</Grid>,
				]}
				skeletonContents={[
					<Grid key="tags-skeleton" container spacing={1} alignItems="center">
						<Grid item>
							<Skeleton
								width="50px"
								variant="rect"
								style={{borderRadius: '8px'}}
							/>
						</Grid>
						<Grid item>
							<Skeleton
								width="50px"
								variant="rect"
								style={{borderRadius: '8px'}}
							/>
						</Grid>
						<Grid item>
							<Skeleton
								width="50px"
								variant="rect"
								style={{borderRadius: '8px'}}
							/>
						</Grid>
					</Grid>,
				]}
				SkeletonTitle={<SkeletonTitle />}
			/>
			<BoxInfo
				loading={loading}
				title="Information"
				contents={[
					<Typography key="createdAt" variant="body2">
						Project created on {moment(createdAt).format('MMMM Do YYYY, HH:mm')}
					</Typography>,
					<Typography key="updatedAt" variant="body2">
						Latest update was {format(updatedAt)}
					</Typography>,
				]}
				SkeletonTitle={<SkeletonTitle />}
				skeletonContents={[
					<Skeleton key="createdAt-skeleton" width="70%" variant="text" />,
					<Skeleton key="updatedAt-skeleton" width="55%" variant="text" />,
				]}
			/>
		</div>
	);
};

export default InfoSection;
