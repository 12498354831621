import {
	path,
	propOr,
	complement,
	propEq,
	pipe,
	head,
	map,
	assoc,
	filter,
} from 'ramda';
import {
	GET_PROJECTS,
	GET_MY_USER,
	GET_USERS,
} from '../../../../../../../graphql/queries';

const removeProjectFromUsers = deletedId =>
	map(obj =>
		assoc(
			'projects',
			pipe(
				propOr([], 'projects'),
				filter(complement(propEq('_id', deletedId))),
			)(obj),
		)(obj),
	);

export default (cache, {data}) => {
	const deletedId = path(['deleteProject', '_id'], data);
	const read = cache.readQuery({query: GET_PROJECTS});
	const projects = propOr([], 'getProjects', read);
	cache.writeQuery({
		query: GET_PROJECTS,
		data: {
			getProjects: projects.filter(complement(propEq('_id', deletedId))),
		},
	});
	// Remove project from my user
	try {
		const readMyUser = cache.readQuery({query: GET_MY_USER});
		const user = propOr({}, 'getMyUser', readMyUser);
		cache.writeQuery({
			query: GET_MY_USER,
			data: {
				getMyUser: pipe(removeProjectFromUsers(deletedId), head)([user]),
			},
		});
	} catch {}

	// Remove project from any users
	try {
		const readUsers = cache.readQuery({query: GET_USERS});
		const users = propOr([], 'getUsers', readUsers);
		cache.writeQuery({
			query: GET_USERS,
			data: {
				getUsers: removeProjectFromUsers(deletedId)(users),
			},
		});
	} catch {}
};
