import React from 'react';
import {Container, Typography, Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import logoWhite from '../../../../assets/logo_white.png';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		backgroundColor: theme.palette.secondary.main,
	},
	logo: {
		width: '250px',
		height: 'auto',
		[theme.breakpoints.up('sm')]: {
			width: '500px',
		},
	},
	text: {
		color: '#efefef',
		textAlign: 'center',
		fontWeight: 400,
	},
}));

const LoginRegistrationContainer = ({
	children,
	subtitle = 'Please fill the form below',
}) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<Container maxWidth="sm">
				<Box mt={['32px', '50px']} mb="50px" textAlign="center"> {/*mt=120, mb:68*/}
					<img alt="usereq-logo" src={logoWhite} className={classes.logo} />
				</Box>
				<Box mt={[3, 4]} p={[0, 4]}>
					<Typography variant="subtitle2" className={classes.text}>
						{subtitle}
					</Typography>
					<Box mt={2}>{children}</Box>
				</Box>
			</Container>
		</div>
	);
};

export default LoginRegistrationContainer;
