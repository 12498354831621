import {Box, Button, Divider, Grid, Typography} from '@material-ui/core';
import {ArrowBack, Refresh} from '@material-ui/icons';
import React, {useEffect} from 'react';
import {BugFixingIcon} from '../../icons';

const dividerStyle = {marginTop: 16, marginBottom: 8};

const TryAgain = ({
	error,
	divider = false,
	message = 'Sorry something went wrong',
	size,
	refetch = () => {},
	withGoBackButton = true,
}) => {
	useEffect(() => {
		console.log(error);
	}, [error]);

	return (
		<div>
			{divider && <Divider style={dividerStyle} />}
			<Grid container spacing={1} direction="column" alignItems="center">
				<Grid item xs={12}>
					<Box width="100%" textAlign="center">
						<BugFixingIcon
							height={size === 'small' ? 150 : 270}
							alt="bug-fix"
						/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Grid container direction="column" spacing={size === 'small' ? 3 : 6}>
						<Grid item xs={12}>
							<Typography
								align="center"
								variant={size === 'small' ? 'subtitle1' : 'h5'}
								color="textPrimary"
							>
								{message}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={2} justifyContent="center">
								{withGoBackButton && (
									<Grid item>
										<Button
											size="small"
											startIcon={<ArrowBack />}
											color="primary"
											variant="outlined"
											onClick={() => window.history.go(-1)}
										>
											Go Back
										</Button>
									</Grid>
								)}
								{refetch && (
									<Grid item>
										<Button
											size="small"
											startIcon={<Refresh />}
											color="primary"
											variant="contained"
											onClick={() => refetch()}
										>
											Try Again
										</Button>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default {
	TryAgain,
};
