import gql from 'graphql-tag';

export default gql`
	query GetProject($projectId: String!) {
		getProject(projectId: $projectId) {
			_id
			name
			description
			swagger
			tags
			users {
				_id
				username
				fullName
			}
			stories {
				_id
				seq
				text
				title
				rating
				comment
			}
			requirements {
				_id
				seq
				text
				rating
				comment
				type
			}
			createdAt
			updatedAt
		}
	}
`;
