/* Same as material-ui button but provides also "Danger" theming */
import React from 'react';
import {Button as MaterialButton} from '@material-ui/core';
import {styled} from '@material-ui/styles';
import {
	ifElse,
	propSatisfies,
	contains,
	anyPass,
	__,
	isNil,
	pipe,
	dissoc,
	identity,
	complement,
	propEq,
} from 'ramda';

const MATERIAL_BUTTON_COLORS = ['default', 'inherit', 'primary', 'secondary'];

const shouldExtendColorProp = complement(
	propSatisfies(
		anyPass([isNil, contains(__, MATERIAL_BUTTON_COLORS)]),
		'color',
	),
);

const withDangerColor = ifElse(
	propEq('color', 'danger'),
	() => ({
		color: 'rgb(244, 67, 54)',
		'&:hover': {
			backgroundColor: 'rgba(244, 67, 54, 0.08)',
		},
		'&.MuiButton-outlined': {
			border: '1px solid rgba(244, 67, 54, 0.5)',
		},
		'&.MuiButton-contained': {
			color: '#fff',
			backgroundColor: 'rgb(244, 67, 54)',
		},
		'&.MuiButton-contained:hover': {
			backgroundColor: '#a20b00',
		},
	}),
	() => ({}),
);

const Button = styled(props => {
	const removedProps = pipe(
		ifElse(shouldExtendColorProp, dissoc('color'), identity),
	)(props);

	return <MaterialButton {...removedProps} />;
})(props => ({
	...withDangerColor(props),
}));

export default Button;
