import React from 'react';
import {AppBar, Divider, Drawer, IconButton, Toolbar} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	appBar: {
		backgroundColor: theme.palette.secondary.main,
	},

	container: {
		width: 1200,

		'@media (max-width:1500px)': {
			width: '75vw',
		},

		'@media (max-width:960px)': {
			width: '100vw',
		},

		'& .header': {
			textAlign: 'center',
			// padding: 10,
			backgroundColor: '#ececec',
		},
	},
}));

const DrawerContainer = ({onClose = () => {}, Header, children}) => {
	const classes = useStyles();

	return (
		<Drawer open anchor="right" onClose={onClose}>
			<div className={classes.container}>
				<AppBar position="relative" className={classes.appBar}>
					<Toolbar>
						<IconButton color="inherit" onClick={() => onClose()}>
							<Close />
						</IconButton>
						{Header}
					</Toolbar>
				</AppBar>
				<Divider className="divider" />
				{children}
			</div>
		</Drawer>
	);
};

export default DrawerContainer;
