import React, {useMemo} from 'react';
import {Tabs as MaterialTabs, Tab} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {append, join, path, pipe, prop, slice, split} from 'ramda';
import {makeStyles} from '@material-ui/styles';
import {
	ProjectDashboardIcon,
	ProjectRequirementIcon,
	ProjectStoryIcon,
	ProjectFileIcon,
	ProjectSwaggerIcon,
	ProjectSettingsIcon,
} from '../../../../../../utilities/constants';

const useTabContainerStyles = makeStyles(theme => ({
	indicator: {
		backgroundColor: theme.palette.primary.main,
	},
}));

const useTabStyles = makeStyles(theme => ({
	root: {
		textTransform: 'none',
		color: theme.palette.secondary.light,
		fontWeight: 'normal',
		minHeight: '60px',
		'& svg': {
			color: theme.palette.secondary.light,
			marginRight: '12px',
			alignSelf: 'flex-end',
			fontSize: '16px',
		},
	},
	wrapper: {
		flexDirection: 'row',
	},
	selected: {
		color: theme.palette.primary.main,
		fontWeight: '600',
		'& svg': {
			color: theme.palette.primary.main,
		},
	},
}));

const getTabValue = pipe(split('/'), prop(3));

const Tabs = () => {
	const history = useHistory();
	const tabStyles = useTabStyles();
	const tabContainerStyles = useTabContainerStyles();
	const pathname = path(['location', 'pathname'], history);
	const tabValue = useMemo(() => getTabValue(pathname), [pathname]);

	const onTabChange = (_, newTab) => {
		history.push(
			pipe(split('/'), slice(0, 3), append(newTab), join('/'))(pathname),
		);
	};

	return (
		<MaterialTabs
			value={tabValue}
			textColor="primary"
			variant="scrollable"
			aria-label="full width tabs example"
			classes={{indicator: tabContainerStyles.indicator}}
			onChange={onTabChange}
		>
			<Tab
				classes={{
					root: tabStyles.root,
					selected: tabStyles.selected,
					wrapper: tabStyles.wrapper,
				}}
				label="Dashboard"
				icon={<ProjectDashboardIcon />}
				value="dashboard"
				index={0}
			/>
			<Tab
				classes={{
					root: tabStyles.root,
					selected: tabStyles.selected,
					wrapper: tabStyles.wrapper,
				}}
				icon={<ProjectRequirementIcon />}
				label="Requirements"
				value="requirements"
				index={1}
			/>
			<Tab
				classes={{
					root: tabStyles.root,
					selected: tabStyles.selected,
					wrapper: tabStyles.wrapper,
				}}
				icon={<ProjectStoryIcon />}
				label="Stories"
				value="stories"
				index={2}
			/>
			<Tab
				classes={{
					root: tabStyles.root,
					selected: tabStyles.selected,
					wrapper: tabStyles.wrapper,
				}}
				icon={<ProjectFileIcon />}
				label="Files"
				value="files"
				index={3}
			/>
			<Tab
				classes={{
					root: tabStyles.root,
					selected: tabStyles.selected,
					wrapper: tabStyles.wrapper,
				}}
				icon={<ProjectSwaggerIcon />}
				label="Swagger"
				value="swagger"
				index={4}
			/>
			<Tab
				classes={{
					root: tabStyles.root,
					selected: tabStyles.selected,
					wrapper: tabStyles.wrapper,
				}}
				icon={<ProjectSwaggerIcon />}
				label="CI/CD"
				value="ci-cd"
				index={5}
			/>
			<Tab
				classes={{
					root: tabStyles.root,
					selected: tabStyles.selected,
					wrapper: tabStyles.wrapper,
				}}
				icon={<ProjectSettingsIcon />}
				label="Settings"
				value="settings"
				index={6}
			/>
		</MaterialTabs>
	);
};

export default Tabs;
