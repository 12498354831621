import React from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Box,
	Tabs,
	Tab
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Title, useStyles } from '../../../../common/control/table-uncontrolled';

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export const ProjectsTableSkeleton = () => {
	const classes = useStyles();
	const rows = [...new Array(12).keys()];

	return (
		<Paper className={classes.paper} elevation={4}>
			<Title
				tableTitle={
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={0} onChange={() => {}} aria-label="basic tabs example">
							<Tab label="Projects" {...a11yProps(0)} />
							<Tab label="Invitations" {...a11yProps(1)} />
						</Tabs>
					</Box>
				}
			/>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>Stats</TableCell>
						<TableCell>Assigned Users</TableCell>
						<TableCell>Tags</TableCell>
						<TableCell>Created at</TableCell>
						<TableCell>Modified on</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map(row => (
						<TableRow key={row} style={{height: 100}}>
							<TableCell>
								<Skeleton variant="text" />
							</TableCell>
							<TableCell>
								<Skeleton variant="text" />
							</TableCell>
							<TableCell>
								<Skeleton variant="text" />
							</TableCell>
							<TableCell>
								<Skeleton variant="text" />
							</TableCell>
							<TableCell>
								<Skeleton variant="text" />
							</TableCell>
							<TableCell>
								<Skeleton variant="text" />
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Paper>
	);
};
