// Basically it's the same with update-project we just return swagger as well
import gql from 'graphql-tag';

export default gql`
	mutation UpdateProjectSwagger(
		$projectId: String!
		$data: UpdateProjectInput!
	) {
		updateProject(projectId: $projectId, data: $data) {
			_id
			# name
			# description
			swagger
			# tags
			# users {
			# 	_id
			# 	username
			# 	fullName
			# }
			# stories {
			# 	_id
			# 	text
			# 	title
			# 	rating
			# 	comment
			# }
			# requirements {
			# 	_id
			# 	text
			# 	rating
			# 	comment
			# 	type
			# }
			createdAt
			updatedAt
		}
	}
`;
