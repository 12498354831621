import * as yup from 'yup';

export const EMAIL_CHARACTERS = {
	// eslint-disable-next-line
	regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
};

export const USERNAME_CHARACTERS = {
	min: 3,
	max: 15,
	regex: /^[A-Za-z._0123456789]+$/,
};

export const PASSWORD_CHARACTERS = {
	min: 6,
};

export const FULLNAME_CHARACTERS = {
	min: 5,
	max: 50,
	regex: /^[A-Za-zΑ-Ωα-ωΆάΈέΉήΊίϊΐΌόΎύΏώ ]+$/,
};

export const EMAIL_SCHEMA = yup
	.string()
	.matches(EMAIL_CHARACTERS.regex, 'Please enter a valid email!')
	.required('Email is required.');

export const USERNAME_SCHEMA = yup
	.string()
	.min(
		USERNAME_CHARACTERS.min,
		`Username must be between ${USERNAME_CHARACTERS.min} and ${USERNAME_CHARACTERS.max} characters!`,
	)
	.max(
		USERNAME_CHARACTERS.max,
		`Username must be between ${USERNAME_CHARACTERS.min} and ${USERNAME_CHARACTERS.max} characters!`,
	)
	.matches(USERNAME_CHARACTERS.regex, 'Username contains illegal characters!')
	.required('Username is required.');

export const PASSWORD_SCHEMA = yup
	.string()
	.min(
		PASSWORD_CHARACTERS.min,
		`Password must be at least ${PASSWORD_CHARACTERS.min} characters!`,
	)
	.required('Password is required.');

export const FULLNAME_SCHEMA = yup
	.string()
	.min(
		FULLNAME_CHARACTERS.min,
		`Fullname must be between ${FULLNAME_CHARACTERS.min} and ${FULLNAME_CHARACTERS.max} characters!`,
	)
	.max(
		FULLNAME_CHARACTERS.max,
		`Fullname must be between ${FULLNAME_CHARACTERS.min} and ${FULLNAME_CHARACTERS.max} characters!`,
	)
	.matches(FULLNAME_CHARACTERS.regex, 'Full name contains illegal characters!')
	.required('Full name is required.');
