import React from 'react';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-apollo';
import { Grid, Box, Button, Typography, FormHelperText } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { pathOr } from 'ramda';
import { useUi } from '../../../../hoc';
import { CREATE_PROJECT } from '../../../../../graphql/mutations';
import {
	isSubmitButtonDisabled,
	isNilOrEmpty,
} from '../../../../../utilities/tools';
import { Name, Description, Users, Tags } from '../../../../common/form/project';
import { TextField } from '../../../../common/form';
import Formik from './formik';
import updateCache from './update-cache';
import { useState, useEffect } from 'react';

const CopiesInput = props => (
	<TextField
		{...props}
		textFieldProps={{type: 'number', size: 'small', style: {width: 150}}}
	/>
);

const CreateProjectForm = ({
	userData,
	setOpenCreateProjectModal,
}) => {
	const history = useHistory();
	const ui = useUi();
	const snackbar = useSnackbar();
	const [createProject] = useMutation(CREATE_PROJECT, {update: updateCache});

	const [role, setRole] = useState('');
	const [usersChanged, setUsersChanged] = useState(false);

	useEffect(() => {
		console.log("User data!")
		setRole(userData?.role);
	}, [userData])

	const justConsoleLog = () => {
		console.log("Project Created now!");
	}
	
	return (
		<Formik
			history={history}
			ui={ui}
			snackbar={snackbar}
			createProject={createProject}
			setOpenCreateProjectModal={setOpenCreateProjectModal?? justConsoleLog}
			currentUser={userData}
		>
			{formikProps => {
				const submitDisabled =
					(!usersChanged && !formikProps.dirty) || isSubmitButtonDisabled(formikProps);
				const warnOnCopies = !(
					isNilOrEmpty(formikProps.values.users) ||
					isNilOrEmpty(formikProps.values.copies) ||
					formikProps.values.copies === 1
				);

				return (
					<form onSubmit={formikProps.handleSubmit}>
						<Grid container spacing={2} justifyContent="center">
							<Grid item xs={12}>
								<Name {...formikProps} />
							</Grid>
							<Grid item xs={12}>
								<Description {...formikProps} />
							</Grid>
							<Grid item xs={12}>
								<Tags {...formikProps} />
							</Grid>
							{
								role && (role === 'TeamLead' || role === 'Researcher')?
								<>
									<Grid item xs={12}>
										<Users {...formikProps} isAssigned={true} isProjectCreator={false} userData={userData} setUsersChanged={setUsersChanged} />
									</Grid>
									<Grid item xs={12}>
										<Users {...formikProps} isInviteMore={true} isProjectCreator={true} setUsersChanged={setUsersChanged}/>
									</Grid>
								</>
								: // admin
								<>
									<Grid item xs={12}>
										<Users {...formikProps} userData={userData} setUsersChanged={setUsersChanged} />
									</Grid>
								</>
							}
							{
								role && (role === 'TeamLead' || role === 'Researcher')?
								''
								: <>
									<Grid item xs={12}>
										<CopiesInput
											{...formikProps}
											name="copies"
											label="Total copies"
										/>
										<FormHelperText style={warnOnCopies ? {color: 'black'} : {}}>
											{warnOnCopies
												? `Watch out, you are going to create ${
														formikProps.values.copies
												} projects and assign them all to ${
														pathOr([], ['values', 'users'], formikProps).length
												} users.`
												: `Set this value if you want to create multiple total copies of
												this project.`}
										</FormHelperText>
									</Grid>
								</>
							}
							
							<Grid item xs={12} md={6}>
								<Box mt={[1, 1, 3]}>
									<Button // TODO: Maybe this must be sticky
										fullWidth
										id="create-invited-user-form-submit-button"
										type="submit"
										color="primary"
										variant="contained"
										disabled={submitDisabled}
									>
										Submit
									</Button>
								</Box>
								<Box mt={3}>
									<Typography variant="body2" color="textSecondary">
										You will be able to add requirements, stories, swagger and
										supplementary files later.
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</form>
				);
			}}
		</Formik>
	);
};

export default CreateProjectForm;
