import React, {useEffect} from 'react';
import {pipe, prop} from 'ramda';
import queryString from 'query-string';
import {LoginRegistrationContainer} from '../../../common/ui';
// import {isNilOrEmpty} from '../../../../utilities/tools';
import CompleteRegistrationForm from './complete-registration-form';

const CompleteRegistrationScreen = ({
	location,
	// history,
	onAuthenticateSuccess,
}) => {
	// const [isReady, setIsReady] = useState(false);

	const invitationHash = pipe(
		queryString.parse,
		prop('invitationHash'),
	)(location.search);

	useEffect(() => {
		// if (isNilOrEmpty(invitationHash)) {
		// 	history.push('/');
		// 	return;
		// }

		// setIsReady(true);
	}, []); //history, invitationHash

	// if (!isReady) return null;

	return (
		<LoginRegistrationContainer subtitle="Please complete your registration by filling the form below.">
			<CompleteRegistrationForm
				invitationHash={invitationHash}
				onAuthenticateSuccess={onAuthenticateSuccess}
			/>
		</LoginRegistrationContainer>
	);
};

export default CompleteRegistrationScreen;
