import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';
import './index.css';

if (process.env.NODE_ENV === 'development') {
	const whyDidYouRender = require('@welldone-software/why-did-you-render');
	whyDidYouRender(React, {
		trackAllPureComponents: false,
	});
}

ReactDOM.render(<App />, document.querySelector('#root'));
