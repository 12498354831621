import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Prompt, useHistory, useRouteMatch } from 'react-router-dom';
import { path, pathEq, propOr } from 'ramda';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-apollo';
import { Box, Button, Container, Divider, Typography } from '@material-ui/core';
// import { TextField } from '../../../../../../common/form';
import { makeStyles } from '@material-ui/styles';
import { ErrorStates } from '../../../../../../common/ui';
import { GET_MY_USER } from '../../../../../../../graphql/queries';
import { useUi } from '../../../../../../hoc';
import {
	DELETE_PROJECT,
	UPDATE_PROJECT_SETTINGS,
} from '../../../../../../../graphql/mutations';
import { isNilOrEmpty } from '../../../../../../../utilities/tools';
import useFormikBag from './use-formik-bag';
import Form from './form';
import Actions from './actions';
import updateCacheDelete from './update-cache-delete';
import CycloptIntegration from './cyclopt-integration';
import Branches from './branches';

const useStyles = makeStyles(() => ({
	container: {
		'& .section-divider': {
			marginTop: '5px',
			marginBottom: '25px',
		},
	},
}));

const GET_PROJECT_SETTINGS = gql`
	query getProjectSettings($projectId: String!) {
		getProject(projectId: $projectId) {
			_id
			name
			description
			tags
			branches
			users {
				_id
				username
				fullName
				email
			}
			createdAt
			updatedAt
			creatorId
			invitedUsers
			usersToInvite
		}
	}
`;

const Section = ({title, children}) => (
	<Box mb={['32px', '64px']}>
		<Typography variant="h6" color="secondary">
			{title}
		</Typography>
		<Divider className="section-divider" />
		{children}
	</Box>
);

const Settings = () => {
	const classes = useStyles();
	const {data: ud} = useQuery(GET_MY_USER, {fetchPolicy: 'cache-only'});
	const amIAdmin = pathEq(['getMyUser', 'role'], 'Admin', ud);

	const match = useRouteMatch();
	const history = useHistory();
	const ui = useUi();
	const projectId = path(['params', 'projectId'], match);
	const snackbar = useSnackbar();
	const [isProjectCreator, setIsProjectCreator] = useState(false);
	const [usersChanged, setUsersChanged] = useState(false);

	const {data, loading, error, refetch} = useQuery(GET_PROJECT_SETTINGS, {
		notifyOnNetworkStatusChange: true,
		variables: {projectId},
	});
	let project = propOr({}, ['getProject'], data);
	
	const [updateProject, {loading: updateLoading}] = useMutation(
		UPDATE_PROJECT_SETTINGS,
		{
			onCompleted: () => {
				project.invitedUsers = project.invitedUsers.concat(project.usersToInvite);
				project.usersToInvite = [];
				setUsersChanged(false);
				refetch();
			}
		}
	);

	const deleteProjectProps = useMutation(DELETE_PROJECT, {
		onCompleted: () => {
			snackbar.enqueueSnackbar('Project deleted successfuly', {
				variant: 'success',
				anchorOrigin: {vertical: 'top', horizontal: 'center'},
			});
			history.replace('/projects');
		},
		update: updateCacheDelete,
	});
	
	const formikProps = useFormikBag({
		project,
		amIAdmin,
		isProjectCreator,
		ui,
		snackbar,
		updateProject,
	});
	const {dirty} = formikProps;

	const submitDisabled =
		!(isProjectCreator || amIAdmin) || !isNilOrEmpty(formikProps.errors) || updateLoading || !(usersChanged || dirty);

	// const enableSubmit = !submitDisabled && (usersChanged || dirty);
	
	useEffect(() => {
		console.log("setting mounted!!");
		refetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	useEffect(() => {
		if (dirty) {
			// eslint-disable-next-line
			window.onbeforeunload = () => true;
		} else {
			// eslint-disable-next-line
			window.onbeforeunload = undefined;
		}
	}, [dirty]);

	useEffect(() => {
		return () => {
			window.onbeforeunload = undefined;
		};
		// eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		// check if this user is the project creator of this project!
		let result = ud?.getMyUser?._id === project?.creatorId;
		// console.log('is creator:', result);
		// console.log("got project settings!!", project);
		setIsProjectCreator(result);
		
	}, [project, ud])

	return (
		<Container maxWidth="md" className="bg-filled">
			<div className={classes.container}>
				<Prompt
					when={dirty}
					message="You have unsaved changes. Are you sure you want to leave?"
				/>
				{error ? (
					<ErrorStates.TryAgain
						withGoBackButton
						error={error}
						refetch={refetch}
					/>
				) : (
					<>
						<Section title="Project Settings">
							<Form
								{...formikProps}
								initLoading={loading}
								amIAdmin={amIAdmin}
								setUsersChanged={setUsersChanged}
								isProjectCreator={isProjectCreator}
							/>
							<Box
								mt="20px"
								width="160px"
								display="flex"
								justifyContent="center"
							>
								<Button
									fullWidth
									disabled={submitDisabled}
									variant="contained"
									color="primary"
									onClick={() => formikProps.handleSubmit()}
								>
									Save Settings
								</Button>
							</Box>
						</Section>
						<Section title="Branches">
							<Branches projectId={projectId} data={data} refetch={refetch} />
						</Section>
						<Section title="Integrations">
							<CycloptIntegration projectId={projectId} isProjectCreator={isProjectCreator}/>
						</Section>
						{amIAdmin && (
							<Section title="Actions">
								<Actions
									project={project}
									deleteProjectProps={deleteProjectProps}
									amIAdmin={amIAdmin}
								/>
							</Section>
						)}
					</>
				)}
			</div>
		</Container>
	);
};

export default Settings;
