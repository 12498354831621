import gql from 'graphql-tag';

export default gql`
	query GetProjectRequirements($projectId: String!) {
		getProject(projectId: $projectId) {
			_id
			requirements {
				_id
				seq
				text
				rating
				comment
				type
				createdAt
				updatedAt
			}
			createdAt
			updatedAt
		}
	}
`;
