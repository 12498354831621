import React, {useMemo, useRef} from 'react';
import {InputAdornment, TextField} from '@material-ui/core';
import {useLazyQuery} from 'react-apollo';
import {useDebouncedCallback} from 'use-debounce/lib';
import {propOr} from 'ramda';
import useDidUpdate from '@rooks/use-did-update';
import {USERNAME_SCHEMA} from '../../../../utilities/validations/user-validation-schema';
import {
	formatFormErrorHelperText,
	getFormErrorsField,
} from '../../../../utilities/tools';
import {IS_USERNAME_AVAILABLE} from '../../../../graphql/queries';
import useFilledInputAdornmentStyles from '../../ui/filled-input-adornment-classes';
import {UserIcon} from '../../../../utilities/constants';

const getNotAvailableMessage = isAvailable => {
	if (isAvailable === null) return null;
	if (isAvailable === true) return null;

	return 'Not available';
};

const getNotAvailableError = isAvailable => {
	if (isAvailable === null) return false;
	return !isAvailable;
};

const Username = ({
	enableIsAvailableQuery = false,
	errors,
	touched,
	isSubmitting,
	values,
	status, // this is also from formik
	setStatus, // this is also from formik
	handleChange,
	handleBlur,
	textFieldProps = {},
	shouldUseFilledInputAdornmentStyles = true,
}) => {
	const classes = useFilledInputAdornmentStyles();
	const usernameInitialValue = useRef(values.username);
	const [executeIsUsernameAvailable, {data}] = useLazyQuery(
		IS_USERNAME_AVAILABLE,
		{
			onCompleted: data => {
				const isAvailable = propOr(null, 'isUsernameAvailable', data);
				if (isAvailable === true) {
					setStatus({...status, isUsernameAvailable: true});
				} else {
					setStatus({...status, isUsernameAvailable: false});
				}
			},
		},
	);

	const [debouncedUsername] = useDebouncedCallback(
		value =>
			executeIsUsernameAvailable({
				variables: {username: value},
			}),
		200,
	);

	useDidUpdate(() => {
		if (
			enableIsAvailableQuery === true &&
			usernameInitialValue.current !== values.username
		) {
			// only debounce if username is valid
			const {username} = values;
			try {
				USERNAME_SCHEMA.validateSync(username);
				debouncedUsername(username);
			} catch (_) {}
		}
	}, [values.username]);

	const isUsernameAvailable = propOr(null, 'isUsernameAvailable', data);

	const filledInputAdornmentProps = useMemo(() => {
		return shouldUseFilledInputAdornmentStyles
			? {
					classes: {
						root: classes.inputRoot,
						adornedStart: classes.inputAdornedStart,
						focused: classes.inputFocused,
						notchedOutline: classes.notchedOutline,
						input: classes.inputInput,
					},
					startAdornment: (
						<InputAdornment className={classes.inputAdornment} position="start">
							<UserIcon />
						</InputAdornment>
					),
			  }
			: {};
	}, [
		classes.inputAdornedStart,
		classes.inputAdornment,
		classes.inputFocused,
		classes.inputInput,
		classes.inputRoot,
		classes.notchedOutline,
		shouldUseFilledInputAdornmentStyles,
	]);

	return (
		<TextField
			fullWidth
			inputProps={{
				form: {
					autocomplete: 'off',
				},
			}}
			disabled={isSubmitting}
			id="username"
			name="username"
			placeholder="Username* (e.g johndoe)"
			value={values.username}
			helperText={
				formatFormErrorHelperText('username', errors, touched) ||
				getNotAvailableMessage(isUsernameAvailable)
			}
			error={
				Boolean(getFormErrorsField('username', errors, touched)) ||
				getNotAvailableError(isUsernameAvailable)
			}
			variant="outlined"
			type="text"
			// eslint-disable-next-line react/jsx-no-duplicate-props
			InputProps={filledInputAdornmentProps}
			onChange={handleChange}
			onBlur={handleBlur}
			{...textFieldProps}
		/>
	);
};

export default Username;
