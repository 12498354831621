import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import gql from 'graphql-tag';
import {complement, length, path, pathOr, pathSatisfies, pipe} from 'ramda';
import React, { useState } from 'react';
import {useQuery} from 'react-apollo';
import {useRouteMatch} from 'react-router-dom';
import {
	ProjectFileIcon,
	ProjectRequirementIcon,
	ProjectStoryIcon,
	ProjectSwaggerIcon,
} from '../../../../../../../utilities/constants';
import {isNilOrEmpty} from '../../../../../../../utilities/tools';
import {ErrorStates} from '../../../../../../common/ui';
import BoxStat from './box-stat';
import { GitHub } from '@material-ui/icons';
import { useEffect } from 'react';
import { getCodeQuality } from '../../../../../../../utilities/cycloptAPI';
import QualityAnalyticsModal from '../../../../../../modals/quality-analitics-modal';

// this in the future can be a different gql query endpoint just for stats
const GET_PROJECT_STATS = gql`
	query GetProjectStats($projectId: String!) {
		getProject(projectId: $projectId) {
			_id
			swagger
			cicd
			branches
			integrations {
				name
				apiKey
				projectId,
			}
			stories {
				_id
			}
			requirements {
				_id
			}
			files {
				_id
			}
		}
	}
`;

const useStyles = makeStyles(theme => ({
	gridContainer: {
		// basically spacing={2}
		width: 'calc(100% + 8px)',
		margin: '-4px',

		'& > .MuiGrid-item': {
			padding: '4px',
		},

		[theme.breakpoints.up('md')]: {
			width: 'calc(100% + 40px)',
			margin: '-20px',
			'& > .MuiGrid-item': {
				padding: '20px',
			},
		},
	},
}));

const StatsSection = () => {
	const classes = useStyles();
	const match = useRouteMatch();
	const projectId = path(['params', 'projectId'], match);
	const {data, loading, error, refetch} = useQuery(GET_PROJECT_STATS, {
		notifyOnNetworkStatusChange: true,
		variables: {projectId},
	});
	const [codeQualities, setCodeQualities] = useState([]);
	const [openQualityAnalyticsModal, setOpenQualityAnalyticsModal] = useState(false);
	const totalRequirements = pipe(
		pathOr([], ['getProject', 'requirements']),
		length,
	)(data);
	const totalStories = pipe(
		pathOr([], ['getProject', 'stories']),
		length,
	)(data);
	const totalFiles = pipe(pathOr([], ['getProject', 'files']), length)(data);
	const hasSwagger = pathSatisfies(complement(isNilOrEmpty), [
		'getProject',
		'swagger',
	])(data);
	const hasCicd = pathSatisfies(complement(isNilOrEmpty), [
		'getProject',
		'cicd',
	])(data);
	const branches = pathOr([], ['getProject', 'branches'])(data);
	const integrations = pathOr([], ['getProject', 'integrations'])(data);
	const totalBranches = pipe(pathOr([], ['getProject', 'branches']), length)(data);

	useEffect(() => {
		// console.log("Project", data);
		// console.log("branches", branches);
		// console.log("integrations", integrations);

		if (branches.length !== 0) {
			const cycloptIntegration = integrations.find(integ => integ.name === "cyclopt");
			if (cycloptIntegration) {
				getQualityAnalytics(cycloptIntegration.apiKey, cycloptIntegration.projectId, branches);
			}
		}
		// eslint-disable-next-line
	}, [data]);

	const getQualityAnalytics = async (apiKey, projectId, branches) => {
		let res = await getCodeQuality(apiKey, projectId, branches);
		// console.log("Dashboard quality res:", res.analyses);
		setCodeQualities(res.analyses);
	}

	if (error) {
		return (
			<ErrorStates.TryAgain withGoBackButton error={error} refetch={refetch} />
		);
	}

	return (
		<Grid container className={classes.gridContainer}>
			<QualityAnalyticsModal 
				open={openQualityAnalyticsModal}
				setOpen={setOpenQualityAnalyticsModal}
				codeQualities={codeQualities}
			/>
			<Grid item sm={4}>
				<BoxStat
					title="Requirements"
					path={`/projects/${projectId}/requirements`}
					body={totalRequirements}
					Icon={ProjectRequirementIcon}
					loading={loading}
				/>
			</Grid>
			<Grid item sm={4}>
				<BoxStat
					title="Stories"
					path={`/projects/${projectId}/stories`}
					body={totalStories}
					Icon={ProjectStoryIcon}
					loading={loading}
				/>
			</Grid>
			<Grid item sm={4}>
				<BoxStat
					title="Files"
					path={`/projects/${projectId}/files`}
					body={totalFiles}
					Icon={ProjectFileIcon}
					loading={loading}
				/>
			</Grid>
			<Grid item xs={6}>
				<BoxStat
					title="Swagger"
					path={`/projects/${projectId}/swagger`}
					body={
						hasSwagger
							? 'Swagger file is uploaded'
							: 'No swagger file is uploaded'
					}
					Icon={ProjectSwaggerIcon}
					loading={loading}
				/>
			</Grid>
			<Grid item xs={6}>
				<BoxStat
					title="CI/CD"
					path={`/projects/${projectId}/ci-cd`}
					body={
						hasCicd
							? 'CI/CD file is uploaded'
							: 'No CI/CD file is uploaded'
					}
					Icon={ProjectSwaggerIcon}
					loading={loading}
				/>
			</Grid>
			<Grid item xs={4}>
				<BoxStat
					title="Branches"
					path={`/projects/${projectId}/settings`}
					body={totalBranches}
					Icon={GitHub}
					loading={loading}
				/>
			</Grid>
			<Grid item xs={4}>
				<BoxStat
					title="Quality"
					path={`modal`}
					body={codeQualities.length > 0 ? codeQualities[0].overallQuality : "..."}
					// Icon={''}
					loading={loading}
					showCycloptLogo={true}
					modal={true}
					setOpen={setOpenQualityAnalyticsModal}
					// onClick={setOpenQualityAnalyticsModal(true)}
				/>
			</Grid>
		</Grid>
	);
};

export default StatsSection;
