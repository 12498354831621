import React from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {Title, useStyles} from '../../../../common/control/table-uncontrolled';

export const InvitedUsersTableSkeleton = () => {
	const classes = useStyles();
	const rows = [...new Array(6).keys()];

	return (
		<Paper className={classes.paper}>
			<Title
				tableTitle={
					<Typography variant="h6" component="div" color="secondary">
						Invited Users
					</Typography>
				}
			/>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>&nbsp;</TableCell>
						<TableCell>Email</TableCell>
						<TableCell>Role</TableCell>
						<TableCell>Invited on</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map(row => (
						<TableRow key={row} style={{height: 100}}>
							<TableCell>
								<Skeleton variant="text" />
							</TableCell>
							<TableCell>
								<Skeleton variant="text" />
							</TableCell>
							<TableCell>
								<Skeleton variant="text" />
							</TableCell>
							<TableCell>
								<Skeleton variant="text" />
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Paper>
	);
};
