import React from 'react';
import {LinearProgress, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	root: {
		textTransform: 'none',
	},
}));

const linearProgressDefaultProps = {
	style: {width: 150},
	color: 'primary',
	variant: 'query',
};

const WithTextLinear = ({text}) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<Typography variant="overline">{text}</Typography>
			<LinearProgress
				classes={{root: classes.root}}
				{...linearProgressDefaultProps}
			/>
		</div>
	);
};

export default WithTextLinear;
