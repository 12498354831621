import React from 'react';
import { LoginRegistrationContainer } from '../../../common/ui';
import { Grid, Button, Box, InputAdornment } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Password } from '../../../common/form/user';
import { TextField } from '../../../common/form';
import { useFormik } from 'formik';
import { useUi } from '../../../hoc';
import { UserIcon } from '../../../../utilities/constants';
import useFilledInputAdornmentStyles from '../../../common/ui/filled-input-adornment-classes';
import * as Yup from 'yup';
import axios from 'axios';

const ForgotPasswordScreen = () => {
	const classes = useFilledInputAdornmentStyles();
	const ui = useUi();
	const snackbar = useSnackbar();
	
	const validationSchema = Yup.object().shape({
		email: Yup.string().required('Email is required.'),
		password1: Yup.string().required('New password1 is required.'),
		password2: Yup.string().required().oneOf([Yup.ref("password1"), null], "Passwords must match."),
	
	});

	const initialValues = {
		email: '',
		password1: '',
		password2: '',
	};
	
	function timeout(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	const handleSubmit = () => async (values) => {
		console.log('Submiting forgot password form...');
		// console.log("values:", values);
		try {
			ui.setAppProgressShown(true);
			let url = process.env.REACT_APP_GRAPHQL_HTTP_URI.split('/graphql')[0]; // remove /graphql from the uri
			console.log(url);
			let res = await axios.post(url + "/api/users/sendPasswordPin", {
				email: values.email,
				password: values.password1
			});
			console.log(res.data);
			if (res.data.status === 200) {
				snackbar.enqueueSnackbar('An email was sent!', {
					variant: 'success',
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
				})
				// redirect to login page
				await timeout(1500);
				window.location.href='/pin?email='+values.email;
			} else if (res.data.status === 400 || res.data.status === 404) {
				if (res.data.type && res.data.type === 'database') {
					snackbar.enqueueSnackbar('User does not exist', {
						variant: 'error',
						anchorOrigin: {vertical: 'top', horizontal: 'center'},
					});
				} else if (res.data.message === 'Error while sending email') {
					snackbar.enqueueSnackbar('Error while sending email', {
						variant: 'error',
						anchorOrigin: {vertical: 'top', horizontal: 'center'},
					});
				}
			}
			
		} catch (error) {
			snackbar.enqueueSnackbar('Something went wrong!', {
				variant: 'error',
				anchorOrigin: {vertical: 'top', horizontal: 'center'},
			});
		
		} finally {
			ui.setAppProgressShown(false);
		}
	};

	const formikProps = useFormik({
		initialValues,
		onSubmit: handleSubmit(),
		validationSchema,
	});

	return (
		<LoginRegistrationContainer subtitle="Please fill the form below in order to request a password reset.">
			<form onSubmit={(e) => {
					e.preventDefault();
					formikProps.handleSubmit();
				}
			}
			>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<TextField
							name="email"
							// label="Email or Username*"
							textFieldProps={{
								placeholder: 'Email*',
								InputProps: {
									classes: {
										root: classes.inputRoot,
										adornedStart: classes.inputAdornedStart,
										focused: classes.inputFocused,
										notchedOutline: classes.notchedOutline,
										input: classes.inputInput,
									},
									startAdornment: (
										<InputAdornment
											className={classes.inputAdornment}
											position="start"
										>
											<UserIcon />
										</InputAdornment>
									),
								},
							}}
							{...formikProps}
						/>
					</Grid>
					<Grid item xs={12}>
						<Password name="password1" placeholder="New password*" {...formikProps} />
					</Grid>

					<Grid item xs={12}>
						<Password name="password2" placeholder="Repeat password*" {...formikProps} />
					</Grid>
					
					<Grid item xs={12}>
						<Grid container justifyContent="center">
							<Grid item xs={12} md={6}>
								<Box mt={[1, 1, 3]}>
									<Button
										fullWidth
										id="forgot-password-form-submit-button"
										type="submit"
										color="primary"
										variant="contained"
										// disabled={isSubmitButtonDisabled(formikProps)}
									>
										Request Pin 
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container justifyContent="center">
							<Grid item xs={12} md={6}>
								<Button
									fullWidth
									// id="create-user-form-submit-button"
									color="secondary"
									variant="contained"
									onClick={() => {
										window.location.href='/';
									}}
								>
									Back to Log in
								</Button>
							</Grid>
						</Grid>
					</Grid>

				</Grid>
			</form>
		</LoginRegistrationContainer>
	);
};

export default ForgotPasswordScreen;
