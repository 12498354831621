import gql from 'graphql-tag';

export default gql`
	mutation DeleteUser($userId: String!) {
		deleteUser(userId: $userId) {
			_id
			email
			username
			role
			fullName
			projects {
				_id
				name
			}
			createdAt
		}
	}
`;
