import {makeStyles} from '@material-ui/styles';
import {path} from 'ramda';

const minWidth600 = '@media (min-width:600px)';
const landscape = '@media (min-width:0px) and (orientation: landscape)';
const defaultStickySpacing = 3;

const useStyles = makeStyles(theme => ({
	stickyItemMdUp: {
		[theme.breakpoints.up('md')]: {
			position: 'sticky',
			[landscape]: {
				top:
					path(['mixins', 'toolbar', landscape, 'minHeight'], theme) +
					theme.spacing(defaultStickySpacing),
			},
			[minWidth600]: {
				top:
					path(['mixins', 'toolbar', minWidth600, 'minHeight'], theme) +
					theme.spacing(defaultStickySpacing),
			},
		},
	},
	stickyItem: {
		position: 'sticky',
		top:
			path(['mixins', 'toolbar', 'minHeight'], theme) +
			theme.spacing(defaultStickySpacing),
		[landscape]: {
			top:
				path(['mixins', 'toolbar', landscape, 'minHeight'], theme) +
				theme.spacing(defaultStickySpacing),
		},
		[minWidth600]: {
			top:
				path(['mixins', 'toolbar', minWidth600, 'minHeight'], theme) +
				theme.spacing(defaultStickySpacing),
		},
	},
	stickyItemNoSpacing: {
		position: 'sticky',
		top: path(['mixins', 'toolbar', 'minHeight'], theme),
		[landscape]: {
			top: path(['mixins', 'toolbar', landscape, 'minHeight'], theme),
		},
		[minWidth600]: {
			top: path(['mixins', 'toolbar', minWidth600, 'minHeight'], theme),
		},
	},
	// basicaly this is it: MuiSelect-root MuiSelect-select MuiSelect-selectMenu MuiInputBase-input MuiInput-input MuiInputBase-inputMarginDense MuiInput-inputMarginDense
	selectInputBase: {
		paddingRight: '24px',
		height: 'auto',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		cursor: 'pointer',
		minWidth: '16px',
		userSelect: 'none',
		borderRadius: 0,
		MozAppearance: 'none',
		WebkitAppearance: 'none',
		paddingTop: '3px',
		font: 'inherit',
		color: 'currentColor',
		width: '100%',
		border: 0,
		margin: 0,
		display: 'block',
		padding: '6px 0 7px',
		background: 'none',
		boxSizing: 'content-box',
		animationName: 'mui-auto-fill-cancel',
		WebkitTapHighlightColor: 'transparent',
	},
}));

export default useStyles;
