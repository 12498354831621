import React, {useEffect, useState, useMemo} from 'react';
import {
	pipe,
	propOr,
	length,
	prop,
	propEq,
	map,
	includes,
	find,
	flatten,
	uniqBy,
	identity,
	path,
} from 'ramda';
import {
	Chip,
	Grid,
	Box,
	IconButton,
	Tooltip,
	Link,
	Typography,
} from '@material-ui/core';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {Delete as DeleteIcon} from '@material-ui/icons';
import {Link as RouterLink} from 'react-router-dom';
import {WithSkeleton} from '../../../../common/hoc';
import {
	TableColumnFilterText,
	TableUncontrolled,
	TableColumnFilterDateRange,
	AlertActionDialog,
	TableColumnFilterAutocomplete,
} from '../../../../common/control';
import {
	filterByDateRange,
	isNilOrEmpty,
	filterByObjectMultiSelect,
} from '../../../../../utilities/tools';
import {ProjectIcon} from '../../../../../utilities/constants';
import {ItemsWithMore} from '../../../../common/ui';
import {UsersTableSkeleton} from './skeletons';
import EditIcon from '@material-ui/icons/Edit';
import EditUserModal from '../../../../modals/edit-user-modal';

const getUsers = propOr([], 'getUsers');

const getProjects = pipe(
	getUsers,
	map(propOr([], 'projects')),
	flatten,
	uniqBy(prop('_id')),
);

const getTotalCount = pipe(propOr([], 'getUsers'), length);

const UserProjects = ({cell: {value}, state}) => {
	const projectsIdsFilters = pipe(
		propOr([], 'filters'),
		find(propEq('id', 'projects')),
		propOr([], 'value'),
		map(prop('_id')),
	)(state);

	const ItemRenderer = ({item: {_id, name}}) => (
		<Link
			underline="none"
			component={RouterLink}
			to={`/projects/${_id}/dashboard`}
		>
			<Chip
				clickable
				icon={<ProjectIcon fontSize="inherit" />}
				color="primary"
				size="small"
				variant={
					isNilOrEmpty(projectsIdsFilters) || includes(_id, projectsIdsFilters)
						? 'default'
						: 'outlined'
				}
				label={name}
			/>
		</Link>
	);

	return (
		<ItemsWithMore
			items={value}
			maxShown={3}
			EmptyRenderer="-"
			ItemRenderer={ItemRenderer}
		/>
	);
};

const Actions = ({value, row, deleteUser, editUser}) => {
	const role = path(['original', 'role'], row);
	const [showEditModal, setShowEditModal] = useState(false);

	useEffect(() => {
		// console.log("rendering actions");
		// if (deleteUser) console.log("deleteUser");
		// if (editUser) console.log("editing user");
		// console.log(value, row);
	}, [])

	if (role === 'Admin') return null;

	return (
		// show action according to the passed function
		editUser?
			<>
				<EditUserModal open={showEditModal} setOpen={setShowEditModal} userData={value} editUser={editUser}/>
				<Tooltip title="Edit User">
					<IconButton size="small" onClick={() => setShowEditModal(true)}>
						<EditIcon fontSize="inherit" />
					</IconButton>
				</Tooltip>
			</>
		: deleteUser?
			<AlertActionDialog
				canEscape
				title={`Delete user  "${value.username}"`}
				text="Are you sure you want to delete this user? He will also be unassigned from all of his projects."
				actions={[
					{text: 'Back', buttonProps: {color: 'default'}},
					{
						text: 'Delete User',
						buttonProps: {color: 'danger'},
						onClick: () =>
							deleteUser({
								variables: {userId: value._id},
							}),
					},
				]}
			>
				{({handleOpen}) => (
					<Tooltip title="Delete User">
						<IconButton size="small" onClick={() => handleOpen(true)}>
							<DeleteIcon fontSize="inherit" />
						</IconButton>
					</Tooltip>
				)}
			</AlertActionDialog>
		: ''
	);
};

const UsersTableWrapper = ({data, deleteUser, editUser, ...props}) => {
	const snackbar = useSnackbar();
	const users = getUsers(data);
	const projects = getProjects(data);
	const totalCount = getTotalCount(data);

	const columns = useMemo(
		() => [
			{
				Header: '',
				id: 'actions',
				accessor: identity,
				Cell: ({cell: {value, row}}) => (
					<Grid 
						container
						spacing={1}
					>
						<Box> 
							<Actions
								value={value}
								row={row}
								snackbar={snackbar}
								editUser={editUser}
							/>
						</Box>
						<Box> 
							<Actions
								value={value}
								row={row}
								snackbar={snackbar}
								deleteUser={deleteUser}
							/>
						</Box>
					</Grid>
					
				),
				disableSortBy: true,
				disableFilters: true,
				headerStyle: {width: 48, minWidth: 48},
				style: {width: 48, minWidth: 48},
			},
			{
				Header: 'Username',
				id: 'username',
				accessor: 'username',
				Filter: TableColumnFilterText,
				headerStyle: {width: 160, minWidth: 160},
				style: {width: 160, minWidth: 160, fontWeight: 'bold'},
				Cell: ({cell: {value, row}}) => {
					const role = path(['original', 'role'], row); // TODO: edit user role
					return (
						<Grid container spacing={1}>
							<Grid item>{value}</Grid>
							<Grid item> 
								<Chip
									label={role}
									size="small"
									style={{fontWeight: 'normal'}}
								/>
							</Grid>
						</Grid>
					)
				},
			},
			{
				Header: 'Full name',
				id: 'fullName',
				accessor: 'fullName',
				Filter: TableColumnFilterText,
				headerStyle: {width: 175, minWidth: 175},
				style: {width: 175, minWidth: 175},
			},
			{
				Header: 'Email',
				id: 'email',
				accessor: 'email',
				Filter: TableColumnFilterText,
				headerStyle: {width: 200, minWidth: 175},
				style: {width: 200, minWidth: 175},
			},
			{
				Header: 'Joined At',
				id: 'createdAt',
				accessor: 'createdAt',
				Cell: ({cell: {value}}) => moment(value).format('MMMM Do YYYY, HH:mm'),
				Filter: TableColumnFilterDateRange,
				filter: filterByDateRange,
				headerStyle: {width: 235, minWidth: 175, maxWidth: 250},
				style: {width: 235, minWidth: 175, maxWidth: 250},
			},
			{
				Header: 'Assigned Projects',
				id: 'projects',
				accessor: 'projects',
				disableSortBy: true,
				headerStyle: {
					width: 200,
					minWidth: 175,
				},
				style: {width: 200, minWidth: 175},
				filter: filterByObjectMultiSelect,
				Filter: filterProps => (
					<TableColumnFilterAutocomplete
						{...filterProps}
						options={projects}
						formatOption={({name}) => name}
						optionKeyExtractor={prop('_id')}
						chipProps={{color: 'primary'}}
					/>
				),
				Cell: UserProjects,
			},
		],
		// eslint-disable-next-line
		[],
	);

	return (
		<TableUncontrolled
			{...props}
			columns={columns}
			data={users}
			totalCount={totalCount}
			tableTitle={
				<Typography variant="h6" component="div" color="secondary">
					Users
				</Typography>
			}
		/>
	);
};

export default props => (
	<WithSkeleton
		shouldShowSkeleton={props.shouldShowSkeleton}
		Skeleton={UsersTableSkeleton}
	>
		<UsersTableWrapper {...props} />
	</WithSkeleton>
);
