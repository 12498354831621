import React, {useMemo} from 'react';
import {
	Paper,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TablePagination,
	CircularProgress,
	Checkbox,
} from '@material-ui/core';
import useDidUpdate from '@rooks/use-did-update';
import {ArrowDownward, ArrowUpward} from '@material-ui/icons';
import {path} from 'ramda';
import {makeStyles, styled} from '@material-ui/styles';
import {
	useTable,
	useSortBy,
	useFilters,
	usePagination,
	useRowSelect,
} from 'react-table';
import clsx from 'clsx';
import {isNilOrEmpty} from '../../../../utilities/tools';
import NoRecordsFound from './no-records-found';
import EnhancedTitle from './enhanced-title';
import {TablePaginationActions as TablePaginationActionsComponent} from '..';

export const useStyles = makeStyles(theme => ({
	paper: {
		position: 'relative',
		marginBottom: theme.spacing(2),
		borderRadius: '8px',
	},
	circularProgress: {
		position: 'absolute',
		top: '50%',
		right: '50%',
	},
	tableRowSelectable: {
		cursor: 'pointer',
	},
	tableRowNotEditting: {
		opacity: 0.2,
		transition: 'all 300ms ease 0s',
		backgroundColor: 'rgba(220, 0, 78, 0.08)',
	},
	checkbox: {
		'&.header': {
			color: '#e2e2e2',
		},
	},
	checkboxChecked: {
		color: theme.palette.primary.main,
	},
}));

const StyledTable = styled(({tableLoading, ...rest}) => <Table {...rest} />)(
	({tableLoading}) => ({
		...(tableLoading
			? {opacity: 0.75, pointerEvents: 'none', cursor: 'default'}
			: {opacity: 1}),
	}),
);

const HeaderColumn = ({column}) => {
	const {onClick, style} = column.getSortByToggleProps();

	return (
		<div style={{display: 'flex', ...column.headerStyle}}>
			<div style={style} onClick={onClick}>
				{column.render('Header')}
			</div>
			<div style={{marginLeft: '4px'}}>
				{column.isSorted ? (
					column.isSortedDesc ? (
						<ArrowDownward fontSize="small" style={style} onClick={onClick} />
					) : (
						<ArrowUpward fontSize="small" style={style} onClick={onClick} />
					)
				) : null}
			</div>
		</div>
	);
};

const FilterColumn = ({column}) => (
	<div>{column.canFilter ? column.render('Filter') : null}</div>
);

const calcPageCount = (totalCount = 0, pageSize = 1) =>
	Math.ceil(totalCount / pageSize);

const TableUncontrolled = ({
	columns,
	data,
	totalCount,
	pageOptions,
	rowIdSelected,
	rowIdEditting,
	queryParams = {},
	setQueryParams,
	defaultPageSize,
	handleTableRowClick = () => null,
	tableTitle,
	rowIdKey = '_id',
	rowClickable = false,
	tableLoading,
	noRecordsFoundMessage = 'No records found',
	disablePagination = false,
	enableRowSelect = false,
	SelectedRowsActionsComponent = () => null,
	selectedRowsActionsProps = {},
	paperProps = {},
}) => {
	const classes = useStyles();

	const TablePaginationActions = useMemo(
		() => props => (
			<TablePaginationActionsComponent
				disabled={disablePagination}
				{...props}
			/>
		),
		[disablePagination],
	);

	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		headerGroups,
		prepareRow,
		page,
		rows, // those are filtered too
		selectedFlatRows,
		state: {sortBy, filters, pageIndex, pageSize},
		setAllFilters,
		gotoPage,
		setPageSize,
	} = useTable(
		{
			columns,
			data,
			manualSortBy: false,
			manualFilters: false,
			manualPagination: false,
			autoResetPage: false,
			autoResetFilters: false,
			autoResetSortBy: false,
			autoResetSelectedRows: false,
			initialState: {
				sortBy: queryParams.sortBy || [],
				filters: queryParams.filters || [],
				pageIndex: queryParams.pageIndex || 0,
				pageSize: queryParams.pageSize || defaultPageSize,
				pageCount: calcPageCount(
					(totalCount || 0) / (queryParams.pageSize || defaultPageSize),
				),
			},
		},
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
		hooks => {
			if (!enableRowSelect) return hooks.visibleColumns;

			hooks.visibleColumns.push(columns => [
				// Let's make a column for selection
				{
					id: 'selection',
					className: 'no-border-right',
					headerStyle: {marginTop: '25px'},
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox
					Header: ({getToggleAllRowsSelectedProps}) => (
						<div>
							<Checkbox
								{...getToggleAllRowsSelectedProps()}
								color="primary"
								classes={{root: clsx(classes.checkbox, 'header')}}
							/>
						</div>
					),
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					Cell: ({row}) => (
						<div>
							<Checkbox {...row.getToggleRowSelectedProps()} color="primary" />
						</div>
					),
				},
				...columns,
			]);
		},
	);

	const qParamPageIndex = queryParams && queryParams.pageIndex;
	useDidUpdate(() => {
		gotoPage(qParamPageIndex);
	}, [qParamPageIndex]);

	// On Filters changed
	useDidUpdate(() => {
		gotoPage(0);
		if (setQueryParams)
			setQueryParams({sortBy, filters, pageIndex: 0, pageSize});
	}, [sortBy, filters, pageSize]);

	// On Page change
	const handlePageChange = (_, newPage) => {
		gotoPage(newPage);
		if (setQueryParams)
			setQueryParams({sortBy, filters, pageIndex: newPage, pageSize});
	};

	// Render the UI for your table
	return (
		<Paper elevation={4} className={classes.paper} {...paperProps}>
			<EnhancedTitle
				tableTitle={tableTitle}
				setAllFilters={setAllFilters}
				filters={filters}
				selectedFlatRows={selectedFlatRows}
				SelectedRowsActionsComponent={SelectedRowsActionsComponent}
				selectedRowsActionsProps={selectedRowsActionsProps}
			/>
			<TableContainer>
				<StyledTable {...getTableProps()} tableLoading={tableLoading}>
					<TableHead>
						{headerGroups.map(headerGroup => (
							// eslint-disable-next-line react/jsx-key
							<TableRow {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => {
									const id = column && column.id;
									const className = (column && column.className) || '';
									return (
										// eslint-disable-next-line react/jsx-key
										<TableCell
											padding={id === 'selection' ? 'checkbox' : 'normal'}
											className={className}
											{...column.getHeaderProps()}
											title=""
										>
											<HeaderColumn column={column} />
											<FilterColumn column={column} />
										</TableCell>
									);
								})}
							</TableRow>
						))}
					</TableHead>
					{isNilOrEmpty(rows) ? (
						<NoRecordsFound
							filters={filters}
							noRecordsFoundMessage={noRecordsFoundMessage}
						/>
					) : (
						<TableBody>
							{page.map(row => {
								prepareRow(row);
								const isRowSelected =
									path(['original', rowIdKey], row) === rowIdSelected;
								const isRowEditting =
									path(['original', rowIdKey], row) === rowIdEditting;
								return (
									// eslint-disable-next-line react/jsx-key
									<TableRow
										hover={!rowIdEditting}
										{...row.getRowProps()}
										className={clsx(
											rowClickable && classes.tableRowSelectable,
											rowIdEditting &&
												!isRowEditting &&
												classes.tableRowNotEditting,
										)}
										selected={isRowSelected}
										onClick={handleTableRowClick({row})}
									>
										{row.cells.map(cell => {
											const id = cell && cell.column && cell.column.id;
											return (
												// eslint-disable-next-line react/jsx-key
												<TableCell
													padding={id === 'selection' ? 'checkbox' : 'normal'}
													{...cell.getCellProps([
														{
															className: cell.column.className,
															style: cell.column.style,
														},
													])}
												>
													{cell.render('Cell')}
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					)}
				</StyledTable>
				{tableLoading && (
					<CircularProgress
						disableShrink
						className={classes.circularProgress}
					/>
				)}
			</TableContainer>
			<TablePagination
				align="right"
				component="div"
				page={pageIndex}
				count={rows.length}
				rowsPerPageOptions={pageOptions}
				rowsPerPage={pageSize}
				ActionsComponent={TablePaginationActions}
				onPageChange={handlePageChange}
				onRowsPerPageChange={event =>
					setPageSize(parseInt(event.target.value, 10))
				}
			/>
		</Paper>
	);
};

export default TableUncontrolled;
