import React, {useState, useEffect} from 'react';
import {InputAdornment} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {CalendarToday as CalendarIcon} from '@material-ui/icons';
import {propOr} from 'ramda';
import {DateRangePicker} from '../../form';
import {isNilOrEmpty, nullizeString} from '../../../../utilities/tools';

const useStyles = makeStyles(() => ({
	root: {
		fontWeight: '400!important',
	},
	inputRoot: {
		backgroundColor: '#fff',
	},
}));

const TableColumnFilterDateRange = ({column: {filterValue, setFilter}}) => {
	const classes = useStyles();
	const [startDate, setStartDate] = useState(
		propOr(null, 'startDate', filterValue),
	);
	const [endDate, setEndDate] = useState(propOr(null, 'endDate', filterValue));

	const handleSetFilter = () => {
		const st = nullizeString(startDate && startDate.toString());
		const en = nullizeString(endDate && endDate.toString());
		const st1 = nullizeString(
			filterValue && filterValue.startDate && filterValue.startDate.toString(),
		);
		const en1 = nullizeString(
			filterValue && filterValue.endDate && filterValue.endDate.toString(),
		);
		// const value = nullizeString(internalState);
		if (st !== st1 || en !== en1) {
			setFilter({startDate, endDate});
		}
	};

	// To reset internal state
	useEffect(() => {
		if (isNilOrEmpty(filterValue) || filterValue === undefined) {
			setStartDate(null);
			setEndDate(null);
		}
	}, [filterValue]);

	const handleChange = (st, en) => {
		setStartDate(st);
		setEndDate(en);
	};

	return (
		<DateRangePicker
			time={false}
			textFieldProps={{
				classes: {
					root: classes.root,
				},
				InputProps: {
					classes: {
						root: classes.inputRoot,
					},
					startAdornment: (
						<InputAdornment position="start">
							<CalendarIcon fontSize="small" color="action" />
						</InputAdornment>
					),
				},
			}}
			startDate={startDate}
			endDate={endDate}
			onChange={handleChange}
			onBlur={handleSetFilter}
		/>
	);
};

export default TableColumnFilterDateRange;
