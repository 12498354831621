import React, {useState} from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@material-ui/core';
import {Button} from '..';

const handleClick = ({onClick, handleClose}) => {
	if (onClick) {
		onClick();
		handleClose();
	} else {
		handleClose();
	}
};

const defaultActionButtonProps = {
	color: 'primary',
	variant: 'text',
};

const renderActions = props => {
	const {actions} = props;

	return Array.isArray(actions)
		? actions.map(({text, onClick, buttonProps = {}}) => (
				<Button
					key={text}
					{...defaultActionButtonProps}
					{...buttonProps}
					onClick={() => {
						handleClick({...props, onClick});
					}}
				>
					{text}
				</Button>
		  ))
		: actions;
};

const defaultDialogProps = {
	maxWidth: 'sm',
};

const AlertActionDialog = props => {
	const {children, title, text, dialogProps, canEscape} = props;

	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			{children({handleOpen, handleClose, open})}
			<Dialog
				{...defaultDialogProps}
				{...dialogProps}
				open={open}
				aria-labelledby="alert-action-dialog-title"
				aria-describedby="alert-action-dialog-description"
				onClose={canEscape ? handleClose : null}
			>
				<DialogTitle id="alert-action-dialog-title">{title}</DialogTitle>
				{text && (
					<DialogContent>
						<DialogContentText id="alert-action-dialog-description">
							{text}
						</DialogContentText>
					</DialogContent>
				)}
				<DialogActions>
					{renderActions({...props, handleOpen, handleClose})}
				</DialogActions>
			</Dialog>
		</>
	);
};

export default AlertActionDialog;
