import React, {useState, lazy, Suspense, memo} from 'react';
import {useTheme, makeStyles} from '@material-ui/styles';
import {
	Check as SaveIcon,
	Close as CloseIcon,
	BrightnessMedium as ThemeIcon,
} from '@material-ui/icons';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	useMediaQuery,
	IconButton,
	Box,
	CircularProgress,
	Button,
	Grid,
	Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import createPersistedState from 'use-persisted-state';
import useDidUpdate from '@rooks/use-did-update';
import {pipe, ifElse, prop, identity, propEq} from 'ramda';
import AlertActionDialog from '../alert-action-dialog';
import {isNilOrEmpty} from '../../../../utilities/tools';

const useDarkThemeState = createPersistedState('useReq:codeEditorDarkTheme');

const CodeEditor = lazy(() => import('./code-editor'));

const SuspenseFallback = ({darkTheme}) => (
	<Box
		height="500px"
		display="flex"
		alignItems="center"
		justifyContent="center"
		flexDirection="column"
		bgcolor={darkTheme ? '#272822' : '#fff'}
	>
		<Box flex={0.25}>
			<CircularProgress />
		</Box>
	</Box>
);

const useStyles = makeStyles(() => ({
	dialogTitle: {
		position: 'relative',
		color: '#000',
		backgroundColor: '#fafafa',
		borderBottom: '1px solid #ededed',
	},
	dialogTitleDarkTheme: {
		color: '#fff',
		backgroundColor: '#33342f',
		borderBottom: '1px solid #202020',
	},
	dialogContent: {
		padding: 0,
	},
	closeButton: {
		color: 'inherit',
	},
	themeButton: {
		color: 'inherit',
	},
}));

const CloseButton = ({classes, onClick}) => (
	<IconButton className={classes.closeButton} onClick={onClick}>
		<CloseIcon />
	</IconButton>
);

const ThemeButton = ({classes, darkTheme, setDarkTheme}) => (
	<IconButton
		size="small"
		className={classes.themeButton}
		onClick={() => setDarkTheme(!darkTheme)}
	>
		<ThemeIcon fontSize="inherit" />
	</IconButton>
);

const SaveButton = ({iconOnly, onClick, disabled}) =>
	iconOnly ? (
		<IconButton disabled={disabled} color="primary" onClick={() => onClick()}>
			<SaveIcon color="inherit" />
		</IconButton>
	) : (
		<Button
			disabled={disabled}
			startIcon={<SaveIcon />}
			variant="contained"
			color="primary"
			onClick={() => onClick()}
		>
			Done
		</Button>
	);

const StoryDialogEditor = memo(({open, setOpen, initialValue = {}, onSave}) => {
	const [darkTheme, setDarkTheme] = useDarkThemeState(false);
	const {text} = initialValue;
	const [value, setValue] = useState(text || '');
	const [codeAnnotations, setCodeAnnotations] = useState([]);
	const classes = useStyles();
	const theme = useTheme();
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));
	const title = pipe(
		prop('title'),
		ifElse(isNilOrEmpty, () => 'Create Story', identity),
	)(initialValue);

	useDidUpdate(() => {
		if (open) setValue(text);
	}, [open]);

	const onSaveClick = () => {
		onSave({value});
		setOpen(false);
	};

	const onClose = ({openAlert}) => {
		if (value === text) {
			setOpen(false);
		} else {
			openAlert();
		}
	};

	const hasCodeError =
		(value && value.trim() === '') ||
		(codeAnnotations && codeAnnotations.some(propEq('type', 'error')));

	return (
		<AlertActionDialog
			canEscape
			title="Close without saving"
			text="Are you sure you want to close without saving?"
			actions={[
				{text: 'Back to Safety', buttonProps: {color: 'default'}},
				{
					text: 'Close without Save',
					buttonProps: {color: 'danger'},
					onClick: () => setOpen(false),
				},
			]}
		>
			{({handleOpen}) => (
				<Dialog
					fullWidth
					fullScreen={isSm}
					maxWidth="md"
					open={open}
					scroll="paper"
					onClose={() => onClose({openAlert: handleOpen})}
				>
					<DialogTitle
						className={clsx(
							classes.dialogTitle,
							darkTheme && classes.dialogTitleDarkTheme,
						)}
					>
						<Grid container justifyContent="space-between" alignItems="center">
							<Grid item xs="auto">
								<ThemeButton
									classes={classes}
									setDarkTheme={setDarkTheme}
									darkTheme={darkTheme}
								/>
							</Grid>
							<Grid item xs={6} sm={9}>
								<Box textAlign="center">{title}</Box>
							</Grid>
							<Grid item xs="auto">
								<Grid container alignItems="center" justifyContent="space-between">
									<Grid item>
										<Box mr={2}>
											<SaveButton
												disabled={hasCodeError || isNilOrEmpty(value)}
												iconOnly={isSm}
												onClick={onSaveClick}
											/>
										</Box>
									</Grid>
									<Grid item>
										<CloseButton
											classes={classes}
											onClick={() => onClose({openAlert: handleOpen})}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						{hasCodeError && (
							<Box position="absolute" right={30} bottom={0} fontStyle="italic">
								{p => (
									<Typography
										{...p}
										align="center"
										color="error"
										variant="body2"
									>
										{value && value.trim() === ''
											? 'You must provide Gherkin code'
											: 'Your code has errors'}
									</Typography>
								)}
							</Box>
						)}
					</DialogTitle>
					<DialogContent className={classes.dialogContent}>
						<Suspense fallback={<SuspenseFallback darkTheme={darkTheme} />}>
							<CodeEditor
								value={value}
								setValue={setValue}
								darkTheme={darkTheme}
								setCodeAnnotations={setCodeAnnotations}
							/>
						</Suspense>
					</DialogContent>
				</Dialog>
			)}
		</AlertActionDialog>
	);
});

export default StoryDialogEditor;
