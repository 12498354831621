import React from 'react';
import {Box, Typography, Button} from '@material-ui/core';
import {lighten} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {Refresh} from '@material-ui/icons';
import {isNilOrEmpty} from '../../../../utilities/tools';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	highlight: {
		color: '#000',
		backgroundColor: lighten(theme.palette.secondary.light, 0.85),
		borderTopLeftRadius: '4px',
		borderTopRightRadius: '4px',
	},
}));

const ResetFiltersButton = ({setAllFilters}) => (
	<Button
		variant="text"
		color="default"
		// variant="contained"
		size="small"
		startIcon={<Refresh />}
		onClick={() => setAllFilters([])}
	>
		Reset Filters
	</Button>
);

const SelectedActionsContainer = ({
	selectedRowsNum,
	SelectedRowsActionsComponent,
	selectedFlatRows,
	selectedRowsActionsProps,
}) => (
	<>
		<Typography variant="subtitle1" component="div">
			{selectedRowsNum} selected
		</Typography>
		<SelectedRowsActionsComponent
			{...selectedRowsActionsProps}
			selectedRows={selectedFlatRows}
		/>
	</>
);

const EnhancedTitle = props => {
	const classes = useStyles();
	const {filters, tableTitle, selectedFlatRows} = props;

	const selectedRowsNum = isNilOrEmpty(selectedFlatRows)
		? 0
		: selectedFlatRows.length;

	return (
		<div>
			<Box
				px={[2, 3]}
				py={2}
				className={clsx(classes.root, selectedRowsNum > 0 && classes.highlight)}
			>
				{selectedRowsNum === 0 && (
					<>
						{tableTitle}
						{/* {!isNilOrEmpty(filters) && (
							<Box>
								<ResetFiltersButton {...props} />
							</Box>
						)} */}
					</>
				)}
				{selectedRowsNum > 0 && (
					<SelectedActionsContainer
						selectedRowsNum={selectedRowsNum}
						{...props}
					/>
				)}
			</Box>
			{!isNilOrEmpty(filters) && (
				<Box display="flex" justifyContent="flex-end" mt={1} px={2} mb={1}>
					<ResetFiltersButton {...props} />
				</Box>
			)}
		</div>
	);
};

export default EnhancedTitle;
