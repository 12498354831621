import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import MomentUtils from '@date-io/moment';
import { QueryParamProvider } from 'use-query-params';
import theme from '../utilities/theme';
import { ApolloProvider, ErrorBoundary, AuthProvider, UiProvider } from './hoc';
import AppRoutes from './app-routes';
import AppProgress from './app-progress';
import AppDrawer from './app-drawer';
import 'moment';

const App = () => (
	<ThemeProvider theme={theme}>
		<CssBaseline />
		<ErrorBoundary>
			<SnackbarProvider preventDuplicate maxSnack={3} autoHideDuration={2500}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<ApolloProvider>
						<Router>
							<QueryParamProvider ReactRouterRoute={Route}>
								<UiProvider>
									<AppProgress />
									<AuthProvider>
										<AppDrawer />
										<AppRoutes />
									</AuthProvider>
								</UiProvider>
							</QueryParamProvider>
						</Router>
					</ApolloProvider>
				</MuiPickersUtilsProvider>
			</SnackbarProvider>
		</ErrorBoundary>
	</ThemeProvider>
);

export default App;
