import gql from 'graphql-tag';

export default gql`
	query GetProjectStories($projectId: String!) {
		getProject(projectId: $projectId) {
			_id
			updatedAt
			stories {
				_id
				title,
				text,
				seq
			}
		}
	}
`;
