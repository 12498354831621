import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
	emailOrUsername: Yup.string().required('Email or username is required.'),
	password: Yup.string().required('Password is required.'),
});

const initialValues = {
	emailOrUsername: '',
	password: '',
};

const handleSubmit = props => async values => {
	const {authenticateUser, ui, snackbar, onAuthenticateSuccess} = props;
	try {
		ui.setAppProgressShown(true);
		const {
			data: {authenticateUser: authData},
		} = await authenticateUser({variables: values});
		console.log('Auth data', authData);
		onAuthenticateSuccess(authData);
	} catch (error) {
		snackbar.enqueueSnackbar(error.message.replace('GraphQL error:', ''), {
			variant: 'error',
			anchorOrigin: {vertical: 'top', horizontal: 'center'},
		});
	} finally {
		ui.setAppProgressShown(false);
	}
};

export default props =>
	useFormik({
		initialValues,
		onSubmit: handleSubmit(props),
		validationSchema,
	});
