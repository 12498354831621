/* eslint-disable camelcase */
import React, {useState, useEffect} from 'react';
import {
	Button,
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	List,
	ListItem,
	ListItemText,
	CircularProgress,
	Typography,
} from '@material-ui/core';
import {CloudUpload as AddFromFileIcon, Info} from '@material-ui/icons';
import {useDropzone} from 'react-dropzone';
import {useSnackbar} from 'notistack';
import {trim, split, reject, pipe, isEmpty, propEq, propOr, pick} from 'ramda';
import {Alert} from '@material-ui/lab';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {useApolloClient, useMutation} from 'react-apollo';
import {ANNOTATE_PROJECT_REQUIREMENTS} from '../../../../../../../graphql/queries';
import {hasAnnotations, UPDATE_PROJECT_REQUIREMENT} from './common';

const useStyles = makeStyles(theme => ({
	overflowYScroll: {
		overflowY: 'scroll',
	},
	textAlignRight: {
		textAlign: 'right',
	},
	dropzone: {
		cursor: 'pointer',
		flex: '1',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: '2px',
		borderRadius: '2px',
		borderColor: theme.palette.primary.main,
		borderStyle: 'dashed',
		backgroundColor: '#fafafa',
		color: theme.palette.primary.main,
		outline: 'none',
		transition: 'border .24s ease-in-out',

		'&.disabled': {
			opacity: 0.6,
		},
	},

	reqBoxList: {
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: '4px',

		'&.error': {
			border: `1px solid ${theme.palette.error.main}`,
		},

		'& .reqBoxListAlert': {
			margin: theme.spacing(2),
		},
	},
	alert: {
		backgroundColor: theme.palette.info.main,
		color: 'black',
		'& svg': {
			fill: 'gray',
		},
	},
}));

const formatUploadedRequirements = pipe(
	trim,
	split('\n'),
	reject(pipe(trim, isEmpty)),
);

const UploadDialog = ({classes, open, setOpen, dropzoneProps, validating}) => {
	const {getRootProps, getInputProps} = dropzoneProps;
	const onClose = () => setOpen(false);

	return (
		<Dialog
			fullWidth
			disableEscapeKeyDown={validating}
			//disableBackdropClick={validating}
			open={open}
			maxWidth="sm"
			onClose={onClose}
		>
			<DialogTitle>Upload requirements from a file</DialogTitle>
			<DialogContent>
				{validating && (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
					>
						<CircularProgress />
						<br />
						<Typography>Validating your requirements...</Typography>
					</Box>
				)}
				{!validating && (
					<div>
						<Box mb={4}>
							<Alert
								variant="standard"
								severity="info"
								className={classes.alert}
								icon={<Info />}
							>
								Upload a single .txt file no more than 20KB. <br />
								<br />
								Requirements must be separated with new lines, and each
								requirement must be valid syntactically by containing Actor and
								Actions annotations.
							</Alert>
						</Box>
						<section className="container">
							<div {...getRootProps({className: classes.dropzone})}>
								<input {...getInputProps()} />
								<p>
									Drag &apos;n&apos; drop a file here, or click to select file
								</p>
							</div>
						</section>
					</div>
				)}
			</DialogContent>
			<DialogActions>
				<Button disabled={validating} onClick={onClose}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const AfterUploadDialog = ({
	open,
	setOpen,
	previewReqs = [],
	classes,
	dialogAccept,
	updateProjectLoading,
}) => {
	const onClose = () => setOpen(false);

	const validReqs = previewReqs.filter(propEq('isValid', true));
	const inValidReqs = previewReqs.filter(propEq('isValid', false));

	const ReqsList = ({alertMessage, alertProps, reqs, maxHeight}) => (
		<div
			className={clsx(
				classes.reqBoxList,
				alertProps && alertProps.severity === 'error' && 'error',
			)}
		>
			<Box mb={2}>
				<Alert {...alertProps} className="reqBoxListAlert">
					{alertMessage}
				</Alert>
			</Box>
			<Box maxHeight={maxHeight} className={classes.overflowYScroll}>
				<List dense>
					{reqs.map(({text, id}) => (
						<ListItem key={id}>
							<ListItemText
								primary={
									<span>
										<strong>{id} - </strong>
										{text}
									</span>
								}
							/>
						</ListItem>
					))}
				</List>
			</Box>
		</div>
	);

	const allInvalid = !validReqs || validReqs.length === 0;

	return (
		<Dialog
			//disableBackdropClick
			disableEscapeKeyDown
			open={open}
			onClose={onClose}
		>
			<DialogTitle>
				Valid Parsed Requirements ({validReqs.length} total)
			</DialogTitle>
			<DialogContent>
				{allInvalid && (
					<Alert variant="filled" severity="error">
						None of your requirements seem to be valid syntactically.
					</Alert>
				)}

				{!allInvalid && validReqs && validReqs.length > 0 && (
					<ReqsList
						maxHeight="350px"
						alertMessage={
							<span>
								Below are the valid requirements parsed from your file. You can
								add them alltogether in the project.
							</span>
						}
						alertProps={{variant: 'standard', severity: 'info'}}
						reqs={validReqs}
					/>
				)}
				{!allInvalid && inValidReqs && inValidReqs.length > 0 && (
					<Box mt={4}>
						<ReqsList
							maxHeight="250px"
							alertMessage={
								<span>
									Rejected requirements.
									<br />
									Below are the requirements that were rejected due to invalid
									syntax.
								</span>
							}
							alertProps={{variant: 'filled', severity: 'error'}}
							reqs={inValidReqs}
						/>
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button disabled={updateProjectLoading} onClick={onClose}>
					Cancel
				</Button>
				{!allInvalid && (
					<Button
						color="primary"
						disabled={updateProjectLoading}
						onClick={dialogAccept}
					>
						{updateProjectLoading ? (
							<Box>
								<CircularProgress size={20} color="primary" />
							</Box>
						) : (
							'Add valid requirements to the project'
						)}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

const validateRequirements = async ({
	requirements = [],
	snackbar,
	setValidating,
	setPreviewReqs,
	apolloClient,
}) => {
	try {
		const {data} = await apolloClient.query({
			query: ANNOTATE_PROJECT_REQUIREMENTS,
			variables: {
				project_requirements: requirements.map((text, id) => ({
					id,
					text,
				})),
			},
		});
		const content = propOr({}, 'annotateProjectRequirements', data);
		setPreviewReqs(
			requirements.map((text, id) => ({
				text,
				id,
				isValid: hasAnnotations(String(id))(content),
			})),
		);
	} catch (error) {
		const msg = error.message.replace('GraphQL error:', '');
		snackbar.enqueueSnackbar(
			`${msg} Please ensure that your requirements have valid syntax.`,
			{
				variant: 'error',
				autoHideDuration: 8000,
				anchorOrigin: {vertical: 'top', horizontal: 'center'},
			},
		);
		//   Here accept all
		setPreviewReqs(
			requirements.map((text, id) => ({
				text,
				id,
				isValid: true,
			})),
		);
	} finally {
		setValidating(false);
	}
};

const AddRequirementsFromFile = props => {
	const {projectId} = props;
	const classes = useStyles();
	const [validating, setValidating] = useState(false);
	const [previewReqs, setPreviewReqs] = useState([]);
	const [open1, setOpen1] = useState(false);
	const [open2, setOpen2] = useState(false);
	const snackbar = useSnackbar();
	const apolloClient = useApolloClient();
	const [updateProject, {loading: updateProjectLoading}] = useMutation(
		UPDATE_PROJECT_REQUIREMENT,
		{
			// onCompleted: () => goBack(),
			onError: error =>
				snackbar.enqueueSnackbar(error.message.replace('GraphQL error:', ''), {
					variant: 'error',
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
				}),
		},
	);

	useEffect(() => {
		if (previewReqs.length > 0) {
			setOpen2(true);
			setOpen1(false);
		}
	}, [previewReqs]);

	useEffect(() => {
		if (open2 === false && previewReqs.length !== 0) setPreviewReqs([]);
		// eslint-disable-next-line
	},[open2])

	const dropzoneProps = useDropzone({
		accept: ['.txt'],
		maxSize: 20000, // 20 kilo bytes
		multiple: false,
		onDropAccepted: async files => {
			const file = files[0];
			const reader = new FileReader();
			reader.addEventListener('load', function(e) {
				const contents = e.target.result;
				try {
					setValidating(true);
					validateRequirements({
						requirements: formatUploadedRequirements(contents),
						setValidating,
						setPreviewReqs,
						snackbar,
						apolloClient,
					});
				} catch {
					setValidating(false);
					snackbar.enqueueSnackbar(
						'There was an error parsing your .txt file. Ensure that requirements are separated by new line.',
						{
							variant: 'error',
							anchorOrigin: {vertical: 'top', horizontal: 'center'},
							autoHideDuration: 6000,
						},
					);
				}
			});

			reader.readAsText(file);
		},
		onDropRejected: () => {
			setPreviewReqs([]);
			snackbar.enqueueSnackbar(
				'You must upload a single .txt file no bigger than 20 KB',
				{
					variant: 'error',
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
					autoHideDuration: 6000,
				},
			);
		},
	});

	const dialogAccept = () => {
		updateProject({
			variables: {
				withStories: false,
				projectId,
				data: {
					requirements: {
						create: previewReqs
							.filter(propEq('isValid', true))
							.map(pick(['text'])),
					},
				},
			},
		});
		setOpen2(false);
		snackbar.enqueueSnackbar('Requirements added to the project', {
			variant: 'success',
			anchorOrigin: {vertical: 'top', horizontal: 'center'},
		});
	};

	return (
		<div>
			<div className={classes.textAlignRight}>
				<Button
					color="default"
					variant="contained"
					startIcon={<AddFromFileIcon />}
					// size="small"
					onClick={() => setOpen1(true)}
				>
					Add requirements from file
				</Button>
			</div>

			<UploadDialog
				classes={classes}
				open={open1}
				setOpen={setOpen1}
				dropzoneProps={dropzoneProps}
				validating={validating}
			/>
			<AfterUploadDialog
				classes={classes}
				previewReqs={previewReqs}
				open={open2}
				setOpen={setOpen2}
				dialogAccept={dialogAccept}
				updateProjectLoading={updateProjectLoading}
			/>
		</div>
	);
};

export default AddRequirementsFromFile;
