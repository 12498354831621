import React, { useEffect, useState, useMemo } from 'react';
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	IconButton,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import {
	identity,
	ifElse,
	isEmpty,
	isNil,
	path,
	pathEq,
	pathOr,
	pipe,
	propEq,
	propOr,
	find
} from 'ramda';
import { useApolloClient, useMutation, useQuery } from 'react-apollo';
import { useRouteMatch } from 'react-router-dom';
import { Delete } from '@material-ui/icons';
import {
	isMongoId,
	isSubmitButtonDisabled,
	nullizeString,
} from '../../../../../../../../utilities/tools';
import {
	DrawerContainer2,
	UPDATE_PROJECT_STORY,
	useFormikBag,
	Form,
} from '../common';
import { AlertActionDialog } from '../../../../../../../common/control';
import CodeEditor from '../code-editor';
import { GET_MY_USER, GET_PROJECT_INTEGRATIONS } from '../../../../../../../../graphql/queries';
import { UPDATE_PROJECT_INTEGRATIONS } from '../../../../../../../../graphql/mutations';
import { deleteTasksFromProject } from '../../../../../../../../utilities/cycloptAPI';

const GET_PROJECT_STORY = gql`
	query GetProjectStory($projectId: String!, $storyId: String!) {
		getProjectStory(projectId: $projectId, storyId: $storyId) {
			_id
			title
			text
			seq
			rating
			comment
			createdAt
			updatedAt
			requirementsLinked {
				_id
				seq
				text
				type
				rating
			}
		}
	}
`;

const useStyles = makeStyles(theme => ({
	submitButton: {
		width: 150,
	},
	backdrop: {
		position: 'absolute',
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const StoryEdit = ({
	openEdit,
	setOpenEdit,
	storyToEdit,
	setStoryToEdit,
}) => {
	const classes = useStyles();
	const match = useRouteMatch();
	const snackbar = useSnackbar();
	const apolloClient = useApolloClient();

	const [codeAnnotations, setCodeAnnotations] = useState([]);
	const [sendingDataToCyclopt, setSendingDataToCyclopt] = useState(false);

	const {data: getMyUserData} = useQuery(GET_MY_USER, {
		fetchPolicy: 'cache-only',
	});
	const amIAdmin = pathEq(['getMyUser', 'role'], 'Admin', getMyUserData);

	const projectId = path(['params', 'projectId'], match);
	const storyId = storyToEdit._id?? '';

	const { data: integrationData, refetch: refetchProjectIntegrations } = useQuery(GET_PROJECT_INTEGRATIONS, { variables: { projectId } });
	const [updateProjectIntegrationsLinks] = useMutation(UPDATE_PROJECT_INTEGRATIONS);
	const {data, refetch: refetchStory} = useQuery(GET_PROJECT_STORY, {
		variables: {projectId, storyId},
		skip: !isMongoId(projectId) || !isMongoId(storyId),
	});
	
	const [updateProject, {loading: updateProjectLoading}] = useMutation(
		UPDATE_PROJECT_STORY,
	);
	
	const [story, setStory] = useState(propOr({}, 'getProjectStory', data));

	const cycloptIntegration = useMemo(
		() =>
			pipe(
				pathOr([], ['getProject', 'integrations']),
				find(propEq('name', 'cyclopt'))
			)(integrationData),
		[integrationData],
	);

	useEffect(() => {
		if (projectId !== '' && storyId !== '') {
			refetchStory();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storyToEdit]);

	useEffect(() => {
		setStory(propOr({}, 'getProjectStory', data));
	}, [data]);

	const deleteTaskFromProjectIntegrations = async (projectId, storyId, cycloptIntegration, updateProjectIntegrationsLinks) => {
		// storyId is the story that was deleted
		// find the lined cyclopt task id
		console.log(storyId);
		const cycloptTaskLink = cycloptIntegration.links.find(link => link.useReqId === storyId);
		const cycloptTaskIdIndex = cycloptIntegration.links.findIndex(link => link.useReqId === storyId);
		console.log("Found this task!", cycloptTaskLink);
		if (cycloptTaskLink) {
			console.log("deleting cyclopt task!", cycloptTaskLink);
			await deleteTasksFromProject(cycloptIntegration.apiKey, cycloptIntegration.projectId, [cycloptTaskLink.integrationId]);

			// remove from links
			console.log("links before", cycloptIntegration.links);
			cycloptIntegration.links.splice(cycloptTaskIdIndex, 1);
			cycloptIntegration.links = cycloptIntegration.links.map(link => {
				return {
					integrationId: link.integrationId,
					useReqId: link.useReqId,
				}
			})
			console.log("links after", cycloptIntegration.links);
			
			// update integration links
			await updateProjectIntegrationsLinks({
				variables: {
					projectId,
					data: {integrations: [{name: 'cyclopt', apiKey: cycloptIntegration.apiKey, projectId: cycloptIntegration.projectId, links: cycloptIntegration.links}]},
				},
			});
		}
	}

	const deleteStory = async (storyId) => {
		try {
			console.log("Deletiong story");

			setSendingDataToCyclopt(true);
			await updateProject({
				variables: {
					projectId,
					data: {
						stories: {
							deleteMany: [storyId],
						},
					},
				},
			});

			console.log("Project Updated!");
			console.log("Cyclopt integration", cycloptIntegration);
			if (cycloptIntegration?.apiKey) {
				console.log("here!")
				await deleteTaskFromProjectIntegrations(projectId, storyId, cycloptIntegration, updateProjectIntegrationsLinks);
			}
			
			setSendingDataToCyclopt(false);
			setOpenEdit(false);
			setStoryToEdit({});
			refetchProjectIntegrations();
		} catch (error) {
			setSendingDataToCyclopt(false);
			snackbar.enqueueSnackbar(error.message.replace('GraphQL error:', ''), {
				variant: 'error',
				anchorOrigin: {vertical: 'top', horizontal: 'center'},
			});

		}
	}

	const formikProps = useFormikBag({
		updateProject,
		story,
		projectId,
		storyId,
		mode: 'edit',
		apolloClient,
		snackbar,
		amIAdmin,
		setSendingDataToCyclopt,
		cycloptIntegration,
		updateProjectIntegrationsLinks,
		setOpenEdit,
		refetchProjectIntegrations,
	}, [story]);
	const {dirty} = formikProps;

	const hasCodeError =
		pipe(path(['values', 'text']), nullizeString, isNil)(formikProps) ||
		(codeAnnotations && codeAnnotations.some(propEq('type', 'error')));

	const submitDisabled =
		!dirty ||
		updateProjectLoading ||
		isSubmitButtonDisabled(formikProps) ||
		sendingDataToCyclopt ||
		hasCodeError;

	return (
		<DrawerContainer2
			Header={
				<Typography variant="h5">
					{pipe(
						propOr('', 'seq'),
						ifElse(isEmpty, identity, s => `(S-${s})`),
					)(story)}{' '}
					Edit Story
				</Typography>
			}
			open={openEdit}
			setOpen={setOpenEdit}
			dirty={dirty}
			formikProps={formikProps}
		>
			<Backdrop
				open={updateProjectLoading || formikProps.isSubmitting || sendingDataToCyclopt}
				className={classes.backdrop}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Container maxWidth="lg">
				<Box 
					display="flex"
					flexDirection="row"
				>
					<Box sx={{ mt: 4 }} width="35%">
						<Box>
							<Form
								projectId={projectId}
								cycloptIntegration={cycloptIntegration}
								mode="edit"
								amIAdmin={amIAdmin}
								storyId={storyId}
								{...formikProps}
							/>
						</Box>
						<Box 
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
							sx={{ mt: 6 }}
						>
							<Button
								className={classes.submitButton}
								color="primary"
								variant="contained"
								disabled={submitDisabled}
								onClick={() => formikProps.handleSubmit()}
							>
								Submit
							</Button>
							<Grid item>
								<AlertActionDialog
									canEscape
									title="Delete Story"
									text="Are you sure you want to delete this story? This action cannot be undone."
									actions={[
										{text: 'Back', buttonProps: {color: 'default'}},
										{
											text: 'Delete Story',
											buttonProps: {color: 'danger'},
											onClick: () => deleteStory(storyId),
										},
									]}
								>
									{({handleOpen}) => (
										<Tooltip title="Delete Story">
											<IconButton
												disabled={updateProjectLoading}
												onClick={() => handleOpen()}
											>
												<Delete />
											</IconButton>
										</Tooltip>
									)}
								</AlertActionDialog>
							</Grid>
						</Box>
					</Box>
					
					<Box width="65%" sx={{ mt: 4}}>
						<CodeEditor
							codeAnnotations={codeAnnotations}
							setCodeAnnotations={setCodeAnnotations}
							{...formikProps}
						/>
					</Box>
				</Box>
			</Container>
		</DrawerContainer2>
	);
};

export default StoryEdit;
