import {propOr} from 'ramda';
import {GET_INVITED_USERS} from '../../../../../graphql/queries';

//  TODO: Check when there is an error to one of the e-mails with the cache
export default (cache, {data}) => {
	const newInvitedUser = data.createInvitedUser;
	try {
		const read = cache.readQuery({query: GET_INVITED_USERS});
		const invitedUsers = propOr([], 'getInvitedUsers', read);
		cache.writeQuery({
			query: GET_INVITED_USERS,
			data: {
				getInvitedUsers: [newInvitedUser, ...invitedUsers],
			},
		});
	} catch {
		cache.writeQuery({
			query: GET_INVITED_USERS,
			data: {
				getInvitedUsers: [newInvitedUser],
			},
		});
	}
};
