import {useFormik} from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
	email: Yup.string()
		.email('Not a valid email')
		.required('Email required.'),
	role: Yup.string().required('Role is required.'),
});

const initialValues = {
	email: '',
	role: 'User',
};

const handleSubmit = props => async values => {
	const {createInvitedUser, ui, snackbar, setPopoverOpen} = props;

	try {
		ui.setAppProgressShown(true);
		await createInvitedUser({variables: {data: values}});
		setPopoverOpen(true);
	} catch (error) {
		snackbar.enqueueSnackbar(error.message.replace('GraphQL error:', ''), {
			variant: 'error',
			anchorOrigin: {vertical: 'top', horizontal: 'center'},
		});
	} finally {
		ui.setAppProgressShown(false);
	}
};

export default props =>
	useFormik({
		initialValues,
		onSubmit: handleSubmit(props),
		validationSchema,
	});
