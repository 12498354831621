import {useFormik} from 'formik';
import {pathOr, prop} from 'ramda';
import * as Yup from 'yup';
import {nullizeString} from '../../../../../../../../utilities/tools';

import { populateProjectWithTasks, updateTaskInProject } from '../../../../../../../../utilities/cycloptAPI';

const validationSchema = Yup.object().shape({
	title: Yup.string().required('Required.'),
});

const getInitialValues = props => ({
	title: props.mode === 'create' ? '' : pathOr('', ['story', 'title'], props),
	text: props.mode === 'create' ? '' : pathOr('', ['story', 'text'], props),
	rating: props.mode === 'create' ? '' : pathOr('', ['story', 'rating'], props),
	comment:
		props.mode === 'create' ? '' : pathOr('', ['story', 'comment'], props),
	requirementsLinked:
		props.mode === 'create'
			? []
			: pathOr([], ['story', 'requirementsLinked'], props),
});

const formatValuesFn = ({amIAdmin}) => ({
	rating,
	comment,
	type,
	requirementsLinked = [],
	...rest
}) => ({
	...rest,
	...(amIAdmin ? {rating: rating || []} : {}),
	...(amIAdmin ? {comment: nullizeString(comment)} : {}),
	requirementsLinked: requirementsLinked.map(prop('_id')),
});

const createNewTaskIntegration = async (projectId, updatedProject, cycloptIntegration, updateProjectIntegrationsLinks) => {
	// update integrations!
	const newStory = updatedProject.data.updateProject.stories.pop();
	const tasks = [ // just one task
		{
			_id: newStory._id,
			title: `S-${newStory.seq} ${newStory.title}`,
			body: newStory.text? newStory.text : ''
		}
	]
	// add new tasks to the new project selected
	const res = await populateProjectWithTasks(cycloptIntegration.apiKey, cycloptIntegration.projectId, tasks);
	if (res.status !== 200) throw new Error ("Error while linking tasks");

	console.log("old tasks:", cycloptIntegration.links)
	const oldTasks = cycloptIntegration.links.map(task => {
		return {
			integrationId: task.integrationId,
			useReqId: task.useReqId,
		}
	})
	const newTasks = res.message;
	console.log("new tasks to add", newTasks);
	// append the new links to the old array;
	const taskLinks = newTasks.concat(oldTasks);
	console.log("added tasks", taskLinks)

	await updateProjectIntegrationsLinks({
		variables: {
			projectId,
			data: {integrations: [{name: 'cyclopt', apiKey: cycloptIntegration.apiKey, projectId: cycloptIntegration.projectId, links: taskLinks}]},
		},
	});
}

const updateTaskIntegration = async (projectId, storyId, updatedProject, cycloptIntegration, updateProjectIntegrationsLinks) => {
	const updatedStory = updatedProject.data.updateProject.stories.find(story => story._id === storyId);
	console.log(updatedStory);
	const updatedTask = {
		_id: updatedStory._id,
		title: `S-${updatedStory.seq} ${updatedStory.title}`,
		body: updatedStory.text? updatedStory.text : ''
	};

	const cycloptTaskLink = cycloptIntegration.links.find(link => link.useReqId === storyId);

	const res = await updateTaskInProject(cycloptIntegration.apiKey, cycloptIntegration.projectId, cycloptTaskLink.integrationId, updatedTask);
	if (res.status !== 200) throw new Error ("Error while Updating task", storyId);
}

const handleSubmit = props => async (values, { resetForm, setSubmitting }) => {
	const {
		updateProject,
		projectId,
		storyId,
		mode,
		snackbar,
		amIAdmin,
		setSendingDataToCyclopt,
		cycloptIntegration,
		updateProjectIntegrationsLinks,
		setOpenCreate,
		setOpenEdit,
		refetchProjectIntegrations
	} = props;
	const formatValues = formatValuesFn({amIAdmin});

	try {
		setSendingDataToCyclopt(true);
		let updatedProject = await updateProject({
			variables: {
				projectId,
				data: {
					stories: {
						...(mode === 'create'
							? {create: [formatValues(values)]}
							: {
									updateMany: [{_id: storyId, data: formatValues(values)}],
							  }),
					},
				},
			},
		});

		if (cycloptIntegration?.apiKey) {
			if (mode === 'create') {
				await createNewTaskIntegration(projectId, updatedProject, cycloptIntegration, updateProjectIntegrationsLinks);
			} else if (mode === 'edit') {
				console.log("Edit mode");
				await updateTaskIntegration(projectId, storyId, updatedProject, cycloptIntegration, updateProjectIntegrationsLinks);
			}
		}
		
		setSendingDataToCyclopt(false);
		if (setOpenCreate) setOpenCreate(false);
		if (setOpenEdit) setOpenEdit(false);
		refetchProjectIntegrations();
		// TODO - reset form after story creation!

	} catch (error) {
		setSubmitting(false);
		setSendingDataToCyclopt(false);
		const msg = error.message.replace('GraphQL error:', '');
		snackbar.enqueueSnackbar(msg, {
			variant: 'error',
			autoHideDuration: 8000,
			anchorOrigin: {vertical: 'top', horizontal: 'center'},
		});
	}
};

const useFormikBag = props => {
	return useFormik({
		enableReinitialize: props.mode === 'edit',
		initialValues: getInitialValues(props),
		onSubmit: handleSubmit(props),
		validationSchema,
	});
}
	

export default useFormikBag;
