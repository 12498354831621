import gql from 'graphql-tag';

export default gql`
	query GetMyProjects {
		getMyProjects {
			_id
			createdAt
			updatedAt
			name
			description
			tags
			creatorId
			users {
				_id
				username
			}
			stories {
				_id
			}
			requirements {
				_id
			}
		}
	}
`;
