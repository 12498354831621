import axios from 'axios';

const addHeaders = (apiKey) => {
	// let apiKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0MWQ1ZTE0NzU1OWJlYmFjY2Y2NWJhNCIsImVtYWlsIjoiZmJpMzYwMEBnbWFpbC5jb20iLCJ1c2VybmFtZSI6ImdyZWd0cmlhbnQiLCJ0eXBlIjoiZ2l0aHViIiwiaWF0IjoxNjk3MDE3NDcwfQ.t719Vdwz7Ra3Wb30pikJuoUzjLTkJApIAt4-zd4Y3rc";
	let headers = {
		headers: {
			'x-access-cyclopt-token': `${apiKey}`
		}
	}
	return headers;
}

export const getUserProjects = async (apiKey) => {
	try {
		let res = await axios.get(`https://server.cyclopt.services/cyclopt/openapi/projects?limit=100`, addHeaders(apiKey))
		return res
	} catch(e) {
		console.log(e);
		return {
			status: 400,
			message: 'Something went wrong!'
		}
	}
}

export const deleteTasksFromProject = async (apiKey, projectId, tasksIds) => { // list of ids of tasks
	// let tasks = await getAllTasks(apiKey, projectId);
	// console.log(tasks.data);
	// try to delete tasks from a project (careful!! some tasks may not exist but that is ok)
	for (const taskId of tasksIds) {
		try {
			await axios.delete(`https://server.cyclopt.services/cyclopt/openapi/projects/${projectId}/tasks/${taskId}`, addHeaders(apiKey));
		} catch(e) {
			console.log(e);
		}
	}
}

export const updateTaskInProject = async (apiKey, projectId, taskId, task) => { // list of ids of tasks
	try {
		let res = await axios.put(`https://server.cyclopt.services/cyclopt/openapi/projects/${projectId}/tasks/${taskId}`, task, addHeaders(apiKey));
		return res;
	} catch(e) {
		console.log(e);
		return {
			status: 400,
			message: "Error updating cyclopt task..."
		}
	}
}

export const populateProjectWithTasks = async (apiKey, projectId, tasks, setProgressComment) => { // list of of tasks: {title: '', body: '', assignees: '', dueDate: '', points: '', priority: '', author: ''}
	try {
		let taskLinks = [];
		let index = 0;
		for (const task of tasks) {
			if (setProgressComment) {
				setProgressComment(`Creating task ${index+1} of ${tasks.length}...`);
			}
			// console.log('sending post task: ', task)
			let res = await axios.post(`https://server.cyclopt.services/cyclopt/openapi/projects/${projectId}/tasks`, task, addHeaders(apiKey));
			// console.log(res);
			let newTaskId = res.data.id;
			taskLinks.push({
				useReqId: task._id,
				integrationId: newTaskId
			});
			index++;
		}
		return {
			status: 200,
			message: taskLinks
		};
	} catch(e) {
		console.log(e);
		return {
			status: 400,
			message: 'Something went wrong while populating tasks!'
		}
	}
}

// export const closeTask = async (apiKey, projectId, taskId) => {
// 	try {
// 		let res = await axios.patch(`https://server.cyclopt.services/cyclopt/openapi/projects/${projectId}/tasks/${taskId}/close`, addHeaders(apiKey));
// 		console.log(res);
// 		return res
// 	} catch(e) {
// 		console.log(e);
// 		return {
// 			status: 400,
// 			message: 'Something went wrong!'
// 		}
// 	}
// }

// export const openTask = async (apiKey, projectId, taskId) => {
// 	try {
// 		let res = await axios.patch(`https://server.cyclopt.services/cyclopt/openapi/projects/${projectId}/tasks/${taskId}/reopen`, addHeaders(apiKey));
// 		console.log(res);
// 		return res
// 	} catch(e) {
// 		console.log(e);
// 		return {
// 			status: 400,
// 			message: 'Something went wrong!'
// 		}
// 	}
// }


export const getAllTasks = async (apiKey, projectId) => {
	try {
		// console.log("Getting all tasks for project:", projectId);
		let res = await axios.get(`https://server.cyclopt.services/cyclopt/openapi/projects/${projectId}/tasks`, addHeaders(apiKey));
		// console.log(res);
		console.log("Got tasks:", res.data);
		return res;
	} catch(e) {
		console.log(e);
		return {
			status: 400,
			message: 'Something went wrong!'
		}
	}
}

export const getTask = async (apiKey, projectId, taskId) => {
	try {
		// console.log("Getting task for project:", projectId);
		let res = await axios.get(`https://server.cyclopt.services/cyclopt/openapi/projects/${projectId}/tasks/${taskId}`, addHeaders(apiKey));
		// console.log(res);
		// console.log("Got task:", res.data);
		return {
			status: 200,
			task: res.data.task
		};
	} catch(e) {
		console.log(e);
		return {
			status: 400,
			message: 'Something went wrong!'
		}
	}
}


export const getCodeQuality = async (apiKey, projectId, branches) => {
	// Request Body: {
	// 	"repositories": [
	// 		{ "owner": "owner1", "name": "repo1", "branch": "master"},
	// 		{ "owner": "owner1", "name": "repo1", "branch": "main"},
	// 		{ "owner": "owner2", "name": "repo2", "branch": "develop"}
	// 	]

	let repositories = [];
	for (const br of branches) {
		let url_split = br.url.split(".com/");
		url_split.splice(0, 1);
		url_split = url_split[0].split('/');
		const owner = url_split[0];
		const name = url_split[1]; // repo name
		const branch = url_split.includes('tree') ? url_split[3] : br.name; // if the url does not have the word tree then the branch name will be the branch description
		repositories.push({
			owner,
			name,
			branch,
		})
	}
	
	try {
		let res = await axios.post(`https://server.cyclopt.services/cyclopt/openapi/projects/${projectId}/analyses`, { repositories }, addHeaders(apiKey));
		// console.log("Analyses:", res.data);
		return {
			status: 200,
			analyses: res.data.analyses
		};
	} catch(e) {
		console.log(e);
		return {
			status: 400,
			message: 'Something went wrong!'
		}
	}
}


