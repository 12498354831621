import gql from 'graphql-tag';

export default gql`
	mutation CreateProject($data: CreateProjectInput!) {
		createProject(data: $data) {
			_id
			name
			description
			tags
			users {
				_id
				username
			}
			requirements {
				_id
			}
			stories {
				_id
			}
			createdAt
			updatedAt
		}
	}
`;
