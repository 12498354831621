import React from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import { useQuery, useMutation } from 'react-apollo';
import { NetworkStatus } from 'apollo-client';
import { useQueryParams, NumberParam, JsonParam } from 'use-query-params';
// import { path, propOr, complement, propEq, pipe, filter, assoc } from 'ramda';
import { AppBar, MainWrapper, ErrorStates } from '../../../../common/ui';
import { UsersIcon } from '../../../../../utilities/constants';

import { GET_WONNABE_TEAMLEADS } from '../../../../../graphql/queries';
import { MAKE_USER_TEAM_LEAD } from '../../../../../graphql/mutations';
import WonnabeTeamLeadsTableWrapper from './wonnabe-teamleads-table-wrapper';
// import { useEffect } from 'react';

export const USERS_PAGE_SIZE_DEFAULT = 25;
const USERS_PAGE_SIZE_DEFAULT_PAGE_OPTIONS = [12, 25, 50];

const Title = () => (
	<Typography
		variant="h5"
		style={{display: 'flex', alignItems: 'center'}}
		color="secondary"
	>
		<UsersIcon style={{marginRight: '8px'}} /> TeamLead Requests 
	</Typography>
);

const Subtitle = () => (
	<Typography variant="subtitle1" color="secondary">
		Below are listed all the users that have requested to become a TeamLead.
	</Typography>
);

const TeamLeadsRequestsScreen = () => {
	const [queryParams, setQueryParams] = useQueryParams({
		sortBy: JsonParam, // although this is actually a JSON array
		filters: JsonParam, // although this is actually a JSON array
		pageIndex: NumberParam,
		pageSize: NumberParam,
	});
	const {data: teamleads, error, networkStatus, refetch: refetchWonnabes} = useQuery(GET_WONNABE_TEAMLEADS, {
		notifyOnNetworkStatusChange: true,
	});
	
	const [makeUserTeamLead, {loading: makeTeamLeadLoading}] = useMutation(MAKE_USER_TEAM_LEAD, {
		update: (cache, {data}) => {
			console.log("-->> Request answered");
			console.log(data);
		},
	});

	// useEffect(() => {
	// 	console.log("Team leads changed!")
	// 	console.log(teamleads);
	// }, [teamleads])

	const shouldShowTableSkeleton =
		networkStatus === NetworkStatus.loading ||
		networkStatus === NetworkStatus.refetch;

	return (
		<>
			<AppBar />
			<MainWrapper>
				<Container maxWidth="lg">
					{error ? (
						<ErrorStates.TryAgain
							withGoBackButton
							error={error}
							refetch={refetchWonnabes}
						/>
					) : (
						<>
							<Title />
							<Subtitle />
							<Box mt={2}>
								<WonnabeTeamLeadsTableWrapper
									tableLoading={makeTeamLeadLoading}
									data={teamleads}
									makeUserTeamLead={makeUserTeamLead}
									refetchWonnabes={refetchWonnabes}
									shouldShowSkeleton={shouldShowTableSkeleton}
									queryParams={queryParams}
									setQueryParams={setQueryParams}
									pageOptions={USERS_PAGE_SIZE_DEFAULT_PAGE_OPTIONS}
									defaultPageSize={USERS_PAGE_SIZE_DEFAULT}
								/>
							</Box>
						</>
					)}
				</Container>
			</MainWrapper>
		</>
	);
};

export default TeamLeadsRequestsScreen;
