import React from 'react';
import {LinearProgress} from '@material-ui/core';
import {useUi} from '../hoc';

const AppProgress = () => {
	const {appProgressShown} = useUi();

	return (
		appProgressShown && (
			<LinearProgress
				color="secondary"
				style={{position: 'fixed', top: 0, zIndex: 9999, width: '100%'}}
			/>
		)
	);
};

export default AppProgress;
