import React, {useMemo, useRef} from 'react';
import {pipe, propOr, length, prop, path} from 'ramda';
import {
	Chip,
	Grid,
	IconButton,
	Tooltip,
	Box,
	CircularProgress,
	Typography,
} from '@material-ui/core';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {
	FileCopy as FileCopyIcon,
	Delete as DeleteIcon,
	Refresh as ResendEmailIcon,
} from '@material-ui/icons';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useMutation} from 'react-apollo';
import {WithSkeleton} from '../../../../common/hoc';
import {
	TableColumnFilterText,
	TableUncontrolled,
	TableColumnFilterSelect,
	TableColumnFilterDateRange,
	AlertActionDialog,
} from '../../../../common/control';
import {USER_ROLES} from '../../../../../utilities/constants';
import {
	filterByDateRange,
	buildInvitationURL,
} from '../../../../../utilities/tools';
import {SEND_INVITATION_EMAIL} from '../../../../../graphql/mutations';
import {InvitedUsersTableSkeleton} from './skeletons';

const getInvitedUsers = propOr([], 'getInvitedUsers');

const getTotalCount = pipe(propOr([], 'getInvitedUsers'), length);

const Actions = ({
	value,
	row,
	snackbar,
	deleteInvitedUser,
	sendInvitationEmail,
	sendingEmailSnackKey,
}) => (
	<Grid container spacing={2} justifyContent="center" alignItems="center">
		<Grid item>
			<AlertActionDialog
				canEscape
				title="Resend Invitation E-mail"
				text={`Are you sure you want to resend invitation e-mail for this user? (email will be sent to "${path(
					['original', 'email'],
					row,
				)}")`}
				actions={[
					{text: 'Back', buttonProps: {color: 'default'}},
					{
						text: 'Yes, resend e-mail',
						buttonProps: {color: 'primary'},
						onClick: () => {
							sendingEmailSnackKey.current = snackbar.enqueueSnackbar(
								<Box display="flex" alignItems="center">
									<CircularProgress style={{color: '#fff'}} size="20px" />
									<Box ml={2}>Sending invitation e-mail to user...</Box>
								</Box>,
								{
									anchorOrigin: {vertical: 'top', horizontal: 'center'},
									persist: true,
								},
							);
							sendInvitationEmail({
								variables: {invitedUserId: path(['original', '_id'], row)},
							});
						},
					},
				]}
			>
				{({handleOpen}) => (
					<Tooltip title="Resend Invitation E-mail">
						<IconButton onClick={() => handleOpen(true)} size="small">
							<ResendEmailIcon />
						</IconButton>
					</Tooltip>
				)}
			</AlertActionDialog>
		</Grid>
		<Grid item>
			<Tooltip title="Grab Invitation Link">
				<CopyToClipboard
					text={buildInvitationURL(value)}
					onCopy={() =>
						snackbar.enqueueSnackbar('Copied to clipboard!', {
							variant: 'default',
							anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
						})
					}
				>
					<IconButton size="small">
						<FileCopyIcon />
					</IconButton>
				</CopyToClipboard>
			</Tooltip>
		</Grid>
		<Grid item>
			<AlertActionDialog
				canEscape
				title="Cancel Invitation"
				text={`Are you sure you want to cancel "${path(
					['original', 'email'],
					row,
				)}" invitation?`}
				actions={[
					{text: 'Back', buttonProps: {color: 'default'}},
					{
						text: 'Cancel Invitation',
						buttonProps: {color: 'danger'},
						onClick: () =>
							deleteInvitedUser({
								variables: {invitedUserId: path(['original', '_id'], row)},
							}),
					},
				]}
			>
				{({handleOpen}) => (
					<Tooltip title="Cancel Invitation">
						<IconButton onClick={() => handleOpen(true)} size="small">
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				)}
			</AlertActionDialog>
		</Grid>
	</Grid>
);

const InvitedUsersTableWrapper = ({data, deleteInvitedUser, ...props}) => {
	const sendingEmailSnackKey = useRef(null);
	const snackbar = useSnackbar();
	const [sendInvitationEmail] = useMutation(SEND_INVITATION_EMAIL, {
		onCompleted: data => {
			snackbar.closeSnackbar(sendingEmailSnackKey.current);
			snackbar.enqueueSnackbar(
				`Invitation e-mail re-sent successfuly to ${path(
					['sendInvitationEmail', 'email'],
					data,
				)}`,
				{
					variant: 'success',
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
				},
			);
		},
		onError: error => {
			console.log(error);
			snackbar.closeSnackbar(sendingEmailSnackKey.current);
			snackbar.enqueueSnackbar(
				'Email could not be sent! Check the console logs for more info.',
				{
					variant: 'error',
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
					autoHideDuration: 5000,
				},
			);
		},
	});

	const invitedUsers = getInvitedUsers(data);
	const totalCount = getTotalCount(data);

	const columns = useMemo(
		() => [
			{
				Header: '',
				id: 'actions',
				accessor: 'invitationHash',
				Cell: ({cell: {value, row}}) => (
					<Actions
						value={value}
						row={row}
						snackbar={snackbar}
						deleteInvitedUser={deleteInvitedUser}
						sendInvitationEmail={sendInvitationEmail}
						sendingEmailSnackKey={sendingEmailSnackKey}
					/>
				),
				disableSortBy: true,
				disableFilters: true,
				style: {width: 175, minWidth: 175},
			},
			{
				Header: 'Email',
				id: 'email',
				accessor: 'email',
				Filter: TableColumnFilterText,
				style: {width: 300, minWidth: 175},
			},
			{
				Header: 'Role',
				id: 'role',
				accessor: 'role',
				Cell: ({cell: {value}}) => (
					<Chip
						color={value === 'Admin' ? 'secondary' : 'primary'}
						label={value}
					/>
				),
				filter: 'includesSome',
				Filter: filterProps => (
					<TableColumnFilterSelect
						{...filterProps}
						options={USER_ROLES.map(prop('value'))}
					/>
				),
				style: {width: 120},
			},
			{
				Header: 'Invited on',
				id: 'createdAt',
				accessor: 'createdAt',
				Cell: ({cell: {value}}) => moment(value).format('MMMM Do YYYY, HH:mm'),
				Filter: TableColumnFilterDateRange,
				filter: filterByDateRange,
				style: {width: 250, minWidth: 175},
			},
		],
		// eslint-disable-next-line
		[],
	);

	return (
		<TableUncontrolled
			{...props}
			columns={columns}
			data={invitedUsers}
			totalCount={totalCount}
			tableTitle={
				<Typography variant="h6" component="div" color="secondary">
					Invited Users
				</Typography>
			}
		/>
	);
};

export default props => (
	<WithSkeleton
		shouldShowSkeleton={props.shouldShowSkeleton}
		Skeleton={InvitedUsersTableSkeleton}
	>
		<InvitedUsersTableWrapper {...props} />
	</WithSkeleton>
);
