import React from 'react';
import {Warning as NoRecordsIcon} from '@material-ui/icons';
import {TableBody, TableRow, TableCell, Typography} from '@material-ui/core';
import {isNilOrEmpty} from '../../../../utilities/tools';

const NoRecordsFound = ({filters, noRecordsFoundMessage}) => {
	return (
		<TableBody>
			<TableRow>
				<TableCell align="center" colSpan={100}>
					<Typography
						component="div"
						variant="subtitle2"
						color="textSecondary"
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<NoRecordsIcon
							style={{marginRight: '8px'}}
							fontSize="inherit"
							color="inherit"
						/>
						{isNilOrEmpty(filters)
							? noRecordsFoundMessage
							: 'No records found, try adjusting the filters.'}
					</Typography>
				</TableCell>
			</TableRow>
		</TableBody>
	);
};

export default NoRecordsFound;
