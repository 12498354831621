/* eslint-disable camelcase */
import React from 'react';
import {
	AppBar,
	Divider,
	Drawer,
	IconButton,
	Toolbar,
	Box,
	Grid,
	Typography,
	Chip,
	FormLabel,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {makeStyles} from '@material-ui/styles';
import gql from 'graphql-tag';
import {useFormik} from 'formik';
import {
	pathOr,
	prop,
	pipe,
	ifElse,
	F,
	propEq,
	find,
	isNil,
	propSatisfies,
	complement,
	propOr,
	path,
} from 'ramda';
import * as Yup from 'yup';
import {
	isNilOrEmpty,
	nullizeString,
} from '../../../../../../../utilities/tools';
import {ANNOTATE_PROJECT_REQUIREMENTS} from '../../../../../../../graphql/queries';
import {
	PROJECT_REQUIREMENT_RATINGS,
	PROJECT_REQUIREMENT_TYPES,
} from '../../../../../../../utilities/constants';
import {Select, TextField} from '../../../../../../common/form';

const PROJECT_REQUIREMENT_TYPES_OBJECT = PROJECT_REQUIREMENT_TYPES.reduce(
	(acc, {value, ...rest}) => ({...acc, [value]: rest}),
	{},
);

const PROJECT_REQUIREMENT_RATINGS_OBJECT = PROJECT_REQUIREMENT_RATINGS.reduce(
	(acc, {value, ...rest}) => ({...acc, [value]: rest}),
	{},
);

export const UPDATE_PROJECT_REQUIREMENT = gql`
	mutation UpdateProject(
		$projectId: String!
		$data: UpdateProjectInput!
		$withStories: Boolean!
	) {
		updateProject(projectId: $projectId, data: $data) {
			_id
			requirements {
				_id
				seq
				text
				rating
				comment
				type
				createdAt
				updatedAt
			}
			stories @include(if: $withStories) {
				_id
				requirementsLinked {
					_id
				}
			}
			createdAt
			updatedAt
		}
	}
`;

const useStyles = makeStyles(theme => ({
	appBar: {
		backgroundColor: theme.palette.secondary.main,
	},

	container: {
		width: 750,

		'@media (max-width:900px)': {
			width: '80vw',
		},

		'@media (max-width:600px)': {
			width: '100vw',
		},

		'& .header': {
			textAlign: 'center',
			// padding: 10,
			backgroundColor: '#ececec',
		},
	},
}));

export const DrawerContainer = ({onClose = () => {}, Header, children}) => {
	const classes = useStyles();

	return (
		<Drawer open anchor="right" onClose={onClose}>
			<div className={classes.container}>
				<AppBar position="relative" className={classes.appBar}>
					<Toolbar>
						<IconButton color="inherit" onClick={() => onClose()}>
							<Close />
						</IconButton>
						{Header}
					</Toolbar>
				</AppBar>
				<Divider className="divider" />
				{children}
			</div>
		</Drawer>
	);
};

const validationSchema = Yup.object().shape({
	text: Yup.string().required('Required.'),
});

const getInitialValues = props => ({
	text:
		props.mode === 'create' ? '' : pathOr('', ['requirement', 'text'], props),
	rating:
		props.mode === 'create' ? '' : pathOr('', ['requirement', 'rating'], props),
	comment:
		props.mode === 'create'
			? ''
			: pathOr('', ['requirement', 'comment'], props),
	type:
		props.mode === 'create' ? '' : pathOr('', ['requirement', 'type'], props),
});

const formatValuesFn = ({amIAdmin}) => ({rating, comment, type, ...rest}) => ({
	...rest,
	...(isNilOrEmpty(type) ? {} : {type}),
	...(amIAdmin && !isNilOrEmpty(rating) ? {rating} : {}),
	...(amIAdmin ? {comment: nullizeString(comment)} : {}),
});

export const hasAnnotations = reqId =>
	pipe(
		prop('annotations'),
		ifElse(
			isNilOrEmpty,
			F,
			pipe(
				find(propEq('id', reqId)),
				ifElse(isNil, F, propSatisfies(complement(isNilOrEmpty), 'annotation')),
			),
		),
	);

const handleSubmit = props => async (
	values,
	{setSubmitting, setFieldError},
) => {
	const {
		updateProject,
		projectId,
		requirementId,
		mode,
		apolloClient,
		snackbar,
		goBack,
		amIAdmin,
	} = props;
	const text = prop('text', values);
	const formatValues = formatValuesFn({amIAdmin});

	try {
		const {data} = await apolloClient.query({
			query: ANNOTATE_PROJECT_REQUIREMENTS,
			variables: {project_requirements: [{id: '1', text}]},
		});
		if (hasAnnotations('1')(propOr({}, 'annotateProjectRequirements', data))) {
			try {
				await updateProject({
					variables: {
						withStories: false,
						projectId,
						data: {
							requirements: {
								...(mode === 'create'
									? {create: [formatValues(values)]}
									: {
											updateMany: [
												{_id: requirementId, data: formatValues(values)},
											],
									  }),
							},
						},
					},
				});
				goBack({justClose: mode === 'edit'});
			} catch (error) {
				setSubmitting(false);
				snackbar.enqueueSnackbar(error.message.replace('GraphQL error:', ''), {
					variant: 'error',
					autoHideDuration: 8000,
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
				});
			}
		} else {
			setFieldError('text', 'Invalid syntax for a Requirement');
			setSubmitting(false);
		}
	} catch (error) {
		setSubmitting(false);
		const msg = error.message.replace('GraphQL error:', '');
		snackbar.enqueueSnackbar(
			`${msg} Please ensure that your requirements have valid syntax.`,
			{
				variant: 'error',
				autoHideDuration: 8000,
				anchorOrigin: {vertical: 'top', horizontal: 'center'},
			},
		);
		try {
			await updateProject({
				variables: {
					withStories: false,
					projectId,
					data: {
						requirements: {
							...(mode === 'create'
								? {create: [formatValues(values)]}
								: {
										updateMany: [
											{_id: requirementId, data: formatValues(values)},
										],
								  }),
						},
					},
				},
			});
			goBack({justClose: mode === 'edit'});
		} catch (error) {
			setSubmitting(false);
			snackbar.enqueueSnackbar(error.message.replace('GraphQL error:', ''), {
				variant: 'error',
				autoHideDuration: 8000,
				anchorOrigin: {vertical: 'top', horizontal: 'center'},
			});
		}
	}
};

export const useFormikBag = props =>
	useFormik({
		enableReinitialize: props.mode === 'edit',
		initialValues: getInitialValues(props),
		onSubmit: handleSubmit(props),
		validationSchema,
	});

export const Form = props => {
	const {mode, amIAdmin} = props;

	return (
		<div>
			<Box mb={4}>
				<Typography variant="body2">
					Please fill all the fields below in order to&nbsp;
					{mode === 'create' ? 'create the' : 'edit this'} requirement.
					<br />
					Fields marked with an asterisk (*) are required.
				</Typography>
			</Box>
			<Grid container spacing={3}>
				<Grid item xs={12} md={11}>
					<TextField
						{...props}
						multiline
						maxRows={4}
						textFieldProps={{
							autoFocus: mode === 'create',
							multiline: true,
							maxRows: 10,
							onKeyDown: event => {
								if (event.key === 'Enter' && !event.shiftKey) {
									props.handleSubmit();
									event.preventDefault();
								}
							},
						}}
						name="text"
						label="Requirement*"
					/>
				</Grid>
				<Grid item xs={12} md={7}>
					<Select
						{...props}
						formControlProps={{margin: 'none'}}
						selectProps={{
							renderValue: value => (
								<Chip
									label={path(
										[value, 'label'],
										PROJECT_REQUIREMENT_TYPES_OBJECT,
									)}
								/>
							),
						}}
						name="type"
						label="Type"
						options={PROJECT_REQUIREMENT_TYPES}
					/>
				</Grid>
				<Grid item xs={12} md={7}>
					{mode === 'edit' && !amIAdmin && (
						<Grid container spacing={2} alignItems="center">
							<Grid item>
								<FormLabel>Rating:</FormLabel>
							</Grid>
							<Grid item>
								{isNilOrEmpty(path(['values', 'rating'], props)) ? (
									'-'
								) : (
									<Chip
										label={path(
											[path(['values', 'rating'], props), 'label'],
											PROJECT_REQUIREMENT_RATINGS_OBJECT,
										)}
										style={pathOr(
											{},
											[path(['values', 'rating'], props), 'style'],
											PROJECT_REQUIREMENT_RATINGS_OBJECT,
										)}
									/>
								)}
							</Grid>
						</Grid>
					)}
					{amIAdmin && (
						<Select
							{...props}
							formControlProps={{margin: 'none'}}
							selectProps={{
								renderValue: value => (
									<Chip
										label={path(
											[value, 'label'],
											PROJECT_REQUIREMENT_RATINGS_OBJECT,
										)}
										style={pathOr(
											{},
											[value, 'style'],
											PROJECT_REQUIREMENT_RATINGS_OBJECT,
										)}
									/>
								),
							}}
							name="rating"
							label="Rating"
							options={PROJECT_REQUIREMENT_RATINGS}
						/>
					)}
				</Grid>
				<Grid item xs={12} md={11}>
					{mode === 'edit' && !amIAdmin && (
						<Grid container spacing={2} alignItems="center">
							<Grid item>
								<FormLabel>Comment:</FormLabel>
							</Grid>
							<Grid item>
								{isNilOrEmpty(path(['values', 'comment'], props)) ? (
									'-'
								) : (
									<Typography variant="body1">
										{path(['values', 'comment'], props)}
									</Typography>
								)}
							</Grid>
						</Grid>
					)}
					{amIAdmin && (
						<TextField
							{...props}
							textFieldProps={{
								minRows: 2,
								multiline: true,
								maxRows: 10,
								onKeyDown: event => {
									if (event.key === 'Enter' && !event.shiftKey) {
										props.handleSubmit();
										event.preventDefault();
									}
								},
							}}
							name="comment"
							label="Comment"
						/>
					)}
				</Grid>
			</Grid>
		</div>
	);
};
