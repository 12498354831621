import * as Yup from 'yup';

Yup.addMethod(Yup.array, 'unique', function(message, mapper = a => a) {
	return this.test('unique', message, function(list) {
		return !list || list.length === new Set(list.map(mapper)).size;
	});
});

export const PROJECT_NAME_LENGTH = {
	max: 40,
	min: 3,
};

export const PROJECT_DESCRIPTION_LENGTH = {
	min: 5,
	max: 250,
};

export const createProjectValidationSchema = Yup.object().shape({
	name: Yup.string()
		.max(
			PROJECT_NAME_LENGTH.max,
			`Must not be more than ${PROJECT_NAME_LENGTH.max} characters`,
		)
		.min(
			PROJECT_NAME_LENGTH.min,
			`Must not be less than ${PROJECT_NAME_LENGTH.min} characters`,
		)
		.required('Required'),
	description: Yup.string()
		.max(
			PROJECT_DESCRIPTION_LENGTH.max,
			`Must not be more than ${PROJECT_DESCRIPTION_LENGTH.max} characters`,
		)
		.min(
			PROJECT_DESCRIPTION_LENGTH.min,
			`Must not be less than ${PROJECT_DESCRIPTION_LENGTH.min} characters`,
		)
		.required('Required'),
	tags: Yup.array()
		.unique('Tags list must be unique')
		.of(Yup.string().matches(/^\S+$/g, 'Spaces in tags are not allowed!')),
	copies: Yup.number()
		.integer('Must be a positive integer')
		.min(1, 'Must be a positive integer')
		.max(100, 'Must be less than 101'),
	// No need for project.requirements and project.stories array validation
	// as we validate it before actually adding it to the array
});

export const updateProjectValidationSchema = createProjectValidationSchema;
