import React from 'react';
import {TextField} from '@material-ui/core';
import {
	formatFormErrorHelperText,
	getFormErrorsField,
} from '../../../../utilities/tools';

const Name = ({
	errors,
	touched,
	isSubmitting,
	values,
	handleChange,
	handleBlur,
	textFieldProps = {},
}) => {
	return (
		<TextField
			fullWidth
			inputProps={{
				form: {
					autocomplete: 'off',
				},
			}}
			disabled={isSubmitting}
			id="name"
			name="name"
			label="Project Name*"
			value={values.name}
			helperText={formatFormErrorHelperText('name', errors, touched)}
			error={Boolean(getFormErrorsField('name', errors, touched))}
			variant="outlined"
			type="text"
			onChange={handleChange}
			onBlur={handleBlur}
			{...textFieldProps}
		/>
	);
};

export default Name;
