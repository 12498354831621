import React, {useState, useEffect, useRef} from 'react';
import {
	FormControl,
	Select,
	MenuItem,
	Checkbox,
	ListItemText,
	Typography,
	InputLabel,
} from '@material-ui/core';
import {pipe, ifElse, identity, map, isNil, join} from 'ramda';
import {getIn} from 'formik';
import {
	mantainUniqueValues,
	getFormErrorsField,
} from '../../../../utilities/tools';

const defaultRenderValue = formatOption =>
	pipe(
		ifElse(() => isNil(formatOption), identity, map(formatOption)),
		join(', '),
	);

const MultiSelect = ({
	name,
	label,
	options,
	formatOption = identity, // used to format label option
	// Warning: if selected values are not plain texts then a custom renderValue must be provided!
	optionKeyExtractor = identity, // extract key from option item
	optionValueExtractor = identity, // extract value from option item
	renderValue, // function to render the selected options in the input text
	formControlProps = {},
	selectProps = {},
	...formikProps
	// column: {filterValue, setFilter},
}) => {
	const inputLabel = useRef(null);
	const [labelWidth, setLabelWidth] = useState(0);
	const {values, setFieldValue, errors, touched, handleBlur} = formikProps;
	const error = getFormErrorsField(name, errors, touched);
	const value = getIn(values, name) || [];

	useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth);
	}, []);

	const handleCheckboxChange = event => {
		// there is a bug if event.target.value is not array of strings and internalState it does not return the unique ones
		const {value} = event.target;
		if (value && value.length !== 0 && typeof value[0] !== 'string') {
			setFieldValue(
				name,
				mantainUniqueValues(event.target.value, optionKeyExtractor),
			);
			return;
		}

		setFieldValue(name, event.target.value);
	};

	// used to render checkbox state
	const selectedKeys = value ? value.map(optionKeyExtractor) : [];

	return (
		<FormControl
			fullWidth
			variant="outlined"
			error={Boolean(error)}
			{...formControlProps}
		>
			<InputLabel ref={inputLabel} id={`${name}-input-label`}>
				{label}
			</InputLabel>
			<Select
				multiple
				name={name}
				labelId={`${name}-input-label`}
				id={`${name}-input-multiselect`}
				labelWidth={labelWidth}
				value={value}
				renderValue={
					renderValue ? renderValue : defaultRenderValue(formatOption)
				}
				MenuProps={{variant: 'menu'}}
				onChange={handleCheckboxChange}
				onBlur={handleBlur}
				{...selectProps}
			>
				{options && options.length > 0 ? (
					options.map(option => (
						<MenuItem
							key={optionKeyExtractor(option)}
							value={optionValueExtractor(option)}
						>
							<Checkbox
								checked={selectedKeys.includes(optionKeyExtractor(option))}
								value={option}
							/>
							<ListItemText primary={formatOption(option)} />
						</MenuItem>
					))
				) : (
					<Typography
						variant="body2"
						align="center"
						color="textSecondary"
						style={{padding: '5px'}}
					>
						No options to show.
					</Typography>
				)}
			</Select>
		</FormControl>
	);
};

export default MultiSelect;
