import React, {useCallback, useState} from 'react';
import {Container, Typography, Box, Tabs, Tab} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {AppBar, MainWrapper} from '../../../../common/ui';
import {
	InvitedUserIcon,
	InvitedUsersIcon,
} from '../../../../../utilities/constants';
import CreateInvitedUserForm from './create-invited-user-form';
import InviteMultipleUsersForm from './invite-multiple-users-form';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		minHeight: 450,
	},
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
}));

function TabPanel(props) {
	const {children, value, index, ...other} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			style={{flex: 1}}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
}

const CreateInvitedUserScreen = () => {
	const classes = useStyles();
	const [value, setValue] = useState(0);

	const handleChange = useCallback((event, newValue) => {
		setValue(newValue);
	}, []);

	return (
		<>
			<AppBar />
			<MainWrapper>
				<Container maxWidth="md" className="bg-filled">
					<div className={classes.root}>
						<Tabs
							orientation="vertical"
							variant="scrollable"
							value={value}
							aria-label="Vertical tabs example"
							className={classes.tabs}
							onChange={handleChange}
						>
							<Tab label="Invite user" />
							<Tab label="Invite multiple users" />
						</Tabs>
						<TabPanel value={value} index={0}>
							<Typography
								variant="h5"
								style={{display: 'flex', alignItems: 'center'}}
								color="secondary"
							>
								<InvitedUserIcon style={{marginRight: '8px'}} /> Invite User
							</Typography>
							<Typography variant="subtitle1" color="secondary">
								Please fill the form below in order to invite a new user:
							</Typography>
							<Box mt={2}>
								<CreateInvitedUserForm />
							</Box>
						</TabPanel>
						<TabPanel value={value} index={1}>
							<Typography
								variant="h5"
								style={{display: 'flex', alignItems: 'center'}}
								color="secondary"
							>
								<InvitedUsersIcon style={{marginRight: '8px'}} /> Invite
								Multiple Users
							</Typography>
							<Typography variant="subtitle1" color="secondary">
								Please fill the form below in order to invite new users:
							</Typography>
							<Box mt={2}>
								<InviteMultipleUsersForm />
							</Box>
						</TabPanel>
					</div>
				</Container>
			</MainWrapper>
		</>
	);
};

export default CreateInvitedUserScreen;
