/*
Breakpoints: {
    xs: 0
    sm: 600
    md: 960
    lg: 1280
    xl: 1920
}
*/
import {responsiveFontSizes} from '@material-ui/core/styles';
import {pipe} from 'ramda';
import { createTheme } from '@material-ui/core/styles'
// Green: #678d79 Blue: #183356 Cyan: #D1D6DD

const PRIMARY = '#678d79';
const SECONDARY = '#183356';
const INFO = '#D1D6DD';

const theme = pipe(
	() =>
		createTheme({
			palette: {
				primary: {
					main: PRIMARY,
				},
				secondary: {
					main: SECONDARY,
				},
				info: {
					main: INFO,
					// the info.light for the above is: rgb(70, 91, 119)
				},
			},
			overrides: {
				MuiChip: {
					colorSecondary: {
						backgroundColor: 'rgb(70, 91, 119)',
						color: '#fff',
					},
				},
				MuiButton: {
					root: {
						borderRadius: '8px',
					},
				},
				MuiContainer: {
					root: {
						'&.bg-filled': {
							backgroundColor: '#fff',
							padding: '32px',
							borderRadius: '8px',
						},
					},
				},
				MuiTableRow: {
					head: {
						backgroundColor: SECONDARY,
					},
					root: {
						'& .MuiTableCell-root:not(:last-child)': {
							borderRight: `1px solid ${INFO}`,
						},
						'& .MuiTableCell-root.no-border-right': {
							borderRight: 'none',
						},
					},
				},
				MuiTableCell: {
					head: {color: '#fff'},
					root: {
						borderBottom: `1px solid ${INFO}`,
					},
				},
			},
		}),
	responsiveFontSizes,
)();

export default theme;
