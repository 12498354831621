/* eslint-disable */
import {useRef, useEffect} from 'react';

const useComponentDidUpdate = (handler, watchProps = []) => {
	const mounted = useRef();
	useEffect(() => {
		if (mounted.current) {
			handler();
		} else {
			mounted.current = true;
		}
	}, watchProps);
};

export default useComponentDidUpdate;
