import React, {useRef, useState, useEffect} from 'react';
import {
	Select as MaterialSelect,
	FormControl,
	InputLabel,
	MenuItem,
	FormHelperText,
} from '@material-ui/core';
import {getIn} from 'formik';
import {getFormErrorsField} from '../../../../utilities/tools';
import {Skeletons} from '../../ui';

const Select = props => {
	const {
		name,
		label,
		options,
		selectProps,
		formControlProps,
		formatOption,
		initLoading,
		skeletonProps = {},
		...formikProps
	} = props;
	const {values, setFieldValue, errors, touched, handleBlur} = formikProps;

	const inputLabel = useRef(null);
	const [labelWidth, setLabelWidth] = useState(0);
	const error = getFormErrorsField(name, errors, touched);

	useEffect(() => {
		if (inputLabel && inputLabel.current && inputLabel.current.offsetWidth)
			setLabelWidth(inputLabel.current.offsetWidth);
	}, [initLoading]);

	if (initLoading) return <Skeletons.SelectFieldSkeleton {...skeletonProps} />;

	const handleChange = event => {
		setFieldValue(name, event.target.value);
	};

	return (
		<FormControl
			fullWidth
			variant="outlined"
			margin="normal"
			error={Boolean(error)}
			{...formControlProps}
		>
			<InputLabel ref={inputLabel} id={`${name}-input-label`}>
				{label}
			</InputLabel>
			<MaterialSelect
				name={name}
				labelId={`${name}-input-label`}
				id={`${name}-input-select`}
				value={getIn(values, name)}
				labelWidth={labelWidth}
				onChange={handleChange}
				onBlur={handleBlur}
				{...selectProps}
			>
				{options.map(({label, value, description}) => (
					<MenuItem key={value} value={value}>
						{
							description? label + "   (" + description + ")"
							: formatOption ? formatOption(label) 
							: label
						}
					</MenuItem>
				))}
			</MaterialSelect>
			{error && <FormHelperText>{error}</FormHelperText>}
		</FormControl>
	);
};

export default Select;
