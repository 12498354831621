import React from 'react';
import ReactErrorBoundary from 'react-error-boundary';
import {ErrorStates} from '../../common/ui';

const ErrorFallbackComponent = () => (
	<ErrorStates.TryAgain
		withGoBackButton={false}
		message="Sorry something went wrong"
		// eslint-disable-next-line no-return-assign
		refetch={() => (window.location.href = '/')}
	/>
);

const ErrorBoundary = ({children}) => (
	<ReactErrorBoundary
		FallbackComponent={ErrorFallbackComponent}
		onError={console.error}
	>
		{children}
	</ReactErrorBoundary>
);

export default ErrorBoundary;
