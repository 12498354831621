import gql from 'graphql-tag';

export default gql`
	query GetInvitedUsers {
		getInvitedUsers {
			_id
			email
			role
			invitationHash
			createdAt
		}
	}
`;
