import React, {useMemo, useState} from 'react';
import {getIn} from 'formik';
import {TextField, InputAdornment, IconButton} from '@material-ui/core';
import {Lock, Visibility, VisibilityOff} from '@material-ui/icons';
import {
	formatFormErrorHelperText,
	getFormErrorsField,
} from '../../../../utilities/tools';
import useFilledInputAdornmentStyles from '../../ui/filled-input-adornment-classes';

const Password = ({
	name = 'password',
	placeholder = 'Password*',
	errors,
	touched,
	isSubmitting,
	values,
	handleChange,
	handleBlur,
	textFieldProps = {},
	shouldUseFilledInputAdornmentStyles = true,
}) => {
	const classes = useFilledInputAdornmentStyles();
	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};

	const filledInputAdornmentProps = useMemo(() => {
		return shouldUseFilledInputAdornmentStyles
			? {
					classes: {
						root: classes.inputRoot,
						adornedStart: classes.inputAdornedStart,
						focused: classes.inputFocused,
						notchedOutline: classes.notchedOutline,
						input: classes.inputInput,
					},
					startAdornment: (
						<InputAdornment className={classes.inputAdornment} position="start">
							<Lock />
						</InputAdornment>
					),
			  }
			: {};
	}, [classes, shouldUseFilledInputAdornmentStyles]);

	return (
		<TextField
			fullWidth
			name={name}
			disabled={isSubmitting}
			id={name}
			value={getIn(values, name)}
			helperText={formatFormErrorHelperText(name, errors, touched)}
			error={Boolean(getFormErrorsField(name, errors, touched))}
			type={showPassword ? 'text' : 'password'}
			placeholder={placeholder}
			variant="outlined"
			InputProps={{
				...filledInputAdornmentProps,
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
						>
							{showPassword ? <Visibility /> : <VisibilityOff />}
						</IconButton>
					</InputAdornment>
				),
			}}
			onChange={handleChange}
			onBlur={handleBlur}
			{...textFieldProps}
		/>
	);
};

export default Password;
