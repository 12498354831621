import gql from 'graphql-tag';

export default gql`
	query GetProjectFiles($projectId: String!) {
		getProject(projectId: $projectId) {
			_id
			files {
				_id
				url
				handle
				filename
				mimetype
				createdAt
				size
			}
		}
	}
`;
