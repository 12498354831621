import {Box, Divider, Grid, Hidden} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/styles';
import StatsSection from './stats-section';
import InfoSection from './info-section';

const useStyles = makeStyles(() => ({
	infoSection: {
		display: 'flex',
	},
	divider: {height: '70vh', margin: '0 25px'},
}));

export default () => {
	const classes = useStyles();

	return (
		<Grid container spacing={1}>
			<Grid item md={8} xs={12}>
				<Box pt={2} width="100%">
					<StatsSection />
				</Box>
			</Grid>
			<Grid item md={4} xs={12}>
				<div className={classes.infoSection}>
					<Hidden smDown>
						<Divider orientation="vertical" className={classes.divider} />
					</Hidden>
					<Box pt={2} position="relative" width="100%">
						<InfoSection />
					</Box>
				</div>
			</Grid>
		</Grid>
	);
};
