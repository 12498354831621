import React, {useState} from 'react';
import {Grid, Button} from '@material-ui/core';
import shortid from 'shortid';
import {isNilOrEmpty} from '../../../../utilities/tools';

const btnSt = {textTransform: 'none'};

const ItemsWithMore = ({
	items = [],
	maxShown = Number.POSITIVE_INFINITY,
	ItemRenderer = () => {},
	EmptyRenderer = null,
	gridContainerProps = {},
	gridItemProps = {},
	buttonMoreProps = {},
}) => {
	const [itemsShown, setItemsShown] = useState(items.slice(0, maxShown));

	return isNilOrEmpty(itemsShown) ? (
		EmptyRenderer
	) : (
		<Grid container spacing={1} {...gridContainerProps}>
			{itemsShown.map((item, index) => (
				<Grid key={shortid.generate()} item {...gridItemProps}>
					<ItemRenderer item={item} index={index} />
				</Grid>
			))}
			{items && items.length > itemsShown.length && (
				<Grid item>
					<Button
						color="primary"
						variant="text"
						style={btnSt}
						size="small"
						onClick={() => setItemsShown(items)}
						{...buttonMoreProps}
					>
						+{items.length - itemsShown.length} More...
					</Button>
				</Grid>
			)}
		</Grid>
	);
};

export default ItemsWithMore;
