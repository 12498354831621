import gql from 'graphql-tag';

export default gql`
	query getProjectIntegrations($projectId: String!) {
		getProject(projectId: $projectId) {
			_id
			updatedAt
			integrations {
				name
				apiKey
				projectId,
				links
			}
		}
	}
`;
