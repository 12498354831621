import gql from 'graphql-tag';

export default gql`
	query GetProjects {
		getProjects {
			_id
			createdAt
			updatedAt
			name
			tags
			users {
				_id
				username
			}
			stories {
				_id
			}
			requirements {
				_id
			}
		}
	}
`;
