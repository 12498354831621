import gql from 'graphql-tag';

export default gql`
	mutation UpdateProjectIntegrations(
		$projectId: String!
		$data: UpdateProjectInput!
	) {
		updateProject(projectId: $projectId, data: $data) {
			_id
			updatedAt
			integrations {
				name
				apiKey
				projectId
			}
		}
	}
`;
