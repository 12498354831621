import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import {CircularProgress, Typography, Fade} from '@material-ui/core';
import {makeStyles, styled} from '@material-ui/styles';
import {mergeDeepRight} from 'ramda';
import {getRandomMessage} from './random-messages';

const CenteredDiv = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	minHeight: '85vh',
	width: '100%',
	alignItems: 'center',
	position: 'absolute',
	top: 0,
	left: 0,
	zIndex: 9999,
}));

const useStyles = makeStyles(theme => ({
	root: {
		height: '60px!important',
		width: '60px!important',
		[theme.breakpoints.down('xs')]: {
			height: '40px!important',
			width: '40px!important',
		},
	},
}));

const MESSAGE_INTERVAL_MS = 3000;
const FADE_TIMEOUT = 1000;

const FullScreenLoader = props => {
	const [randomMessage, setRandomMessage] = useState(getRandomMessage());
	const [isFadeIn, setIsFadeIn] = useState(true);
	const classes = useStyles();

	useEffect(() => {
		const timeout1 = setTimeout(() => {
			setIsFadeIn(false);
		}, MESSAGE_INTERVAL_MS / 2);
		const timeout2 = setTimeout(() => {
			setIsFadeIn(true);
			setRandomMessage(getRandomMessage());
		}, MESSAGE_INTERVAL_MS);
		return () => {
			clearTimeout(timeout1);
			clearTimeout(timeout2);
		};
	}, [randomMessage]);

	return (
		<CenteredDiv>
			<CircularProgress
				{...props}
				classes={mergeDeepRight(classes, props.classes)}
			/>
			<Fade in={isFadeIn} timeout={FADE_TIMEOUT}>
				<Typography
					variant="overline"
					style={{marginTop: '20px'}}
					test-id="full-screen-loader-random-message"
				>
					{randomMessage}...
				</Typography>
			</Fade>
		</CenteredDiv>
	);
};

FullScreenLoader.propTypes = {
	classes: propTypes.object,
	color: propTypes.oneOf(['primary', 'secondary', 'inherit']),
	// eslint-disable-next-line
	disableShrink: propTypes.bool,
	size: propTypes.oneOfType([propTypes.number, propTypes.string]),
	thickness: propTypes.number,
	value: propTypes.number,
	variant: propTypes.oneOf(['determinate', 'indeterminate', 'static']),
};

FullScreenLoader.defaultProps = {
	classes: {},
	color: 'primary',
	disableShrink: false,
	size: 40,
	thickness: 3,
	value: 0,
	variant: 'indeterminate',
};

export default FullScreenLoader;
