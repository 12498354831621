import React from 'react';
import {Typography, Breadcrumbs, Link} from '@material-ui/core';
import {Link as RouterLink, useRouteMatch} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import gql from 'graphql-tag';
import {useQuery} from 'react-apollo';
import {path} from 'ramda';
import clsx from 'clsx';

const GET_PROJECT_NAME = gql`
	query GetProjectName($projectId: String!) {
		getProject(projectId: $projectId) {
			name
		}
	}
`;

const useStyles = makeStyles(theme => ({
	root: {
		marginBottom: theme.spacing(4),
	},

	li: {
		'& > p': {
			fontWeight: 'bold',
		},

		'& > p.loading': {
			fontStyle: 'italic',
			fontWeight: 'normal',
		},

		[theme.breakpoints.up('sm')]: {
			'& p ': {
				fontSize: '24px',
			},
		},
	},

	separator: {
		[theme.breakpoints.up('sm')]: {
			fontSize: '24px',
		},
	},

	icon: {
		verticalAlign: 'text-top',
		marginRight: 6,
	},
}));

const SimpleBreadcrumbs = () => {
	const match = useRouteMatch();
	const projectId = path(['params', 'projectId'], match);
	const classes = useStyles();
	const {data, loading} = useQuery(GET_PROJECT_NAME, {variables: {projectId}});

	const projectName = !loading && path(['getProject', 'name'], data);

	return (
		<Breadcrumbs
			aria-label="breadcrumb"
			classes={{
				root: classes.root,
				li: classes.li,
				separator: classes.separator,
			}}
		>
			<Link color="secondary" to="/projects" component={RouterLink}>
				<Typography display="inline">Projects</Typography>
			</Link>
			<Typography
				display="inline"
				className={clsx(loading && 'loading')}
				color="secondary"
			>
				{loading ? 'Loading...' : projectName}
			</Typography>
		</Breadcrumbs>
	);
};

export default SimpleBreadcrumbs;
