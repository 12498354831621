import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	List,
	ListItem,
	Grid,
	Box,
	Typography
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
	title: {
		textTransform: 'uppercase',
	},
	body: {
		fontSize: '26px',
		fontWeight: 'bold',
	},
	qualityScore: {
		display: "flex",
		flexDirection: "column",
		textAlign: "center",
		marginRight: "1rem",
		border: "1px solid rgb(0, 66, 110)",
		borderRadius: "10px",
		padding: "16px",
	},
}));

export default function QualityAnalyticsModal({
    open, 
    setOpen,
    codeQualities,
}) {
	const classes = useStyles();

    useEffect(() => {
		// console.log(codeQualities);
	}, [codeQualities]);

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
			maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">Quality Analytics (by Cyclopt)</DialogTitle>
            <DialogContent>
				<List>
					{
						codeQualities.map((quality, index) => {
							return (
								<ListItem key={index}>
									<Box sx={{ display: "flex", flexDirection: "column" }}>
										<Grid item>
											<Typography className={classes.title}>
												<b>{index+1}.{quality.branch}</b>
											</Typography>
										</Grid>
										<Box sx={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}>
											<Grid item className={classes.qualityScore}>
												<Typography className={classes.body} color="secondary">
													{quality.overallQuality}
												</Typography>
												<Typography>
													Overall
												</Typography>
											</Grid>
											<Grid item className={classes.qualityScore}>
												
												<Typography className={classes.body} color="secondary">
													{quality.maintainability}
												</Typography>
												<Typography>
													Maintainability
												</Typography>
											</Grid>
											<Grid item className={classes.qualityScore}>
												
												<Typography className={classes.body} color="secondary">
													{quality.readability}
												</Typography>
												<Typography>
													Readability
												</Typography>
											</Grid>
											<Grid item className={classes.qualityScore}>
												
												<Typography className={classes.body} color="secondary">
													{quality.reusability}
												</Typography>
												<Typography>
													Reusability
												</Typography>
											</Grid>
											<Grid item className={classes.qualityScore}>
												
												<Typography className={classes.body} color="secondary">
													{quality.security}
												</Typography>
												<Typography>
													Security
												</Typography>
											</Grid>
										</Box> {/*row end*/}
									</Box> {/*col end*/}
								</ListItem>
							)
						})
					}
				</List>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => handleClose()} color="primary" autoFocus>
                close
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}