import React from 'react';
import {Container, Typography, Box} from '@material-ui/core';
import {useQuery, useMutation} from 'react-apollo';
import {NetworkStatus} from 'apollo-client';
import {useQueryParams, NumberParam, JsonParam} from 'use-query-params';
import {path, propOr, complement, propEq, pipe, filter, assoc} from 'ramda';
import {AppBar, MainWrapper, ErrorStates} from '../../../../common/ui';
import {UsersIcon} from '../../../../../utilities/constants';
import {GET_USERS, GET_PROJECTS} from '../../../../../graphql/queries';
import {DELETE_USER, EDIT_USER} from '../../../../../graphql/mutations';
import UsersTableWrapper from './users-table-wrapper';

export const USERS_PAGE_SIZE_DEFAULT = 25;
const USERS_PAGE_SIZE_DEFAULT_PAGE_OPTIONS = [12, 25, 50];

const Title = () => (
	<Typography
		variant="h5"
		style={{display: 'flex', alignItems: 'center'}}
		color="secondary"
	>
		<UsersIcon style={{marginRight: '8px'}} /> Users
	</Typography>
);

const Subtitle = () => (
	<Typography variant="subtitle1" color="secondary">
		Below are listed all the users of the platform
	</Typography>
);

const UsersScreen = () => {
	const [queryParams, setQueryParams] = useQueryParams({
		sortBy: JsonParam, // although this is actually a JSON array
		filters: JsonParam, // although this is actually a JSON array
		pageIndex: NumberParam,
		pageSize: NumberParam,
	});
	const {data, error, networkStatus, refetch} = useQuery(GET_USERS, {
		notifyOnNetworkStatusChange: true,
	});
	const [deleteUser, {loading}] = useMutation(DELETE_USER, {
		update: (cache, {data}) => {
			// Update users
			const deletedId = path(['deleteUser', '_id'], data);
			const read = cache.readQuery({query: GET_USERS});
			const users = propOr([], 'getUsers', read);
			cache.writeQuery({
				query: GET_USERS,
				data: {
					getUsers: users.filter(complement(propEq('_id', deletedId))),
				},
			});
			// Remove user also from any projects
			try {
				const read = cache.readQuery({query: GET_PROJECTS});
				const projects = propOr([], 'getProjects', read);
				cache.writeQuery({
					query: GET_PROJECTS,
					data: {
						getProjects: projects.map(obj =>
							assoc(
								'users',
								pipe(
									propOr([], 'users'),
									filter(complement(propEq('_id', deletedId))),
								)(obj),
							)(obj),
						),
					},
				});
			} catch {}
		},
	});

	const [editUser] = useMutation(EDIT_USER, {
		update: (cache, {data}) => {
			console.log("-->> Edited User");
			console.log(data);
			// Update users
			// const deletedId = path(['deleteUser', '_id'], data);
			// const read = cache.readQuery({query: GET_USERS});
			// const users = propOr([], 'getUsers', read);
			// cache.writeQuery({
			// 	query: GET_USERS,
			// 	data: {
			// 		getUsers: users.filter(complement(propEq('_id', deletedId))),
			// 	},
			// });
		},
	});

	const shouldShowTableSkeleton =
		networkStatus === NetworkStatus.loading ||
		networkStatus === NetworkStatus.refetch;

	return (
		<>
			<AppBar />
			<MainWrapper>
				<Container maxWidth="lg">
					{error ? (
						<ErrorStates.TryAgain
							withGoBackButton
							error={error}
							refetch={refetch}
						/>
					) : (
						<>
							<Title />
							<Subtitle />
							<Box mt={2}>
								<UsersTableWrapper
									tableLoading={loading}
									data={data}
									deleteUser={deleteUser}
									editUser={editUser}
									shouldShowSkeleton={shouldShowTableSkeleton}
									queryParams={queryParams}
									setQueryParams={setQueryParams}
									pageOptions={USERS_PAGE_SIZE_DEFAULT_PAGE_OPTIONS}
									defaultPageSize={USERS_PAGE_SIZE_DEFAULT}
								/>
							</Box>
						</>
					)}
				</Container>
			</MainWrapper>
		</>
	);
};

export default UsersScreen;
