import {getIn} from 'formik';
import {
	propOr,
	anyPass,
	isNil,
	isEmpty,
	identity,
	ifElse,
	pipe,
	trim,
	pathOr,
	reverse,
	reduceWhile,
	dec,
	complement,
	propSatisfies,
	equals,
	last,
	init,
	map,
	includes,
	filter,
	sort,
	cond,
	__,
	prop,
	allPass,
	omit,
	has,
	propEq,
	find,
	reject,
	any,
} from 'ramda';
import moment from 'moment';
import yaml from 'yamljs';
import {PROJECT_REQUIREMENT_RATINGS} from './constants';

export const isNilOrEmpty = anyPass([isNil, isEmpty]);

export const isSubmitButtonDisabled = anyPass([
	propSatisfies(complement(isNilOrEmpty), 'errors'),
	propSatisfies(equals(true), 'isSubmitting'),
]);

export const getFormErrorsField = (name, errors, touched) => {
	const error = getIn(errors, name);
	const touch = getIn(touched, name);
	return touch && error;
};

// Useful if you want to show error on name1 if name2 is touched
export const getFormErrorsFieldV2 = (name1, name2, errors, touched) => {
	const error = getIn(errors, name1);
	const touch = getIn(touched, name2);
	return touch && error;
};

export const formatFormErrorHelperText = (name, errors, touched) =>
	ifElse(
		isNil,
		() => null,
		pipe(
			trim,
			ifElse(isEmpty, () => null, identity),
		),
	)(getFormErrorsField(name, errors, touched));

export const getBreakpointKey = ({theme}) => windowInnerWidth => {
	const breakpointsKeys = pathOr([], ['breakpoints', 'keys'], theme);
	const breakpointsValues = pathOr([], ['breakpoints', 'values'], theme);

	return pipe(
		reverse,
		reduceWhile((_, key) => windowInnerWidth < breakpointsValues[key], dec, 4),
		i => breakpointsKeys[i],
	)(breakpointsKeys);
};

export const getRandomInt = (min, max) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const nullizeString = pipe(
	ifElse(isNilOrEmpty, () => null, trim),
	ifElse(isEmpty, () => null, identity),
);

// example 1: input [1,2,3,4] output [1,2,3,4]
// example 2: input [1,2,3,1] output [2,3]
// example 3: input [] || null output []
export const mantainUniqueValues = (
	newValues,
	valueUniqueSelector = identity,
) => {
	if (isNilOrEmpty(newValues)) {
		return [];
	}

	const lastValue = pipe(last, valueUniqueSelector)(newValues);
	const withoutLastValue = pipe(init, map(valueUniqueSelector))(newValues);
	const alreadyExists = includes(lastValue, withoutLastValue);

	return ifElse(
		() => alreadyExists,
		filter(pipe(valueUniqueSelector, complement(equals(lastValue)))),
		identity,
	)(newValues);
};

export const stringArraysEqual = (a, b) => {
	if (a === b) return true;
	if (a === null || b === null) return false;
	if (a === undefined || b === undefined) return false;
	if (a.length !== b.length) return false;

	const aSorted = sort((x, y) => x.localeCompare(y), a);
	const bSorted = sort((x, y) => x.localeCompare(y), b);

	return equals(aSorted, bSorted);
};

export const filterByDateRange = (rows, id, filterValue) => {
	if (!filterValue) return rows;

	if (!filterValue.startDate && !filterValue.endDate) return rows;

	const comparator = cond([
		[
			({startDate, endDate}) => startDate && endDate,
			({startDate, endDate}) => val => val.isBetween(startDate, endDate),
		],
		[
			({startDate}) => startDate,
			({startDate}) => val => val.isAfter(startDate),
		],
		[({endDate}) => endDate, ({endDate}) => val => val.isBefore(endDate)],
	])(filterValue);

	return rows.filter(
		pipe(
			propOr({}, 'original'),
			propSatisfies(
				ifElse(
					isNil,
					() => [],
					pipe(value => moment(value), comparator),
				),
				id,
			),
		),
	);
};

export const filterByObjectMultiSelect = (rows, id, filterValue) => {
	if (isNilOrEmpty(filterValue)) return rows;

	const filterValueIds = map(prop('_id'))(filterValue);

	return rows.filter(
		pipe(
			pathOr([], ['original', id]),
			map(prop('_id')),
			any(includes(__, filterValueIds)),
		),
	);
};

export const filterByStringMultiSelect = (rows, id, filterValue) => {
	if (isNilOrEmpty(filterValue)) return rows;

	return rows.filter(
		pipe(pathOr([], ['original', id]), any(includes(__, filterValue))),
	);
};

export const buildInvitationURL = invitationHash =>
	`${window.location.origin}/complete-registration?invitationHash=${invitationHash}`;

export const getReadOnlyProps = type =>
	ifElse(
		equals(true),
		() =>
			cond([
				[
					equals('TextField'),
					() => ({
						InputProps: {
							readOnly: true,
						},
						inputProps: {
							style: {
								cursor: 'default',
							},
						},
					}),
				],
				[
					equals('DatePicker'),
					() => ({
						readOnly: true,
						KeyboardButtonProps: {
							style: {
								cursor: 'not-allowed',
							},
						},
						InputProps: {
							readOnly: true,
						},
						inputProps: {
							style: {
								cursor: 'default',
							},
						},
					}),
				],
				[
					includes(__, ['Autocomplete']),
					() => ({
						InputProps: {
							style: {
								cursor: 'default',
							},
							readOnly: true,
							endAdornment: null,
						},
						inputProps: {
							style: {
								cursor: 'default',
							},
							onMouseDown: () => {},
							onFocus: () => {},
						},
					}),
				],
				[
					equals('Select'),
					() => ({
						SelectDisplayProps: {
							style: {
								cursor: 'default',
							},
						},
						inputProps: {
							readOnly: true,
						},
						IconComponent: () => null,
					}),
				],
			])(type),
		() => {},
	);

export const constructArrayConnectDisconnectQuery = (
	currentConnections = [],
	newConnections = [],
	{uniquePropName} = {uniquePropName: '_id'},
) => {
	// connect
	const currentConnectionsIds = map(prop(uniquePropName))(currentConnections);
	const connect = pipe(
		reject(pipe(prop(uniquePropName), includes(__, currentConnectionsIds))),
		map(prop(uniquePropName)),
	)(newConnections);

	// disconnect
	const newConnectionsIds = map(prop(uniquePropName))(newConnections);
	const disconnect = pipe(
		reject(propSatisfies(includes(__, newConnectionsIds), uniquePropName)),
		map(prop(uniquePropName)),
	)(currentConnections);

	return {connect, disconnect};
};

export const constructEmbeddedFieldQuery = (
	oldArray,
	newArray,
	{uniquePropName, keepUniquePropInUpdate} = {
		uniquePropName: '_id',
		keepUniquePropInUpdate: false,
	},
) => {
	// First the new creations
	// A newly created object won't have an id
	const create = filter(complement(has(uniquePropName)))(newArray);

	// Then the deletions
	const newArrayIds = newArray.map(prop(uniquePropName));

	const deleteMany = pipe(
		filter(
			complement(propSatisfies(includes(__, newArrayIds), uniquePropName)),
		),
		map(prop(uniquePropName)),
		// ifElse(isNilOrEmpty, identity, arr => ({[`${uniquePropName}_in`]: arr})),
	)(oldArray);

	// And finally the edits (included in the new array and different than the old)
	const updateMany = pipe(
		filter(
			allPass([
				propSatisfies(includes(__, newArrayIds), uniquePropName),
				obj => {
					const newObj = find(propEq(uniquePropName, obj[uniquePropName]))(
						newArray,
					);
					if (!newObj) return false;
					return !equals(obj, newObj);
				},
			]),
		),
		map(obj => ({
			[uniquePropName]: prop(uniquePropName, obj),
			data: pipe(
				obj =>
					find(propEq(uniquePropName, prop(uniquePropName, obj)))(newArray),
				omit(['__typename', keepUniquePropInUpdate ? null : uniquePropName]),
			)(obj),
		})),
	)(oldArray);

	return {create, deleteMany, updateMany};
};

export const isMongoId = id => id.match(/^[0-9a-fA-F]{24}$/);

export const findAppliedRating = value =>
	pipe(find(propEq('value', value)))(PROJECT_REQUIREMENT_RATINGS);

export const toJSONorYAML = (inputString, convertTo) => {
	if (isNilOrEmpty(nullizeString(inputString))) return '';

	try {
		if (convertTo === 'json') {
			return JSON.stringify(yaml.parse(inputString), null, 2);
		}

		if (convertTo === 'yaml') {
			try {
				const obj = JSON.parse(inputString);
				return yaml.stringify(obj, 4);
			} catch {
				return inputString;
			}
		}

		console.warn('undefined value of convertTo');
		return '';
	} catch (error) {
		console.error(error);
		return '';
	}
};
