import gql from 'graphql-tag';

export default gql`
	query GetUsers {
		getUsers {
			_id
			email
			username
			role
			fullName
			projects {
				_id
				name
			}
			createdAt
		}
	}
`;
