import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,
	CircularProgress,
} from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { pathOr, pipe, prop } from 'ramda';
import { Users, Name } from '../common/form/project';
import { useUi } from '../hoc';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-apollo';
import { CREATE_PROJECT } from '../../graphql/mutations';
import { PROJECT_NAME_LENGTH } from '../../utilities/validations/project-validation-schema';
import { useHistory } from 'react-router-dom';

export default function CloneProjectModal({
    open, 
    setOpen,
	project,
	amIAdmin,
}) {
	const history = useHistory();
	const ui = useUi();
	const snackbar = useSnackbar();
    const handleClose = () => {
        setOpen(false);
    };

	const [currentTimestamp, setCurrentTimestamp] = useState(new Date().toISOString().split('.')[0].split('T').join(' '));
	const [loading, setLoading] = useState(false);

	const [createProject] = useMutation(CREATE_PROJECT);

    useEffect(() => {
        if (open && project) {
            // console.log(project);
			setCurrentTimestamp(new Date().toISOString().split('.')[0].split('T').join(' '));
        }
		// eslint-disable-next-line
    }, [project, open])

	const getInitialValues = props => ({
		projectId: pathOr('', ['project', '_id'], props),
		clone: true,
		name: `${pathOr('', ['project', 'name'], props)} - ${currentTimestamp}`,
		description: pathOr('', ['project', 'description'], props),
		tags: pathOr([], ['project', 'tags'], props),
		users: pathOr([], ['project', 'users'], props),
		creator: '',
	});
	
	const cloneProjectValidationSchema = Yup.object().shape({
		projectId: Yup.string()
			.required('Required'),
		name: Yup.string()
			.max(
				PROJECT_NAME_LENGTH.max,
				`Must not be more than ${PROJECT_NAME_LENGTH.max} characters`,
			)
			.min(
				PROJECT_NAME_LENGTH.min,
				`Must not be less than ${PROJECT_NAME_LENGTH.min} characters`,
			)
			.required('Required'),
		
		users: Yup.array()
			.min(1)
			.required('Required')
		
		// No need for project.requirements and project.stories array validation
		// as we validate it before actually adding it to the array
	});

	const handleSubmit = props => async values => {
		// console.log("Props:", props)
		const { ui, snackbar, /*amIAdmin, isProjectCreator*/ } = props;
		// console.log("Project:", project)
		try {
			setLoading(true);
			ui.setAppProgressShown(true);
			values.creator = values.users[0].email;
			const formattedValues = pipe(
				({users, ...rest}) => ({
					...rest,
					users: {connect: users.map(prop('_id'))},
				}),
			)(values);
			console.log("Values after:", values);
			await createProject({variables: {
				data: formattedValues
			}});

			snackbar.enqueueSnackbar('Project was cloned successfully.', {
				variant: 'success',
				anchorOrigin: {vertical: 'top', horizontal: 'center'},
			});

			setOpen(false);
			ui.setAppProgressShown(false);
			setLoading(false);
			history.push('/projects');
		} catch (error) {
			console.log(error);
			snackbar.enqueueSnackbar(error.message.replace('GraphQL error:', ''), {
				variant: 'error',
				anchorOrigin: {vertical: 'top', horizontal: 'center'},
			});
			setOpen(false);
			ui.setAppProgressShown(false);
			setLoading(false);
		} 
	};
	
	const useFormikBag = (props) =>
		useFormik({
			enableReinitialize: true,
			initialValues: getInitialValues(props),
			onSubmit: handleSubmit(props),
			validationSchema: cloneProjectValidationSchema,
		});

	const formikProps = useFormikBag({
		project,
		amIAdmin,
		ui,
		snackbar,
	});

	const submitDisabled = !formikProps.isValid || loading;

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth={'md'}
		>
			<DialogTitle id="alert-dialog-title">{`Clone project "${project.name}"`}</DialogTitle>
			<DialogContent>
				<Box sx={{width: '750px'}} >
					<Name {...formikProps} />
				</Box>
				<Box sx={{mt: 4, width: '750px'}} >
					<Users {...formikProps} optional={false} />
				</Box>
			</DialogContent>
			<DialogActions sx={{mt: 4}}>
				<Button disabled={loading} onClick={() => handleClose()}>
					Close
				</Button>
				<Button
					disabled={submitDisabled}
					variant='text'
					onClick={() => formikProps.handleSubmit()}
					color="primary"
					endIcon={
						loading ? (
							<CircularProgress disableShrink size={20} color="inherit" />
						) : null
					}
				>
					Clone
				</Button>
			</DialogActions>
		</Dialog>
    );
}