import React, {useState} from 'react';
import {
	propOr,
	pathOr,
	propEq,
	find,
	ifElse,
	isEmpty,
	identity,
	pipe,
	prop,
} from 'ramda';
import {useQuery} from 'react-apollo';
import {createFilterOptions} from '@material-ui/lab';
import {
	Box,
	Chip,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	Popover,
	Typography,
} from '@material-ui/core';
import {getIn} from 'formik';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {capitalCase} from 'change-case';
import {ExitToApp} from '@material-ui/icons';
import {useSnackbar} from 'notistack';
import {ProjectRequirementIcon} from '../../../../../../../../utilities/constants';
import {
	findAppliedRating,
	getReadOnlyProps,
} from '../../../../../../../../utilities/tools';
import {GET_PROJECT_REQUIREMENTS} from '../../../../../../../../graphql/queries';
import {Skeletons} from '../../../../../../../common/ui';
import {Autocomplete} from '../../../../../../../common/form';

const useStyles = makeStyles(theme => ({
	popoverPaper: {
		backgroundColor: '#585858',
		padding: 12,
		color: '#fff',

		'& .req-link': {
			color: '#fff',
		},
	},
	chip: {
		minHeight: 40,
		height: 'unset',
		width: '100%',
		maxWidth: '100%',
		paddingTop: 4,
		paddingBottom: 4,
	},
	chipLabel: {
		whiteSpace: 'pre-wrap',
		lineHeight: '16px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		boxOrient: 'vertical',
		WebkitBoxOrient: 'vertical',
		lineClamp: 2,
		WebkitLineClamp: 2,
		maxHeight: `${2 *
			Number(pathOr(1.5, ['typography', 'body2', 'lineHeight'], theme))}em`,
	},
}));

const getOptionLabel = option => (
	<span>
		{renderSeq(true)(option)} <span>{propOr('', 'text', option)}</span>
	</span>
);

const renderSeq = withParenthesis =>
	pipe(
		propOr('', 'seq'),
		ifElse(isEmpty, identity, s => (withParenthesis ? `(R-${s})` : `R-${s}`)),
	);

const ChipComponent = ({tagProps = {}, option, projectId}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const styles = useStyles();
	const {onDelete: onDeleteTagProp, ...restTagProps} = tagProps;
	const reqId = prop('_id', option);
	const seqRendered = renderSeq(true)(option);
	const text = propOr('', 'text', option);
	const type = prop('type', option);
	const rating = prop('rating', option);
	const object = findAppliedRating(rating);
	const chipStyle = {
		backgroundColor: pathOr('#e0e0e0', ['style', 'backgroundColor'], object),
		color: pathOr('rgba(0, 0, 0, 0.87)', ['style', 'color'], object),
	};

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<div>
			<Chip
				{...restTagProps}
				clickable
				classes={{root: styles.chip, label: styles.chipLabel}}
				icon={<ProjectRequirementIcon />}
				color="default"
				label={getOptionLabel(option)}
				onClick={handleClick}
				onDelete={event => {
					event.stopPropagation();
					event.preventDefault();
					onDeleteTagProp(event);
				}}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				classes={{paper: styles.popoverPaper}}
				onClose={handleClose}
			>
				<div>
					<Typography variant="body1">{`${seqRendered}  ${text}`}</Typography>
					<Box mt={1}>
						<Grid container spacing={1}>
							{type && (
								<Grid item>
									<Chip
										size="small"
										label={type && capitalCase(type)}
										color="default"
									/>
								</Grid>
							)}
							{rating && (
								<Grid item>
									<Chip
										size="small"
										label={propOr(rating, 'label', object)}
										style={chipStyle}
									/>
								</Grid>
							)}
							<Grid
								item
								style={{
									display: 'flex',
									flex: 1,
									justifyContent: 'flex-end',
								}}
							>
								<Link
									underline="always"
									color="primary"
									component={RouterLink}
									target="_blank"
									to={{
										pathname: `/projects/${projectId}/requirements/${reqId}`,
									}}
								>
									<IconButton size="small" color="inherit">
										<ExitToApp color="inherit" fontSize="inherit" />
									</IconButton>
								</Link>
							</Grid>
						</Grid>
					</Box>
				</div>
			</Popover>
		</div>
	);
};

export const RequirementsLinked = ({
	projectId,
	isReadOnly,
	fieldOverrideProps = {},
	...props
}) => {
	const {enqueueSnackbar} = useSnackbar();
	const formikName = propOr('requirementsLinked', 'name', fieldOverrideProps);
	const values = getIn(props.values, formikName) || [];
	const {data, loading} = useQuery(GET_PROJECT_REQUIREMENTS, {
		variables: {
			projectId,
		},
		onError: error => {
			console.error(error);
			enqueueSnackbar('Project requirements could not be loaded.', {
				variant: 'error',
				anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
			});
		},
	});

	const allRequirements = pathOr([], ['getProject', 'requirements'], data);

	const isCheckboxSelectedFn = option => {
		return Boolean(find(propEq('_id', option._id))(values));
	};

	const filterOptions = createFilterOptions({
		stringify: option =>
			`${renderSeq(true)(option)} ${propOr('', 'text', option)}`,
	});

	return (
		<Autocomplete
			{...props}
			multiple
			name={formikName}
			label="Requirements linked"
			options={allRequirements}
			optionsLoading={loading}
			autoCompleteProps={{
				filterOptions,
				renderTags: (value, getTagProps) =>
					value.map((option, index) => (
						<ChipComponent
							key={option._id}
							tagProps={getTagProps({
								index,
							})}
							option={option}
							projectId={projectId}
						/>
					)),
			}}
			textFieldProps={{
				fullWidth: true,
				margin: 'none',
				variant: 'outlined',
				...(propOr(true, 'multiple', fieldOverrideProps)
					? {}
					: {
							InputProps: {
								startAdornment: (
									<InputAdornment position="start">
										<ProjectRequirementIcon fontSize="inherit" color="action" />
									</InputAdornment>
								),
							},
					  }),
				...getReadOnlyProps('Autocomplete')(isReadOnly),
			}}
			getCheckboxLabel={getOptionLabel}
			isCheckboxSelectedFn={isCheckboxSelectedFn}
			{...fieldOverrideProps}
		/>
	);
};

export default ({skeletonProps = {}, initLoading, ...props}) => {
	if (initLoading) {
		return <Skeletons.TextFieldSkeleton {...skeletonProps} />;
	}

	return <RequirementsLinked {...props} />;
};
