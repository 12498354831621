import React from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import { AppBar, MainWrapper } from '../../../../common/ui';
import { CreateProjectIcon } from '../../../../../utilities/constants';
import CreateProjectForm from './create-project-form';
import { useQuery } from 'react-apollo';
import { GET_MY_USER } from '../../../../../graphql/queries';

const CreateProjectScreen = () => {
	const {data: getMyUserData} = useQuery(GET_MY_USER, {
		fetchPolicy: 'cache-only',
	});
	return (
		<>
			<AppBar />
			<MainWrapper>
				<Container maxWidth="md" className="bg-filled">
					<Typography
						variant="h5"
						color="secondary"
						style={{display: 'flex', alignItems: 'center'}}
					>
						<CreateProjectIcon style={{marginRight: '8px'}} /> Create Project
					</Typography>
					<Typography variant="subtitle1" color="secondary">
						Please fill the form below in order to create a new project:
					</Typography>
					<Box mt={2}>
						<CreateProjectForm userData={getMyUserData?.getMyUser}/>
					</Box>
				</Container>
			</MainWrapper>
		</>
	);
};

export default CreateProjectScreen;
