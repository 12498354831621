import React from 'react';
import {Skeleton} from '@material-ui/lab';

const textFieldStyles = {borderRadius: 4};
const TextFieldSkeleton = props => (
	<Skeleton height="56px" variant="rect" style={textFieldStyles} {...props} />
);
const SelectFieldSkeleton = props => (
	<Skeleton height="56px" variant="rect" style={textFieldStyles} {...props} />
);

export default {TextFieldSkeleton, SelectFieldSkeleton};
