import gql from 'graphql-tag';

export default gql`
	query GetFilestackKeys {
		getFilestackKeys {
			signature
			policy
			policyb64
		}
	}
`;
