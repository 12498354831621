import gql from 'graphql-tag';

export default gql`
	mutation SendInvitationEmail($invitedUserId: String!) {
		sendInvitationEmail(invitedUserId: $invitedUserId) {
			_id
			email
			role
			invitationHash
		}
	}
`;
