import gql from 'graphql-tag';

export default gql`
	mutation CreateUser($data: CreateUserInput!) {
		createUser(data: $data) {
			token
			user {
				_id
				email
				username
				role
				fullName
				projects {
					_id
					name
					description
				}
				createdAt
			}
		}
	}
`;
