import React, {useState, useEffect, useRef, memo} from 'react';
import {Menu, MenuItem, Button} from '@material-ui/core';

const ButtonMenu = ({
	buttonProps,
	buttonText,
	menuItems = [], // item, handleClick, props
	menuProps = {},
}) => {
	const mounted = useRef(null);
	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		};
	});

	const handleClick = event => {
		if (mounted.current === true) {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClose = () => {
		if (mounted.current === true) {
			setAnchorEl(null);
		}
	};

	const open = Boolean(anchorEl);

	return (
		<>
			<Button {...buttonProps} onClick={handleClick}>
				{buttonText}
			</Button>
			<Menu
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				{...menuProps}
			>
				{menuItems.map(({item, handleClick, props}, i) => (
					<MenuItem
						key={i}
						{...props}
						onClick={async () => {
							await handleClick();
							handleClose();
						}}
					>
						{item}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default memo(ButtonMenu);
