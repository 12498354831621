import React from 'react';
import {Container, Typography, Box} from '@material-ui/core';
import {useQuery, useMutation} from 'react-apollo';
import {NetworkStatus} from 'apollo-client';
import {useQueryParams, NumberParam, JsonParam} from 'use-query-params';
import {path, propOr, complement, propEq} from 'ramda';
import {AppBar, MainWrapper, ErrorStates} from '../../../../common/ui';
import {InvitedUsersIcon} from '../../../../../utilities/constants';
import {GET_INVITED_USERS} from '../../../../../graphql/queries';
import {DELETE_INVITED_USER} from '../../../../../graphql/mutations';
import InvitedUsersTableWrapper from './invited-users-table-wrapper';

export const INVITED_USERS_PAGE_SIZE_DEFAULT = 25;
const INVITED_USERS_PAGE_OPTIONS = [12, 25, 50];

const InvitedUsersScreen = () => {
	const [queryParams, setQueryParams] = useQueryParams({
		sortBy: JsonParam, // although this is actually a JSON array
		filters: JsonParam, // although this is actually a JSON array
		pageIndex: NumberParam,
		pageSize: NumberParam,
	});
	const {data, error, networkStatus, refetch} = useQuery(GET_INVITED_USERS, {
		notifyOnNetworkStatusChange: true,
	});
	const [deleteInvitedUser, {loading}] = useMutation(DELETE_INVITED_USER, {
		update: (cache, {data}) => {
			const deletedId = path(['deleteInvitedUser', '_id'], data);
			const read = cache.readQuery({query: GET_INVITED_USERS});
			const invitedUsers = propOr([], 'getInvitedUsers', read);
			cache.writeQuery({
				query: GET_INVITED_USERS,
				data: {
					getInvitedUsers: invitedUsers.filter(
						complement(propEq('_id', deletedId)),
					),
				},
			});
		},
	});

	const shouldShowTableSkeleton =
		networkStatus === NetworkStatus.loading ||
		networkStatus === NetworkStatus.refetch;

	return (
		<>
			<AppBar />
			<MainWrapper>
				<Container maxWidth="md">
					{error ? (
						<ErrorStates.TryAgain
							withGoBackButton
							error={error}
							message="Sorry something went wrong"
							refetch={refetch}
						/>
					) : (
						<>
							<Typography
								variant="h5"
								style={{display: 'flex', alignItems: 'center'}}
								color="secondary"
							>
								<InvitedUsersIcon style={{marginRight: '8px'}} /> Invited Users
							</Typography>
							<Typography variant="subtitle1" color="secondary">
								Below are listed all the invited users of the platform:
							</Typography>
							<Box mt={2}>
								<InvitedUsersTableWrapper
									tableLoading={loading}
									data={data}
									deleteInvitedUser={deleteInvitedUser}
									shouldShowSkeleton={shouldShowTableSkeleton}
									queryParams={queryParams}
									setQueryParams={setQueryParams}
									pageOptions={INVITED_USERS_PAGE_OPTIONS}
									defaultPageSize={INVITED_USERS_PAGE_SIZE_DEFAULT}
								/>
							</Box>
						</>
					)}
				</Container>
			</MainWrapper>
		</>
	);
};

export default InvitedUsersScreen;
