import React from 'react';
import {ApolloProvider} from 'react-apollo';
import {ApolloClient} from 'apollo-client';
import {createHttpLink} from 'apollo-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {onError} from 'apollo-link-error';
import {ApolloLink} from 'apollo-link';
import {isNilOrEmpty} from '../../../utilities/tools';

const httpLink =
	process.env.NODE_ENV === 'test'
		? null
		: createHttpLink({
				uri: process.env.REACT_APP_GRAPHQL_HTTP_URI,
				// fetchOptions: {
				// 	mode: 'no-cors',
				// },
		  });

let cachedToken; // cache it here

const authLink = new ApolloLink((operation, forward) => {
	// get it from localstorage here
	let token;
	if (isNilOrEmpty(cachedToken)) {
		try {
			token = JSON.parse(localStorage.getItem('useReq:token'));
			if (!isNilOrEmpty(token)) cachedToken = token;
		} catch {}
	} else {
		token = cachedToken;
	}

	const operationName = operation && operation.operationName;
	operation.setContext(({headers = {}}) => {
		return {
			...(operationName === 'AuthenticateUser' ||
			operationName === 'IntrospectionQuery' ||
			operationName === 'IsUsernameAvailable' ||
			operationName === 'IsEmailAvailable' ||
			operationName === 'CreateUser'
				? {}
				: {
						headers: {
							...headers,
							authorization: `Bearer ${token}`,
						},
				  }),
		};
	});

	return forward(operation);
});

const errorLink = onError(({graphQLErrors, networkError}) => {
	if (graphQLErrors)
		graphQLErrors.map(({message, locations, path}) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
			),
		);
	if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
	link: ApolloLink.from([errorLink, authLink, httpLink]),
	cache: new InMemoryCache(),
});

export default ({children}) => (
	<ApolloProvider client={client}>{children}</ApolloProvider>
);
