import {makeStyles} from '@material-ui/styles';

const useFilledInputAdornmentStyles = makeStyles(theme => ({
	inputRoot: {
		backgroundColor: '#fff',
		'&:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
	},
	inputInput: {
		paddingLeft: '8px',
	},
	inputAdornedStart: {
		paddingLeft: 0,
	},
	inputFocused: {
		borderColor: '#fff!important',
	},
	inputAdornment: {
		height: '56px',
		width: '60px',
		marginRight: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.primary.main,
		color: '#fff',
		maxHeight: 'unset',
		borderTopLeftRadius: '4px',
		borderBottomLeftRadius: '4px',
		'& svg': {
			color: 'inherit',
			fontSize: '30px',
		},
	},
}));

export default useFilledInputAdornmentStyles;
