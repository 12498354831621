import React, {useMemo} from 'react';
import {
	Box,
	Paper,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TablePagination,
	Typography,
	Button,
	CircularProgress,
} from '@material-ui/core';
import {
	ArrowDownward,
	ArrowUpward,
	Warning as NoRecordsIcon,
} from '@material-ui/icons';
import {path} from 'ramda';
import {makeStyles, styled} from '@material-ui/styles';
import {useTable, useSortBy, useFilters, usePagination} from 'react-table';
import clsx from 'clsx';
import useDidUpdate from '@rooks/use-did-update';
import {isNilOrEmpty} from '../../../../utilities/tools';
import {TablePaginationActions as TablePaginationActionsComponent} from '..';

export const useStyles = makeStyles(theme => ({
	paper: {
		position: 'relative',
		marginBottom: theme.spacing(2),
	},
	circularProgress: {
		position: 'absolute',
		top: '50%',
		right: '50%',
	},
	tableRowSelectable: {
		cursor: 'pointer',
	},
	tableRowNotEditting: {
		opacity: 0.2,
		transition: 'all 300ms ease 0s',
		backgroundColor: 'rgba(220, 0, 78, 0.08)',
	},
}));

const StyledTable = styled(({tableLoading, ...rest}) => <Table {...rest} />)(
	({tableLoading}) => ({
		...(tableLoading
			? {opacity: 0.75, pointerEvents: 'none', cursor: 'default'}
			: {opacity: 1}),
	}),
);

const ResetFiltersButton = ({setAllFilters}) => (
	<Button
		variant="contained"
		color="primary"
		size="small"
		onClick={() => setAllFilters([])}
	>
		Reset Filters
	</Button>
);

export const Title = props => {
	const {filters, tableTitle} = props;
	return (
		<Box
			px={[2, 3]}
			pt={2}
			pb={1}
			style={{display: 'flex', justifyContent: 'space-between'}}
		>
			<Typography variant="h6">{tableTitle}</Typography>
			{!isNilOrEmpty(filters) && <ResetFiltersButton {...props} />}
		</Box>
	);
};

const NoRecordsFound = ({filters, noRecordsFoundMessage}) => {
	return (
		<TableBody>
			<TableRow>
				<TableCell align="center" colSpan={100}>
					<Typography
						component="div"
						variant="subtitle2"
						color="textSecondary"
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<NoRecordsIcon
							style={{marginRight: '8px'}}
							fontSize="inherit"
							color="inherit"
						/>
						{isNilOrEmpty(filters)
							? noRecordsFoundMessage
							: 'No records found, try adjusting the filters.'}
					</Typography>
				</TableCell>
			</TableRow>
		</TableBody>
	);
};

const HeaderColumn = ({column}) => {
	const {onClick, style} = column.getSortByToggleProps();

	return (
		<div style={{display: 'flex', ...column.headerStyle}}>
			<div style={style} onClick={onClick}>
				{column.render('Header')}
			</div>
			<div style={{marginLeft: '4px'}}>
				{column.isSorted ? (
					column.isSortedDesc ? (
						<ArrowDownward fontSize="small" style={style} onClick={onClick} />
					) : (
						<ArrowUpward fontSize="small" style={style} onClick={onClick} />
					)
				) : null}
			</div>
		</div>
	);
};

const FilterColumn = ({column}) => (
	<div>{column.canFilter ? column.render('Filter') : null}</div>
);

const calcPageCount = (totalCount = 0, pageSize = 1) =>
	Math.ceil(totalCount / pageSize);

const TableControlled = ({
	columns,
	data,
	totalCount,
	pageOptions,
	rowIdSelected,
	rowIdEditting,
	defaultPageSize,
	handleTableRowClick = () => null,
	tableTitle,
	rowIdKey = '_id',
	rowClickable = false,
	tableLoading,
	noRecordsFoundMessage = 'No records found',
	disablePagination = false,
	tableState,
	setTableState,
}) => {
	const {pageIndex: tableStatePageIndex} = tableState;
	const classes = useStyles();

	const TablePaginationActions = useMemo(
		() => props => (
			<TablePaginationActionsComponent
				disabled={disablePagination}
				{...props}
			/>
		),
		[disablePagination],
	);

	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		headerGroups,
		rows,
		prepareRow,
		page,
		state: {filters, pageIndex, pageSize},
		setAllFilters,
		setPageSize,
	} = useTable(
		{
			useControlledState: state => {
				return React.useMemo(
					() => ({
						...state,
						pageIndex: tableStatePageIndex, // we need to control pageIndex outside of table
					}),
					// eslint-disable-next-line
					[state, tableStatePageIndex],
				);
			},
			columns,
			data,
			initialState: {
				sortBy: tableState.sortBy || [],
				filters: tableState.filters || [],
				pageIndex: tableState.pageIndex || 0,
				pageSize: tableState.pageSize || defaultPageSize,
				pageCount: calcPageCount(
					(totalCount || 0) / (tableState.pageSize || defaultPageSize),
				),
			},
		},
		useFilters,
		useSortBy,
		usePagination,
	);

	// On Page change
	const handlePageChange = (_, newPage) => {
		setTableState({
			...tableState,
			pageIndex: newPage,
		});
	};

	// Sync up tableState with internal state (can put more dependencies here)
	useDidUpdate(() => {
		setTableState({
			...tableState,
			pageIndex: 0, // go back to 0
			pageSize,
		});
	}, [pageSize]);

	// Render the UI for your table
	return (
		<Paper className={classes.paper}>
			{tableTitle && (
				<Title
					tableTitle={tableTitle}
					setAllFilters={setAllFilters}
					filters={filters}
				/>
			)}
			<TableContainer>
				<StyledTable {...getTableProps()} tableLoading={tableLoading}>
					<TableHead>
						{headerGroups.map(headerGroup => (
							// eslint-disable-next-line react/jsx-key
							<TableRow {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => {
									return (
										// eslint-disable-next-line react/jsx-key
										<TableCell {...column.getHeaderProps()} title="">
											<HeaderColumn column={column} />
											<FilterColumn column={column} />
										</TableCell>
									);
								})}
							</TableRow>
						))}
					</TableHead>
					{isNilOrEmpty(rows) ? (
						<NoRecordsFound
							filters={filters}
							noRecordsFoundMessage={noRecordsFoundMessage}
						/>
					) : (
						<TableBody>
							{page.map(row => {
								prepareRow(row);
								const isRowSelected =
									path(['original', rowIdKey], row) === rowIdSelected;
								const isRowEditting =
									path(['original', rowIdKey], row) === rowIdEditting;
								return (
									// eslint-disable-next-line react/jsx-key
									<TableRow
										hover={!rowIdEditting}
										{...row.getRowProps()}
										className={clsx(
											rowClickable && classes.tableRowSelectable,
											rowIdEditting &&
												!isRowEditting &&
												classes.tableRowNotEditting,
										)}
										selected={isRowSelected}
										onClick={handleTableRowClick({row})}
									>
										{row.cells.map(cell => (
											// eslint-disable-next-line react/jsx-key
											<TableCell
												{...cell.getCellProps([
													{
														className: cell.column.className,
														style: cell.column.style,
													},
												])}
											>
												{cell.render('Cell')}
											</TableCell>
										))}
									</TableRow>
								);
							})}
						</TableBody>
					)}
				</StyledTable>
				{tableLoading && (
					<CircularProgress
						disableShrink
						className={classes.circularProgress}
					/>
				)}
			</TableContainer>
			<TablePagination
				SelectProps={{disabled: disablePagination}}
				align="right"
				component="div"
				page={pageIndex}
				count={totalCount}
				rowsPerPageOptions={pageOptions}
				rowsPerPage={pageSize}
				ActionsComponent={TablePaginationActions}
				onPageChange={handlePageChange}
				onRowsPerPageChange={event =>
					setPageSize(parseInt(event.target.value, 10))
				}
			/>
		</Paper>
	);
};

export default TableControlled;
