import React, { useState } from 'react';
import StoryEdit from './story-edit';
import StoryCreate from './story-create';
import StoriesTableWrapper from './stories-table-wrapper';

export const STORIES_PAGE_SIZE_DEFAULT = 25;
export const STORIES_PAGE_SIZE_DEFAULT_PAGE_OPTIONS = [12, 25, 50];

export default props => {
	const [openCreate, setOpenCreate] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [storyToEdit, setStoryToEdit] = useState({});
	
	return (
		<div>
			<StoriesTableWrapper 
				{...props}
				setOpenCreate={setOpenCreate}
				setOpenEdit={setOpenEdit}
				setStoryToEdit={setStoryToEdit}
			/>

			<StoryCreate 
				{...props}
				openCreate={openCreate}
				setOpenCreate={setOpenCreate}
			/>

			<StoryEdit 
				{...props}
				openEdit={openEdit}
				setOpenEdit={setOpenEdit}
				storyToEdit={storyToEdit}
				setStoryToEdit={setStoryToEdit}
			/>
		</div>
	);
};
