import React, {lazy, Suspense} from 'react';
import createPersistedState from 'use-persisted-state';
import {Box, CircularProgress, IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {BrightnessMedium as ThemeIcon} from '@material-ui/icons';
import clsx from 'clsx';

const AceCodeEditor = lazy(() => import('./ace-code-editor'));

const useDarkThemeState = createPersistedState('useReq:codeEditorDarkTheme');

const useStyles = makeStyles(() => ({
	container: {
		position: 'relative',
		border: '1px solid #c4c4c4',
		borderRadius: '4px',
		maxHeight: '640px',
		overflowY: 'auto',

		'&.disabled': {
			opacity: 0.7,
			pointerEvents: 'none',
		},
	},
	themeButton: {
		float: 'right',
	},
}));

const ThemeButton = ({darkTheme, setDarkTheme, classes}) => (
	<IconButton
		size="small"
		className={classes.themeButton}
		onClick={() => setDarkTheme(!darkTheme)}
	>
		<ThemeIcon fontSize="inherit" />
	</IconButton>
);

const SuspenseFallback = ({darkTheme}) => (
	<Box
		height="640px"
		display="flex"
		alignItems="center"
		justifyContent="center"
		flexDirection="column"
		bgcolor={darkTheme ? '#272822' : '#fff'}
	>
		<Box flex={0.25}>
			<CircularProgress />
		</Box>
	</Box>
);

const SwaggerEditor = props => {
	const {
		viewMode,
		initLoading,
		swagger = '',
		setSwagger,
		setCodeAnnotations,
		disabled,
	} = props;
	const [darkTheme, setDarkTheme] = useDarkThemeState(true);
	const classes = useStyles();

	if (initLoading)
		return (
			<div className={classes.container}>
				<SuspenseFallback darkTheme={darkTheme} />
			</div>
		);

	return (
		<div>
			<div className={clsx(classes.container, disabled && 'disabled')}>
				<Suspense fallback={<SuspenseFallback darkTheme={darkTheme} />}>
					<AceCodeEditor
						viewMode={viewMode}
						value={swagger}
						setValue={setSwagger}
						darkTheme={darkTheme}
						setCodeAnnotations={setCodeAnnotations}
					/>
				</Suspense>
			</div>
			<ThemeButton
				darkTheme={darkTheme}
				setDarkTheme={setDarkTheme}
				classes={classes}
			/>
		</div>
	);
};

export default SwaggerEditor;
