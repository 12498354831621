import React, {useCallback, useEffect} from 'react';
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	IconButton,
	Tooltip,
	Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import gql from 'graphql-tag';
import {useSnackbar} from 'notistack';
import {
	identity,
	ifElse,
	isEmpty,
	isNil,
	path,
	pathEq,
	pathOr,
	pipe,
	propOr,
} from 'ramda';
import {useApolloClient, useMutation, useQuery} from 'react-apollo';
import {Prompt, useHistory, useRouteMatch} from 'react-router-dom';
import {Delete} from '@material-ui/icons';
import {
	isMongoId,
	isSubmitButtonDisabled,
} from '../../../../../../../../utilities/tools';
import {
	DrawerContainer,
	UPDATE_PROJECT_REQUIREMENT,
	useFormikBag,
	Form,
} from '../common';
import {AlertActionDialog} from '../../../../../../../common/control';
import {GET_MY_USER} from '../../../../../../../../graphql/queries';

const GET_PROJECT_REQUIREMENT = gql`
	query GetProjectRequirement($projectId: String!, $requirementId: String!) {
		getProjectRequirement(
			projectId: $projectId
			requirementId: $requirementId
		) {
			_id
			text
			seq
			rating
			comment
			type
			createdAt
			updatedAt
		}
	}
`;

const useStyles = makeStyles(theme => ({
	submitButton: {
		width: 150,
	},
	backdrop: {
		position: 'absolute',
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const RequirementEdit = () => {
	const classes = useStyles();
	const match = useRouteMatch();
	const history = useHistory();
	const snackbar = useSnackbar();
	const apolloClient = useApolloClient();
	const {data: getMyUserData} = useQuery(GET_MY_USER, {
		fetchPolicy: 'cache-only',
	});
	const amIAdmin = pathEq(['getMyUser', 'role'], 'Admin', getMyUserData);

	const projectId = path(['params', 'projectId'], match);
	const requirementId = path(['params', 'requirementId'], match);

	const {data, loading} = useQuery(GET_PROJECT_REQUIREMENT, {
		variables: {projectId, requirementId},
		skip: !isMongoId(projectId) || !isMongoId(requirementId),
	});
	const [updateProject, {loading: updateProjectLoading}] = useMutation(
		UPDATE_PROJECT_REQUIREMENT,
	);
	const requirement = propOr({}, 'getProjectRequirement', data);

	const goBack = useCallback(
		({justClose = true} = {}) => {
			const from = pathOr('', ['location', 'state', 'from'], history);
			if (isEmpty(from)) {
				history.push(`/projects/${projectId}/requirements`);
			} else {
				const newPageAfterRequirementRemove = path(
					['location', 'state', 'newPageAfterRequirementRemove'],
					history,
				);
				if (justClose || isNil(newPageAfterRequirementRemove)) {
					history.goBack();
				} else {
					const [pathname, search = ''] = from.split('?');
					if (pathname.match('/projects/(.*?)/requirements')) {
						try {
							const urlSearch = new URLSearchParams(search);
							urlSearch.set('pageIndex', newPageAfterRequirementRemove);
							history.replace({
								pathname,
								search: urlSearch.toString(),
							});
						} catch (error) {
							console.error(error);
							history.replace({
								pathname: from,
							});
						}
					}
				}
			}
		},
		[history, projectId],
	);

	const deleteRequirement = useCallback(
		async requirementId => {
			try {
				await updateProject({
					variables: {
						// Return stories here because if the requirement to be deleted is linked to a story it's pullde from the story in DB
						withStories: true,
						projectId,
						data: {
							requirements: {
								deleteMany: [requirementId],
							},
						},
					},
				});
				goBack({justClose: false});
			} catch (error) {
				snackbar.enqueueSnackbar(error.message.replace('GraphQL error:', ''), {
					variant: 'error',
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
				});
			}
		},
		[goBack, projectId, snackbar, updateProject],
	);

	const formikProps = useFormikBag({
		updateProject,
		requirement,
		projectId,
		requirementId,
		mode: 'edit',
		apolloClient,
		snackbar,
		goBack,
		amIAdmin,
	});
	const {dirty} = formikProps;

	useEffect(() => {
		if (dirty) {
			// eslint-disable-next-line
			window.onbeforeunload = () => true;
		} else {
			// eslint-disable-next-line
			window.onbeforeunload = undefined;
		}
	}, [dirty]);
	useEffect(() => {
		return () => {
			// eslint-disable-next-line
			window.onbeforeunload = undefined;
		};
	}, []);

	useEffect(() => {
		if (!isMongoId(projectId)) {
			history.replace(`/projects`);
		} else if (!isMongoId(requirementId)) {
			history.replace(`/projects/${projectId}/requirements`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectId, requirementId]);

	const submitDisabled =
		!dirty || updateProjectLoading || isSubmitButtonDisabled(formikProps);

	return (
		<DrawerContainer
			Header={
				<Typography variant="h5">
					{pipe(
						propOr('', 'seq'),
						ifElse(isEmpty, identity, s => `(R-${s})`),
					)(requirement)}{' '}
					Edit Requirement
				</Typography>
			}
			onClose={goBack}
		>
			<Prompt
				when={dirty}
				message="You have unsaved changes. Are you sure you want to leave?"
			/>
			<Backdrop
				open={updateProjectLoading || formikProps.isSubmitting}
				className={classes.backdrop}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Container maxWidth="sm">
				<Box mt={4}>
					<Form
						initLoading={loading}
						mode="edit"
						amIAdmin={amIAdmin}
						{...formikProps}
					/>
					<Box mt={5}>
						<Grid container alignItems="center" justifyContent="space-between">
							<Grid item>
								<Button
									className={classes.submitButton}
									color="primary"
									variant="contained"
									disabled={submitDisabled}
									onClick={() => formikProps.handleSubmit()}
								>
									Submit
								</Button>
							</Grid>
							<Grid item>
								<AlertActionDialog
									canEscape
									title="Delete Requirement"
									text="Are you sure you want to delete this requirement? This action cannot be undone."
									actions={[
										{text: 'Back', buttonProps: {color: 'default'}},
										{
											text: 'Delete Requirement',
											buttonProps: {color: 'danger'},
											onClick: () => deleteRequirement(requirementId),
										},
									]}
								>
									{({handleOpen}) => (
										<Tooltip title="Delete Requirement">
											<IconButton
												disabled={updateProjectLoading}
												onClick={() => handleOpen()}
											>
												<Delete />
											</IconButton>
										</Tooltip>
									)}
								</AlertActionDialog>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Container>
		</DrawerContainer>
	);
};

export default RequirementEdit;
