import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	CircularProgress,
} from '@material-ui/core';
import {Button} from '../../../../../../common/control';

const DeleteProjectDialog = ({
	open,
	setOpen,
	project = {},
	deleteProjectProps,
}) => {
	const {_id, name} = project;
	const [deleteProject, {loading}] = deleteProjectProps;

	const onClose = () => setOpen(false);

	const onClickDelete = () => {
		deleteProject({variables: {projectId: _id}});
	};

	return (
		<Dialog
			open={open}
			maxWidth="sm"
			//disableBackdropClick={loading}
			disableEscapeKeyDown={loading}
			onClose={onClose}
		>
			<DialogTitle>{`Delete "${name}" project`}</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure you want to delete this project? This will also delete
					all its project requirements, stories, files and OpenAPI
					specifications!
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button disabled={loading} onClick={() => onClose()}>
					Cancel
				</Button>
				<Button
					color="danger"
					disabled={loading}
					endIcon={
						loading ? (
							<CircularProgress disableShrink size={20} color="inherit" />
						) : null
					}
					onClick={() => onClickDelete()}
				>
					Delete Project
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteProjectDialog;
