import gql from 'graphql-tag';

export default gql`
	query DownloadProjectFile(
		$projectId: String!
		$fileId: String!
		$fileHandle: String!
		$fileUrl: String!
	) {
		downloadProjectFile(
			fileId: $fileId
			projectId: $projectId
			fileHandle: $fileHandle
			fileUrl: $fileUrl
		) {
			fileId
			url
		}
	}
`;
