import React, {useMemo} from 'react';
import {InputAdornment, TextField} from '@material-ui/core';
import {PermContactCalendar} from '@material-ui/icons';
import {
	formatFormErrorHelperText,
	getFormErrorsField,
} from '../../../../utilities/tools';
import useFilledInputAdornmentStyles from '../../ui/filled-input-adornment-classes';

const FullName = ({
	errors,
	touched,
	isSubmitting,
	values,
	handleChange,
	handleBlur,
	shouldUseFilledInputAdornmentStyles = true,
	textFieldProps = {},
}) => {
	const classes = useFilledInputAdornmentStyles();

	const filledInputAdornmentProps = useMemo(() => {
		return shouldUseFilledInputAdornmentStyles
			? {
					classes: {
						root: classes.inputRoot,
						adornedStart: classes.inputAdornedStart,
						focused: classes.inputFocused,
						notchedOutline: classes.notchedOutline,
						input: classes.inputInput,
					},
					startAdornment: (
						<InputAdornment className={classes.inputAdornment} position="start">
							<PermContactCalendar />
						</InputAdornment>
					),
			  }
			: {};
	}, [classes, shouldUseFilledInputAdornmentStyles]);

	return (
		<TextField
			fullWidth
			inputProps={{
				form: {
					autocomplete: 'off',
				},
			}}
			disabled={isSubmitting}
			id="fullName"
			name="fullName"
			placeholder="Full name* (e.g John Doe)"
			value={values.fullName}
			helperText={formatFormErrorHelperText('fullName', errors, touched)}
			error={Boolean(getFormErrorsField('fullName', errors, touched))}
			variant="outlined"
			type="text"
			// eslint-disable-next-line react/jsx-no-duplicate-props
			InputProps={filledInputAdornmentProps}
			onChange={handleChange}
			onBlur={handleBlur}
			{...textFieldProps}
		/>
	);
};

export default FullName;
