import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSnackbar} from 'notistack';
import {path, propEq} from 'ramda';
import {useMutation, useQuery} from 'react-apollo';
import {Prompt, useRouteMatch} from 'react-router-dom';
import {
	Box,
	Button,
	FormControlLabel,
	Grid,
	Radio,
	Typography,
} from '@material-ui/core';
import {CloudUpload, GetApp as DownloadFile} from '@material-ui/icons';
import gql from 'graphql-tag';
import isEqual from 'react-fast-compare';
import {GET_PROJECT_SWAGGER} from '../../../../../../../graphql/queries';
import {ErrorStates} from '../../../../../../common/ui';
import {isNilOrEmpty, toJSONorYAML} from '../../../../../../../utilities/tools';
import {useUi} from '../../../../../../hoc';
import {useComponentDidUpdate} from '../../../../../../../hooks';
import SwaggerEditor from './swagger-editor';
import UploadSwaggerDialog from './upload-swagger-dialog';

const downloadAsFile = ({value}) => {
	const dataStr =
		'data:text/json;charset=utf-8,' +
		encodeURIComponent(JSON.stringify(value, null, 2));
	const dlAnchorElem = document.querySelector('#downloadAnchorElem');
	dlAnchorElem.setAttribute('href', dataStr);
	dlAnchorElem.setAttribute('download', 'swagger.json');
	dlAnchorElem.click();
};

const UPDATE_PROJECT_SWAGGER = gql`
	mutation UpdateProject($projectId: String!, $data: UpdateProjectInput!) {
		updateProject(projectId: $projectId, data: $data) {
			_id
			swagger
			createdAt
			updatedAt
		}
	}
`;

const objectize = value => {
	try {
		const parsed = JSON.parse(value);
		return parsed;
	} catch {
		return {};
	}
};

const SelectViewMode = ({viewMode, setViewMode}) => {
	return (
		<Box display="flex" justifyContent="flex-end" alignItems="center">
			<FormControlLabel
				value="json"
				control={
					<Radio
						checked={viewMode === 'json'}
						value="json"
						size="small"
						color="secondary"
					/>
				}
				label="JSON"
				onClick={() => setViewMode('json')}
			/>
			<FormControlLabel
				value="yaml"
				control={
					<Radio
						checked={viewMode === 'yaml'}
						value="yaml"
						size="small"
						color="secondary"
					/>
				}
				label="YAML"
				onClick={() => setViewMode('yaml')}
			/>
		</Box>
	);
};

const Swagger = () => {
	const match = useRouteMatch();
	const ui = useUi();
	const projectId = path(['params', 'projectId'], match);
	const snackbar = useSnackbar();
	const {data, loading, error, refetch} = useQuery(GET_PROJECT_SWAGGER, {
		notifyOnNetworkStatusChange: true,
		variables: {projectId},
	});
	const [updateProject, {loading: updateLoading}] = useMutation(
		UPDATE_PROJECT_SWAGGER,
		{
			onCompleted: () => {
				ui.setAppProgressShown(false);
				snackbar.enqueueSnackbar('Swagger updated succesfully!', {
					variant: 'success',
					autoHideDuration: 4000,
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
				});
			},
			onError: error => {
				console.error(error);
				ui.setAppProgressShown(false);
				snackbar.enqueueSnackbar(
					'Error occured, please make sure your Swagger input is valid!',
					{
						variant: 'error',
						autoHideDuration: 8000,
						anchorOrigin: {vertical: 'top', horizontal: 'center'},
					},
				);
			},
		},
	);
	const swaggerProject = path(['getProject', 'swagger'], data);
	const initSwagger = useMemo(() => {
		if (isNilOrEmpty(swaggerProject)) return '';
		return JSON.stringify(swaggerProject, null, 2);
	}, [swaggerProject]);
	const initSwaggerInYAML = useMemo(() => {
		if (isNilOrEmpty(swaggerProject)) return '';
		return toJSONorYAML(JSON.stringify(swaggerProject, null, 2), 'yaml');
	}, [swaggerProject]);
	const [swagger, setSwagger] = useState(initSwagger);
	const [codeAnnotations, setCodeAnnotations] = useState([]);
	const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
	const [viewMode, setViewMode] = useState('json');

	const submit = useCallback(
		(value, viewMode = 'json') => {
			try {
				const parsed =
					viewMode === 'yaml'
						? JSON.parse(toJSONorYAML(value, 'json'))
						: JSON.parse(value);
				ui.setAppProgressShown(true);
				updateProject({
					variables: {
						projectId,
						data: {
							swagger: parsed,
						},
					},
				});
			} catch {
				snackbar.enqueueSnackbar(
					'There seems to be an error with your Swagger input',
					{
						variant: 'error',
						autoHideDuration: 8000,
						anchorOrigin: {vertical: 'top', horizontal: 'center'},
					},
				);
			}
		},
		[projectId, snackbar, ui, updateProject],
	);

	useEffect(() => {
		// we only want this effect for synchronization of swagger after the mutation
		if (viewMode === 'yaml') {
			setSwagger(initSwaggerInYAML);
		} else {
			setSwagger(initSwagger);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initSwagger, initSwaggerInYAML]);

	useComponentDidUpdate(() => {
		setSwagger(toJSONorYAML(swagger, viewMode));
	}, [viewMode]);

	const hasCodeError =
		Boolean(codeAnnotations) && codeAnnotations.some(propEq('type', 'error'));
	const dirty = !isEqual(
		swagger,
		viewMode === 'yaml' ? initSwaggerInYAML : initSwagger,
	);

	const submitDisabled =
		!dirty ||
		(viewMode === 'json' && isNilOrEmpty(objectize(swagger))) ||
		(viewMode === 'yaml' && isNilOrEmpty(swagger)) ||
		hasCodeError;

	useEffect(() => {
		if (dirty) {
			// eslint-disable-next-line
				window.onbeforeunload = () => true;
		} else {
			// eslint-disable-next-line
				window.onbeforeunload = undefined;
		}
	}, [dirty]);
	useEffect(() => {
		return () => {
			// eslint-disable-next-line
				window.onbeforeunload = undefined;
		};
	}, []);

	if (error) {
		return (
			<ErrorStates.TryAgain withGoBackButton error={error} refetch={refetch} />
		);
	}

	return (
		<div>
			<Prompt
				when={dirty}
				message="You have unsaved changes. Are you sure you want to leave?"
			/>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={3}>
					<Typography variant="h6" color="secondary">
						Use the code editor in order to create your open API specification:
					</Typography>
					<Box mt={2}>
						<a href="/" id="downloadAnchorElem" style={{visibility: 'hidden'}}>
							&nbsp;
						</a>
						<Button
							variant="text"
							size="small"
							color="primary"
							startIcon={<DownloadFile />}
							disabled={updateLoading}
							onClick={() => downloadAsFile({value: initSwagger})}
						>
							Download as JSON file
						</Button>
						<Box mt="50px">
							<Grid container spacing={2}>
								<Grid item>
									<Button
										variant="outlined"
										color="primary"
										startIcon={<CloudUpload />}
										disabled={updateLoading}
										onClick={() => setUploadDialogOpen(true)}
									>
										Upload from file
									</Button>
								</Grid>
								<Grid item>
									<Box width="140px">
										{p => (
											<Button
												{...p}
												variant="contained"
												color="primary"
												disabled={submitDisabled || updateLoading}
												onClick={() => submit(swagger, viewMode)}
											>
												Submit
											</Button>
										)}
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Box>
					{hasCodeError && (
						<Box mt={1} fontWeight="bold">
							{p => (
								<Typography {...p} color="error" variant="body2">
									Your code has errors!
								</Typography>
							)}
						</Box>
					)}
				</Grid>
				<Grid item xs={12} lg={9}>
					<SelectViewMode viewMode={viewMode} setViewMode={setViewMode} />
					<SwaggerEditor
						disabled={updateLoading}
						initLoading={loading}
						swagger={swagger}
						setSwagger={setSwagger}
						codeAnnotations={codeAnnotations}
						setCodeAnnotations={setCodeAnnotations}
						viewMode={viewMode}
					/>
				</Grid>
			</Grid>
			<UploadSwaggerDialog
				open={uploadDialogOpen}
				setOpen={setUploadDialogOpen}
				afterFileAccepted={submit}
			/>
		</div>
	);
};

export default Swagger;
