import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Cicd from './ci-cd';


const useStyles = makeStyles(theme => ({
	root: {
		// [theme.breakpoints.up('md')]: {
		// flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		// display: 'flex',
		minHeight: '70vh',
		padding: '20px',
		borderRadius: '8px',
		// 	'& .ui-tabpanel': {
		// 		flex: 1,
		// 	},
		// },
	},
}));

export default props => {
	const classes = useStyles();
	// const theme = useTheme();

	return (
		<div className={classes.root}>
			<Cicd {...props} />
		</div>
	);
};
