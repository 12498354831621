import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	toolbar: theme.mixins.toolbar,
	content: {
		overflowX: 'auto',
		flexGrow: 1,
		backgroundColor: theme.palette.info.main,
		padding: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			paddingLeft: '0px',
			paddingRight: '0px',
		},
	},
}));

const MainWrapper = ({children}) => {
	const classes = useStyles();
	return (
		<main className={classes.content}>
			<div className={classes.toolbar} />
			{children}
		</main>
	);
};

export default MainWrapper;
