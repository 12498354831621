import gql from 'graphql-tag';

export default gql`
	mutation AddProjectFiles($projectId: String!, $files: [ProjectFileInput!]!) {
		addProjectFiles(projectId: $projectId, files: $files) {
			_id
			files {
				_id
				url
				handle
				filename
				mimetype
				createdAt
				size
			}
		}
	}
`;
