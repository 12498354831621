import {Box, Grid, Typography} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {makeStyles} from '@material-ui/styles';
import { useHistory } from "react-router-dom";
import React from 'react';
import clsx from 'clsx';
import cycloptImage from '../../../../../../../../src/assets/cyclopt.png';

const useStyles = makeStyles(() => ({
	box: {
		backgroundColor: '#f5f5f5',
		color: '#465b72',
		boxShadow: '2px 2px 1px 2px #a0a0a0',
		fontWeight: 400,
		borderRadius: '4px',
		padding: '20px',
		'&:hover': {
			// opacity: 0.8,
			cursor: 'pointer',
			transform: 'scale(1.02)',
		},
	},

	title: {
		textTransform: 'uppercase',
	},
	body: {
		fontSize: '26px',
		fontWeight: 'bold',
	},
	icon: {
		fontSize: '28px',
	},
	skeleton: {
		borderRadius: '4px',
		width: '100%',
		height: '110px',
	},
}));

const BoxStat = ({
	title, 
	body, 
	Icon = () => null, 
	loading, 
	path, 
	showCycloptLogo, 
	modal=false, 
	setOpen= () => null,
}) => {
	const classes = useStyles();
	const history = useHistory();

	const handleClick = () => {
		if (!modal && path) {
			history.push(path)
		} else if (modal === true && setOpen) {
			setOpen(true);
		}
	}

	if (loading) return <Skeleton variant="rect" className={classes.skeleton} />;

	return (
		<Box 
			className={clsx(path && classes.box)}
			onClick={() => handleClick()}
		>
			<Grid container>
				<Grid item xs={12}>
					<Typography className={classes.title} color="secondary">
						{title}
					</Typography>
				</Grid>
				<Grid item container xs={12}>
					<Grid container justifyContent="space-between" alignItems="center">
						<Grid item>
							<Typography className={classes.body} color="secondary">
								{body}
							</Typography>
						</Grid>
						{showCycloptLogo ? (
								<Grid item>
									<img width="30" alt="cyclopt-logo" src={cycloptImage} className={classes.logo} />
								</Grid>
							) : (

								<Grid item>
									<Icon className={classes.icon} />
								</Grid>
							)
						}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default BoxStat;
