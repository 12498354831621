import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {IconButton} from '@material-ui/core';
import {
	FirstPage as FirstPageIcon,
	KeyboardArrowLeft,
	KeyboardArrowRight,
	LastPage as LastPageIcon,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}));

const TablePaginationActions = props => {
	const classes = useStyles();
	const {count, page, rowsPerPage, onPageChange, disabled} = props;

	const handleFirstPageButtonClick = event => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = event => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = event => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = event => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<div className={classes.root}>
			<IconButton
				disabled={disabled || page === 0}
				aria-label="first page"
				onClick={handleFirstPageButtonClick}
			>
				<FirstPageIcon />
			</IconButton>
			<IconButton
				disabled={disabled || page === 0}
				aria-label="previous page"
				onClick={handleBackButtonClick}
			>
				<KeyboardArrowLeft />
			</IconButton>
			<IconButton
				disabled={disabled || page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
				onClick={handleNextButtonClick}
			>
				<KeyboardArrowRight />
			</IconButton>
			<IconButton
				disabled={disabled || page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
				onClick={handleLastPageButtonClick}
			>
				<LastPageIcon />
			</IconButton>
		</div>
	);
};

export default TablePaginationActions;
