import React, {useState} from 'react';
import {Box} from '@material-ui/core';
import {
	CloudDownload as ExportProjectIcon,
	Delete as DeleteIcon,
	FileCopy as FileCopyIcon,
} from '@material-ui/icons';
import {Button, ExportProjectsDialog} from '../../../../../../common/control';
import DeleteProjectDialog from './delete-project-dialog';
import CloneProjectModal from '../../../../../../modals/clone-project-modal';
import { useEffect } from 'react';

const Actions = props => {
	const {project, deleteProjectProps, amIAdmin} = props;
	const [deleteProjectDialogOpen, setDeleteProjectDialogOpen] = useState(false);
	const [openCloneProjectModal, setOpenCloneProjectModal] = useState(false);
	const [exportProjectDialogOpen, setExportProjectDialogOpen] = useState(false);

	useEffect(() => {
		console.log("actions", project);
		
	}, [project]);

	return (
		<div>
			<Box>
				<Button
					size="small"
					variant="contained"
					color="default"
					startIcon={<ExportProjectIcon />}
					onClick={() => setExportProjectDialogOpen(true)}
				>
					Export Project
				</Button>
			</Box>
			{amIAdmin && (
				<>
					<CloneProjectModal
						open={openCloneProjectModal}
						setOpen={setOpenCloneProjectModal}
						project={project}
						amIAdmin={amIAdmin}
					/>
					<Box mt="20px">
						<Button
							size="small"
							variant="text"
							color="primary"
							startIcon={<FileCopyIcon />}
							onClick={() => setOpenCloneProjectModal(true)}
						>
							Clone Project
						</Button>
					</Box>
				</>
			)}
			{amIAdmin && (
				<Box mt="20px">
					<Button
						size="small"
						variant="text"
						color="danger"
						startIcon={<DeleteIcon />}
						onClick={() => setDeleteProjectDialogOpen(true)}
					>
						Delete Project
					</Button>
				</Box>
			)}
			{amIAdmin && (
				<DeleteProjectDialog
					deleteProjectProps={deleteProjectProps}
					project={project}
					open={deleteProjectDialogOpen}
					setOpen={setDeleteProjectDialogOpen}
				/>
			)}
			
			<ExportProjectsDialog
				projects={[project]}
				open={exportProjectDialogOpen}
				setOpen={setExportProjectDialogOpen}
			/>
		</div>
	);
};

export default Actions;
