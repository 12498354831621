import React, { useEffect, useState } from 'react';
import { Grid, Chip, FormLabel, Typography, Box } from '@material-ui/core';
import { pathOr, path } from 'ramda';
import { makeStyles } from '@material-ui/styles';
import { isNilOrEmpty } from '../../../../../../../../utilities/tools';
import { PROJECT_STORY_RATINGS } from '../../../../../../../../utilities/constants';
import { MultiSelect, TextField } from '../../../../../../../common/form';
import RequirementsLinkedInput from './requirements-linked-input';
// import CycloptTasks from './cyclopt-tasks';
import { getTask } from '../../../../../../../../utilities/cycloptAPI';

const PROJECT_STORY_RATINGS_OBJECT = PROJECT_STORY_RATINGS.reduce(
	(acc, {value, ...rest}) => ({...acc, [value]: rest}),
	{},
);
const PROJECT_STORY_RATINGS_VALUES = Object.keys(PROJECT_STORY_RATINGS_OBJECT);

const useStyles = makeStyles(() => ({
	ratingChips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	ratingChip: {
		margin: 2,
	},
	container: {
		border: '1px solid #c4c4c4',
		padding: '8px',
		borderRadius: '4px',
	},
}));

const Form = props => {
	const styles = useStyles();
	const [cycloptTask, setCycloptTask] = useState({});
	const {
		mode, 
		amIAdmin, 
		// projectId,
		cycloptIntegration,
		storyId,
	} = props;

	const getCycloptTask = async (storyId, cycloptIntegration) => {
		console.log("getting task");
		const cycloptTaskLink = cycloptIntegration.links.find(link => link.useReqId === storyId);
		const res = await getTask(cycloptIntegration.apiKey, cycloptIntegration.projectId, cycloptTaskLink.integrationId);
		if (res.status === 200) {
			// console.log(res.task);
			setCycloptTask(res.task);
		} else {
			// console.log(res);
		}
	};

	useEffect(() => {
		setCycloptTask({});
		if (cycloptIntegration && cycloptIntegration.apiKey) {
			// console.log("Edit form!", cycloptIntegration);
			getCycloptTask(storyId, cycloptIntegration);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cycloptIntegration]);

	
	return (
		<div>
			<Grid container spacing={3} alignItems="center">
				<Grid item xs={12} md={11}>
					<TextField
						{...props}
						textFieldProps={{
							autoFocus: mode === 'create',
							onKeyDown: event => {
								if (event.key === 'Enter') {
									event.preventDefault();
								}
							},
						}}
						name="title"
						label="Story title*"
					/>
				</Grid>
				<Grid item xs={11}>
					{mode === 'edit' && !amIAdmin && (
						<Grid container spacing={2} alignItems="center">
							<Grid item>
								<FormLabel>Rating:</FormLabel>
							</Grid>
							<Grid item>
								{isNilOrEmpty(path(['values', 'rating'], props)) ? (
									'-'
								) : (
									<div className={styles.ratingChips}>
										{path(['values', 'rating'], props).map(value => (
											<Chip
												key={value}
												className={styles.ratingChip}
												label={path(
													[value, 'label'],
													PROJECT_STORY_RATINGS_OBJECT,
												)}
												style={pathOr(
													{},
													[value, 'style'],
													PROJECT_STORY_RATINGS_OBJECT,
												)}
											/>
										))}
									</div>
								)}
							</Grid>
						</Grid>
					)}
					{amIAdmin && (
						<MultiSelect
							{...props}
							name="rating"
							label="Rating"
							options={PROJECT_STORY_RATINGS_VALUES}
							formatOption={value =>
								path([value, 'label'], PROJECT_STORY_RATINGS_OBJECT)
							}
							renderValue={(valueArray = []) => (
								<div className={styles.ratingChips}>
									{valueArray.map(value => (
										<Chip
											key={value}
											className={styles.ratingChip}
											label={path(
												[value, 'label'],
												PROJECT_STORY_RATINGS_OBJECT,
											)}
											style={pathOr(
												{},
												[value, 'style'],
												PROJECT_STORY_RATINGS_OBJECT,
											)}
										/>
									))}
								</div>
							)}
							formControlProps={{margin: 'none'}}
						/>
					)}
				</Grid>
				<Grid item xs={12} md={11}>
					{mode === 'edit' && !amIAdmin && (
						<Grid container spacing={2} alignItems="center">
							<Grid item>
								<FormLabel>Comment:</FormLabel>
							</Grid>
							<Grid item>
								{isNilOrEmpty(path(['values', 'comment'], props)) ? (
									'-'
								) : (
									<Typography variant="body1">
										{path(['values', 'comment'], props)}
									</Typography>
								)}
							</Grid>
						</Grid>
					)}
					{amIAdmin && (
						<TextField
							{...props}
							multiline
							maxRows={4}
							textFieldProps={{
								multiline: true,
								maxRows: 10,
								minRows: 2,
							}}
							name="comment"
							label="Comment"
						/>
					)}
				</Grid>
				<Grid item xs={12} md={11}>
					<RequirementsLinkedInput {...props} />
				</Grid>
				{mode === 'edit' && (
					<Grid item xs={12} md={11}>
						<Box className={styles.container}>
							<Typography variant="subtitle1" color="textSecondary">
								Cyclopt Task
							</Typography>
							<Box>
								<Typography sx={{ fontSize: 12 }} color="textSecondary">
									{cycloptTask.title}
								</Typography>
							</Box>
							<Box>
								<Typography sx={{ fontSize: 12 }} color="textSecondary">
									Status: {cycloptTask.status}
								</Typography>
							</Box>
							<Box>
								<Typography sx={{ fontSize: 12 }} color="textSecondary">
									Points: {cycloptTask?.points?.done} / {cycloptTask?.points?.total}
								</Typography>
							</Box>
						</Box>
						{/* <CycloptTasks {...props} /> */}
					</Grid>
				)}
			</Grid>
		</div>
	);
};

export default Form;
