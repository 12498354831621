import React, {useState} from 'react';
import {
	Typography,
	Box,
	Grid,
	Button,
	IconButton,
	CircularProgress,
	Container,
} from '@material-ui/core';
import ReactFilestack from 'filestack-react';
import {useQuery, useLazyQuery, useMutation} from 'react-apollo';
import moment from 'moment';
import {
	propOr,
	pipe,
	prop,
	multiply,
	reject,
	equals,
	pick,
	includes,
	__,
	map,
	isEmpty,
	path,
} from 'ramda';
import {format} from 'timeago.js';
import {makeStyles} from '@material-ui/styles';
import {
	Folder as FileIcon,
	Delete as DeleteIcon,
	GetApp as DownloadIcon,
	Add as AddFileIcon,
} from '@material-ui/icons';
import prettyBytes from 'pretty-bytes';
import {useSnackbar} from 'notistack';
import {useRouteMatch} from 'react-router-dom';
import {
	GET_FILESTACK_KEYS,
	GET_PROJECT_FILES,
	DOWNLOAD_PROJECT_FILE,
} from '../../../../../../../graphql/queries';
import {
	ADD_PROJECT_FILES,
	DELETE_PROJECT_FILES,
} from '../../../../../../../graphql/mutations';
import {isNilOrEmpty} from '../../../../../../../utilities/tools';
import {ErrorStates} from '../../../../../../common/ui';
import {AlertActionDialog} from '../../../../../../common/control';

const useStyles = makeStyles(theme => ({
	itemFile: {
		border: '1px solid black',
		borderRadius: '4px',

		'& .iconContainer': {
			borderTopRightRadius: '2px',
			borderTopLeftRadius: '2px',
			width: '100%',
			flex: 1,
			height: 50,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.palette.secondary.main,
		},

		'& .iconContainer .icon': {
			width: '30px',
			height: '30px',
			color: '#fff',
		},

		'& .info': {
			padding: '8px 16px',
		},

		'& p': {
			overflowWrap: 'break-word',
		},
	},
	fileIconButton: {},
}));

const loadFilestackKeys = () => {
	try {
		const today = new Date();
		const item = localStorage.getItem('useReq.filestackKeys');
		const hasExpired = pipe(
			JSON.parse,
			prop('policy'),
			JSON.parse,
			propOr(0, 'expiry'),
			multiply(1000),
			ms => new Date(ms),
			expDate => moment(today).isAfter(expDate),
		)(item);
		if (hasExpired === true) {
			localStorage.removeItem('useReq.filestackKeys');
			return null;
		}

		return JSON.parse(item);
	} catch {
		localStorage.removeItem('useReq.filestackKeys');
		return null;
	}
};

const _filestackKeys = loadFilestackKeys();

const ItemFile = ({
	projectId,
	file,
	classes,
	fileIdsLoading = [],
	setFileIdsLoading,
	downloadFile,
	deleteProjectFiles,
	fileIdsDeleting,
	setFileIdsDeleting,
}) => {
	const {_id, filename, url, createdAt, size, handle} = file;
	const isFileLoading = fileIdsLoading.includes(_id);
	const isFileDeleting = fileIdsDeleting.includes(_id);
	const actionsDisabled = !isEmpty(fileIdsDeleting) || !isEmpty(fileIdsLoading);

	const onDownload = () => {
		setFileIdsLoading([...fileIdsLoading, _id]);
		downloadFile({
			variables: {projectId, fileId: _id, fileHandle: handle, fileUrl: url},
		});
	};

	const onDelete = () => {
		setFileIdsDeleting([...fileIdsDeleting, _id]);
		deleteProjectFiles({
			variables: {projectId, filesIds: [_id]},
		});
	};

	return (
		<div className={classes.itemFile}>
			<div className="iconContainer" onClick={onDownload}>
				<FileIcon className="icon" />
			</div>
			<div className="info">
				<Typography className="filename">{filename}</Typography>
				<Typography color="textSecondary" variant="body2">
					{prettyBytes(size)}
				</Typography>
				<Box mt={1}>
					<Typography color="textSecondary" variant="body2">
						{`Added ${format(Number(createdAt))}`}
					</Typography>
				</Box>
				<Box mt={1}>
					<Grid container justifyContent="space-between" spacing={3}>
						<Grid item>
							<Button
								disabled={actionsDisabled}
								color="secondary"
								size="small"
								startIcon={<DownloadIcon />}
								onClick={onDownload}
							>
								{isFileLoading ? (
									<Box display="flex" justifyContent="center">
										<CircularProgress
											disableShrink
											size={15}
											color="secondary"
										/>
									</Box>
								) : (
									'Download'
								)}
							</Button>
						</Grid>
						<Grid item>
							<AlertActionDialog
								canEscape
								title={`Delete file "${filename}"`}
								text="Are you sure you want to delete this file?"
								actions={[
									{text: 'Back', buttonProps: {color: 'default'}},
									{
										text: 'Delete file',
										buttonProps: {color: 'danger'},
										onClick: () => onDelete(),
									},
								]}
							>
								{({handleOpen}) => (
									<IconButton
										disabled={actionsDisabled}
										size="small"
										onClick={() => handleOpen()}
									>
										{isFileDeleting ? (
											<CircularProgress size={16} color="inherit" />
										) : (
											<DeleteIcon fontSize="inherit" />
										)}
									</IconButton>
								)}
							</AlertActionDialog>
						</Grid>
					</Grid>
				</Box>
			</div>
		</div>
	);
};

const Files = () => {
	const match = useRouteMatch();
	const projectId = path(['params', 'projectId'], match);
	const snackbar = useSnackbar();
	const [fileIdsLoading, setFileIdsLoading] = useState([]);
	const [fileIdsDeleting, setFileIdsDeleting] = useState([]);
	const classes = useStyles();
	const {data: d1, loading: l1, error: e1, refetch: rf1} = useQuery(
		GET_FILESTACK_KEYS,
		{
			notifyOnNetworkStatusChange: true,
			skip: !isNilOrEmpty(_filestackKeys),
			onCompleted: data => {
				localStorage.setItem(
					'useReq.filestackKeys',
					JSON.stringify(data && data.getFilestackKeys),
				);
			},
		},
	);
	const {data: d2, loading: l2, error: e2, refetch: rf2} = useQuery(
		GET_PROJECT_FILES,
		{
			notifyOnNetworkStatusChange: true,
			variables: {projectId},
		},
	);
	const [downloadFile, {loading: downloadFileLoading}] = useLazyQuery(
		DOWNLOAD_PROJECT_FILE,
		{
			fetchPolicy: 'network-only',
			onCompleted: data => {
				const {fileId, url} = data.downloadProjectFile;
				setFileIdsLoading(reject(equals(fileId), fileIdsLoading));
				window.open(url, 'blank');
			},
			onError: error => {
				console.log(error);
				snackbar.enqueueSnackbar(error.message.replace('GraphQL error:', ''), {
					variant: 'error',
					anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
				});
			},
		},
	);

	const [addProjectFiles, {loading: addFileLoading}] = useMutation(
		ADD_PROJECT_FILES,
		{
			onCompleted: () => {
				snackbar.enqueueSnackbar('File(s) added & saved succesfully', {
					variant: 'success',
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
				});
			},
			onError: error => {
				console.log(error);
				snackbar.enqueueSnackbar(error.message.replace('GraphQL error:', ''), {
					variant: 'error',
					anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
				});
			},
		},
	);
	const [deleteProjectFiles, {loading: deleteFileLoading}] = useMutation(
		DELETE_PROJECT_FILES,
		{
			onCompleted: data => {
				const fdl = pipe(
					propOr({}, 'project'),
					propOr([], 'files'),
					map(prop('_id')),
					remainingIds => fileIdsDeleting.filter(includes(__, remainingIds)),
				)(data);
				setFileIdsDeleting(fdl);

				snackbar.enqueueSnackbar('File(s) deleted succesfully', {
					variant: 'success',
					anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
				});
			},
			onError: error => {
				console.log(error);
				snackbar.enqueueSnackbar(error.message.replace('GraphQL error:', ''), {
					variant: 'error',
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
				});
			},
		},
	);

	const onFilestackUploadSuccess = ({filesUploaded = []}) => {
		if (!isNilOrEmpty(filesUploaded)) {
			addProjectFiles({
				variables: {
					projectId,
					files: filesUploaded.map(
						pick(['url', 'handle', 'filename', 'mimetype', 'uploadId', 'size']),
					),
				},
			});
		}
	};

	const loading = l1 || l2;
	const error = e1 || e2;

	if (error) {
		return (
			<ErrorStates.TryAgain
				withGoBackButton
				error={error}
				refetch={() => {
					rf1();
					rf2();
				}}
			/>
		);
	}

	const filestackKeys = _filestackKeys || propOr({}, 'getFilestackKeys', d1);
	const {policyb64, signature} = filestackKeys;

	const files = pipe(prop('getProject'), propOr([], 'files'))(d2);

	return (
		<Container maxWidth="lg" className="bg-filled">
			{loading && (
				<Box mt={3} display="flex" justifyContent="center">
					<CircularProgress disableShrink color="primary" />
				</Box>
			)}
			{!loading && (
				<div>
					<Typography variant="h6" color="secondary">
						Add any supplentary files for your project.
					</Typography>
					<Box mt={3}>
						<Grid container spacing={3}>
							{files.map(file => (
								<Grid key={file._id} item xs={12} sm={6} md={4} lg={3}>
									<ItemFile
										projectId={projectId}
										fileIdsLoading={fileIdsLoading}
										setFileIdsLoading={setFileIdsLoading}
										fileIdsDeleting={fileIdsDeleting}
										setFileIdsDeleting={setFileIdsDeleting}
										file={file}
										classes={classes}
										downloadFile={downloadFile}
										downloadFileLoading={downloadFileLoading}
										deleteProjectFiles={deleteProjectFiles}
									/>
								</Grid>
							))}
						</Grid>
						<Box mt={3}>
							<ReactFilestack
								apikey={process.env.REACT_APP_FILESTACK_API_KEY}
								action="pick"
								actionOptions={{
									accept: ['.pdf', 'image/*', '.zip', '.rar', 'text/*'],
									maxFiles: 2,
									maxSize: 1 * 1024 * 1024, // 2 mb total,
								}}
								clientOptions={{
									security: {
										policy: policyb64,
										signature,
									},
								}}
								customRender={({onPick}) => (
									<Button
										disabled={deleteFileLoading}
										style={{pointerEvents: addFileLoading ? 'none' : 'all'}}
										variant="contained"
										color="primary"
										startIcon={<AddFileIcon />}
										onClick={onPick}
									>
										{addFileLoading ? (
											<Box display="flex" justifyContent="center">
												<CircularProgress size={15} color="inherit" />
											</Box>
										) : (
											'Add file'
										)}
									</Button>
								)}
								onSuccess={onFilestackUploadSuccess}
								onError={err => {
									console.log(err);
									snackbar.enqueueSnackbar('Whatver');
								}}
							/>
						</Box>
					</Box>
				</div>
			)}
		</Container>
	);
};

export default Files;
