import React from 'react';
import { Grid, Button, Box } from '@material-ui/core';
import { useMutation } from 'react-apollo';
import { useSnackbar } from 'notistack';
import { Password, Username, FullName, Email } from '../../../common/form/user';
import { isSubmitButtonDisabled } from '../../../../utilities/tools';
import { CREATE_USER } from '../../../../graphql/mutations';
import { useUi } from '../../../hoc';
import useFormikBag from './use-formik-bag';

const CompleteRegistrationForm = ({invitationHash, onAuthenticateSuccess}) => {
	const ui = useUi();
	const snackbar = useSnackbar();
	const [createUser] = useMutation(CREATE_USER);
	const formikProps = useFormikBag({
		ui,
		snackbar,
		createUser,
		invitationHash,
		onAuthenticateSuccess,
	});

	return (
		<form onSubmit={formikProps.handleSubmit}>
			<Grid container spacing={2}>
				{
					invitationHash?
					''
					:
					<Grid item xs={12}>
						<Email enableIsAvailableQuery {...formikProps} />
					</Grid>
				} 
				<Grid item xs={12}>
					<Username enableIsAvailableQuery {...formikProps} />
				</Grid>
				<Grid item xs={12}>
					<FullName {...formikProps} />
				</Grid>
				<Grid item xs={12}>
					<Password {...formikProps} />
				</Grid>
				<Grid item xs={12}>
					<Grid container justifyContent="center">
						<Grid item xs={12} md={6}>
							<Box mt={[1, 1, 3]}>
								<Button
									fullWidth
									id="create-user-form-submit-button"
									type="submit"
									color="primary"
									variant="contained"
									disabled={isSubmitButtonDisabled(formikProps)}
								>
									SUBMIT
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Grid container justifyContent="center">
						<Grid item xs={12} md={6}>
							<Button
								fullWidth
								// id="create-user-form-submit-button"
								color="secondary"
								variant="contained"
								onClick={() => {
									window.location.href='/';
								}}
							>
								Back to Log in
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
};

export default CompleteRegistrationForm;
