import React, { useState } from 'react';
import { propOr, pathOr, propEq, find } from 'ramda';
import { useQuery } from 'react-apollo';
import { createFilterOptions } from '@material-ui/lab';
import {
	InputAdornment,
	Grid,
	Paper,
	Box,
	Chip,
	Typography,
	Button,
	TextField
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { UserIcon } from '../../../../utilities/constants';
import { getReadOnlyProps } from '../../../../utilities/tools';
import { GET_USERS } from '../../../../graphql/queries';
import { Skeletons } from '../../ui';
import { Autocomplete } from '..';
import AddIcon from '@material-ui/icons/Add';
import { useEffect } from 'react';
// import { useReducer } from 'react';
// import { isPostfixUnaryExpression } from 'typescript';


const getUsers = propOr([], 'getUsers');
const strongStyle = {fontWeight: 500};
const italicStyle = {fontStyle: 'italic', marginLeft: '4px', fontWeight: 400};

export const Users = ({isReadOnly, fieldOverrideProps = {}, userData, optional, ...props}) => {
	const {enqueueSnackbar} = useSnackbar();
	const formikName = propOr('users', 'name', fieldOverrideProps);
	const {data, loading} = useQuery(GET_USERS, {
		onError: error => {
			console.error(error);
			enqueueSnackbar("Platform's users could not be loaded.", {
				variant: 'error',
				anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
			});
		},
	});

	const allUsers = getUsers(data);
	const getOptionLabel = option => (
		<span style={strongStyle}>
			{option.username} -<span style={italicStyle}>{option.fullName}</span>
		</span>
	);

	const isCheckboxSelectedFn = option => {
		const values = pathOr([], ['values', formikName], props);
		return Boolean(find(propEq('_id', option._id))(values));
	};

	const filterOptions = createFilterOptions({
		stringify: option => `${option.username} ${option.fullName}`,
	});

	return (
		<Autocomplete
			{...props}
			multiple
			name={formikName}
			label={`Users assigned${optional===false? '' : ' (optional)'}`}
			options={allUsers}
			optionsLoading={loading}
			autoCompleteProps={{
				getOptionLabel,
				filterOptions,
				ChipProps: {
					icon: <UserIcon />,
					color: 'secondary',
				},
			}}
			textFieldProps={{
				fullWidth: true,
				margin: 'none',
				variant: 'outlined',
				...(propOr(true, 'multiple', fieldOverrideProps)
					? {}
					: {
						InputProps: {
							startAdornment: (
								<InputAdornment position="start">
									<UserIcon fontSize="inherit" color="action" />
								</InputAdornment>
							),
						},
					  }),
				...getReadOnlyProps('Autocomplete')(isReadOnly),
			}}
			getCheckboxLabel={getOptionLabel}
			isCheckboxSelectedFn={isCheckboxSelectedFn}
			{...fieldOverrideProps}
		/>
	);
};

const UsersReadOnly = props => {
	const users = pathOr([], ['values', 'users'], props);
	return (
		<Paper elevation={1}>
			<Box p={2}>
				<Grid container alignItems="center" spacing={2}>
					<Grid item>
						<Typography variant="body2">Users assigned:</Typography>
					</Grid>
					<Grid item container xs={10} spacing={1}>
						{users.map(({username}, i) => (
							<Grid key={i} item>
								<Chip label={username} icon={<UserIcon />} color="secondary" />
							</Grid>
						))}
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};

const AssignedUsers = ({
	isProjectCreator,
	setUsersChanged,
	userData,
	...props
}) => {
	const [rerender, setRerender] = useState(0);
	let users = pathOr([], ['values', 'users'], props);
	if (userData && users.length === 0) {
		users.push(userData);
	}
	const deleteUser = (indexToDelete) => {
		users = users.splice(indexToDelete, 1);
		setRerender(rerender +1);
		if (setUsersChanged) setUsersChanged(true);
	}
	return (
		<Paper elevation={1}>
			<Box p={2}>
				<Grid container alignItems="center" spacing={2}>
					<Grid item>
						<Typography variant="body2">Users assigned:</Typography>
					</Grid>
					<Grid item container xs={10} spacing={1}>
						{users.map(({username}, i) => (
							<Grid key={i} item>
								<Chip 
									label={username} 
									icon={<UserIcon />} 
									color="secondary" 
									onDelete={
											isProjectCreator? 
												() => deleteUser(i)
											: 
												null
										}
								/>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};

const InvitedUsers = ({ setUsersChanged, isProjectCreator, ...props }) => {
	let invitedUsers = pathOr([], ['values', 'invitedUsers'], props);
	const [rerender, setRerender] = useState(0);

	const deleteUser = (indexToDelete) => {
		invitedUsers = invitedUsers.splice(indexToDelete, 1);
		setRerender(rerender +1);
		if (setUsersChanged) setUsersChanged(true);
	}

	return (
		<Paper elevation={1}>
			<Box p={2}>
				<Grid container alignItems="center" spacing={2}>
					<Grid item>
						<Typography variant="body2">Invited Users:</Typography>
					</Grid>
					<Grid item container xs={10} spacing={1} alignItems="center">
						{invitedUsers.map((userEmail, i) => (
							<Grid key={i} item>
								<Chip
									label={userEmail}
									icon={<UserIcon />}
									color="secondary"
									onDelete={
										isProjectCreator? 
											() => deleteUser(i)
										: 
											null
									}
								/>
							</Grid>
						))}
						
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};

const InviteMoreUsers = ({ setUsersChanged, isProjectCreator, ...props }) => {
	const [users, setUsers] = useState([]);
	const [newEmail, setNewEmail] = useState('');
	const addUser = () => {
		if (!newEmail) return;
		setUsers([...users, newEmail]);
		setNewEmail('');
		if (setUsersChanged) setUsersChanged(true);
	}

	const deleteUser = (indexToDelete) => {
		setUsers(prev => {
			return prev.filter((_,index) => index !== indexToDelete);
		});
		if (setUsersChanged) setUsersChanged(true);
	}

	useEffect(() => {
		props.setFieldValue('usersToInvite', users);
		// eslint-disable-next-line
	}, [users]);

	return (
		<Paper elevation={1}>
			<Box p={2}>
				<Grid container alignItems="center" spacing={2}>
					<Grid item container xs={10} spacing={1} alignItems="center">
						<Typography variant="body2" style={{marginRight: 12}}>Invite more users:</Typography>
						{users.map((userEmail, i) => (
							<Grid key={i} item>
								<Chip
									label={userEmail}
									icon={<UserIcon />}
									color="secondary"
									onDelete={
										isProjectCreator? 
											() => deleteUser(i)
										: 
											null
									}
								/>
							</Grid>
						))}
						
					</Grid>
				</Grid>
			</Box>
			<Box 
				p={2}
				display="flex"
				alignItems="center"
				justifyContent="start"
			>
				<TextField
					id="new_user_id"
					label="Add user by email"
					value={newEmail}
					onChange={(e) => setNewEmail(e.target.value)}
					variant='outlined'
				/>
				<Box sx={{ ml: 2 }}>
					<Button
						variant='contained'
						onClick={() => {addUser()}}
					>
						<AddIcon />
					</Button>
				</Box>
			</Box>
		</Paper>
	);
};

export default ({ 
	isReadOnly, 
	isInvited, 
	isInviteMore, 
	isAssigned, 
	isProjectCreator, 
	skeletonProps = {}, 
	initLoading, 
	userData, 
	setUsersChanged,
	optional,
	...props 
}) => {
	if (initLoading) {
		return <Skeletons.TextFieldSkeleton {...skeletonProps} />;
	}

	if (isReadOnly) {
		return <UsersReadOnly {...props} setUsersChanged={setUsersChanged} />;
	}

	if (isAssigned) {
		return <AssignedUsers {...props} isProjectCreator={isProjectCreator} setUsersChanged={setUsersChanged} userData={userData} />;
	}

	if (isInvited) {
		return <InvitedUsers {...props} isProjectCreator={isProjectCreator} setUsersChanged={setUsersChanged} />;
	}

	if (isInviteMore && isProjectCreator) {
		return <InviteMoreUsers {...props} isProjectCreator={isProjectCreator} setUsersChanged={setUsersChanged} />;
	} else if (isInviteMore && !isProjectCreator) {
		return <></>
	}

	return <Users {...props} optional={optional} />;
};
