import React, {useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import createPersistedState from 'use-persisted-state';
import {useLazyQuery, useApolloClient} from 'react-apollo';
import {Switch, Route, Redirect} from 'react-router-dom';
import {isNilOrEmpty} from '../../../utilities/tools';
import {LoginScreen, CompleteRegistrationScreen, ForgotPasswordScreen, PinScreen} from '../../screens/public';
import {GET_MY_USER} from '../../../graphql/queries';
import {Loader} from '../../common/ui';

const useTokenState = createPersistedState('useReq:token');

const PublicRoutes = props => (
	<Switch>
		<Route
			exact
			path="/"
			component={renderProps => <LoginScreen {...renderProps} {...props} />}
		/>
		<Route
			exact
			path="/complete-registration"
			component={renderProps => (
				<CompleteRegistrationScreen {...renderProps} {...props} />
			)}
		/>
		<Route
			exact
			path="/forgot"
			component={renderProps => (
				<ForgotPasswordScreen {...renderProps} {...props} />
			)}
		/>
		<Route
			exact
			path="/pin"
			component={renderProps => (
				<PinScreen {...renderProps} {...props} />
			)}
		/>
		<Redirect from="*" to="/" />
	</Switch>
);

export const AuthProvider = ({children}) => {
	const snackbar = useSnackbar();
	const [token, setToken] = useTokenState(null);
	const [loading, setLoading] = useState(true);
	const client = useApolloClient();
	const [getMyUser] = useLazyQuery(GET_MY_USER, {
		onError: () => {
			client.resetStore();
			setToken(null);
			setLoading(false);
			snackbar.enqueueSnackbar('Error loading user', {
				variant: 'error',
				vertical: 'top',
				horizontal: 'center',
			});
		},
		onCompleted: () => setLoading(false),
	});

	useEffect(() => {
		if (isNilOrEmpty(token)) {
			setLoading(false);
		} else {
			getMyUser();
		}
		// eslint-disable-next-line
	},[])

	const onAuthenticateSuccess = ({token, user}) => {
		setToken(token);
		client.writeQuery({
			query: GET_MY_USER,
			data: {
				getMyUser: user,
			},
		});
	};

	if (isNilOrEmpty(token)) {
		return <PublicRoutes onAuthenticateSuccess={onAuthenticateSuccess} />;
	}

	if (loading) {
		return <Loader.FullScreen />;
	}

	return children;
};
