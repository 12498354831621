import React from 'react';
import {Container} from '@material-ui/core';
import {Switch, Route, Redirect, useRouteMatch} from 'react-router-dom';
import {prop} from 'ramda';
import {AppBar, MainWrapper} from '../../../../common/ui';
import {
	Dashboard,
	Requirements,
	Stories,
	Files,
	Swagger,
	Cicd,
	Settings,
} from './tab-screens';
import HeaderArea from './header-area';

const BASE_URL = '/projects/:projectId';
const InnerRoutes = () => {
	const match = useRouteMatch();
	const url = prop('url', match);

	return (
		<Switch>
			<Route exact path={`${BASE_URL}/dashboard`} component={Dashboard} />
			<Route path={`${BASE_URL}/requirements`} component={Requirements} />
			<Route path={`${BASE_URL}/stories`} component={Stories} />
			<Route exact path={`${BASE_URL}/files`} component={Files} />
			<Route exact path={`${BASE_URL}/swagger`} component={Swagger} />
			<Route exact path={`${BASE_URL}/ci-cd`} component={Cicd} />
			<Route exact path={`${BASE_URL}/settings`} component={Settings} />
			<Redirect from="*" to={`${url}/dashboard`} />
		</Switch>
	);
};

const appBarProps = {elevation: 0};

export default () => {
	return (
		<>
			<AppBar appBarProps={appBarProps} />
			<MainWrapper>
				<HeaderArea />
				<Container maxWidth="lg">
					<InnerRoutes />
				</Container>
			</MainWrapper>
		</>
	);
};
