import React, {useState, useRef, useEffect} from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	Box,
	FormControlLabel,
	Checkbox,
	Grid,
} from '@material-ui/core';
import {CloudDownload as ExportProjectIcon} from '@material-ui/icons';
import {propOr, reject, equals, prop, pipe, head} from 'ramda';
import {useQuery, useLazyQuery} from 'react-apollo';
import {useSnackbar} from 'notistack';
import {
	GET_EXPORTABLE_FIELDS,
	EXPORT_PROJECTS,
} from '../../../../graphql/queries';
import {Loader} from '../../ui';
import {Button} from '..';

const FieldsForm = props => {
	const {
		mustHaveFields = [],
		projects,
		ALL_OPTIONS,
		optionsToExport,
		handleChange,
	} = props;

	const title =
		projects.length > 1
			? `Export ${projects.length} Projects`
			: `Export project: ${pipe(head, prop('name'))(projects)}`;

	const subtitle = `Please select which ${
		projects.length > 1 ? 'fields of each project' : 'fields of the project'
	} you want to be included in the exported file:`;

	return (
		<>
			<DialogTitle>
				<Box display="flex" alignItems="center">
					<ExportProjectIcon />
					<Box ml={2}>{title}</Box>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Typography variant="subtitle1">{subtitle}</Typography>
				<Box mt={1} border="1px solid #a1a1a1" padding="8px" borderRadius="6px">
					<Grid container spacing={0}>
						{[...mustHaveFields, ...ALL_OPTIONS.current].map(label => {
							const isMustHaveField = mustHaveFields.includes(label);
							const isChecked =
								isMustHaveField || optionsToExport.includes(label);

							return (
								<Grid key={label} item md={4}>
									<FormControlLabel
										disabled={isMustHaveField}
										control={
											<Checkbox
												checked={isChecked}
												name={label}
												onChange={handleChange}
											/>
										}
										label={label}
									/>
								</Grid>
							);
						})}
					</Grid>
				</Box>
			</DialogContent>
		</>
	);
};

const downloadUrl = url => {
	const a = document.createElement('a');
	a.href = url;
	const fileName = url.split('/').pop();
	a.download = fileName;
	document.body.append(a);
	a.click();
	window.URL.revokeObjectURL(url);
	a.remove();
};

const ExportProjectDialog = ({open, setOpen, projects = []}) => {
	const {enqueueSnackbar} = useSnackbar();
	const ALL_OPTIONS = useRef([]);
	const [optionsToExport, setOptionsToExport] = useState([]);
	const [exportLoading, setExportLoading] = useState(false);
	const {loading} = useQuery(GET_EXPORTABLE_FIELDS, {
		onCompleted: data => {
			const d = propOr([], 'getExportableFields', data);
			ALL_OPTIONS.current = d;
			setOptionsToExport(d);
		},
	});
	const [exportProjects] = useLazyQuery(EXPORT_PROJECTS, {
		fetchPolicy: 'network-only',
		onCompleted: data => {
			setOpen(false);
			enqueueSnackbar(
				'Project exported succesfully and is being downloaded by your browser',
				{
					autoHideDuration: 4000,
					variant: 'success',
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
				},
			);
			downloadUrl(data.exportProjects);
		},
		onError: error => {
			console.log(error);
			setExportLoading(false);
			enqueueSnackbar(error.message.replace('GraphQL error:', ''), {
				variant: 'error',
				anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
			});
		},
	});

	const handleChange = event => {
		if (event.target.checked) {
			setOptionsToExport([...optionsToExport, event.target.name]);
		} else {
			setOptionsToExport(reject(equals(event.target.name), optionsToExport));
		}
	};

	const onClose = () => setOpen(false);

	const onClickExport = () => {
		setExportLoading(true);
		exportProjects({
			variables: {
				projectIds: [...projects.map(prop('_id'))],
				fields: optionsToExport,
			},
		});
	};

	useEffect(() => {
		if (open) {
			setExportLoading(false);
		}
	}, [open]);

	return (
		<Dialog
			fullWidth
			open={open}
			maxWidth="sm"
			//disableBackdropClick={exportLoading}
			disableEscapeKeyDown={exportLoading}
			onClose={onClose}
		>
			{loading && (
				<DialogContent>
					<Loader.WithText />
				</DialogContent>
			)}
			{!loading && (
				<>
					{!exportLoading && (
						<>
							<FieldsForm
								projects={projects}
								optionsToExport={optionsToExport}
								ALL_OPTIONS={ALL_OPTIONS}
								handleChange={handleChange}
								mustHaveFields={['_id', 'name']}
							/>
							<DialogActions>
								<Button disabled={exportLoading} onClick={() => onClose()}>
									Cancel
								</Button>
								<Button
									color="primary"
									disabled={exportLoading}
									onClick={() => onClickExport()}
								>
									{projects.length > 1 ? 'Export Projects' : 'Export Project'}
								</Button>
							</DialogActions>
						</>
					)}
					{exportLoading && (
						<DialogContent>
							<Loader.WithText
								disableShrink
								text={
									projects.length > 1
										? 'Exporting Projects...'
										: 'Exporting Project...'
								}
							/>
						</DialogContent>
					)}
				</>
			)}
		</Dialog>
	);
};

export default ExportProjectDialog;
