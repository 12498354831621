import React from 'react';
import {
	Button,
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	CircularProgress,
	Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDropzone } from 'react-dropzone';
// import SwaggerParser from '@apidevtools/swagger-parser';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
// import { propEq } from 'ramda';
// import { toJSONorYAML } from '../../../../../../../utilities/tools';

const useStyles = makeStyles(theme => ({
	dropzone: {
		cursor: 'pointer',
		flex: '1',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: '2px',
		borderRadius: '2px',
		borderColor: theme.palette.primary.main,
		borderStyle: 'dashed',
		backgroundColor: '#fafafa',
		color: theme.palette.primary.main,
		outline: 'none',
		transition: 'border .24s ease-in-out',

		'&.disabled': {
			opacity: 0.6,
		},
	},
}));

const UploadCicdDialog = ({
	open, 
	setOpen, 
	afterFileAccepted,
	editorValue,
	setEditorValue,
}) => {
	const classes = useStyles();
	const snackbar = useSnackbar();
	const validating = false;

	const dropzoneProps = useDropzone({
		accept: ['.yaml', '.yml'],
		maxSize: 100000, // 100 kilo bytes
		multiple: false,
		onDropAccepted: async files => {
			try {
				const file = files[0];
				if (file.name.split('.')[file.name.split('.').length - 1] !== 'yml' && file.name.split('.')[file.name.split('.').length - 1] !== 'yaml') {
					throw new Error('Wrong file type selected!')
				}
				const reader = new FileReader();
				reader.addEventListener('load', async function(e) {
					const contents = e.target.result;
					console.log(contents);
					setEditorValue(contents);
				});
				reader.readAsText(file);
				setOpen(false);
			} catch (e) {
				console.log(e);
				snackbar.enqueueSnackbar(e.message, {
					variant: 'error',
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
					autoHideDuration: 6000,
				});
			}
		},
		onDropRejected: () => {
			snackbar.enqueueSnackbar(
				'You must upload a single .yml or .yaml CI/CD file no more than 100 KB',
				{
					variant: 'error',
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
					autoHideDuration: 6000,
				},
			);
		},
	});

	const { getRootProps, getInputProps } = dropzoneProps;

	const onClose = () => setOpen(false);

	return (
		<Dialog
			fullWidth
			disableEscapeKeyDown={validating}
			//disableBackdropClick={validating}
			open={open}
			maxWidth="sm"
			onClose={onClose}
		>
			<DialogTitle>Upload YAML File</DialogTitle>
			<DialogContent>
				{validating && (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
					>
						<CircularProgress />
						<br />
						<Typography>Validating CI/CD Specs...</Typography>
					</Box>
				)}
				{!validating && (
					<div>
						<Box mb={4}>
							<Alert variant="standard" severity="info">
								Upload a single valid CI/CD YAML file no more than 100KB.
							</Alert>
						</Box>
						<section className="container">
							<div {...getRootProps({className: classes.dropzone})}>
								<input {...getInputProps()} />
								<p>
									Drag &apos;n&apos; drop a file here, or click to select file
								</p>
							</div>
						</section>
					</div>
				)}
			</DialogContent>
			<DialogActions>
				<Button disabled={validating} onClick={onClose}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default UploadCicdDialog;
