import gql from 'graphql-tag';

export default gql`
	mutation DeleteProjectFiles($projectId: String!, $filesIds: [String!]!) {
		deleteProjectFiles(projectId: $projectId, filesIds: $filesIds) {
			_id
			files {
				_id
				url
				handle
				filename
				mimetype
				createdAt
				size
			}
		}
	}
`;
