import React, { Suspense } from 'react';
import createPersistedState from 'use-persisted-state';
import {Box, CircularProgress, IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {BrightnessMedium as ThemeIcon} from '@material-ui/icons';
import clsx from 'clsx';

import AceEditor from 'react-ace';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';

// const AceCodeEditor = lazy(() => import('./ace-code-editor'));

const useDarkThemeState = createPersistedState('useReq:codeEditorDarkTheme');

const useStyles = makeStyles(() => ({
	container: {
		position: 'relative',
		border: '1px solid #c4c4c4',
		borderRadius: '4px',
		maxHeight: '640px',
		overflowY: 'auto',

		'&.disabled': {
			opacity: 0.7,
			pointerEvents: 'none',
		},
	},
	themeButton: {
		float: 'right',
	},
}));

const ThemeButton = ({darkTheme, setDarkTheme, classes}) => (
	<IconButton
		size="small"
		className={classes.themeButton}
		onClick={() => setDarkTheme(!darkTheme)}
	>
		<ThemeIcon fontSize="inherit" />
	</IconButton>
);

const SuspenseFallback = ({darkTheme}) => (
	<Box
		height="640px"
		display="flex"
		alignItems="center"
		justifyContent="center"
		flexDirection="column"
		bgcolor={darkTheme ? '#272822' : '#fff'}
	>
		<Box flex={0.25}>
			<CircularProgress />
		</Box>
	</Box>
);

const SwaggerEditor = props => {
	const {
		viewMode,
		initLoading,
		swagger = '',
		setSwagger,
		setCodeAnnotations,
		disabled,
	} = props;
	const [darkTheme, setDarkTheme] = useDarkThemeState(true);
	const classes = useStyles();

	if (initLoading)
		return (
			<div className={classes.container}>
				<SuspenseFallback darkTheme={darkTheme} />
			</div>
		);

	return (
		<div>
			<div className={clsx(classes.container, disabled && 'disabled')}>
				<Suspense fallback={<SuspenseFallback darkTheme={darkTheme} />}>
				<AceEditor
					showPrintMargin
					showGutter
					highlightActiveLine
					width="100%"
					maxLines={Infinity}
					minLines={40}
					placeholder={`Type your specification here in ${viewMode.toUpperCase()}...`}
					mode={viewMode}
					theme={darkTheme ? 'monokai' : 'chrome'}
					name={viewMode}
					fontSize={16}
					value={swagger}
					debounceChangePeriod={200}
					setOptions={{
						showPrintMargin: false,
						enableBasicAutocompletion: true,
						enableLiveAutocompletion: true,
						enableSnippets: false,
						showLineNumbers: true,
						tabSize: 4,
					}}
					onChange={value => setSwagger(value)}
					onValidate={annotations => setCodeAnnotations(annotations)}
				/>
				</Suspense>
			</div>
			<ThemeButton
				darkTheme={darkTheme}
				setDarkTheme={setDarkTheme}
				classes={classes}
			/>
		</div>
	);
};

export default SwaggerEditor;
