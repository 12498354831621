import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Container, Typography, Box } from '@material-ui/core';
import { MainWrapper } from '../common/ui';

import { CreateProjectIcon } from '../../utilities/constants';
import CreateProjectForm from '../screens/private/project/create-project-screen/create-project-form'; 

export default function AlertDialog({
    open, 
    setOpen,
    userData
}) {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle id="alert-dialog-title">{"New Project"}</DialogTitle>
            <DialogContent>
                <MainWrapper>
                    <Container maxWidth="md" className="bg-filled">
                        <Typography
                            variant="h5"
                            color="secondary"
                            style={{display: 'flex', alignItems: 'center'}}
                        >
                            <CreateProjectIcon style={{marginRight: '8px'}} /> Create Project
                        </Typography>
                        <Typography variant="subtitle1" color="secondary">
                            Please fill the form below in order to create a new project:
                        </Typography>
                        <Box mt={2}>
                            <CreateProjectForm userData={userData} setOpenCreateProjectModal={setOpen} />
                        </Box>
                    </Container>
                </MainWrapper>
            </DialogContent>
            <DialogActions>
            
            <Button onClick={() => handleClose()} color="primary" autoFocus>
                close
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}