import React from 'react';
import {Typography, Grid, IconButton} from '@material-ui/core';
import {Edit, Close} from '@material-ui/icons';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
	editIcon: {
		fontSize: 16,
	},
	mb: {
		marginBottom: 24,
	},
}));

const EditableTypography = ({
	TextFieldComponent,
	textFieldProps,
	onCancelEdit,
	isEdit,
	setIsEdit,
	...typographyProps
}) => {
	const classes = useStyles();

	if (isEdit) {
		return (
			<Grid container justifyContent="flex-end" className={clsx(isEdit && classes.mb)}>
				<Grid item>
					<IconButton
						color="default"
						size="small"
						onClick={() => {
							setIsEdit(false);
							if (onCancelEdit) {
								onCancelEdit();
							}
						}}
					>
						<Close />
					</IconButton>
				</Grid>
				<Grid item xs={12}>
					<TextFieldComponent {...textFieldProps} />
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid container alignItems="center" spacing={1}>
			<Grid item>
				<Typography {...typographyProps} />
			</Grid>
			<Grid item>
				<IconButton size="small" onClick={() => setIsEdit(true)}>
					<Edit className={classes.editIcon} />
				</IconButton>
			</Grid>
		</Grid>
	);
};

export default EditableTypography;
