import React, {useState, createContext, useContext} from 'react';

export const UiContext = createContext();

export const useUi = () => useContext(UiContext);

export const UiProvider = ({children}) => {
	const [drawerMobileOpen, setDrawerMobileOpen] = useState(false);
	const [appProgressShown, setAppProgressShown] = useState(false);

	return (
		<UiContext.Provider
			value={{
				drawerMobileOpen,
				setDrawerMobileOpen,
				appProgressShown,
				setAppProgressShown,
			}}
		>
			<div style={{display: 'flex', minHeight: '100vh', height: '100%'}}>{children}</div>
		</UiContext.Provider>
	);
};
