import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { REQUEST_TO_BECOME_TEAMLEAD } from '../../graphql/queries/';
import { useSnackbar } from 'notistack';
import { useLazyQuery } from 'react-apollo';
import { useUi } from '../hoc';

export default function AlertDialog({
    open, 
    setOpen,
    userData
}) {
    const ui = useUi();
	const snackbar = useSnackbar();

    const [loading, setLoading] = useState(false);

    const [executeRequestToBecomeTeamlead] = useLazyQuery(
		REQUEST_TO_BECOME_TEAMLEAD,
		{
			onCompleted: data => {
                console.log(data);
                setLoading(false);
                ui.setAppProgressShown(false);
                if (data.requestToBecomeTeamLead === true) {
                    setOpen(false);
                    snackbar.enqueueSnackbar('An email was sent!', {
                        variant: 'success',
                        anchorOrigin: {vertical: 'top', horizontal: 'center'},
                    });
                } else {
                    console.log('something went wrong');
                    snackbar.enqueueSnackbar('Something went wrong!', {
                        variant: 'error',
                        anchorOrigin: {vertical: 'top', horizontal: 'center'},
                    });
                }
			},
		},
	);  

    const handleClose = () => {
        setOpen(false);
    };

    const sendRequest = () => {
        console.log('sending email to page admin')
        setLoading(true);
        ui.setAppProgressShown(true);
        executeRequestToBecomeTeamlead({
            variables: {
                _id: userData._id,
                email: userData.email,
                username: userData.username,
                role: userData.role
            },
        });
    }

    return (
        <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Become a Team Lead"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                By becoming a team lead you will be able to create and manage a new project.
                You will also be able to invite other users to collaborate on the same project.
                <br />
                By pressing the button below an email will be sent to the server admin with your request.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button variant='contained' onClick={() => sendRequest()} color="primary" disabled={loading}>
                Send request
            </Button>
            <Button onClick={() => handleClose()} color="primary" autoFocus disabled={loading}>
                close
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}