import React, {lazy, Suspense, useCallback} from 'react';
import {makeStyles} from '@material-ui/styles';
import {BrightnessMedium as ThemeIcon} from '@material-ui/icons';
import {IconButton, Box, CircularProgress, Typography} from '@material-ui/core';
import createPersistedState from 'use-persisted-state';
import {pathOr, propEq} from 'ramda';

const useDarkThemeState = createPersistedState('useReq:codeEditorDarkTheme');

const AceCodeEditor = lazy(() => import('./ace-code-editor'));

const SuspenseFallback = ({darkTheme}) => (
	<Box
		height="640px"
		display="flex"
		alignItems="center"
		justifyContent="center"
		flexDirection="column"
		bgcolor={darkTheme ? '#272822' : '#fff'}
	>
		<Box flex={0.25}>
			<CircularProgress />
		</Box>
	</Box>
);

const useStyles = makeStyles(() => ({
	container: {
		position: 'relative',
		border: '1px solid #c4c4c4',
		borderRadius: '4px',
		maxHeight: '640px',
		overflowY: 'auto',

		'& .text-error': {
			fontWeight: 'bold',
		},
	},
	themeButton: {
		float: 'right',
	},
}));

const ThemeButton = ({darkTheme, setDarkTheme, classes}) => (
	<IconButton
		size="small"
		className={classes.themeButton}
		onClick={() => setDarkTheme(!darkTheme)}
	>
		<ThemeIcon fontSize="inherit" />
	</IconButton>
);

const StoryDialogEditor = props => {
	const {
		setFieldValue,
		initLoading,
		codeAnnotations,
		setCodeAnnotations,
	} = props;
	const [darkTheme, setDarkTheme] = useDarkThemeState(true);
	const classes = useStyles();

	const value = pathOr('', ['values', 'text'], props);
	const setValue = useCallback(
		value => {
			setFieldValue('text', value);
		},
		[setFieldValue],
	);

	const hasCodeError =
		(value && value.trim() === '') ||
		(codeAnnotations && codeAnnotations.some(propEq('type', 'error')));

	if (initLoading)
		return (
			<div className={classes.container}>
				<SuspenseFallback darkTheme={darkTheme} />
			</div>
		);

	return (
		<div>
			<div className={classes.container}>
				<Suspense fallback={<SuspenseFallback darkTheme={darkTheme} />}>
					<AceCodeEditor
						value={value}
						setValue={setValue}
						darkTheme={darkTheme}
						setCodeAnnotations={setCodeAnnotations}
					/>
				</Suspense>
			</div>
			{hasCodeError && (
				<Box fontWeight="bold">
					{p => (
						<Typography color="error" variant="body2" {...p}>
							{value && value.trim() === ''
								? 'You must provide Gherkin code.'
								: 'Your code has errors.'}
						</Typography>
					)}
				</Box>
			)}
			<ThemeButton
				darkTheme={darkTheme}
				setDarkTheme={setDarkTheme}
				classes={classes}
			/>
		</div>
	);
};

export default StoryDialogEditor;
