import {Box} from '@material-ui/core';
import React from 'react';
import Breadcrumbs from './breadcrumbs';
import Tabs from './tabs';

export default () => {
	return (
		<Box
			pt={2}
			px={[1, 9]}
			mx={[0, -3]}
			mt={-3}
			mb={3}
			bgcolor="#fff"
			boxShadow="inset 0 -1px 0 #e1e4e8"
		>
			<Breadcrumbs />
			<Tabs />
		</Box>
	);
};
