import React from 'react';
import {
	AppBar as AppBarCore,
	Toolbar,
	IconButton,
	Grid,
	Container,
	Button,
	Hidden,
	Link,
} from '@material-ui/core';
import {useQuery} from 'react-apollo';
import {
	Menu as MenuIcon,
	AccountCircle as MyProfileIcon,
	ExitToApp as LogoutIcon,
	Feedback as FeedbackIcon,
} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import {pathEq, pathOr} from 'ramda';
import clsx from 'clsx';
import {Link as RouterLink} from 'react-router-dom';
import {DRAWER_WIDTH_PX} from '../../../../utilities/constants';
import {useUi} from '../../../hoc';
import {GET_MY_USER} from '../../../../graphql/queries';
import {AlertActionDialog} from '../../control';
import logoWhite from '../../../../assets/logo_white.png';

const {REACT_APP_GOOGLE_FORMS_FEEDBACK_URL} = process.env;

const useStyles = makeStyles(theme => ({
	appBar: {
		marginLeft: DRAWER_WIDTH_PX,
		[theme.breakpoints.up('md')]: {
			width: `calc(100% - ${DRAWER_WIDTH_PX}px)`,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	appBarContainer: {
		backgroundColor: theme.palette.secondary.main,
		'& .action-button': {
			textTransform: 'none',
			color: '#fff',
			fontWeight: 'bold',
		},
		'& .action-button.fake': {
			pointerEvents: 'none',
			color: theme.palette.primary.main,
		},
	},
	logo: {
		width: '85px',
		[theme.breakpoints.up('sm')]: {
			width: '130px',
		},
	},
}));

const ActionButton = ({fake, icon, text, onClick, ...props}) => (
	<>
		<Hidden smUp>
			{!fake && (
				<IconButton className="action-button" {...props} onClick={onClick}>
					{icon}
				</IconButton>
			)}
		</Hidden>
		<Hidden xsDown>
			<Button
				{...props}
				className={clsx('action-button', fake && 'fake')}
				size="large"
				startIcon={icon}
				onClick={onClick}
			>
				{text}
			</Button>
		</Hidden>
	</>
);

const AppBar = props => {
	const {data, client} = useQuery(GET_MY_USER, {fetchPolicy: 'cache-only'});
	const isAdmin = pathEq(['getMyUser', 'role'], 'Admin', data);
	const {gridContainerProps, appBarProps = {}} = props;
	const classes = useStyles();
	const {drawerMobileOpen, setDrawerMobileOpen} = useUi();

	const handleDrawerToggle = () => {
		setDrawerMobileOpen(!drawerMobileOpen);
	};

	const logout = () => {
		localStorage.removeItem('useReq:token');
		client.cache.reset();
		window.location.reload();
	};

	return (
		<AppBarCore
			elevation={1}
			position="fixed"
			className={clsx(classes.appBarContainer, isAdmin && classes.appBar)}
			{...appBarProps}
		>
			<Toolbar>
				<Container maxWidth="xl">
					<Grid
						container
						spacing={2}
						alignItems="center"
						justifyContent="flex-start"
						{...gridContainerProps}
					>
						{!isAdmin && (
							<Grid item md={4} lg={2}>
								<Link to="/" component={RouterLink} underline="none">
									<img
										alt="usereq-logo"
										src={logoWhite}
										className={classes.logo}
									/>
								</Link>
							</Grid>
						)}
						{isAdmin && (
							<Hidden mdUp>
								<Grid item xs={1}>
									<IconButton
										color="inherit"
										aria-label="open drawer"
										edge="start"
										className={classes.menuButton}
										onClick={handleDrawerToggle}
									>
										<MenuIcon />
									</IconButton>
								</Grid>
							</Hidden>
						)}
						{props.children && <Grid item>{props.children}</Grid>}
						<Grid item xs>
							<Grid
								container
								spacing={2}
								alignItems="center"
								justifyContent="flex-end"
							>
								<Grid item>
									<ActionButton
										fake
										text={pathOr('', ['getMyUser', 'username'], data) + " (" + pathOr('', ['getMyUser', 'role'], data) + ")"}
										icon={<MyProfileIcon />}
									/>
								</Grid>
								{REACT_APP_GOOGLE_FORMS_FEEDBACK_URL && (
									<Grid item>
										<ActionButton
											component={Link}
											href={REACT_APP_GOOGLE_FORMS_FEEDBACK_URL}
											target="_blank"
											text="Feedback"
											underline="none"
											icon={<FeedbackIcon />}
										/>
									</Grid>
								)}
								<Grid item>
									<AlertActionDialog
										canEscape
										title="Logout"
										text="Are you sure you want to logout?"
										actions={[
											{text: 'Back', buttonProps: {color: 'default'}},
											{
												text: 'Logout',
												buttonProps: {color: 'danger'},
												onClick: () => logout(),
											},
										]}
									>
										{({handleOpen}) => (
											<ActionButton
												text="Logout"
												icon={<LogoutIcon />}
												onClick={() => handleOpen()}
											/>
										)}
									</AlertActionDialog>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Toolbar>
		</AppBarCore>
	);
};

export default AppBar;
