import gql from 'graphql-tag';

export default gql`
	query GetInvitedProjectsOfUser {
		getInvitedProjectsOfUser {
			_id
			createdAt
			name
			tags
			users {
                _id
                username
                email
            }
		}
	}
`;