import React, {useState, useEffect} from 'react';
import {TextField, InputAdornment} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {Search as SearchIcon} from '@material-ui/icons';
import {nullizeString} from '../../../../utilities/tools';

const useStyles = makeStyles(() => ({
	root: {
		fontWeight: '400!important',
	},
	inputRoot: {
		backgroundColor: '#fff',
	},
}));

const TableColumnFilterText = ({column: {filterValue, setFilter}}) => {
	const classes = useStyles();
	const [internalState, setInternalState] = useState(filterValue);

	const handleSetFilter = () => {
		const value = nullizeString(internalState);
		if (nullizeString(filterValue) !== value) {
			setFilter(nullizeString(value));
		}
	};

	// To reset internal state
	useEffect(() => {
		if (filterValue === null || filterValue === undefined) {
			setInternalState(null);
		}
	}, [filterValue]);

	const handleChange = event => {
		setInternalState(event.target.value);
	};

	return (
		<TextField
			fullWidth
			classes={{
				root: classes.root,
			}}
			value={internalState || ''}
			variant="outlined"
			margin="dense"
			InputProps={{
				classes: {
					root: classes.inputRoot,
				},
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon fontSize="small" color="action" />
					</InputAdornment>
				),
			}}
			onChange={handleChange}
			onKeyUp={e => {
				if (e.key === 'Enter') {
					handleSetFilter();
				}
			}}
			onBlur={handleSetFilter}
		/>
	);
};

export default TableColumnFilterText;
