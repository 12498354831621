import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { PROJECT_INVITATION_RESULT } from '../../graphql/mutations';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-apollo';
import { useUi } from '../hoc';

export default function AlertDialog({
    open, 
    setOpen,
    data,
}) {
    const ui = useUi();
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [projectInvitationResult] = useMutation(PROJECT_INVITATION_RESULT);

    const handleClose = () => {
        setOpen(false);
    };

    const sendRequest = async () => {
        console.log('Sending result to server')
        setLoading(true);
        ui.setAppProgressShown(true);

        try {
            const result = await projectInvitationResult({
                    variables: {
                        projectId: data._id,
                        accepted: data.result
                    },
                });
                // console.log(result.data);
                if (result.data) {
                    setLoading(false);
                    ui.setAppProgressShown(false);
                    setOpen(false);
                } else {
                    enqueueSnackbar("Something went wrong while sending invitation result.", {
                        variant: 'error',
                        anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
                    });
                    setOpen(false);
                }
        } catch (e) {
            console.log(e)
            setLoading(false);
            ui.setAppProgressShown(false);
            enqueueSnackbar("Something went wrong while sending invitation result.", {
				variant: 'error',
				anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
			});
            setOpen(false);
        }
    }

    return (
        <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{data?.result? 'Accept' : 'Decline'} Invitation</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {
                    data?.result? 
                    <>
                        By accepting this invitation you will be added to the <b>{data?.name}</b> project. 
                    </>
                    :
                    <>
                        By declining this invitation you will not be able to join the <b>{data?.name}</b> project. 
                    </>
                }
                &nbsp;Are you sure you want to continue?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button variant='contained' onClick={() => sendRequest()} color="primary" disabled={loading}>
                {data?.result? 'Accept' : 'Decline'}
            </Button>
            <Button onClick={() => handleClose()} color="primary" autoFocus disabled={loading}>
                close
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}