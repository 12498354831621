import React, { useEffect, useState, useMemo } from 'react';
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { isNil, path, pathEq, pathOr, pipe, propEq, find } from 'ramda';
import { useApolloClient, useMutation, useQuery } from 'react-apollo';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
	isMongoId,
	isSubmitButtonDisabled,
	nullizeString,
} from '../../../../../../../../utilities/tools';
import {
	DrawerContainer2,
	UPDATE_PROJECT_STORY,
	useFormikBag,
	Form,
} from '../common';
import CodeEditor from '../code-editor';
import { GET_MY_USER, GET_PROJECT_INTEGRATIONS } from '../../../../../../../../graphql/queries';
import { UPDATE_PROJECT_INTEGRATIONS } from '../../../../../../../../graphql/mutations';

const useStyles = makeStyles(theme => ({
	submitButton: {
		width: 200,
	},
	backdrop: {
		position: 'absolute',
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const StoryCreate = ({
	openCreate,
	setOpenCreate
}) => {
	
	const classes = useStyles();
	const match = useRouteMatch();
	const history = useHistory();
	const snackbar = useSnackbar();
	const apolloClient = useApolloClient();
	const [codeAnnotations, setCodeAnnotations] = useState([]);
	const {data: getMyUserData} = useQuery(GET_MY_USER, {
		fetchPolicy: 'cache-only',
	});

	const [sendingDataToCyclopt, setSendingDataToCyclopt] = useState(false);
	const amIAdmin = pathEq(['getMyUser', 'role'], 'Admin', getMyUserData);

	const projectId = path(['params', 'projectId'], match);

	const { data: integrationData, refetch: refetchProjectIntegrations } = useQuery(GET_PROJECT_INTEGRATIONS, { variables: { projectId } });

	const [updateProjectIntegrationsLinks, {loading: loadingIntegrations}] = useMutation(UPDATE_PROJECT_INTEGRATIONS);

	const cycloptIntegration = useMemo(
		() =>
			pipe(
				pathOr([], ['getProject', 'integrations']),
				find(propEq('name', 'cyclopt'))
			)(integrationData),
		[integrationData],
	);

	const [updateProject, {loading: updateProjectLoading}] = useMutation(UPDATE_PROJECT_STORY);

	useEffect(() => {
		// console.log("Story Creation mounted!");
		refetchProjectIntegrations();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const formikProps = useFormikBag({
		updateProject,
		projectId,
		mode: 'create',
		apolloClient,
		snackbar,
		amIAdmin,
		cycloptIntegration,
		updateProjectIntegrationsLinks,
		setSendingDataToCyclopt,
		setOpenCreate,
		refetchProjectIntegrations
	});
	const {dirty, submitCount} = formikProps;

	useEffect(() => {
		if (!isMongoId(projectId)) {
			history.replace(`/projects`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectId]);

	const hasCodeError =
		pipe(path(['values', 'text']), nullizeString, isNil)(formikProps) ||
		(codeAnnotations && codeAnnotations.some(propEq('type', 'error')));
	const submitDisabled =
		updateProjectLoading || isSubmitButtonDisabled(formikProps) || hasCodeError;

	return (
		<DrawerContainer2
			Header={<Typography variant="h5">Add Story</Typography>}
			open={openCreate}
			setOpen={setOpenCreate}
			dirty={dirty}
			submitCount={submitCount}
			formikProps={formikProps}
		>
			<Backdrop
				open={updateProjectLoading || formikProps.isSubmitting || loadingIntegrations || sendingDataToCyclopt}
				className={classes.backdrop}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Container maxWidth="lg">
				<Box 
					display="flex"
					flexDirection="row"
				>
					<Box sx={{ mt: 4}} width="35%">

						<Box item xs={11} sm={4}>
							<Form
								mode="create"
								projectId={projectId}
								amIAdmin={amIAdmin}
								{...formikProps}
							/>
						</Box>
						<Box sx={{ mt: 6 }}>
							<Button
								className={classes.submitButton}
								color="primary"
								variant="contained"
								disabled={submitDisabled}
								onClick={() => formikProps.handleSubmit()}
							>
								Submit
							</Button>
						</Box>
					</Box>
					
					<Box width="65%" sx={{ mt: 4}}>
						<CodeEditor
							codeAnnotations={codeAnnotations}
							setCodeAnnotations={setCodeAnnotations}
							{...formikProps}
						/>
					</Box>
				</Box>
			</Container>
		</DrawerContainer2>
	);
};

export default StoryCreate;
