import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
	Select as MaterialSelect,
	FormControl,
	InputLabel,
	MenuItem,
    Box,
    OutlinedInput
} from '@material-ui/core';
import {USER_ROLES} from '../../utilities/constants';

export default function EditUserModal({
    open, 
    setOpen,
    userData,
    editUser
}) {

    const [role, setRole] = useState('');

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        await editUser({
            variables: {userId: userData._id, newUser: {role: role}},
        });
        setOpen(false);
    }

    useEffect(() => {
        if (open) {
            // console.log(userData)
            setRole(userData.role);
        }
    }, [userData, open])

    return (
        <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'md'}
        >
            <DialogTitle id="alert-dialog-title">{`Edit user "${userData.username}"`}</DialogTitle>
            <DialogContent>
                <Box sx={{width: '500px'}}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                        margin="normal"
                    >
                        <InputLabel id='Role-input-label'>
                            {'Role'}
                        </InputLabel>
                        <MaterialSelect
                            id='role-input-select'
                            labelId='Role-input-label'
                            value={role}
                            // labelWidth={0}
                            onChange={(e) => {
                                console.log(e.target.value)
                                setRole(e.target.value);
                            }}
                            input={<OutlinedInput label="Role" />}
                        >
                            {USER_ROLES.map(({label, value, description}) => (
                                <MenuItem key={value} value={value}>
                                    {
                                        label + "   (" + description + ")"
                                    }
                                </MenuItem>
                            ))}
                        </MaterialSelect>
                    </FormControl>
                </Box>
                
            </DialogContent>
            <DialogActions>
            <Button onClick={() => handleClose()}>
                Back
            </Button>
            <Button variant='contained' onClick={() => handleSubmit()} color="primary">
                Save
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}