import gql from 'graphql-tag';

export default gql`
	mutation MakeUserTeamLead($userId: String!, $result: String!) {
		makeUserTeamLead(userId: $userId, result: $result) {
			_id
			email
			username
			role
			fullName
			projects {
				_id
			}
			createdAt
		}
	}
`;