import gql from 'graphql-tag';

export default gql`
	mutation CreateInvitedUser($data: CreateInvitedUserInput!) {
		createInvitedUser(data: $data) {
			_id
			email
			role
			invitationHash
			createdAt
			updatedAt
		}
	}
`;
