import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	TextField,
	Select,
	MenuItem,
	OutlinedInput,
	InputLabel,
	FormControl,
	Typography,
	IconButton
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useMutation, useQuery } from 'react-apollo';
import { find, ifElse, isNil, pathOr, pipe, propEq, propOr } from 'ramda';
import { useSnackbar } from 'notistack';
import { UPDATE_PROJECT_INTEGRATIONS } from '../../../../../../../graphql/mutations';
import { isNilOrEmpty } from '../../../../../../../utilities/tools';
import { GET_PROJECT_INTEGRATIONS, GET_PROJECT_STORIES } from '../../../../../../../graphql/queries';
import { Skeletons } from '../../../../../../common/ui';
import { getUserProjects, populateProjectWithTasks, deleteTasksFromProject } from '../../../../../../../utilities/cycloptAPI';
import RemoveCycloptIntegration from '../../../../../../modals/remove-cyclopt-integration-modal';


const Integrations = ({
	projectId,
}) => {
	const { enqueueSnackbar } = useSnackbar();
	const [cycloptApiKey, setCycloptApiKey] = useState('');
	const [loadingPage, setLoadingPage] = useState(false);
	const [projects, setProjects] = useState([]);
	const [selectedProjectId, setSelectedProjectId] = useState('');
	const [openRemoveIntegrationModal, setOpenRemoveIntegrationModal] = useState(false);
	const [progressComment, setProgressComment] = useState('');

	const {data, loading: l1, error: getError, refetch: refetchProjectIntegrations} = useQuery(GET_PROJECT_INTEGRATIONS, { variables: { projectId } });
	const {data: storiesData} = useQuery(GET_PROJECT_STORIES, { variables: { projectId } });

	const [updateProjectIntegrations, {data: updateData, loading: l2, error: updateError}] = useMutation(UPDATE_PROJECT_INTEGRATIONS);

	const initialCycloptApiKey = useMemo(
		() =>
			pipe(
				pathOr([], ['getProject', 'integrations']),
				find(propEq('name', 'cyclopt')),
				ifElse(isNil, () => '', propOr('', 'apiKey')),
			)(data),
		[data],
	);
	const initialCycloptProjectId = useMemo(
		() =>
			pipe(
				pathOr([], ['getProject', 'integrations']),
				find(propEq('name', 'cyclopt')),
				ifElse(isNil, () => '', propOr('', 'projectId')),
			)(data),
	[data]);

	const projectStories = useMemo(() =>
		pipe(pathOr([], ['getProject', 'stories']))(storiesData),
	[storiesData]);

	const initialTaskLinks = useMemo(() =>
		pipe(pathOr([], ['getProject', 'integrations']), find(propEq('name', 'cyclopt')), ifElse(isNil, () => [], propOr([], 'links')),)(data),
	[data]);

	useEffect(() => {
		// console.log("Integrations mounted");
		refetchProjectIntegrations();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isNilOrEmpty(initialCycloptApiKey)) return;
		setCycloptApiKey(initialCycloptApiKey);
		getProjects(initialCycloptApiKey);
	}, [initialCycloptApiKey]);

	useEffect(() => {
		if (isNilOrEmpty(initialCycloptProjectId)) return;
		setSelectedProjectId(initialCycloptProjectId);
	}, [initialCycloptProjectId]);

	const getProjects = async (key) => {
		if (key !== '') {
			let res = await getUserProjects(key);
			if (res.status === 200) {
				// console.log("Cyclopt projects:", res.data)
				setProjects(res.data.projects);
			}
		} else {
			setSelectedProjectId('');
			setProjects([]);
		}
	}

	useEffect(() => {
		if (getError)
			showError('Project integrations could not be loaded!');
		else if (updateError) 
			showError('Project integration could not be updated!');
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getError, updateError, enqueueSnackbar]);

	function showError(errorMessage) {
		enqueueSnackbar(errorMessage, {
			variant: 'error',
			autoHideDuration: 4000,
			anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
		});
	}

	function showSuccess(successMessage) {
		enqueueSnackbar(successMessage, {
			variant: 'success',
			autoHideDuration: 4000,
			anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
		});
	}

	useEffect(() => {
		if (updateData)
			enqueueSnackbar('Project integration updated succesfully!', {
				variant: 'success',
				anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
			});
	}, [updateData, enqueueSnackbar]);

	const checkApiKey = async () => {
		setLoadingPage(true);
		console.log(cycloptApiKey);
		if (cycloptApiKey) {
			let res = await getUserProjects(cycloptApiKey);
			if (res.status === 200) {
				let projects = res.data.projects;
				setProjects(projects);
				showSuccess('Cyclopt API token is valid!');
			} else {
				showError('Invalid API token');
			}
		} else {
			showError('Please provide an API token');
		}
		setLoadingPage(false);
	}

	const submit = useCallback(async () => {
		setLoadingPage(true);
		// console.log(cycloptApiKey);
		if (cycloptApiKey) {
			try {
				let res = await getUserProjects(cycloptApiKey);
				if (res.status !== 200) throw new Error ("Invalid API key");
				console.log(projectStories);
				// return;
				let tasks = projectStories.map(story => {
					return {
						_id: story._id,
						title: `S-${story.seq} ${story.title}`,
						body: story.text? story.text : ''
					}
				});

				// delete old tasks if exit from the old project selected
				let tasksIds = initialTaskLinks.map(task => {
					return task.integrationId;
				});

				setProgressComment('Deleting tasks from old project...');
				res = await deleteTasksFromProject(cycloptApiKey, initialCycloptProjectId, tasksIds);

				// add new tasks to the new project selected
				res = await populateProjectWithTasks(cycloptApiKey, selectedProjectId, tasks, setProgressComment);
				if (res.status !== 200) throw new Error ("Error while linking tasks");

				let taskLinks = res.message;
				await updateProjectIntegrations({
					variables: {
						projectId,
						data: {integrations: [{name: 'cyclopt', apiKey: cycloptApiKey, projectId: selectedProjectId, links: taskLinks}]},
					},
				});

				refetchProjectIntegrations();
				showSuccess('Cyclopt project updated!');
				setProgressComment('');
			} catch (e) {
				showError(e);
			}
		}
		setLoadingPage(false);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cycloptApiKey, selectedProjectId, projectId, updateProjectIntegrations]);

	const deleteIntegration = () => {
		// console.log("clocked delete!");
		setOpenRemoveIntegrationModal(true);
	}

	const initLoading = l1;
	const submitLoading = l2 || loadingPage;
	const dirtyKey = cycloptApiKey !== initialCycloptApiKey;
	const checkApiKeyDisabled = loadingPage || !dirtyKey;
	
	const dirty = (selectedProjectId !== initialCycloptProjectId) && selectedProjectId !== '';
	const submitDisabled = initLoading || submitLoading || !dirty;

	return (
		<div>
			<Grid container spacing={3}>
				<RemoveCycloptIntegration 
					open={openRemoveIntegrationModal}
					setOpen={setOpenRemoveIntegrationModal}
					projectId={projectId}
					showError={showError}
					showSuccess={showSuccess}
					updateProjectIntegrations={updateProjectIntegrations}
					refetchProjectIntegrations={refetchProjectIntegrations}
					setCycloptApiKey={setCycloptApiKey}
					getProjects={getProjects}
				/>
				<Grid item xs={12} md={10}>
					{initLoading ? (
						<Skeletons.TextFieldSkeleton />
					) : (
						<Box
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="center"
						>
							<Box flex="11">
								<TextField
									multiline
									minRows={3}
									fullWidth
									variant="outlined"
									value={cycloptApiKey}
									label="Cyclopt API Token"
									onKeyDown={event => {
										if (event.key === 'Enter') {
											submit();
										}
									}}
									onChange={(e) => setCycloptApiKey(e.target.value && e.target.value.trim())}
								/>
							</Box>
							<Box flex="1" sx={{ mb: 3 }}>
								<IconButton onClick={() => deleteIntegration()}>
									<DeleteIcon />
								</IconButton>
							</Box>
						</Box>
						
					)}
				</Grid>
				<Grid item xs={12} md={10}>
					<Box 
						display="flex"
						flexDirection="row"
						alignItems="center"
					>
						<Box width="160px">
							<Button
								fullWidth
								disabled={checkApiKeyDisabled}
								variant="contained"
								color="secondary"
								onClick={() => checkApiKey()}
							>
								Check API token
							</Button>
						</Box>
						{submitLoading && (
							<Box width="100px" sx={{ ml: 1 }}>
								<CircularProgress color="secondary" size={20} />
							</Box>
						)}
					</Box>
				</Grid>
				<Grid item xs={12} md={10}>
					{initLoading ? (
						<Skeletons.SelectFieldSkeleton />
					) : (
						<Box
							display="flex"
							flexDirection="row"
						>
							<Box flex="11">
								<FormControl
									fullWidth
									variant="outlined"
									margin="normal"
								>
									<InputLabel id='Role-input-label'>
										{'Cyclopt project'}
									</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										label="Cyclopt project"
										input={<OutlinedInput label="Cyclopt project"/>}
										MenuProps={{
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "left"
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "left"
											},
											getContentAnchorEl: null,
											style: {
												maxHeight: "500px"
											}
										}}
										
										value={selectedProjectId}
										onChange={(e) => {
											console.log(e.target.value);
											setSelectedProjectId(e.target.value);
										}}
									>
										<MenuItem key={`project_00`} value={''}>{"Select..."}</MenuItem>
										{
											projects.map((project, ind) => {
												return <MenuItem key={`project${ind}`} value={project._id}>{project.name}</MenuItem>
											})
										}
									</Select>
								</FormControl>
							</Box>
							<Box flex="1">
								{/* just to align the select properly */}
							</Box>
						</Box>
					)}
				</Grid>
			</Grid>
			<Box 
				display="flex"
				flexDirection="row"
				alignItems="center"
				sx={{mt: "16px"}}
			>
				<Grid item>
					<Box width="180px">
						<Button
							fullWidth
							disabled={submitDisabled}
							variant="contained"
							color="primary"
							onClick={() => submit()}
						>
							Save integration
						</Button>
					</Box>
				</Grid>
				{submitLoading && (
					<>
						<Box width="100px" sx={{ ml: 1 }}>
							<CircularProgress color="secondary" size={20} />
						</Box>
						<Box width="100%" sx={{ ml: 1, textAlign: "left" }}>
							<Typography>
								{progressComment}
							</Typography>
						</Box>
					</>
				)}
			</Box>
		</div>
	);
};

export default Integrations;
