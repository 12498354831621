import React, {useState, useRef} from 'react';
import {
	Grid,
	Button,
	Box,
	Typography,
	Popover,
	CircularProgress,
} from '@material-ui/core';
import {useMutation} from 'react-apollo';
import {useSnackbar} from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {path, propOr} from 'ramda';
import {USER_ROLES} from '../../../../../utilities/constants';
import {
	isSubmitButtonDisabled,
	buildInvitationURL,
} from '../../../../../utilities/tools';
import {
	CREATE_INVITED_USER,
	SEND_INVITATION_EMAIL,
} from '../../../../../graphql/mutations';
import {GET_INVITED_USERS} from '../../../../../graphql/queries';
import {useUi} from '../../../../hoc';
import {Select, TextField} from '../../../../common/form';
import useFormikBag from './use-formik-bag';

const Email = props => <TextField name="email" label="Email*" {...props} />;

const Role = props => (
	<Select {...props} name="role" label="Role*" options={USER_ROLES} />
);

const PopoverInvited = ({snackbar, open, data, anchorEl, handleClose}) => (
	<Popover
		open={open}
		anchorEl={anchorEl}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		PaperProps={{elevation: 16}}
		onClose={handleClose}
	>
		<Box py={1} px={2}>
			<Typography style={{fontWeight: 'bold'}}>
				User invitation link created.
			</Typography>
			<Box mt={1}>
				<Typography variant="body2">Grab the link below:</Typography>
			</Box>
			<Box textAlign="center">
				<CopyToClipboard
					text={buildInvitationURL(
						path(['createInvitedUser', 'invitationHash'], data),
					)}
					onCopy={() =>
						snackbar.enqueueSnackbar('Copied to clipboard!', {
							variant: 'default',
							anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
						})
					}
				>
					<Button color="primary">Copy Invitation Link</Button>
				</CopyToClipboard>
			</Box>
		</Box>
	</Popover>
);

const CreateInvitedUserForm = () => {
	const sendingEmailSnackKey = useRef(null);
	const [popoverOpen, setPopoverOpen] = useState(false);
	const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
	const ui = useUi();
	const snackbar = useSnackbar();
	const [sendInvitationEmail] = useMutation(SEND_INVITATION_EMAIL, {
		onCompleted: data => {
			snackbar.closeSnackbar(sendingEmailSnackKey.current);
			snackbar.enqueueSnackbar(
				`Invitation e-mail sent successfuly to ${path(
					['sendInvitationEmail', 'email'],
					data,
				)}`,
				{
					variant: 'success',
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
				},
			);
		},
		onError: error => {
			console.log(error);
			snackbar.closeSnackbar(sendingEmailSnackKey.current);
			snackbar.enqueueSnackbar(
				'Email could not be sent! Check the console logs for more info.',
				{
					variant: 'error',
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
					autoHideDuration: 5000,
				},
			);
		},
	});
	const [createInvitedUser, {data}] = useMutation(CREATE_INVITED_USER, {
		onCompleted: data => {
			sendingEmailSnackKey.current = snackbar.enqueueSnackbar(
				<Box display="flex" alignItems="center">
					<CircularProgress style={{color: '#fff'}} size="20px" />
					<Box ml={2}>Sending invitation e-mail to user...</Box>
				</Box>,
				{
					anchorOrigin: {vertical: 'top', horizontal: 'center'},
					persist: true,
				},
			);
			sendInvitationEmail({
				variables: {invitedUserId: path(['createInvitedUser', '_id'], data)},
			});
		},
		update: (cache, {data}) => {
			const newInvitedUser = data.createInvitedUser;
			try {
				const read = cache.readQuery({query: GET_INVITED_USERS});
				const invitedUsers = propOr([], 'getInvitedUsers', read);
				cache.writeQuery({
					query: GET_INVITED_USERS,
					data: {
						getInvitedUsers: [newInvitedUser, ...invitedUsers],
					},
				});
			} catch {
				cache.writeQuery({
					query: GET_INVITED_USERS,
					data: {
						getInvitedUsers: [newInvitedUser],
					},
				});
			}
		},
	});
	const formikProps = useFormikBag({
		ui,
		snackbar,
		createInvitedUser,
		setPopoverOpen,
	});

	return (
		<form onSubmit={formikProps.handleSubmit}>
			<Grid container spacing={1} justifyContent="center">
				<Grid item xs={12}>
					<Email {...formikProps} />
				</Grid>
				<Grid item xs={12}>
					<Role {...formikProps} />
				</Grid>
				<Grid item xs={12} md={6}>
					<Box mt={[1, 1, 3]}>
						<Button
							fullWidth
							id="create-invited-user-form-submit-button"
							type="submit"
							color="primary"
							variant="contained"
							disabled={isSubmitButtonDisabled(formikProps)}
							onClick={event => setPopoverAnchorEl(event.currentTarget)}
						>
							Submit
						</Button>
						<PopoverInvited
							snackbar={snackbar}
							data={data}
							open={popoverOpen}
							anchorEl={popoverAnchorEl}
							handleClose={() => {
								setPopoverAnchorEl(null);
								setPopoverOpen(false);
							}}
						/>
					</Box>
				</Grid>
			</Grid>
		</form>
	);
};

export default CreateInvitedUserForm;
