import React from 'react';
import {createFilterOptions, Autocomplete} from '@material-ui/lab';
import {TextField, CircularProgress} from '@material-ui/core';
import {getIn} from 'formik';
import {identity, last} from 'ramda';
import {getFormErrorsField} from '../../../../utilities/tools';

const filter = createFilterOptions();

const AutocompleteCreatable = ({
	name,
	label,
	options,
	optionsLoading = false,
	autoCompleteProps = {},
	textFieldProps = {inputProps: {}, InputProps: {}},
	formatInputValueBeforeAdding = identity,
	...formikProps
}) => {
	const {values, setFieldValue, errors, touched, handleBlur} = formikProps;
	const error = getFormErrorsField(name, errors, touched);
	const value = getIn(values, name) || [];

	const handleChange = (event, newValue = []) => {
		const allNewValues = newValue.map(val => {
			if (typeof val === 'string') {
				return formatInputValueBeforeAdding(val);
			}

			// Add "xxx" option created dynamically
			if (val.inputValue) {
				return formatInputValueBeforeAdding(val.inputValue);
			}

			return null;
		});

		setFieldValue(name, allNewValues);
	};

	return (
		<Autocomplete
			multiple
			selectOnFocus
			clearOnBlur
			handleHomeEndKeys
			freeSolo
			value={value}
			filterOptions={(options, params) => {
				const filtered = filter(options, params);

				// Suggest the creation of a new value
				const inputValue = formatInputValueBeforeAdding(params.inputValue);
				if (inputValue !== '' && !filtered.includes(inputValue)) {
					filtered.push({
						inputValue,
						title: `Add "${inputValue}"`,
					});
				}

				return filtered;
			}}
			options={options}
			getOptionLabel={option => {
				// Value selected with enter, right from the input
				if (typeof option === 'string') {
					return option;
				}

				// Add "xxx" option created dynamically
				if (option.inputValue) {
					return option.inputValue;
				}
			}}
			renderOption={option => option.title || option}
			renderInput={params => (
				<TextField
					{...params}
					label={label}
					{...textFieldProps}
					inputProps={{...params.inputProps, ...textFieldProps.inputProps}}
					// eslint-disable-next-line
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{optionsLoading ? (
									<CircularProgress color="inherit" size={20} />
								) : null}
								{params.InputProps.endAdornment}
							</>
						),
						...textFieldProps.InputProps,
					}}
					name={name}
					error={Boolean(error)}
					helperText={Array.isArray(error) ? last(error) : error}
					onBlur={handleBlur}
				/>
			)}
			onChange={handleChange}
			{...autoCompleteProps}
		/>
	);
};

export default AutocompleteCreatable;
