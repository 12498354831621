import gql from 'graphql-tag';

export default gql`
	mutation UpdateProject($projectId: String!, $data: UpdateProjectInput!) {
		updateProject(projectId: $projectId, data: $data) {
			_id
			name
			description
			tags
			users {
				_id
				username
				fullName
			}
			createdAt
			updatedAt
			integrations {
				name
				apiKey
			}
		}
	}
`;
