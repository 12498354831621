// Update apollo cache after project creation
// 1. Push project to GET_PROJECTS
// 2. Push project to GET_USERS to whoever is assigned (this also updates my GET_MY_USER)
import {
	prop,
	propOr,
	prepend,
	map,
	assoc,
	pipe,
	ifElse,
	includes,
	propSatisfies,
	__,
	identity,
	concat,
} from 'ramda';
import {
	GET_PROJECTS,
	GET_USERS,
	GET_PROJECTS_TAGS,
} from '../../../../../graphql/queries';

const addProjectToUsers = (project, users = []) => {
	const assignedUsersIds = pipe(propOr([], 'users'), map(prop('_id')))(project);

	return users.map(
		ifElse(
			propSatisfies(includes(__, assignedUsersIds), '_id'),
			obj =>
				assoc(
					'projects',
					pipe(propOr([], 'projects'), prepend(project))(obj),
				)(obj),
			identity,
		),
	);
};

export default (cache, {data}) => {
	const createdProject = data.createProject;
	// 1. Add to GET_PROJECTS
	try {
		const read = cache.readQuery({query: GET_PROJECTS});
		const projects = propOr([], 'getProjects', read);
		cache.writeQuery({
			query: GET_PROJECTS,
			data: {
				getProjects: prepend(createdProject, projects),
			},
		});
	} catch {}

	// 2. Add project to GET_USERS
	try {
		const readUsers = cache.readQuery({query: GET_USERS});
		const users = propOr([], 'getUsers', readUsers);
		cache.writeQuery({
			query: GET_USERS,
			data: {
				getUsers: addProjectToUsers(createdProject, users),
			},
		});
	} catch {}

	// 3. Add any newly created tags to GET_PROJECTS_TAGS
	try {
		const readTags = cache.readQuery({query: GET_PROJECTS_TAGS});
		const tags = propOr([], 'getProjectsTags', readTags);
		cache.writeQuery({
			query: GET_PROJECTS_TAGS,
			data: {
				getProjectsTags: pipe(propOr([], 'tags'), concat(tags), vals => [
					...new Set(vals),
				])(createdProject),
			},
		});
	} catch {}
};
