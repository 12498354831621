import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button } from '@material-ui/core';
import { useQuery, useMutation, useLazyQuery } from 'react-apollo';
import { NetworkStatus } from 'apollo-client';
import { useQueryParams, NumberParam, JsonParam } from 'use-query-params';
import {
	path,
	map,
	propOr,
	complement,
	propEq,
	pathEq,
	assoc,
	pipe,
	head,
	filter,
	// project,
} from 'ramda';
import { AppBar, MainWrapper, ErrorStates } from '../../../../common/ui';
import { ProjectIcon } from '../../../../../utilities/constants';
import {
	GET_PROJECTS,
	GET_MY_PROJECTS,
	GET_MY_USER,
	GET_USERS,
	EXPORT_PROJECTS,
	GET_INVITED_PROJECTS_OF_USER
} from '../../../../../graphql/queries';
import { DELETE_PROJECT } from '../../../../../graphql/mutations';
import ProjectsTableWrapper from './projects-table-wrapper';

import BecomeTeamLeadModal from '../../../../modals/become-team-lead-modal';
import CreateProjectModal from '../../../../modals/create-project-modal';
import ProjectInvitationModal from '../../../../modals/project-invitation-modal';


export const PROJECTS_PAGE_SIZE_DEFAULT = 25;
const PROJECTS_PAGE_SIZE_DEFAULT_PAGE_OPTIONS = [12, 25, 50];

const Title = ({isAdmin}) => (
	<Typography
		variant="h5"
		style={{display: 'flex', alignItems: 'center', fontWeight: 500}}
		color="secondary"
	>
		<ProjectIcon style={{marginRight: '8px'}} />{' '}
		{isAdmin ? 'Projects' : 'Assigned Projects'}
	</Typography>
);

const Subtitle = ({isAdmin}) => (
	<Typography variant="subtitle1" color="secondary">
		{isAdmin
			? 'Below are listed all the projects of the platform'
			: 'Below are listed all the projects that you have been assigned.'}
	</Typography>
);

const removeProjectFromUsers = deletedId =>
	map(obj =>
		assoc(
			'projects',
			pipe(
				propOr([], 'projects'),
				filter(complement(propEq('_id', deletedId))),
			)(obj),
		)(obj),
	);

const ProjectsScreen = () => {
	const {data: getMyUserData, refetch: refetchUser} = useQuery(GET_MY_USER, {
		// fetchPolicy: 'cache-only',
	});

	const [openTeamLeadModal, setOpenTeamLeadModal] = useState(false);
	const [openCreateProjectModal, setOpenCreateProjectModal] = useState(false);

	const [showCreateProjectButton, setShowCreateProjectButton] = useState(false);
	
	const userId = path(['getMyUser', '_id'], getMyUserData);
	const isAdmin = pathEq(['getMyUser', 'role'], 'Admin', getMyUserData);
	const isUser = pathEq(['getMyUser', 'role'], 'User', getMyUserData);
	const isTeamLead = pathEq(['getMyUser', 'role'], 'TeamLead', getMyUserData);
	const isResearcher = pathEq(['getMyUser', 'role'], 'Researcher', getMyUserData);
	// const [userProjects, setUserProjects] = useState([]);
	
	const [invitationResult, setInvitationResult] = useState(null);
	const [showInvitationModal, setShowInvitationModal] = useState(false);

	const [queryParams, setQueryParams] = useQueryParams({
		sortBy: JsonParam,  // although this is actually a JSON array
		filters: JsonParam, // although this is actually a JSON array
		pageIndex: NumberParam,
		pageSize: NumberParam,
	});
	const GET_QUERY = isAdmin ? GET_PROJECTS : GET_MY_PROJECTS;
	const {data, error, networkStatus, refetch: refetchProjects} = useQuery(GET_QUERY, {
		notifyOnNetworkStatusChange: true,
	});

	const {
		data: invitedProjects,
		// error: invitedProjectsError, 
		// networkStatus: invitedProjectsNetworkStatus, 
		refetch: refetchInvitedProjects
	} = useQuery(GET_INVITED_PROJECTS_OF_USER, {
		notifyOnNetworkStatusChange: true,
	});

	// refetching projects whenever the createProjectModal or the invitationModal is closed
	useEffect(() => {
		if (openCreateProjectModal === false && showInvitationModal === false) {
			refetchProjects();
			refetchInvitedProjects();
		}
		// eslint-disable-next-line
	}, [openCreateProjectModal, showInvitationModal])

	// updating userProjects when the Get_Projects query is run
	useEffect(()=> {
		console.log("Projects SCREEN User:", getMyUserData);
		if (!getMyUserData) {
			refetchUser();
		}
		// eslint-disable-next-line
	}, [getMyUserData])

	const [exportProjects] = useLazyQuery(EXPORT_PROJECTS, {
		fetchPolicy: 'network-only',
	});
	const [deleteProject, {loading}] = useMutation(DELETE_PROJECT, {
		update: (cache, {data}) => {
			const deletedId = path(['deleteProject', '_id'], data);
			const read = cache.readQuery({query: GET_PROJECTS});
			const projects = propOr([], 'getProjects', read);
			cache.writeQuery({
				query: GET_PROJECTS,
				data: {
					getProjects: projects.filter(complement(propEq('_id', deletedId))),
				},
			});
			// Remove project from my user
			try {
				const readMyUser = cache.readQuery({query: GET_MY_USER});
				const user = propOr({}, 'getMyUser', readMyUser);
				cache.writeQuery({
					query: GET_MY_USER,
					data: {
						getMyUser: pipe(removeProjectFromUsers(deletedId), head)([user]),
					},
				});
			} catch {}

			// Remove project from any users
			try {
				const readUsers = cache.readQuery({query: GET_USERS});
				const users = propOr([], 'getUsers', readUsers);
				cache.writeQuery({
					query: GET_USERS,
					data: {
						getUsers: removeProjectFromUsers(deletedId)(users),
					},
				});
			} catch {}
		},
	});

	const shouldShowTableSkeleton =
		networkStatus === NetworkStatus.loading ||
		networkStatus === NetworkStatus.refetch;

	useEffect(() => {
		
		if (data === undefined) return;
		// check if is creator of a project
		const myProjects = data.getMyProjects;
		let hasCreated = myProjects?.find(project => project.creatorId === userId) !== undefined;
		setShowCreateProjectButton(isResearcher || (!hasCreated && isTeamLead));
	}, [userId, data, isTeamLead, isResearcher]);

	return (
		<>
			<AppBar />
			<BecomeTeamLeadModal open={openTeamLeadModal} setOpen={setOpenTeamLeadModal} userData={getMyUserData?.getMyUser} />
			<CreateProjectModal open={openCreateProjectModal} setOpen={setOpenCreateProjectModal} userData={getMyUserData?.getMyUser} />
			<ProjectInvitationModal open={showInvitationModal} setOpen={setShowInvitationModal} data={invitationResult}/>
			<MainWrapper>
				<Container maxWidth="xl">
					{error && (
						<ErrorStates.TryAgain
							withGoBackButton
							error={error}
							refetch={refetchProjects}
						/>
					)}
					{!error && (
						<>
							<Title isAdmin={isAdmin} />
							{
								isUser?
									<Box mt={2} mb={2}>
										<Button
											variant='contained'
											onClick={() => {
												setOpenTeamLeadModal(true);
											}}
										>
											Become a Team Lead
										</Button> 
									</Box>
									
								: showCreateProjectButton?
									<Box mt={2} mb={2}>
										<Button
											variant='contained'
											onClick={() => {
												setOpenCreateProjectModal(true);
											}}
										>
											Create a new project
										</Button> 
									</Box>
								: ''
							}

							<Subtitle isAdmin={isAdmin} />
							<Box mt={2}>
								<ProjectsTableWrapper
									isAdmin={isAdmin}
									tableLoading={loading}
									data={data}
									invitedProjects={invitedProjects}
									setInvitationResult={setInvitationResult}
									setShowInvitationModal={setShowInvitationModal}
									deleteProject={deleteProject}
									shouldShowSkeleton={shouldShowTableSkeleton}
									queryParams={queryParams}
									setQueryParams={setQueryParams}
									pageOptions={PROJECTS_PAGE_SIZE_DEFAULT_PAGE_OPTIONS}
									defaultPageSize={PROJECTS_PAGE_SIZE_DEFAULT}
									exportProjects={exportProjects}
								/>
							</Box>
						</>
					)}
				</Container>
			</MainWrapper>
		</>
	);
};

export default ProjectsScreen;
